import { useEffect, useContext, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// types
import type { Dispatch } from 'redux';

// components
import Counter from 'components/Counter/Counter';
import Notification from '../ShoppingBasketNotification';
import { BUTTON_COLORS, BUTTON_BACKGROUND, Button } from 'components/Button/Button';
import { Translation } from 'components/Translation/Translation';

// actions
import { loadList, addProduct as addProductAction } from '../actions';

// utils
import { webshopEnabledSelector } from 'utils/selectors/globalsSelectors';
import { currentCatalogSelector } from 'utils/selectors/productCatalogSelectors';
import { webshopMaintenanceEnabledSelector } from 'utils/selectors/maintenanceSelectors';
import { SessionContext } from 'components/Session/SessionContext';

// styles
import styles from './addToBasket.module.scss';

interface AddToBasketProps {
  productId: string;
  variantId?: null | string;
  list: any[];
}

function AddToBasket({ productId, variantId = null, list = [] }: Readonly<AddToBasketProps>) {
  const dispatch: Dispatch<any> = useDispatch();
  const [inputValue, setInputValue] = useState(1);
  const [isAdding, setIsAdding] = useState(false);
  const [buyable, setBuyable] = useState(false);
  const [variant, setVariant] = useState<null | number>(null);
  const timeout = useRef<Timeout | undefined>();
  const [showNotification, setShowNotification] = useState(false);
  const {
    state: { session, ciam, sessionIsLoading },
  } = useContext(SessionContext);

  const webshopEnabled = useSelector(webshopEnabledSelector);
  const webshopDowntime = useSelector(webshopMaintenanceEnabledSelector);
  const lang = useSelector(currentCatalogSelector);

  useEffect(() => {
    if (session && lang && !sessionIsLoading) {
      dispatch(loadList(session, lang, ciam));
    }

    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [session, ciam, lang, dispatch, sessionIsLoading]);

  useEffect(() => {
    if (list && list.length > 0 && variantId) {
      const selectedVariant = list?.find((article: any) => article.key === variantId);
      setVariant(parseInt(selectedVariant.articleId, 10));
      setBuyable(selectedVariant.state.buyable);
    }
  }, [list, productId, variantId]);

  if (!list || !webshopEnabled || webshopDowntime) return null;

  const addToCart = () => {
    setShowNotification(true);
    setIsAdding(true);
    const product = {
      productId,
      variantId: variant,
      quantity: inputValue,
    };
    dispatch(addProductAction(product as any, session as any, ciam));

    timeout.current = setTimeout(() => {
      setIsAdding(false);
    }, 2000);

    timeout.current = setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  };

  const decreaseItem = () => setInputValue(Math.max(1, inputValue - 1));
  const increaseItem = () => setInputValue(Math.min(99, inputValue + 1));
  const setItem = (e) =>
    setInputValue(Math.max(1, Math.min(99, parseInt(e.target.value, 10) || 1)));
  const hideNotification = () => setShowNotification(false);

  if (!buyable) return null;

  return (
    <div className={styles.addToBasketWrapper}>
      <Notification show={showNotification} handleClose={hideNotification} />
      <Counter
        className={styles.articleCounter}
        decreaseItem={decreaseItem}
        increaseItem={increaseItem}
        countInput={inputValue}
        setItem={setItem}
      />
      <Button
        symbol={isAdding ? 'check' : 'shoppingcart'}
        color={BUTTON_COLORS.WHITE}
        background={isAdding ? BUTTON_BACKGROUND.SUCCESS : BUTTON_BACKGROUND.PRIMARY}
        className={styles.addToBasketButton}
        onClick={isAdding ? () => {} : addToCart}
      >
        <Translation
          id={
            isAdding ? 'web20_webshop_added_to_shopping_cart' : 'web20_webshop_add_to_shopping_cart'
          }
        />
      </Button>
    </div>
  );
}

export default AddToBasket;

import React from 'react';

function LockedIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 16" {...props}>
      <path d="M11 6V3.5a3.5 3.5 0 00-7 0V6H1v10h13V6zM5 3.5a2.5 2.5 0 015 0V6H5zM13 15H2V7h11z" />
      <path d="M7 11.91V13h1v-1.09a1.5 1.5 0 10-1 0z"></path>
    </svg>
  );
}

export default LockedIcon;

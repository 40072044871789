// types
import type { ThemeNameProps } from 'themes/theme.types';
import type { SliderTextColors } from 'components/ContentElements/slider';

export function getContentBoxColor({
  color,
  themeName,
}: {
  color: SliderTextColors;
  themeName: ThemeNameProps;
}): SliderTextColors {
  const themesWithInvertedContentBoxColor: ThemeNameProps[] = [
    'NORD',
    'KOLO',
    'TWYFORD',
    'DEFAULT',
  ];

  if (!themesWithInvertedContentBoxColor.includes(themeName)) return color;

  const _color = color === 'white' ? 'black' : 'white';

  return _color;
}

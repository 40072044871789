import QS from 'qs';
import { useCallback, useState } from 'react';

// utils
import { isBrowser } from 'utils/is-browser';

const getParamsFromWindowLocation = <T>() =>
  isBrowser ? (QS.parse(window.location.search, { ignoreQueryPrefix: true }) as Partial<T>) : {};

export function useParams<T = Record<string, string>>(): [
  Partial<T>,
  (params: Partial<T>) => void,
] {
  const [state, setState] = useState(getParamsFromWindowLocation());
  const updateParams = useCallback((newParams: Partial<T>) => {
    const currentParams = getParamsFromWindowLocation();
    const params =
      typeof newParams === 'function'
        ? newParams(currentParams)
        : { ...currentParams, ...newParams };
    setState(params);
    if (isBrowser) {
      window.history.replaceState(
        {},
        '',
        window.location.pathname +
          QS.stringify(params, { addQueryPrefix: true }) +
          window.location.hash,
      );
    }
  }, []);

  return [state, updateParams];
}

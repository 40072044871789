// styles
import styles from './intro-text.module.scss';

// types
import type { GetClassNameProps } from './types';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';

function isLastSection(contentLength, contentIndex) {
  return contentLength && contentIndex && contentLength - 1 === contentIndex;
}

export function getClassName({
  hasHeadline,
  contentLength,
  contentIndex,
  spacing,
  backgroundColor,
  isNordics,
  className,
}: Readonly<GetClassNameProps>) {
  const topSpace = `top-${spacing?.top}`;
  const bottomSpace = `bottom-${spacing?.bottom}`;

  return classNameBuilder(
    styles.introTextWrapper,
    hasHeadline && styles.hasHeadline,
    isLastSection(contentLength, contentIndex) && styles.lastSection,
    isNordics && styles.nordics,
    className,
    styles[topSpace],
    styles[bottomSpace],
    backgroundColor && styles[`${backgroundColor}Background`],
    contentIndex === 0 && styles.firstSection,
  );
}

import styled from 'styled-components';

export const StyledDetails = styled.div`
  grid-area: details;

  > p:first-of-type {
    margin-bottom: 0.5rem;
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    column-gap: 0.25rem;
    color: var(--gb-brand-primary-light);

    @media print {
      flex-direction: row !important;
    }

    @media ${({ theme }) => theme.device.medium} {
      flex-direction: row;
    }

    > div {
      display: flex;
      flex-direction: column;

      .links {
        display: flex;
        flex-direction: row;
        align-items: center;

        a > span {
          &.printable {
            display: none;
          }

          @media print {
            display: none;

            &.printable {
              display: initial;
            }
          }
        }
      }

      @media ${({ theme }) => theme.device.medium} {
        flex-direction: row;

        hr {
          margin-left: 1rem;
        }
      }

      & a {
        color: currentColor;
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 500;
      }

      svg {
        fill: currentColor;
      }

      :last-of-type {
        hr {
          display: none;
        }
      }
    }
  }
`;

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import slugify from 'slugify';

// types
import { IEntry } from '../../locator.types';

// utils
import { globalsSelector } from 'utils/selectors/globalsSelectors';
import { useIsMobile } from 'components/App/SizeProvider';

interface DppImage {
  identifier: string;
  image: string;
  pictureAltText: string;
  previewId: string;
  title: string;
  type: 'locator_ddp_mapping';
  url: string;
}

interface ISeriesTile {
  name: string;
  url?: string;
  image?: string;
  title: string;
}

const dppImageMapSelector = (state: AppState) =>
  globalsSelector(state)?.locator?.dealerPage?.dealerDetailPage ?? ([] as DppImage[]);

/**
 * if mapping not working corretly you can fix it here :)
 */
const isDppImageByName = (name: string) => {
  const opts = { lower: true, replacement: '', trim: true };
  const identifier = slugify(name, opts);

  return (other: { identifier: string }) => {
    if (!other.identifier) return false;
    return identifier === slugify(other.identifier, opts);
  };
};

const reduceSerieNames = (acc: Set<string>, { series }: { series: string[] }): Set<string> => {
  series.forEach((name) => acc.add(name));
  return acc;
};

const mapDppImageToSeries =
  (dppImageMap: DppImage[]) =>
  (name: string): ISeriesTile | null => {
    const image = dppImageMap.find(isDppImageByName(name));
    if (!image) {
      return null;
    }

    return {
      name: image.title?.trim() || name,
      image: image?.image || '/images/dummy-image-1x1.jpg',
      url: image?.url,
      title: image.pictureAltText?.trim() || image.title?.trim() || name,
    };
  };

export const useTiles = (
  products: IEntry['products'],
): {
  items: ISeriesTile[];
  itemsCount: number;
  hasMore: boolean;
  showAll: () => void;
} => {
  const [itemsToShow, setItemsToShow] = useState(3);
  const dppImageMap = useSelector(dppImageMapSelector);
  const isMobile = useIsMobile();

  const allItems = useMemo(
    () =>
      Array.from(products.reduce(reduceSerieNames, new Set<string>()))
        .map(mapDppImageToSeries(dppImageMap))
        .filter(Boolean),
    [products, dppImageMap],
  );

  const items = useMemo(() => {
    if (!isMobile) {
      return allItems;
    }

    return allItems.slice(0, itemsToShow);
  }, [isMobile, itemsToShow, allItems]);

  return {
    items,
    itemsCount: allItems.length,
    hasMore: isMobile && itemsToShow < allItems.length,
    showAll: () => {
      setItemsToShow(allItems.length);
    },
  };
};

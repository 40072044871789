import React from 'react';
import { Dropdown, Row, Col, Typo } from '@geberit/gdds';

// styles
import styles from './downloadcenter.module.scss';

// components
import DownloadcenterMediaTile from './dlc-media-tile';

// utils
import { categoryHeadingSize } from './utils/sizes';
import { useTranslationFunction } from '../../../../utils/hooks/use-translations';

interface DownloadcenterMediaSectionProps {
  items: any[];
  updateDownloads: (item: any) => void;
  categoryName: string;
  downloads: any[];
  getFilter4Items: () => any[];
  handleChangeFilter4: (e: any) => void;
  filter4Value: string;
}

function DownloadcenterMediaSection({
  items,
  updateDownloads,
  categoryName,
  downloads,
  getFilter4Items,
  handleChangeFilter4,
  filter4Value,
}: Readonly<DownloadcenterMediaSectionProps>) {
  const translate = useTranslationFunction();

  return (
    <>
      {items.length > 0 && (
        <div className={styles.categoryContainer} id="media">
          <Typo
            tag="div"
            size={categoryHeadingSize}
            fontWeight={600}
            className={styles.categoryHeadline}
          >
            {categoryName}
          </Typo>
          <div className={styles.categoryHeadlineText}>
            <span>{`${translate('group_downloadcenter_more-infos-on-yt')} `}</span>
            <a href="https://www.youtube.com/geberit" target="__blank" className={styles.textLink}>
              {translate('group_downloadcenter_yt_channel')}
            </a>
            <span>.</span>
          </div>
          <Row>
            <Col size={[4, 8, 3]} className={styles.filterDropdown}>
              <Dropdown
                name="dlcMedia"
                items={getFilter4Items()}
                label={translate('group_downloadcenter_filter4')}
                outlined
                onChange={handleChangeFilter4}
                values={{ dlcMedia: filter4Value }}
                placeholder={filter4Value}
              />
            </Col>
          </Row>
          <Row>
            {items.length > 0 &&
              items.map((item) => (
                <Col size={[4, 4, 4]} key={item.id}>
                  <DownloadcenterMediaTile
                    item={item}
                    setDownloadCounter={() => updateDownloads(item)}
                    downloads={downloads}
                  />
                </Col>
              ))}
          </Row>
        </div>
      )}
    </>
  );
}

export default DownloadcenterMediaSection;

'use client';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';

// styles
import styles from './layout.module.scss';

// components
import CampusLayout from './CampusLayout';
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import CourseList from 'components/Campus/Course/CourseList';
import { FilterWrapper } from 'components/Campus/Filter/FilterWrapper';
import { ContactModule } from 'components/Campus/Contact/ContactModule';
import { Translation } from 'components/Translation/Translation';
import { Loader } from 'components/Loader/Loader';

// utils
import { useFilterDashboard } from 'utils/hooks/useCampus';
import { campusReferenceUrlSelector } from 'utils/selectors/globalsSelectors';
import { useCampusToken } from 'utils/hooks/useCiam';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { classNameBuilder } from 'utils/classNameBuilder';

export function CampusHome() {
  const [filters, setFilters] = useState({});

  const router = useRouter();
  const { loading: ciamLoading, campusToken: ciamToken } = useCampusToken();
  const translate = useTranslationFunction();
  const filterConfig = useFilterDashboard(setFilters);
  const landingUrl = useSelector(campusReferenceUrlSelector);

  useEffect(() => {
    if (!ciamLoading && !ciamToken) {
      router.replace(landingUrl);
    }
  }, [ciamLoading, ciamToken, landingUrl, router]);

  if (ciamLoading) {
    return (
      <main className={styles.campusMain}>
        <div className={classNameBuilder('grid-container', styles.loaderWrapper)}>
          <Loader />
        </div>
      </main>
    );
  }

  return (
    <CampusLayout>
      <PageHeadline
        Format={TitleFormats.h1}
        title={translate('campus_headline_dashboard')}
        subtitle={translate('campus_subline_dashboard')}
        pageHeadline
      />
      <div className={`grid-container ${styles.introText}`}>
        <p>
          <Translation id="campus_text_dashboard" />
        </p>
      </div>
      <FilterWrapper filterConfig={filterConfig} />
      <CourseList filters={filters} filterReset={filterConfig.setFilterReset} />
      <ContactModule gdds />
    </CampusLayout>
  );
}

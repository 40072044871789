// components
import SocialShareView from './share-view';
import { Translation } from 'components/Translation/Translation';

// utils
import { isEmpty } from 'utils/is-empty';

type GDDSSocialShareProps = {
  types: string[] | null;
  hasCopyLink: boolean;
  metaData: MetaData;
};

function GDDSSocialShare({
  types = null,
  hasCopyLink = false,
  ...restProps
}: Readonly<GDDSSocialShareProps>) {
  // only used for geberit.com
  if (isEmpty(types)) {
    return null;
  }

  return (
    <SocialShareView
      {...restProps}
      hasCopyLink={hasCopyLink}
      types={types}
      headline={<Translation id="group_insights_detail_share" />}
    />
  );
}

export default GDDSSocialShare;

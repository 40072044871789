import { useIsDesktop } from 'components/App/SizeProvider';
import { PropsWithChildren } from 'react';
import { DesktopProps, DesktopSelectorProps } from './desktop.types';

function Desktop({ children }: PropsWithChildren<DesktopProps & DesktopSelectorProps>) {
  const isDesktop = useIsDesktop();

  if (isDesktop) {
    return children;
  }

  return null;
}

export default Desktop;

// components
import VideocenterChannel from './VideocenterChannel';
import GDDSVideocenterChannel, {
  VideocenterChannelProps,
} from '../ContentElementsGdds/video-channel/videocenter-channel';

// utils
import { useNord } from '../../utils/hooks/use-nord';

function VideocenterChannelWrapper(props: Readonly<VideocenterChannelProps>) {
  const isNord = useNord();

  if (isNord) {
    return <GDDSVideocenterChannel {...props} />;
  }

  return <VideocenterChannel {...props} />;
}

export default VideocenterChannelWrapper;

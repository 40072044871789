import { useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useQuery } from 'react-query';
import debounce from 'lodash.debounce';
import axios from 'axios';
import { Search } from '@geberit/gdds';

// utils
import getEndpoint from 'utils/endpoints';
import { globalsSearchSegmentSelector } from 'utils/selectors/globalsSelectors';
import { useTranslationFunction } from 'utils/hooks/use-translations';

export default function NordicsSearchBar({ handleSearchSubmit, initialValue }) {
  const [val, setVal] = useState(initialValue);
  const state = useStore();
  const translate = useTranslationFunction();
  const segment = useSelector(globalsSearchSegmentSelector);

  const { data } = useQuery({
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!val,
    queryKey: ['dlcSuggestions', { val }],
    queryFn: () =>
      axios.get(getEndpoint('suggest', state), {
        params: {
          segment,
          q: val,
        },
      }),
  });

  const fetchSuggests = debounce((query) => {
    setVal(query);
    if (query === '') {
      handleSearchSubmit('');
    }
  }, 500);

  return (
    <Search
      val={val}
      onChange={fetchSuggests}
      suggestions={val && data?.data?.suggestions.map((entry) => ({ content: entry }))}
      placeholder={translate('web20_search_placeholder')}
      onSubmit={handleSearchSubmit}
    />
  );
}

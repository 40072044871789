import React, { useState, useEffect, useRef } from 'react';
import isEmpty from 'lodash.isempty';

// components
import ProductDetailFiltersSelectView from './ProductDetailFiltersSelectView';

// utils
import { findCurrAttr } from './ProductDetailFilters';
import { DIMENSION_SEPARATOR } from './constants';

interface ProductDetailFiltersSelectProps {
  defaultValue: string | null;
  index: number;
  articlesList: any[];
  filteredAttributes: any[];
  curAvailableDimensions?: any[] | null;
  currentIds: any[];
  attr: any;
  setCurrentIds: (ids: any[]) => void;
  setSelectedFilter: (filter: any[]) => void;
  selectedFilter?: any[] | null;
}

function ProductDetailFiltersSelect({
  attr,
  articlesList,
  index,
  setCurrentIds,
  setSelectedFilter,
  currentIds,
  filteredAttributes,
  selectedFilter = null,
  curAvailableDimensions = null,
  defaultValue = null,
  ...props
}: Readonly<ProductDetailFiltersSelectProps>) {
  const availableOptions = useRef<string[]>([]);
  const [currentValue, setCurrentValue] = useState('');

  const disabled = !selectedFilter.some((filter) => index - 1 === filter.index) && index !== 0;
  useEffect(() => {
    if ((disabled && currentValue) || (!selectedFilter[index] && currentValue)) {
      // reset value after change value of a parent filter
      setCurrentValue('');
      availableOptions.current = [];
    }
  }, [selectedFilter.length]);

  /**
   * Filter current left article ids
   *
   * @param {String} curValue
   * @param {Object} specificAttr
   */
  const getIds = (curValue, specificAttr) => {
    let ids = [];
    const attributeToCheck = specificAttr || attr;

    if (curValue?.includes(DIMENSION_SEPARATOR)) {
      // specific id search handling for dimensionfilter
      const dimensions = curValue.split(DIMENSION_SEPARATOR);

      ids = articlesList
        .filter((article) => {
          let hasDimension = true;

          curAvailableDimensions.forEach((dimensionKey, i) => {
            const curDimension = findCurrAttr(article, dimensionKey);
            hasDimension = hasDimension && curDimension.value === dimensions[i];
          });
          return hasDimension;
        })
        .map((article) => article.key);
    } else {
      ids = articlesList
        .filter((article) => {
          const currArticle = findCurrAttr(article, attributeToCheck);

          return currArticle?.value === curValue;
        })
        .map((article) => article.key);
    }

    return ids;
  };

  /**
   * Check if current option is disabled or not depending on available article ids
   *
   * @param {String} option
   */
  const checkOptionStatus = (option) => {
    const ids = getIds(option);
    const match = currentIds.some((id) => ids.includes(id));
    const isSelectable = availableOptions.current.includes(option);

    if (match && !isSelectable) {
      availableOptions.current = [...availableOptions.current, option];
    }

    return !isSelectable && !match;
  };

  /**
   * Update current value of select and available artIds
   *
   * @param {SyntheticEvent} param0
   */
  const onSelectChange = ({ currentTarget: { value } }) => {
    const filterDisabled =
      selectedFilter.some((filter) => filter.index === index) && value === defaultValue;
    const curValueChange =
      !isEmpty(selectedFilter) &&
      selectedFilter[index] &&
      selectedFilter[index].value !== value &&
      value !== defaultValue;

    if (filterDisabled || curValueChange) {
      let ids = [];

      const newFilter = selectedFilter.filter((filter) => {
        if (curValueChange && filter.index === index) {
          filter.value = value;
          return filter.index <= index;
        }
        return filter.index < index;
      });

      if (isEmpty(newFilter)) {
        ids = articlesList.map((article) => article.key);
      } else {
        newFilter.forEach((filter) => {
          const newIds = getIds(filter.value, filteredAttributes[filter.index]);
          ids = isEmpty(ids) ? [...ids, ...newIds] : ids.filter((id) => newIds.includes(id));
        });
      }

      setSelectedFilter(newFilter);
      setCurrentIds([...new Set(ids)]);
    } else {
      const ids = getIds(value);

      // save the new available ids after selecting an option
      const newIds = currentIds.filter((id) => ids.includes(id));

      setCurrentIds(newIds);
      setSelectedFilter([...selectedFilter, { index, value }]);
    }

    setCurrentValue(value);
  };

  return (
    <ProductDetailFiltersSelectView
      disabled={disabled}
      currentValue={currentValue}
      checkOptionStatus={checkOptionStatus}
      onSelectChange={onSelectChange}
      defaultValue={defaultValue}
      {...props}
    />
  );
}

export default ProductDetailFiltersSelect;

import React from 'react';
import { useFocusVisible } from '../../../utils/focus-visible';
import { classNameBuilder } from '../../../utils/classNameBuilder';
import { Tag } from '../PressPortal/types/mynewsdesk.types';
import styles from './tags.module.scss';

function TagElement({
  name,
  onClick,
}: Tag & { onClick: (e: React.MouseEvent<HTMLButtonElement>) => void }) {
  const { focusVisible, onFocus, onBlur } = useFocusVisible();

  return (
    <div className={classNameBuilder(styles.tag, focusVisible && styles.onFocus)}>
      <button className={styles.tagBody} onClick={onClick} onFocus={onFocus} onBlur={onBlur}>
        <span>{name}</span>
      </button>
    </div>
  );
}

export default TagElement;

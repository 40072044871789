import { useSelector } from 'react-redux';

// config & constants
import SOCIAL_SHARE_DATA from './constants';

import styles from './social-share.module.scss';

// components
import SocialShareIcon from './SocialShareIcon';
import { SearchIndexOff } from '../SearchIndex/SearchIndexOff';

// utils
import { baseURLSelector } from 'utils/selectors/globalsSelectors';
import { classNameBuilder } from 'utils/classNameBuilder';

interface SocialShareViewProps {
  contentType: 'page' | 'video';
  metaData: Record<string, any>;
  hashtags: string[];
  className?: string;
  headline?: React.ReactNode;
  useInContentPage?: boolean;
}

export default function SocialShareView({
  hashtags,
  metaData,
  headline,
  contentType,
  useInContentPage,
}: Readonly<SocialShareViewProps>) {
  const baseURL = useSelector(baseURLSelector);

  return (
    <SearchIndexOff>
      <div
        className={classNameBuilder(
          useInContentPage && styles.use_in_content_page,
          styles.social_share,
        )}
      >
        {headline && <span className={styles.social_share__name}>{headline}</span>}
        <div className={styles.social_share__icons}>
          {SOCIAL_SHARE_DATA.map((item) => (
            <SocialShareIcon
              key={`${item.symbol}-icon`}
              baseUrl={item.baseUrl}
              url={metaData.url || `${baseURL}${metaData.og_url}`}
              symbol={item.symbol}
              color={item.color}
              hashtags={hashtags}
              title={metaData.title}
              image={metaData.image}
              contentType={contentType}
            />
          ))}
        </div>
      </div>
    </SearchIndexOff>
  );
}

import { useSelector } from 'react-redux';

// types
import type { ChangeEvent } from 'react';

// utils
import { translationByKeySelector } from 'utils/selectors/translationSelectors';
import { decodingContent } from 'utils/decodingContent';

interface Option {
  id: string;
  value: string;
  count?: number;
}

interface FilterOptionProps {
  checked?: boolean;
  index: number;
  filterKey: string;
  option: Option;
  isCheckbox?: boolean;
  handleFilterChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isAllFilter: boolean;
}

const FilterOption = ({
  checked = false,
  index,
  filterKey,
  option: { id, value, count },
  isCheckbox = false,
  handleFilterChange,
  isAllFilter,
}: Readonly<FilterOptionProps>) => {
  const translationKey = useSelector((state: any) => translationByKeySelector(state, value));

  const getInputType = isCheckbox ? 'checkbox' : 'radio';
  const getInputName = (i: number) =>
    isCheckbox ? `${getInputType}_${filterKey}_${i}` : `${getInputType}_${filterKey}`;
  const getInputId = (key: string, i: number) => key || getInputName(i);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => handleFilterChange(e);

  return (
    <li>
      <label htmlFor={getInputId(id, index)}>
        <input
          id={getInputId(id, index)}
          type={getInputType}
          name={getInputName(index)}
          value={value.includes('web20') ? translationKey : value}
          checked={checked}
          onChange={handleChange}
          data-filterkey={filterKey}
          data-allfilter={isAllFilter}
        />
        <span>
          {value.includes('web20') ? translationKey : decodingContent(value)}{' '}
          {count && `(${count})`}
        </span>
      </label>
    </li>
  );
};

export default FilterOption;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// styles
import styles from './summaryContainer.module.scss';

// components
import { Translation } from 'components/Translation/Translation';
import { Loader } from 'components/Loader/Loader';
import LinkWrapper from '../../Components/LinkWrapper/LinkWrapper';
import ErrorMessageBox from '../../Components/ErrorMessageBox/ErrorMessageBox';

// utils
import {
  orderDataSelector,
  isOrderDataFetchingSelector,
  orderDataErrorSelector,
} from '../../selectors';
import { fetchOrderDataAction } from '../../actions';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';
import { SummaryData } from './summary-data';

export default function SummaryContainer() {
  const dispatch = useDispatch();
  const translate = useTranslationFunction();
  const orderData = useSelector(orderDataSelector);
  const isOrderDataFetching = useSelector(isOrderDataFetchingSelector);
  const orderError = useSelector(orderDataErrorSelector);

  const urlParams = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : '',
  );
  const orderId = urlParams.get('orderId');
  const locale = urlParams.get('locale');

  useEffect(() => {
    if (orderId && locale) dispatch(fetchOrderDataAction(locale, orderId));
  }, [dispatch, locale, orderId]);

  if (isOrderDataFetching || (isEmpty(orderData) && !orderError)) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader size="medium" />
      </div>
    );
  }

  return (
    <div>
      <h1 className={styles.h1}>
        <span>
          <Translation id="web20_checkout_summary_heading_part1" />
        </span>{' '}
        <Translation id="web20_checkout_summary_heading_part2" />
      </h1>
      <p>
        <Translation id="web20_checkout_summary_description_part1" />{' '}
        <LinkWrapper translationLink={translate('web20_checkout_link_customer_service')} />
        {'. '}
        <Translation id="web20_checkout_summary_description_part3" />
      </p>
      <p>
        <Translation id="web20_checkout_summary_questions_part1" />{' '}
        <LinkWrapper
          translationLink={translate('web20_checkout_link_frequently_asked_questions')}
        />{' '}
        <Translation id="web20_checkout_summary_questions_part3" />
      </p>
      {orderError || !orderId || !locale ? (
        <ErrorMessageBox>
          {'730 - '}
          <Translation id="web20_checkout_summary_error" />{' '}
          <LinkWrapper translationLink={translate('web20_checkout_link_customer_service')} />
        </ErrorMessageBox>
      ) : (
        <SummaryData orderId={orderId} orderData={orderData} />
      )}
    </div>
  );
}

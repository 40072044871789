import { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useStore } from 'react-redux';

// types
import { FetchPressroomResult, PressroomRequestConfig } from '../types/press-portal.types';

// constants
import getEndpoint from '../../../../utils/endpoints';
import { useApiKey } from './use-api-key';

function useFetchPressroomInfo<T>({ locale }: PressroomRequestConfig): FetchPressroomResult<T> {
  const [loading, setLoading] = useState<boolean>(false);
  const [pressroom, setPressroom] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const state = useStore();
  const apiKey = useApiKey();

  useEffect(() => {
    const fetchMaterial = async () => {
      setLoading(true);
      try {
        const url = `${getEndpoint('newsDesk', state)}/pressroom_info?locale=${locale}`;
        const response = await axios.get<{ pressroom: T }>(url, {
          params: { pressroom: locale },
          headers: { 'x-news-desk-key': apiKey },
        });
        const data = response?.data;
        setError(null);
        setPressroom(data.pressroom);
      } catch (err: unknown) {
        const error = err as Error | AxiosError;
        if (axios.isAxiosError(error)) {
          setError(`api error: ${error.message}`);
        } else {
          setError(`internal error: ${error.message}`);
        }
      } finally {
        setLoading(false);
      }
    };

    if (apiKey) {
      fetchMaterial();
    }
  }, [locale, apiKey]);

  return { loading, error, pressroom };
}

export { useFetchPressroomInfo };

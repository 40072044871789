'use client';

// components
import DownloadCenterContainer, {
  type DownloadCenterContainerProps,
} from '../components/country/downloadcenter-container';
import Footer from 'components/Footer/Footer';

export interface DownloadCenterCountryProps extends DownloadCenterContainerProps {
  metaData: MetaData;
  initialData: { pages: any[] };
}

export default function DownloadCenterCountry({
  page,
  contentAreas,
  initialData,
}: Readonly<DownloadCenterCountryProps>) {
  return (
    <>
      <main>
        <DownloadCenterContainer
          pageFromServer={page}
          contentAreas={contentAreas}
          initialData={initialData}
        />
      </main>
      <Footer />
    </>
  );
}

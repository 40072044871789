import { useEffect, useState } from 'react';
import { Col, Container, Row, Typo } from '@geberit/gdds';

// components
import PressTiles from './press-tiles/press-tiles';
import { NavigationTabs } from './navigation-tabs/navigation-tabs';
import { NoResults } from './no-result/no-result';
import ContactTiles from './contact-tiles/contact-tiles';
import MediaTiles from './media-tiles/media-tiles';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';

// styles
import styles from './press-portal.module.scss';

// types
import { Material, Media, Pressroom } from './types/mynewsdesk.types';
import { Tabs } from './types/press-portal.types';

// utils
import { MATERIAL_TYPES } from './constants';
import { useFetchPressroomInfo } from './hooks/usefetchPressroom';
import { useFetchMaterialsByTag } from './hooks/use-fetch-materials-by-tag';
import { buildSize, gridSizes } from 'utils/gridSize';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useTranslationByKey } from 'utils/hooks/use-translations';

const whereIn =
  (materialsTypes) =>
  (ee: Material | Media): boolean =>
    materialsTypes.some((e) => e.value === ee.type_of_media);

interface PressPortalTagOverviewPageProps {
  breadcrumbs: Partial<Breadcrumb>[];
  tag?: string;
  isGdds?: boolean;
}

export default function PressPortalTagOverviewPage({
  breadcrumbs,
  tag,
  isGdds,
}: Readonly<PressPortalTagOverviewPageProps>) {
  const [selectedTab, setSelectedTab] = useState<Tabs | undefined>();
  const language = useCurrentLanguage();
  const pressTopicsTranslation = useTranslationByKey('press_topics');
  const { pressroom } = useFetchPressroomInfo<Pressroom>({
    locale: language,
  });

  const { materials, loading } = useFetchMaterialsByTag({
    tag,
    locale: language?.toLowerCase(),
  });

  const materialList = materials?.filter(whereIn(MATERIAL_TYPES[0])) as Material[];
  const mediaList = materials?.filter(whereIn(MATERIAL_TYPES[1])) as Media[];

  function renderMaterials() {
    if (selectedTab === Tabs.News && (!materialList || materialList.length === 0)) {
      return null;
    }

    if (selectedTab === Tabs.MediaLibrary && (!mediaList || mediaList.length === 0)) {
      return null;
    }

    if (selectedTab === Tabs.News) {
      return <PressTiles withHeroTile={false} language={language} materials={materialList} />;
    }

    if (selectedTab === Tabs.MediaLibrary) {
      return <MediaTiles language={language} mediaList={mediaList} />;
    }

    return null;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [materials?.length]);

  return (
    <>
      <Breadcrumb
        items={breadcrumbs.concat({
          breadcrumbDisabled: true,
          label: `${pressTopicsTranslation} ${tag?.toUpperCase()}`,
        })}
      />
      <div className={styles.pressPortal}>
        <Container>
          <Row className={styles.headline}>
            <Col size={[0, 0, 2]}></Col>
            <Col size={[4, 8, 8]}>
              <Typo variant="h1" {...(!isGdds && { isUppercase: true, fontWeight: 700 })}>
                <span>{pressTopicsTranslation}</span>
                <span>{tag}</span>
              </Typo>
            </Col>
            <Col size={[0, 0, 2]}></Col>
          </Row>
        </Container>

        <div className={styles.feedSection}>
          <NavigationTabs
            count={{ materials: materialList?.length, media: mediaList?.length }}
            onChange={setSelectedTab}
          />
          <Container
            className={styles.tilesWrapper}
            maxContentWidth={isGdds ? buildSize(gridSizes.gddsFullGrid) : '80rem'}
          >
            {renderMaterials() ?? <NoResults loading={loading} />}
          </Container>
        </div>
        <Container {...(!isGdds && { maxContentWidth: '80rem' })}>
          {pressroom?.presscontacts && (
            <ContactTiles language={language} contacts={pressroom?.presscontacts} />
          )}
        </Container>
      </div>
    </>
  );
}

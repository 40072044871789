import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

// components
import ProductDetailFiltersView from './ProductDetailFiltersView';
import AddToBasket from 'components/ShoppingBasket/AddToBasket/AddToBasket';
import ProductPrice from './ProductPrice';
import { Translation } from 'components/Translation/Translation';

// utils
import { pdpFiltersSelector, webshopEnabledSelector } from 'utils/selectors/globalsSelectors';
import {
  NO_FILTER_KEYS,
  IS_DIMENSION_FIELD_KEY,
  DIMENSIONS_TRANSLATIONS,
  DIMENSION_SEPARATOR,
} from './constants';
import { translationByKeySelector } from 'utils/selectors/translationSelectors';
import { webshopMaintenanceEnabledSelector } from 'utils/selectors/maintenanceSelectors';

/**
 * Find matching attribute in arcticlesList to attributesList key
 *
 * @param {Object} article
 * @param {Object || String} attr
 */
export function findCurrAttr(article, attr) {
  return article.attributeValues.find(
    (attribute) => attribute.key === attr.key || attribute.key === attr,
  );
}

interface Article {
  attributesList: any[];
  articlesList: any[];
}

interface Product {
  productId: string;
  articles: Article;
}

interface ProductDetailFiltersProps {
  product: Product;
}

/**
 * ProductDetailFilters Container
 *
 * @param {Object} props
 */
function ProductDetailFilters({
  product: {
    productId,
    articles: { attributesList, articlesList },
  },
}: Readonly<ProductDetailFiltersProps>) {
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const [currentIds, setCurrentIds] = useState([]);
  const [dimensions, setDimensions] = useState();
  // to be used for webshop basket
  const [artId, setArtId] = useState('');
  const [curAvailableDimensions, setCurAvailableDimensions] = useState([]);
  const [dimensionsName, setDimensionsName] = useState(null);
  const dimensionsPosition = useRef(null);

  const pdpFilters = useSelector(pdpFiltersSelector);
  const defaultValue = useSelector((appState: AppState) =>
    translationByKeySelector(appState, 'web20_products_filters_dimension_chose_filter'),
  );
  const webshopEnabled = useSelector(webshopEnabledSelector);
  const webshopDowntime = useSelector(webshopMaintenanceEnabledSelector);

  useEffect(() => {
    // filter the attributes which should not get a filter
    // filter dimension fields and safe first positioning of dimension field to render filter
    const allfilteredAttr = attributesList.reduce((composed, attr) => {
      const isDimensionField = IS_DIMENSION_FIELD_KEY.includes(attr.key);
      const firstDimensionField = isDimensionField && !dimensionsPosition.current;

      // set dimensions position
      if (firstDimensionField) {
        dimensionsPosition.current = composed.length;
      }

      if (!NO_FILTER_KEYS.includes(attr.key) && !IS_DIMENSION_FIELD_KEY.includes(attr.key)) {
        return [...composed, attr];
      }

      return composed;
    }, []);

    // safe the dimensions
    const allDimensions = attributesList.filter((attr) =>
      IS_DIMENSION_FIELD_KEY.includes(attr.key),
    );

    // generate the dimensions options
    if (!isEmpty(allDimensions)) {
      let name: any[] = [];
      let dimKeys: any[] = [];
      let lessDimKeys: any = [];

      let composedDimensions = articlesList.reduce((composed, article) => {
        const dFields = article.attributeValues.filter((field) =>
          IS_DIMENSION_FIELD_KEY.includes(field.key),
        );

        // sort the keys: w x d x l x h
        const sorted = IS_DIMENSION_FIELD_KEY.map((key) =>
          dFields.find((field) => field.key === key),
        ).filter((field) => {
          if (field && !dimKeys.includes(field.key)) {
            // save the available dimensionfields key to compare it later on in the options
            dimKeys = [...dimKeys, field.key];
          }

          return !!field;
        });

        // remove dimension object if one article has less dimensions
        const lessDim = sorted.length !== dimKeys.length;
        if (lessDim) {
          lessDimKeys = isEmpty(lessDimKeys)
            ? sorted.map((dim) => dim.key)
            : lessDimKeys.filter((key) => sorted.map((dim) => dim.key === key));
        }

        return [...composed, sorted];
      }, []);

      // reduce the dimension array to the minimum of dimension
      if (!isEmpty(lessDimKeys)) {
        composedDimensions = composedDimensions.map((allDim) =>
          allDim.filter((dim) => lessDimKeys.includes(dim.key)),
        );
      }

      // only return the values
      const sortedAndReduced = composedDimensions.map((fields) =>
        fields.map((field) => field.value).join(DIMENSION_SEPARATOR),
      );

      // get the current available dimensions translations to generate the name
      if (isEmpty(name)) {
        name = composedDimensions[0].map((field) => DIMENSIONS_TRANSLATIONS[field.key]);
      }

      setCurAvailableDimensions(dimKeys);
      setDimensionsName(name);
      // avoid duplicates
      setDimensions([...new Set(sortedAndReduced)]);
    }

    // generate all other options
    if (!isEmpty(allfilteredAttr)) {
      let filterNames = [];

      allfilteredAttr.forEach((attr) => {
        const firstValueField = findCurrAttr(articlesList[0], attr);

        const hasFilter = articlesList.some((article) => {
          const currentFilterAttr = findCurrAttr(article, attr);
          if (currentFilterAttr) {
            return firstValueField?.value !== currentFilterAttr.value;
          }
          return false;
        });

        if (hasFilter) {
          filterNames = [...filterNames, attr];

          // safe all ids when we have a filter
          const ids = articlesList.map((article) => article.key);
          setCurrentIds(ids);
        }
      });

      setFilteredAttributes(filterNames);
    }
  }, []);

  useEffect(() => {
    // artId might be used for webshop basket
    if (currentIds.length === 1) {
      setArtId(currentIds[0]);
    } else {
      setArtId(null);
    }
  }, [currentIds.length]);

  if (!pdpFilters) {
    return null;
  }

  if (isEmpty(filteredAttributes)) {
    const articleVariant = articlesList[0];
    return articleVariant.state.buyable === true ? (
      <>
        <div className="desc">
          <span className="name">
            <Translation id="web20_product_articles_art_no" />:
          </span>
          <span>{articleVariant.key}</span>
          {webshopEnabled && !webshopDowntime && (
            <div>
              <span className="name">
                <Translation id="web20_webshop_basket_price" />:
              </span>
              <ProductPrice articleId={articleVariant.key} />
            </div>
          )}
        </div>
        {webshopEnabled && !webshopDowntime && (
          <AddToBasket productId={productId} variantId={articleVariant.key} list={articlesList} />
        )}
      </>
    ) : null;
  }

  /**
   * Get options from all articles by attributelist key without duplicates
   *
   * @param {Object} attr
   */
  const currentOptions = (attr) => {
    let options: any[] = [];

    articlesList.forEach((article) => {
      const current = findCurrAttr(article, attr);

      options = [...options, current?.value];
    });

    return [...new Set(options)];
  };

  /**
   * Set final artId
   *
   * @param {SyntheticEvent} param0
   */
  const onArtIdSelectChange = ({ currentTarget: { value } }) => {
    setArtId(value);
  };

  return (
    <>
      <ProductDetailFiltersView
        filteredAttributes={filteredAttributes}
        currentOptions={currentOptions}
        setCurrentIds={setCurrentIds}
        currentIds={currentIds}
        articlesList={articlesList}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        dimensionsName={dimensionsName}
        dimensions={dimensions}
        dimensionsPosition={dimensionsPosition}
        curAvailableDimensions={curAvailableDimensions}
        defaultValue={defaultValue}
        onArtIdSelectChange={onArtIdSelectChange}
        showPrice={webshopEnabled && !webshopDowntime}
        artId={artId}
      />
      {!isEmpty(artId) && webshopEnabled && !webshopDowntime && (
        <AddToBasket productId={productId} list={articlesList} variantId={currentIds[0]} />
      )}
    </>
  );
}

export default ProductDetailFilters;

'use client';

// types
import type { IShowroomData, IEntry } from './locator.types';

// components
import { SearchContextProvider } from './search';
import { LocatorOverviewPage } from './overview-page';
import { LocatorDetailPage } from './detail-page';

// utils
import { useLocatorConfiguration } from './utils/use-locator-configuration';
import { useMapLocatorData } from './use-map-locator-data';
import { contentExists, useContent } from 'components/Page/page-hooks';

export interface LocatorProps {
  page: {
    headline: string;
    subHeadline: string;
  };
  data: {
    showroom: IShowroomData<undefined>;
  };
  dealer?: IEntry;
}

export function Locator(props: Readonly<LocatorProps>) {
  const pageContent = useContent();

  const showroom = props?.data?.showroom || [];
  const dealer = props?.dealer;
  const locatorConfig = useLocatorConfiguration();
  const locatorPath = locatorConfig?.url;
  const { entries, sortStructure } = useMapLocatorData(showroom[0]) || {
    entries: [],
    sortStructure: [],
  };

  if (!contentExists(pageContent)) {
    return null;
  }

  const content = pageContent.contentAreas?.content || [];

  const { page: locatorPage } = pageContent;

  if (dealer) {
    return <LocatorDetailPage locatorPath={locatorPath} {...dealer} />;
  }

  return (
    <SearchContextProvider entries={entries}>
      <LocatorOverviewPage
        page={locatorPage}
        entries={entries}
        sortStructure={sortStructure}
        content={content}
      />
    </SearchContextProvider>
  );
}

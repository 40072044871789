import { SyntheticEvent } from 'react';
import { useRouter } from 'next/navigation';
import { Button } from '@geberit/gdds';

// types
import type { TextBoxButtonProps } from './TextBoxButton.types';

// utils
import { onButtonClick } from 'utils/button-click-handler';
import { useTracking } from 'utils/hooks/useTracking';

function TextBoxButton({
  color,
  button,
  className,
}: Readonly<TextBoxButtonProps> & { className?: string }) {
  const track = useTracking();
  const router = useRouter();

  if (!button) return null;

  const _onButtonClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    onButtonClick(e, button, track, router);
  };

  return (
    <Button
      stylingType={color === 'white' ? 'secondaryInverted' : 'secondary'}
      onClick={_onButtonClick}
      className={className}
    >
      {button.text}
    </Button>
  );
}

export { TextBoxButton };

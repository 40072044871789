import { useEffect, useRef, useState } from 'react';
import { Button } from '@geberit/gdds';

// styles
import styles from './courseInformation.module.scss';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';

const isHtmlElement = (element: HTMLElement | null): element is HTMLElement => {
  return Boolean(element);
};

const CLAMP_THRESHOLD = 2;

interface TruncateTextProps {
  text: string;
}

export function TruncateText({ text }: Readonly<TruncateTextProps>) {
  const paragraphRef = useRef<HTMLParagraphElement | null>(null);
  const [showMore, setShowMore] = useState(false);
  const [moreThenTwoLines, setMoreThenTwoLines] = useState(false);
  const translate = useTranslationFunction();

  useEffect(() => {
    if (!isHtmlElement(paragraphRef.current)) {
      return () => {};
    }

    const observer = new ResizeObserver(() => {
      if (!isHtmlElement(paragraphRef.current)) {
        return;
      }

      const paragraphHeight = paragraphRef.current.clientHeight;
      const lineHeight = Number(
        window.getComputedStyle(paragraphRef.current).lineHeight.replace('px', ''),
      );

      if (paragraphHeight > CLAMP_THRESHOLD * lineHeight) {
        setMoreThenTwoLines(true);
      }
    });

    observer.observe(paragraphRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const toogleShowMore = () => {
    setShowMore((s) => !s);
  };

  const shouldClamp = moreThenTwoLines && !showMore;

  return (
    <div>
      <p ref={paragraphRef} className={shouldClamp ? styles.clamp : undefined}>
        {text}
      </p>

      {moreThenTwoLines && (
        <Button
          onClick={toogleShowMore}
          alignByContent="left"
          symbol={showMore ? 'arrowup' : 'arrowdown'}
          stylingType="flatHighlight"
        >
          {translate(showMore ? 'campus_see_less' : 'campus_see_more')}
        </Button>
      )}
    </div>
  );
}

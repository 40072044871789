import styled from 'styled-components';

export const SearchBarWrapper = styled.div`
  .search-wrapper {
    input + button + div {
      z-index: 50;
    }
  }

  .result-hint {
    color: ${({ theme }) => theme.colors.cl12};
    margin-top: 1rem;
    margin-bottom: 2.25rem;
    display: block;
  }
`;

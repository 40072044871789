'use client';

import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { usePathname, useRouter } from 'next/navigation';

// components
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import TableOfContents from 'components/TableOfContents/TableOfContents';
import ProductDetailGallery from '../DetailGallery/ProductDetailGallery';
import ProductDetailFilterBox from '../DetailFilterBox/ProductDetailFilterBox';
import ProductDetailAccordion from '../DetailAccordion/ProductDetailAccordion';

// utils
import { homepageSelector } from 'utils/selectors/homepageListSelectors';
import { TOC_DATA_PDP } from 'components/TableOfContents/tocDataAdapter';
import { navigationMainItemsSelector } from 'utils/selectors/navigationSelectors';
import { seoConformProductUrl } from 'utils/seoConformProductUrl';
import { translationKeysSelector } from 'utils/selectors/translationSelectors';
import { isClient } from 'utils/environment';
import { useTracking } from 'utils/hooks/useTracking';
import { trackingOnChange } from 'utils/trackAccordion';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { isEmpty } from 'utils/is-empty';

interface ProductDetailContainerProps {
  type: string;
  product: Product;
  isStorybook?: boolean;
  catalogLanguage?: string;
}

export default function ProductDetailContainer({
  type,
  product,
  product: { name, spareParts: sparePartsProps, documents: documentsProps, categories },
  catalogLanguage,
  ...restProps
}: Readonly<ProductDetailContainerProps>) {
  const router = useRouter();
  const pathname = usePathname();
  const track = useTracking();

  const [activePanel, setActivePanel] = useState([]);
  const [accItems, setAccItems] = useState({
    detail: false,
    spareParts: false,
    documents: false,
    scopeOfDelivery: false,
    technicalDrawings: false,
    characteristics: false,
    technicalDataList: false,
    applicationPurposes: false,
    additionalInformation: false,
    notIncluded: false,
  });
  const prevLocation = useRef(pathname);
  const [productPath, setProductPath] = useState<string>('');

  const language = useCurrentLanguage();
  const navigation = useSelector(navigationMainItemsSelector);
  const homepage = useSelector(homepageSelector);
  const translations = useSelector(translationKeysSelector);

  const resetOpenPanels = () => {
    setAccItems({
      detail: false,
      spareParts: false,
      documents: false,
      scopeOfDelivery: false,
      technicalDrawings: false,
      characteristics: false,
      technicalDataList: false,
      applicationPurposes: false,
      additionalInformation: false,
      notIncluded: false,
    });
    setActivePanel([]);
  };

  useEffect(() => {
    if (isClient()) {
      window.addEventListener('scrollToSection', onScrollToPanel);
    }

    return () => {
      resetOpenPanels();
      if (isClient()) {
        window.removeEventListener('scrollToSection', onScrollToPanel);
      }
    };
  }, []);

  useEffect(() => {
    if (!isEqual(prevLocation.current, pathname)) {
      resetOpenPanels();
    }
  }, [pathname]);

  useEffect(() => {
    if (!isEmpty(navigation) && productPath === '') {
      setProductPath(seoConformProductUrl(product, navigation?.[0].url, language, categories));
    }
  }, [navigation]);

  // navigation is needed to render the component
  if (isEmpty(navigation)) return null;

  /**
   * Accordion onChange method
   *
   * @param e section title of scrolled to element
   */
  const onScrollToPanel = (e) => {
    expandAccordionItem(e.detail.id, true);
  };

  /**
   * Accordion onChange method
   *
   * @param e UUID array
   */
  const onChange = (e) => {
    const activeDetail = e.some((panel) => panel === 'detail');
    const activeSpareParts = e.some((panel) => panel === 'spareParts');
    const activeDocuments = e.some((panel) => panel === 'documents');
    const activeTechnicalDrawings = e.some((panel) => panel === 'technicalDrawings');
    const activeScopeOfDelivery = e.some((panel) => panel === 'scopeOfDelivery');
    const activeNotIncluded = e.some((panel) => panel === 'notIncluded');
    const activeApplicationPurposes = e.some((panel) => panel === 'applicationPurposes');
    const activeTechnicalDataList = e.some((panel) => panel === 'technicalDataList');
    const activeCharacteristics = e.some((panel) => panel === 'characteristics');
    const activeAdditionalInformation = e.some((panel) => panel === 'additionalInformation');

    trackingOnChange(track, setActivePanel, activePanel, null, e, translations);

    setActivePanel(e);
    setAccItems({
      detail: activeDetail,
      spareParts: activeSpareParts,
      documents: activeDocuments,
      scopeOfDelivery: activeScopeOfDelivery,
      technicalDrawings: activeTechnicalDrawings,
      characteristics: activeCharacteristics,
      technicalDataList: activeTechnicalDataList,
      applicationPurposes: activeApplicationPurposes,
      additionalInformation: activeAdditionalInformation,
      notIncluded: activeNotIncluded,
    });
  };

  /**
   * Set active panel
   *
   * @param uuid
   * @returns {boolean}
   */
  const isPanelActive = (uuid) =>
    !isEmpty(activePanel) && activePanel.some((panel) => panel === uuid);

  /**
   * Change expanded state of regarding accordion item
   *
   * @param item AccordionItem expanded name
   * @param expanded
   */
  const expandAccordionItem = (item, expanded) => {
    setAccItems({
      ...accItems,
      [item]: expanded || !accItems[item],
    });
  };
  const category = categories.find((item) => !isEmpty(item.name));

  // get seoconform url for metadata
  if (productPath && productPath !== pathname && productPath !== prevLocation.current) {
    router.replace(productPath);
    return null;
  }

  const categoryItem = category && {
    label: category.name,
    url: '',
  };

  const {
    detail,
    spareParts,
    documents,
    scopeOfDelivery,
    technicalDrawings,
    characteristics,
    technicalDataList,
    applicationPurposes,
    additionalInformation,
    notIncluded,
  } = accItems;

  return (
    <>
      {!isEmpty(homepage) && !isEmpty(navigation) && (
        <Breadcrumb
          items={[
            { label: 'homepage', url: homepage.url },
            { ...categoryItem },
            { label: name, url: '' },
          ]}
        />
      )}
      <div className="l-product-page">
        <PageHeadline Format={TitleFormats.h1} title={name} pageHeadline />
        <TableOfContents type={TOC_DATA_PDP} content={{ product }} headline={name} />
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-6">
              <ProductDetailGallery product={product} {...restProps} />
            </div>
            <div className="cell small-12 medium-6">
              <ProductDetailFilterBox
                product={product}
                sparePartsGiven={!isEmpty(sparePartsProps.list)}
                documentsGiven={!isEmpty(documentsProps.list)}
                expandAccordionItem={expandAccordionItem}
                language={language}
              />
            </div>
            <div className="cell small-12">
              <ProductDetailAccordion
                product={product}
                detailOpen={detail}
                sparePartsOpen={spareParts}
                documentsOpen={documents}
                technicalDrawingsOpen={technicalDrawings}
                onChange={onChange}
                isPanelActive={isPanelActive}
                scopeOfDeliveryOpen={scopeOfDelivery}
                notIncludedOpen={notIncluded}
                applicationPurposesOpen={applicationPurposes}
                characteristicsOpen={characteristics}
                technicalDataListOpen={technicalDataList}
                additionalInformationOpen={additionalInformation}
                catalogLanguage={catalogLanguage}
                {...restProps}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';
import key from 'weak-key';
import SlickSlider from 'react-slick';
import Link from 'next/link';

// components
import VideoSliderItem from './VideoSliderItem';
import { Translation } from 'components/Translation/Translation';
import { Title } from 'components/ContentElements/Title/Title';
import { TitleFormats } from 'components/ContentElements/Title/title.types';

// utils
import { generateSlug } from 'utils/slug';
import { isEmpty } from 'utils/is-empty';

const slickSettings = {
  slidesToShow: 3,
  infinite: false,
  responsive: [
    {
      breakpoint: 768, // Breakpoint "medium"
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 992, // Breakpoint "large"
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

function setArrowHeight(imageRef, sliderRef) {
  if (!imageRef || !sliderRef) return;

  const imageHeight = imageRef.offsetHeight;
  const sliderArrows = sliderRef.querySelectorAll('.slick-arrow');

  if (imageHeight) {
    sliderArrows.forEach((arrow) => {
      arrow.style.top = `${imageHeight / 2}px`;
    });
  }
}

/**
 * Video Slider component
 */

interface VideoSliderProps {
  title?: string | null;
  url?: string | null;
  videos: any[];
  anchor?: string | null;
}

function VideoSlider({
  videos,
  title = null,
  anchor = null,
  url = null,
}: Readonly<VideoSliderProps>) {
  const imageRef = useRef();
  const sliderRef = useRef();

  useEffect(() => {
    window.addEventListener('resize', debounce(setArrowHeight, 50));

    return () => {
      window.removeEventListener('resize', debounce(setArrowHeight, 50));
    };
  });

  useEffect(() => {
    if (imageRef && sliderRef.current) {
      setArrowHeight(imageRef, sliderRef.current);
    }
  });

  return (
    <div>
      {title && (
        <div className="c-video-center__title" id={`videochannel-${generateSlug(anchor || title)}`}>
          {!isEmpty(title) && <Title title={title} Format={TitleFormats.h3} />}
          {!isEmpty(url) && (
            <Link shallow href={url} className="noArrow">
              <Translation id="web20_videocenter_channel_link" />
            </Link>
          )}
        </div>
      )}
      {videos && (
        <div ref={sliderRef}>
          <SlickSlider className="c-video-center__slider" {...slickSettings}>
            {videos
              .map((video) => ({
                ...video,
                channelUrl: url,
              }))
              .map((video) => (
                <VideoSliderItem key={key(video)} video={video} />
              ))}
          </SlickSlider>
        </div>
      )}
    </div>
  );
}

export default VideoSlider;

import { Col, Toggle, Typo } from '@geberit/gdds';

// styles
import styles from './softcontent-filters.module.scss';

// components
import { Translation } from 'components/Translation/Translation';

// utils
import { Desktop } from '../../breakpoints';
import { useTranslationFunction } from 'utils/hooks/use-translations';

type Props = {
  aggregations?: {
    indexer_filetype: {
      value: string;
      count: number;
    }[];
  };
  request: {
    filters: string[];
  };
  setSelectedContentFilter: (e: any) => void;
};

export default function SoftContentFilters({
  aggregations,
  request,
  setSelectedContentFilter,
}: Readonly<Props>) {
  const translate = useTranslationFunction();
  const getFilterText = (value) => translate(`web20_search_filter_${value}`);

  if (!aggregations?.indexer_filetype?.length) return null;

  return (
    <Col size={[4, 8, 3]}>
      <Desktop>
        <Typo as="label" variant="p1" fontWeight={700} className={styles.filterLabel}>
          <Translation id="web20_search_filter_by_filetype" />
        </Typo>
        <Toggle
          type="radio"
          name="type"
          alignment="vertical"
          values={{ type: request.filters?.[0] || 'all' }}
          alignByContent="left"
          items={[{ id: 'all', value: 'all', text: translate(`web20_search_filter_all`) }]
            .concat(
              aggregations.indexer_filetype.map((filter) => ({
                id: filter.value,
                text: `${getFilterText(filter.value)} (${filter.count})`,
                value: `indexer_filetype:${filter.value}`,
              })),
            )
            .filter(Boolean)}
          onChange={(e) => {
            setSelectedContentFilter(e);
          }}
        />
      </Desktop>
    </Col>
  );
}

import React from 'react';
import isEmpty from 'lodash.isempty';
import key from 'weak-key';

// components
import { Translation } from '../../Translation/Translation';
import SoftContentFilterItem from './SoftContentFilterItem';
import { BUTTON_TYPES, Button } from '../../Button/Button';

// utils
import { useIsDesktop } from 'components/App/SizeProvider';

interface SoftContentFilterProps {
  selectedFilter: string;
  setSelectedFilter: (filter: string) => void;
  aggregations: any;
}

function SoftContentFilter({
  setSelectedFilter,
  selectedFilter = '',
  aggregations = {},
}: Readonly<SoftContentFilterProps>) {
  const isDesktop = useIsDesktop();
  if (!isDesktop) {
    return null;
  }

  return (
    <div className="c-product-result-left cell small-12 medium-4 large-3">
      <h5>
        <Translation id="web20_search_filter_by_filetype" />
      </h5>
      <ul className="link-list">
        <li>
          <Button
            type={BUTTON_TYPES.PLAIN}
            className={selectedFilter === '' ? 'selected' : undefined}
            data-filter=""
            onClick={() => setSelectedFilter('')}
          >
            <Translation id="web20_search_filter_all" />
          </Button>
        </li>
        {!isEmpty(aggregations) &&
          aggregations.indexer_filetype.map((filter) => (
            <SoftContentFilterItem
              key={key(filter)}
              {...filter}
              handleClick={setSelectedFilter}
              active={selectedFilter === `indexer_filetype:${filter.value}`}
            />
          ))}
      </ul>
    </div>
  );
}

export default SoftContentFilter;

import { useMemo } from 'react';
import { Col, Row, Search, Dropdown, ExtendedDropdown } from '@geberit/gdds';

// styles
import styles from './filter-search-bar.module.scss';

// types
import type { FilterSearchBarProps } from '../types/press-portal.types';

// components
import { Desktop, Mobile, Tablet } from 'components/breakpoints';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';

export function FilterSearchBar({
  materialTypes,
  materialType,
  pressroomTags,
  getMediaTypeChangehandler,
  getTagsChangehandler,
  getSearchChangehandler,
  query,
}: Readonly<FilterSearchBarProps>) {
  const translate = useTranslationFunction();

  const types = useMemo(
    () =>
      materialTypes.map((element) => ({
        ...element,
        text: translate(element.text),
      })),
    [materialTypes, translate],
  );

  const pressSearchTranslation = translate('press_search');
  const pressTagsTranslation = translate('press_tags');
  const pressAllTagsTranslation = translate('press_all_tags');
  const pressSelectTagsTranslation = translate('press_select_tags');

  const materialFilterLabel = translate(
    materialTypes.some((e) => e.text.includes('news')) ? 'press_news' : 'press_media',
  );

  const values = useMemo(() => pressroomTags?.map((e) => e.value), [pressroomTags]);

  return (
    <div className={styles.searchFilersWrapper}>
      <Mobile>
        <Row>
          <Col>
            <div className={styles.searchFilers}>
              <div className={styles.searchBar}>
                <Search
                  onSubmit={getSearchChangehandler}
                  placeholder={pressSearchTranslation}
                  name="search-term"
                  onChange={(term) => {
                    // handle reset
                    if (!term) getSearchChangehandler();
                  }}
                />
              </div>
              <Dropdown
                label={materialFilterLabel}
                values={{ 'material-filter': materialType ?? 'all' }}
                onChange={getMediaTypeChangehandler}
                name="material-filter"
                items={types}
                forceCustom
              />
              <ExtendedDropdown
                allSelectionLabel={pressAllTagsTranslation}
                label={pressTagsTranslation}
                placeholder={pressSelectTagsTranslation}
                values={values}
                onChange={getTagsChangehandler}
                items={pressroomTags ?? []}
              />
            </div>
          </Col>
        </Row>
      </Mobile>

      <Tablet isGdds>
        <Row>
          <Col>
            <div className={styles.searchBar}>
              <Search
                placeholder={pressSearchTranslation}
                onSubmit={getSearchChangehandler}
                name="search-term"
                onChange={(term) => {
                  // handle reset
                  if (!term) getSearchChangehandler();
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col size={[0, 1, 0]} />
          <Col size={[4, 6, 12]}>
            <div className={styles.filters}>
              <Dropdown
                label={materialFilterLabel}
                values={{ 'material-filter': materialType ?? 'all' }}
                onChange={getMediaTypeChangehandler}
                forceCustom
                name="material-filter"
                items={types}
              />
              <ExtendedDropdown
                allSelectionLabel={pressAllTagsTranslation}
                label={pressTagsTranslation}
                placeholder={pressSelectTagsTranslation}
                values={values}
                onChange={getTagsChangehandler}
                items={pressroomTags ?? []}
              />
            </div>
          </Col>
          <Col size={[0, 1, 0]} />
        </Row>
      </Tablet>

      <Desktop isGdds>
        <Row>
          <Col>
            <div className={styles.searchFilers}>
              <div className={styles.searchBar}>
                <Search
                  val={query}
                  onSubmit={getSearchChangehandler}
                  placeholder={pressSearchTranslation}
                  name="search-term"
                  onChange={(term) => {
                    // handle reset
                    if (!term) getSearchChangehandler();
                  }}
                />
              </div>
              <div className={styles.filters}>
                <Dropdown
                  label={materialFilterLabel}
                  values={{ 'material-filter': materialType ?? 'all' }}
                  onChange={getMediaTypeChangehandler}
                  forceCustom
                  name="material-filter"
                  items={types}
                />
                <ExtendedDropdown
                  allSelectionLabel={pressAllTagsTranslation}
                  label={pressTagsTranslation}
                  placeholder={pressSelectTagsTranslation}
                  values={values}
                  onChange={getTagsChangehandler}
                  items={pressroomTags ?? []}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Desktop>
    </div>
  );
}

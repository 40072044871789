import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// types
import type { Dispatch } from 'redux';

// components
import SoftContentFilter from './SoftContentFilter';
import SearchPagination from '../SearchPagination/SearchPagination';
import SoftContentResultItem from './SoftContentResultItem';

// utils
import {
  selectSoftContent,
  selectParamsFromState,
  searchIsFetchingSelector,
} from '../../../utils/selectors/searchSelectors';
import { SEARCH_PAGE_SIZE } from '../constants';
import { setSearchFilter, setSearchOffset } from '../actions';
import { Loader } from '../../Loader/Loader';

function SoftContentResult() {
  const dispatch: Dispatch<any> = useDispatch();
  const softContent = useSelector(selectSoftContent) as any;
  const params = useSelector(selectParamsFromState);
  const isFetching = useSelector(searchIsFetchingSelector);

  const { filter, offset = 0 } = params || {};

  const handleNextPage = useCallback(() => {
    dispatch(setSearchOffset(`${offset + SEARCH_PAGE_SIZE}`));
  }, [dispatch, offset]);

  const setFilter = useCallback(
    (filter) => {
      dispatch(setSearchOffset('0'));
      dispatch(setSearchFilter(filter));
    },
    [dispatch],
  );

  if (!softContent) return null;

  const { results, aggregations, totalResults } = softContent;
  const isLast = (totalResults as number) <= offset + SEARCH_PAGE_SIZE;

  if (!results) return null;

  return (
    <>
      <SoftContentFilter
        aggregations={aggregations}
        setSelectedFilter={setFilter}
        selectedFilter={filter ?? ''}
      />
      <div className="c-product-result-right cell small-12 medium-12 large-9">
        <ul className="c-result-list">
          {results.map((result) => (
            <SoftContentResultItem key={result.id} {...result} /> // Assume each result has a unique 'id' for the key
          ))}
        </ul>
        {isFetching && (
          <div className="grid-container global_search">
            <Loader size="large" />
          </div>
        )}
        <SearchPagination
          isLast={isLast}
          current={isLast ? totalResults : SEARCH_PAGE_SIZE + offset}
          total={totalResults}
          handleNextPage={handleNextPage}
        />
      </div>
    </>
  );
}

export default SoftContentResult;

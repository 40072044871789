// components
import { CmsLink } from 'components/Link/CmsLink';
import { BUTTON_COLORS, BUTTON_TYPES, Button } from 'components/Button/Button';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { BackgroundImage } from 'components/background-image/background-image';

// hooks
import { useIsMobile } from 'components/App/SizeProvider';
import { useGetParent } from 'scenes/parent-provider';

// utils
import { responsiveImage } from 'utils/responsiveImage';
import { classNameBuilder } from 'utils/classNameBuilder';
import { getHeadlineTag } from '.';

interface SlideImageProps {
  slide: SliderItemProps;
  index: number;
  type?: string;
  context?: string;
  sliderOnClickCTA?: (text?: string) => void;
}

export default function SlideImage({
  slide: {
    subheadline,
    headline,
    link,
    textColor = 'white',
    imageObject,
    imageObjectMobile,
    pictureAlt,
  },
  index,
  type = '',
  context = 'default',
  sliderOnClickCTA,
}: Readonly<SlideImageProps>) {
  const isMobile = useIsMobile();
  const image = responsiveImage(isMobile, imageObjectMobile, imageObject);
  const sliderContext = useGetParent();
  let headlineTag = getHeadlineTag(sliderContext);

  const renderLink = () => (
    <CmsLink link={link}>
      <Button
        type={BUTTON_TYPES.DEFAULT}
        color={textColor === 'white' ? BUTTON_COLORS.WHITE : BUTTON_COLORS.BLACK}
        onClick={() => sliderOnClickCTA?.(link.text)}
      >
        {link.text}
      </Button>
    </CmsLink>
  );

  return (
    <BackgroundImage
      className="slide-content__background-image"
      format={image.format}
      format2x={image.format2x}
      format3x={image.format3x}
      imagePath={image.url}
      isRemoteMedia={image.remote}
      retinaSrc={image}
      title={pictureAlt}
      uid={image.uid}
      type={type}
    >
      <div
        className="slide-content-container"
        data-preview-id={context === 'default' ? `#${index}` : undefined}
      >
        <div className={`slide-content slide-content--${textColor}`}>
          {(subheadline || headline) && (
            <div className="slide-content__title">
              {subheadline && (
                <InnerHtml
                  as="span"
                  content={subheadline}
                  className={classNameBuilder('subheadline', headlineTag === 'span' && 'block')}
                  previewId={context === 'default' ? '#st_subline' : undefined}
                />
              )}
              {headline && (
                <InnerHtml
                  as={headlineTag}
                  content={headline}
                  previewId={context === 'default' ? '#st_headline' : undefined}
                  className={classNameBuilder('h2', headlineTag === 'span' && 'block')}
                />
              )}
            </div>
          )}
          {link && <span className="slide-content__link">{renderLink()}</span>}
        </div>
        ,
      </div>
    </BackgroundImage>
  );
}

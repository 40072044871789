'use client';

import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';

// components
import Footer from 'components/Footer/Footer';
import { Checkout as CheckoutComponent } from 'components/Checkout/Checkout';

// utils
import { SessionContext } from 'components/Session/SessionContext';
import { currentCatalogSelector } from 'utils/selectors/productCatalogSelectors';
import {
  webshopBlockedCheckoutPageUrlSelector,
  webshopEnabledSelector,
} from 'utils/selectors/globalsSelectors';
import {
  webshopMaintenanceEnabledSelector,
  webshopMaintenanceLoadingSelector,
} from 'utils/selectors/maintenanceSelectors';
import { homepageSelector } from 'utils/selectors/homepageListSelectors';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';

export function Checkout() {
  const currentCatalog = useSelector(currentCatalogSelector);
  const language = useCurrentLanguage();
  const blockedUrl = useSelector(webshopBlockedCheckoutPageUrlSelector);
  const webshopDowntime = useSelector(webshopMaintenanceEnabledSelector) ?? false;
  const webshopEnabled = useSelector(webshopEnabledSelector) ?? false;
  const webshopDowntimeLoading = useSelector(webshopMaintenanceLoadingSelector);
  const homepage = useSelector(homepageSelector);

  const router = useRouter();
  const {
    state: { session, ciam },
  } = useContext(SessionContext);

  useEffect(() => {
    const country = language.split('_')[1] || language;
    let correctCountry = ciam && ciam.country === country;
    if (ciam && ciam.country.toLowerCase() === 'li' && country.toLowerCase() === 'ch') {
      correctCountry = true;
    }
    if (ciam && !correctCountry && blockedUrl) {
      router.push(blockedUrl);
    }
  }, [language, ciam, blockedUrl, router]);

  useEffect(() => {
    if (!webshopDowntimeLoading && (!webshopEnabled || webshopDowntime)) {
      router.push(homepage?.url || '/');
    }
  }, [webshopEnabled, webshopDowntime, webshopDowntimeLoading, homepage, router]);

  if (!currentCatalog) return null;

  return (
    <>
      <main>
        {webshopEnabled && !webshopDowntime && (
          <CheckoutComponent
            locale={currentCatalog}
            {...(ciam?.UID ? { ciamId: ciam.UID } : { sessionId: session ?? undefined })}
          />
        )}
      </main>
      <Footer />
    </>
  );
}

'use client';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';

// components
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Footer from 'components/Footer/Footer';
import { CheckoutIntroView } from 'components/CheckoutIntro/CheckoutIntroView';

// utils
import {
  webshopCheckoutUrlSelector,
  webshopEnabledSelector,
} from 'utils/selectors/globalsSelectors';
import { useWebshopCartItem } from 'utils/hooks/useWebshopCartItem';
import {
  webshopMaintenanceEnabledSelector,
  webshopMaintenanceLoadingSelector,
} from 'utils/selectors/maintenanceSelectors';
import { homepageSelector } from 'utils/selectors/homepageListSelectors';
import { WEBSHOP_CAMA_ID, WEBSHOP_ONLINE_PAYLIMIT } from 'components/Checkout/constants';
import { isEmpty } from 'utils/is-empty';

export function CheckoutIntro({ breadcrumbs }: Readonly<Content>) {
  const checkoutUrl = useSelector(webshopCheckoutUrlSelector);
  const webshopDowntime = useSelector(webshopMaintenanceEnabledSelector);
  const webshopDowntimeLoading = useSelector(webshopMaintenanceLoadingSelector);
  const webshopEnabled = useSelector(webshopEnabledSelector);
  const homepage = useSelector(homepageSelector);

  const router = useRouter();
  const [productCheckLoading, hasProduct] = useWebshopCartItem(
    WEBSHOP_CAMA_ID,
    WEBSHOP_ONLINE_PAYLIMIT,
  );

  useEffect(() => {
    if (!webshopDowntimeLoading && (!webshopEnabled || webshopDowntime)) {
      router.push(homepage?.url || '/');
    }
  }, [webshopEnabled, webshopDowntime, homepage, webshopDowntimeLoading, router]);

  const handleGuest = () => {
    if (!isEmpty(checkoutUrl)) {
      router.push(checkoutUrl);
    }
  };

  return (
    <>
      <main>
        <Breadcrumb items={breadcrumbs} />
        <CheckoutIntroView
          hasGuestButton={!productCheckLoading && !hasProduct}
          onGuestClick={handleGuest}
        />
      </main>
      <Footer />
    </>
  );
}

import { FormikProps } from 'formik';
import { Toggle } from '@geberit/gdds';

// styles
import styles from '../registrationForm.module.scss';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useToggleItems } from './utils';

interface FactoryTourProps {
  nameSuffix: string;
}

export default function FactoryTour({
  nameSuffix,
  handleChange,
  values,
}: FactoryTourProps & Pick<FormikProps<any>, 'values' | 'handleChange'>) {
  const toggleItems = useToggleItems();
  const translate = useTranslationFunction();

  return (
    <div className="grid-x">
      <div className="small-12 medium-12">
        <span className={styles.hotelLabel}>{translate('campus_form_factory')}</span>
        <Toggle
          onChange={(value) => handleChange({ target: { name: `factory${nameSuffix}`, value } })}
          values={values}
          type="radio"
          name={`factory${nameSuffix}`}
          alignment="horizontal"
          items={toggleItems}
        />
      </div>
    </div>
  );
}

// types
import type { TabletProps, TabletSelectorProps } from './tablet.types';

// components
import { Desktop } from '.';

// utils
import { useIsTablet } from 'components/App/SizeProvider';

function Tablet({
  isGdds = false,
  children,
  and,
}: React.PropsWithChildren<TabletProps & TabletSelectorProps>) {
  const isTablet = useIsTablet();

  if (isTablet) {
    return children;
  }

  if (and === 'desktop') {
    return <Desktop isGdds={isGdds}>{children}</Desktop>;
  }

  return null;
}

export default Tablet;

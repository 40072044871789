// Actions
import {
  addProduct as addProductAction,
  decreaseProduct as decreaseProductAction,
  deleteProduct as deleteProductAction,
  setProduct as setProductAction,
} from '../ShoppingBasket/actions';

export const ARTICLE_LIST_ACTIONS = {
  basketpage: {
    addProduct: addProductAction,
    decreaseProduct: decreaseProductAction,
    deleteProduct: deleteProductAction,
    setProduct: setProductAction,
  },
};

import React from 'react';
import key from 'weak-key';

// utils
import { isEmpty } from 'utils/is-empty';
import { renderLinks } from 'utils/links';
import { decodingContent } from 'utils/decodingContent';

function LinkBlock({ linkBlock }: Readonly<{ linkBlock: LinkBlock }>) {
  const { links, title } = linkBlock;

  if (isEmpty(links)) {
    return null;
  }

  const listItems = links.map((itemLink) => {
    const newItemLink = {
      ...itemLink,
      text: itemLink?.text?.slice(0, 90),
    };
    return <li key={key(itemLink)}>{renderLinks(newItemLink)}</li>;
  });

  return (
    <div className="c-related-links__block">
      {title && <h4 className="h6">{decodingContent(title)}</h4>}
      <nav>
        <ul className="link-list">{listItems}</ul>
      </nav>
    </div>
  );
}

export default LinkBlock;

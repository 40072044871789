import { useRef, useEffect } from 'react';
import { FilterChips as FilterChipsType, FilterChips as FilterChipsGdds } from '@geberit/gdds';

// types
import type { FilterChipsProps } from './filter.types';

export default function FilterChips({
  topics,
  years,
  selectedTopics,
  setSelectedTopics,
  selectedYears,
  setSelectedYears,
}: Readonly<FilterChipsProps>) {
  const chipsRef = useRef<FilterChipsType | null>(null);

  const itemRemoved = (items) => {
    // check whether topics changed
    const differenceTopics = selectedTopics.filter((topic) => !items.includes(topic));
    if (differenceTopics.length > 0) {
      const deletedTopic = differenceTopics[0];
      setSelectedTopics(selectedTopics.filter((topic) => topic !== deletedTopic));
    }

    // check whether years changed
    const differenceYears = selectedYears.filter((year) => !items.includes(year));
    if (differenceYears.length > 0) {
      const deletedYear = differenceYears[0];
      setSelectedYears(selectedYears.filter((year) => year !== deletedYear));
    }
  };

  useEffect(() => {
    chipsRef.current?.reset();
  }, [selectedTopics, selectedYears]);

  return (
    <FilterChipsGdds
      type="A"
      ref={chipsRef}
      gap={16}
      isStrechedWidth={false}
      chipMinWidth={100}
      alignment="horizontal"
      defaultSelectedChips={[...selectedTopics, ...selectedYears]}
      selectedChips={[...selectedTopics, ...selectedYears]}
      chips={[...topics, ...years]}
      onChange={itemRemoved}
    />
  );
}

'use client';

// components
import Layout from './Layout';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { HeroImageGdds } from 'components/ContentElementsGdds/hero-image/hero-image-gdds';
import InsightsTeaserList from 'components/ContentElementsGdds/insights-teaser-list/insights-teaser-list';
import { IntroText } from 'components/ContentElementsGdds/IntroText/intro-text';

// types
import type { FilterProps } from 'components/ContentElementsGdds/insights-teaser-list/filter/filter.types';

// utils
import { contentExists, useContent } from 'components/Page/page-hooks';

export function InsightsLanding() {
  const content = useContent<{}, { slider: HeroImageGddsProps; filter: FilterProps['labels'] }>();

  if (!contentExists(content)) {
    return null;
  }

  const {
    breadcrumbs,
    metaData,
    page: { slider, introsection, filter },
  } = content;
  return (
    <Layout metaData={metaData}>
      <HeroImageGdds {...slider} />
      <Breadcrumb items={breadcrumbs} />
      {introsection && <IntroText {...introsection} />}
      <InsightsTeaserList labels={filter} />
    </Layout>
  );
}

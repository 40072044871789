import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';

// styles
import styles from './orderhistory.module.scss';

// components
import { Translation } from 'components/Translation/Translation';
import { InternalLink } from 'components/Link/InternalLink';
import { Image } from 'components/image/image';
import { Loader } from 'components/Loader/Loader';

// utils
import { fetchOrder } from './actions';
import { ResponsiveTable } from 'components/ResponsiveSlideTable/ResponsiveTable';
import { useIsMobile } from 'components/App/SizeProvider';
import { seoConformProductUrl } from 'utils/seoConformProductUrl';
import { navigationMainItemsSelector } from 'utils/selectors/navigationSelectors';
import { currentCatalogSelector } from 'utils/selectors/productCatalogSelectors';

interface OrderHistoryDetailsProps {
  orderId: string;
  ciam: any;
}

export default function OrderHistoryDetails({ orderId, ciam }: Readonly<OrderHistoryDetailsProps>) {
  const isMobile = useIsMobile();
  const state = useSelector((state: AppState) => state);
  const language = useSelector(currentCatalogSelector);
  const navigation = useSelector(navigationMainItemsSelector);

  const { data, isLoading } = useQuery({
    queryKey: ['histroy-details', ciam?.UID, orderId, language, state.globals?.server?.baseUrl],
    queryFn: async function () {
      if (ciam?.UID && orderId && language && state.globals?.server?.baseUrl) {
        return (await fetchOrder(language, orderId, state))?.orderLines;
      }
      return undefined;
    },
  });

  const getProductLink = useCallback(
    (itemEntry) => {
      if (navigation === null || navigation.length === 0) return '';

      return seoConformProductUrl(
        { key: itemEntry.productId, categories: [] },
        navigation[0].url,
        language,
        null,
        false,
        null,
        null,
      );
    },
    [navigation],
  );

  if (isLoading) {
    return <Loader />;
  }

  if (orderId === 'PENDING') {
    return (
      <span className={styles.pendingOrderDescription}>
        <Translation id="web20_orderhistory_pending_order_description" />
      </span>
    );
  }

  if (isMobile) {
    return (
      <div className="grid-container">
        <ResponsiveTable isOrderHistory numberOfVisibleCols={1} orderData={data} />
      </div>
    );
  }

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th></th>
          <th>
            <Translation id="web20_webshop_basket_art_no" />
          </th>
          <th>
            <Translation id="web20_webshop_basket_product" />
          </th>
          <th>
            <Translation id="web20_webshop_basket_amount" />
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((itemEntry) => (
          <tr
            className={itemEntry.inactive === true ? styles.inactive : ''}
            key={`${orderId}-${itemEntry.articleNumber}`}
          >
            <td>{itemEntry.inactive !== true && <Image src={itemEntry.imageUri} />}</td>
            <td>
              {itemEntry.inactive === true || !itemEntry.productId ? (
                <span>{itemEntry.articleNumber}</span>
              ) : (
                <InternalLink
                  content={{
                    text: itemEntry.articleNumber,
                    target: getProductLink(itemEntry),
                    window: '_self',
                  }}
                />
              )}
            </td>
            <td className={styles.product}>{itemEntry.shortDescription}</td>
            <td>{parseInt(itemEntry.quantity, 10)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const mapItemFileType = {
  mp4: 'MP4',
  mp3: 'MP3',
  excel: 'Excel',
  pdf: 'Pdf',
  zip: 'Zip',
  jpeg: 'Picture',
  jpg: 'Picture',
  gif: 'Picture',
  png: 'Picture',
};

export default mapItemFileType;

import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { Tabs, Typo, Container, Row, Col } from '@geberit/gdds';
import QueryString from 'qs';
import isEqual from 'lodash.isequal';

// styles
import styles from './search.module.scss';

// components
import { Headline, FontWeights, Formats } from 'components/ContentElements/Headline';
import { Translation } from 'components/Translation/Translation';
import SearchBar from './search-bar';
import SearchSoftContent from './search-softcontent';
import SearchProducts from './search-products';

// utils
import { buildSize, gridSizes } from 'utils/gridSize';
import {
  searchNoResultsSelector,
  selectParamsFromState,
  selectProducts,
  selectSoftContent,
} from 'utils/selectors/searchSelectors';
import {
  globalsSearchResultArticles,
  globalsSearchResultContent,
  globalsSearchSegmentSelector,
} from 'utils/selectors/globalsSelectors';
import { currentCatalogSelector } from 'utils/selectors/productCatalogSelectors';
import { translationKeysSelector } from 'utils/selectors/translationSelectors';
import { useKolo } from 'utils/hooks/use-kolo';
import { useKoloOrTwyford } from 'utils/hooks/use-kolo-twyford';
import {
  fetchSearchResults,
  setSearchFilter,
  setSearchOffset,
  setSearchQuery,
} from 'components/Search/actions';

export default function SearchContainer() {
  const articlesDisabled = useSelector(globalsSearchResultArticles);
  const contentDisabled = useSelector(globalsSearchResultContent);
  const noResults = useSelector(searchNoResultsSelector);
  const [selectedTab, setSelectedTab] = useState(contentDisabled ? 1 : 0);
  const dispatch = useDispatch();
  const router = useRouter();
  const query = useSearchParams();
  const pathname = usePathname();
  const params = useSelector(selectParamsFromState);
  const prevParams = useRef(params);
  const segment = useSelector(globalsSearchSegmentSelector);
  const catalogLanguage = useSelector(currentCatalogSelector);
  const softContentResult = useSelector(selectSoftContent);
  const articleResult = useSelector(selectProducts) || {};
  const translations = useSelector(translationKeysSelector) || {};
  const isKolo = useKolo();
  const isKoloOrTwyford = useKoloOrTwyford();

  // use client params on mount
  useEffect(() => {
    const q = query.get('q');
    const filter = query.get('filter');
    const offset = query.get('offset');
    // if search page is loaded without a query set the default query
    if (!q) {
      router.replace(`${pathname}?q=`);
    }

    if (q) dispatch(setSearchQuery(q));
    if (filter) dispatch(setSearchFilter(filter?.[0]));
    if (offset) dispatch(setSearchOffset(Number(offset?.[0])));
  }, []);

  // fetch new results on param update
  useEffect(() => {
    dispatch(fetchSearchResults());

    if (!isEqual(prevParams.current, params)) {
      const newParams = { ...params };
      if (newParams.query) {
        newParams.q = newParams.query;
        delete newParams.query;
      }
      const paramQuery = QueryString.stringify(newParams);
      router.push(`?${paramQuery}`, undefined, { shallow: true });
    }
  }, [segment, catalogLanguage, params, articlesDisabled, contentDisabled]);

  const tabsItems: Record<string, string>[] = [];

  if (!contentDisabled) {
    tabsItems.push({
      title: `${translations['web20_content']} (${softContentResult?.totalResults || 0})`,
    });
  }

  if (!articlesDisabled) {
    tabsItems.push({
      title: `${translations['web20_products_articles']} (${articleResult?.total || 0})`,
    });
  }

  return (
    <Container maxContentWidth={buildSize(gridSizes.gddsFullGrid)}>
      <Row>
        <Col size={[4, 8, 12]}>
          <div className={styles.wrapper}>
            <Headline
              title={translations['web20_search_headline']}
              format={Formats.h1}
              titleFontWeight={isKoloOrTwyford ? FontWeights.bold : undefined}
              subtitleFontWeight={isKolo ? FontWeights.bold : undefined}
              isUppercase
            />
          </div>
        </Col>
      </Row>
      <SearchBar
        count={selectedTab === 0 ? softContentResult?.totalResults : articleResult?.total}
      />
      {!noResults ? (
        <>
          <Row>
            <Col size={[4, 8, 3]} className={styles.searchTabs}>
              <Tabs
                initialSelected={selectedTab}
                alignment="horizontal"
                items={tabsItems}
                onTabChange={(e) => setSelectedTab(contentDisabled ? 1 : e.index)}
              />
            </Col>
          </Row>
          {selectedTab === 0 && <SearchSoftContent softContent={softContentResult} />}
          {selectedTab === 1 && <SearchProducts products={articleResult} />}
        </>
      ) : (
        <Row>
          <Col size={[0, 0, 3]} />
          <Col size={[4, 8, 6]} className={styles.searchWrapper}>
            <Typo variant="h7" fontWeight={700}>
              <Translation id="web20_search_suggestions" />
            </Typo>
            <ul className={styles.noResultSuggest}>
              <li>
                <Typo variant="p1">
                  <Translation id="web20_search_spelled-correctly" />
                </Typo>
              </li>
              <li>
                <Typo variant="p1">
                  <Translation id="web20_search_different-keywords" />
                </Typo>
              </li>
              <li>
                <Typo variant="p1">
                  <Translation id="web20_search_general-keywords" />
                </Typo>
              </li>
              <li>
                <Typo variant="p1">
                  <Translation id="web20_search_less-keywords" />
                </Typo>
              </li>
            </ul>
          </Col>
        </Row>
      )}
    </Container>
  );
}

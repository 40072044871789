import { useEffect, useRef, useContext, useMemo } from 'react';
import { useFormikContext } from 'formik';

// styles
import styles from './form.module.scss';

// types
import type { FieldProps } from './field';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { Message } from 'components/Message/Message';
import { Headline, Formats, FontWeights } from 'components/ContentElements/Headline';
import { Fieldset } from './fieldset';

// utils
import { FormContext } from './form-context';
import { buildFieldsetsMap } from './utils/build-fieldsets-map';
import { FORM_WITH_CONFIRMATION_MESSAGE } from './utils/mappings';
import { getWrapperElement } from './utils/get-wrapper-element';
import { scrollToPosition } from 'utils/scrollTo';
import { useIsDesktop } from 'components/App/SizeProvider';
import { usePreviewIdBuilder } from 'utils/preview-id-builder';
import { isEmpty } from 'utils/is-empty';
import { useGroup } from 'utils/hooks/use-group';
import { classNameBuilder } from '../../../utils/classNameBuilder';

export interface CountryFormProps {
  fields: FieldProps[];
  afterSubmit: () => void;
  sectionId?: string;
  hasInvalidFile: boolean;
  border: string;
  oxomiSubmitted?: boolean;
  title?: string;
  subtitle?: string;
  text?: string;
  isModal?: boolean;
  oxomiButton?: boolean;
}

export function CountryForm({
  fields,
  afterSubmit,
  sectionId,
  oxomiSubmitted,
  hasInvalidFile,
  border,
  title,
  subtitle,
  text,
  isModal = false,
}: Readonly<CountryFormProps>) {
  const { isSubmitting, isValid, handleSubmit } = useFormikContext<Record<string, string>>();
  const prevIsSubmitting = useRef(isSubmitting);
  const { submitMessages, formType, status, setStatus } = useContext(FormContext);
  const isDesktop = useIsDesktop();
  const previewIdBuilder = usePreviewIdBuilder();

  const hasOwnSuccesMessage = FORM_WITH_CONFIRMATION_MESSAGE.includes(formType);
  const isGroup = useGroup();
  const fieldsetGroups = useMemo(() => buildFieldsetsMap(fields), [fields]);

  useEffect(() => {
    if (prevIsSubmitting.current && !isSubmitting && (!isValid || hasInvalidFile)) {
      const errorNodes = document.querySelectorAll('.form-item--error');
      const firstErrorNode = errorNodes.length > 0 ? errorNodes[0].parentElement : null;
      if (!hasOwnSuccesMessage && firstErrorNode) {
        const headerHeight = isDesktop ? 138 : 60;
        const yPos =
          firstErrorNode.getBoundingClientRect().top + window.scrollY - headerHeight - 10;
        scrollToPosition(0, yPos);
      } else {
        setStatus({ messageIcon: 'alert', validationError: true });
      }
    }
    prevIsSubmitting.current = isSubmitting;
  }, [hasOwnSuccesMessage, hasInvalidFile, isDesktop, isSubmitting, isValid, setStatus]);

  useEffect(() => {
    if (!oxomiSubmitted) {
      // remove submit message when new oxomi items are selected
      setStatus({});
    }
  }, [oxomiSubmitted, setStatus]);

  useEffect(() => {
    if (hasOwnSuccesMessage && status?.messageIcon === 'success') {
      if (typeof afterSubmit === 'function') afterSubmit();
    }
  }, [afterSubmit, hasOwnSuccesMessage, status]);

  const ContainerElement = getWrapperElement('container', isGroup);
  const RowElement = getWrapperElement('row', isGroup);

  const formBorder = border === 'disable-border' ? styles.disableBorder : styles.formBorder;
  const maxContentWidth = isGroup ? { maxContentWidth: '52.5rem' } : {};

  return (
    <ContainerElement
      className={classNameBuilder(
        isGroup ? styles.gddsForm : 'grid-container',
        styles.formContainer,
      )}
      id={sectionId}
      {...maxContentWidth}
    >
      <Headline
        title={title}
        subtitle={subtitle}
        format={Formats.h2}
        titleFontWeight={FontWeights.bold}
        titlePreviewId="#st_title"
        subtitlePreviewId="#st_subtitle"
      />
      {text && (
        <InnerHtml
          className={styles.copyText}
          as="div"
          content={text}
          isGdds
          previewId="#st_content"
        />
      )}

      {status?.messageIcon && !hasOwnSuccesMessage && (
        <Message
          type={status.messageIcon}
          closable={false}
          content={status.messageIcon === 'alert' ? submitMessages.error : submitMessages.success}
        />
      )}

      {!isEmpty(status) &&
      status.messageIcon &&
      hasOwnSuccesMessage &&
      status.messageIcon === 'success' ? (
        <Message type={status.messageIcon} closable={false} content={submitMessages.success} />
      ) : (
        <form
          onSubmit={handleSubmit}
          className={formBorder}
          data-preview-id={previewIdBuilder(isModal ? undefined : '#st_formFields')}
        >
          <RowElement className={isGroup ? styles.rowElement : 'grid-x grid-margin-x'}>
            {fieldsetGroups.map(({ id, type, legend, colorVariant, fields }, index) => (
              <Fieldset
                key={(id ?? '') || `container-${index}`}
                id={id}
                type={type}
                legend={legend}
                colorVariant={colorVariant}
                fields={fields}
                withPreviewId={!isModal}
              />
            ))}
          </RowElement>
        </form>
      )}
    </ContainerElement>
  );
}

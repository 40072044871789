// types
import type { ImageThumbnailProps } from './image.types';

// components
import { Image } from './image';

export function ImageThumbnail({
  className,
  thumbnail,
  content,
  description,
  onChange,
}: Readonly<ImageThumbnailProps>) {
  return (
    <div className={`c-${className}__thumbnail`}>
      <Image
        format="1_1"
        src={thumbnail}
        alt={content}
        title={(description ?? '') || content}
        onMouseOver={onChange}
        onFocus={onChange}
      />
    </div>
  );
}

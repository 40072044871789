import { useSelector } from 'react-redux';
import { useRouter, usePathname } from 'next/navigation';
import striptags from 'striptags';
import { Divider, Icon, Button } from '@geberit/gdds';

// styles
import styles from './courseInformation.module.scss';

// types
import type { CourseDateList, CourseMetaItem } from '../types';

// components
import { Image } from 'components/image/image';
import { Translation } from 'components/Translation/Translation';
import { CourseInformationItems } from '../CourseTeaser/CourseInformationItems';

// utils
import { campusBaseUrlSelector } from 'utils/selectors/globalsSelectors';
import { TruncateText } from './TruncateText';

interface CourseInformationProps {
  image: string;
  courseHeadline: string;
  targetGroup: string;
  courseDate: CourseDateList;
  availableSeats: string | number;
  seats: string | number;
  costs: string | number;
  costsIncluded?: CourseMetaItem[];
  location: string;
  language: string;
  isAvailable: boolean;
  isCanceled: boolean;
  isBooked: boolean;
  info: string;
  isBookedPage: boolean;
  isEmployee: boolean;
  isCiam: boolean;
  duration: string;
  noParticipantLimit: boolean;
}

export function CourseInformation({
  image,
  courseHeadline,
  targetGroup,
  courseDate,
  duration,
  availableSeats,
  seats,
  costs,
  costsIncluded,
  location,
  language,
  isAvailable = false,
  isCanceled = false,
  isBooked = false,
  info,
  isBookedPage = false,
  isEmployee = false,
  isCiam = false,
  noParticipantLimit,
}: Readonly<CourseInformationProps>) {
  const campusBaseUrl = useSelector(campusBaseUrlSelector);
  const router = useRouter();
  const pathname = usePathname();

  return (
    <div className="grid-container">
      <div className={styles.courseTeaser}>
        <div className={styles.imageContainer}>
          <Image
            src={
              /* eslint-disable max-len */
              image
                ? `${campusBaseUrl}/admin${image}`
                : `${campusBaseUrl}/admin/data/client_design/geberit/global/images/course_description_image_default.png`
              /* eslint-enable max-len */
            }
            alt={courseHeadline}
            title={courseHeadline}
          />
        </div>
        <div className={styles.courseDetails}>
          {!isEmployee && (
            <>
              {(isAvailable || isCanceled || isBooked) && (
                <div className={styles.flags}>
                  {!isBooked && isAvailable && !isEmployee && (
                    <span className={`${styles.status} available`}>
                      <Translation id="campus_course_flag_status_available" />
                    </span>
                  )}
                  {isCanceled && (
                    <span className={`${styles.status} canceled`}>
                      <Translation id="campus_course_flag_status_canceled" />
                    </span>
                  )}
                  {isBooked && (
                    <span className={`${styles.status} booked`}>
                      <Translation id="campus_course_flag_status_booked" />
                    </span>
                  )}
                </div>
              )}
            </>
          )}
          {info && (
            <div className={styles.info}>
              <Icon symbol="InfoCircleOutline" />
              <TruncateText text={striptags(info)} />
            </div>
          )}
          <CourseInformationItems
            targetGroup={targetGroup}
            courseDate={courseDate}
            availableSeats={availableSeats}
            seats={seats}
            costs={costs}
            costsIncluded={costsIncluded}
            duration={duration}
            location={location}
            language={language}
            isEmployee={isEmployee}
            isCiam={isCiam}
          />
          {!isEmployee && isBooked && (
            <div className={styles.buttonWrapper}>
              {(noParticipantLimit || availableSeats) && (
                <Button
                  onClick={() => {
                    router.push(`${pathname}#register`);
                  }}
                  stylingType="primary"
                >
                  <Translation id={isBooked ? 'campus_add_bookings' : 'campus_book_course'} />
                </Button>
              )}
              <Button
                onClick={() => {
                  router.push(`${pathname}#cancel`);
                }}
              >
                <Translation id="campus_cancel_course" />
              </Button>
            </div>
          )}
          {!isEmployee && !isBooked && (
            <Button
              onClick={() => {
                router.push(`${pathname}#register`);
              }}
              stylingType="primary"
            >
              <Translation id="campus_book_course" />
            </Button>
          )}
        </div>
      </div>
      {!isBookedPage && <Divider />}
    </div>
  );
}

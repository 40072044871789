import styled from 'styled-components';

export const StyledAccordions = styled.div`
  @media print {
    display: none;
  }

  grid-area: accordions;

  h2 {
    margin-bottom: 1rem;
  }
`;

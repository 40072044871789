import qs from 'qs';
import { Icon } from '@geberit/gdds';

// styles
import styles from './social-share.module.scss';

// components
import { CmsLink } from 'components/Link/CmsLink';

// utils
import { useTranslationByKey } from 'utils/hooks/use-translations';

type SocialShareIconProps = {
  baseUrl: string;
  url: string;
  symbol: string;
  title: string;
  hashtags?: string[];
  trackingCategory?: string;
};

function SocialShareIcon({
  baseUrl,
  url,
  symbol,
  hashtags = [],
  title = '',
  trackingCategory,
}: Readonly<SocialShareIconProps>) {
  let sharingBody;
  let trackingIcon = symbol;
  let iconName;
  const headline = useTranslationByKey('group_insights_share_subject');

  switch (symbol) {
    case 'facebook':
      sharingBody = qs.stringify({
        u: url,
        hashtags: hashtags.join(','),
      });
      iconName = 'Facebook';
      break;
    case 'twitter':
      sharingBody = qs.stringify({
        text: title,
        url,
        hashtags: hashtags.join(','),
      });
      iconName = 'Twitter';

      break;
    case 'linkedin':
      sharingBody = qs.stringify({
        mini: 'true',
        url,
        title,
        summary: `\n${title}\n${url}`,
        provider: url,
      });
      iconName = 'Linkedin';

      break;
    case 'service-mail':
      sharingBody = qs.stringify({
        subject: `${headline}: ${title}`,
        body: `\n\n\n${title}\n${url}`,
      });
      trackingIcon = 'mail';
      iconName = 'Envelope';

      break;

    default:
      return null;
  }

  const link = {
    target: `${baseUrl}${sharingBody}`,
    window: symbol !== 'service-mail' ? '_blank' : '',
    rel: 'nofollow',
  };

  const capitalizeIcon = trackingIcon.charAt(0).toUpperCase() + trackingIcon.slice(1);
  return (
    <CmsLink
      link={link}
      socialTrackingName="Social share"
      socialTrackingIcon={capitalizeIcon}
      trackingCategory={trackingCategory}
      className={styles.link}
    >
      <span className={styles[iconName]}>
        <Icon symbol={iconName} width="1.5rem" color="white" />
      </span>
    </CmsLink>
  );
}

export default SocialShareIcon;

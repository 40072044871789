import { trackTemplate } from '../../utils/tracking';

/**
 * Add to download cart click
 * @param string text
 */
export function clickAddToDownloadCart(text) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Download center',
      action: 'Download Center Add to Download Basket',
      label: text,
      value: '0',
    },
  });
}

/**
 * Add to order cart click
 * @param string text
 */
export function clickAddToOrderCart(text) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Download center',
      action: 'Download Center Add to Order Basket',
      label: text,
      value: '0',
    },
  });
}

/**
 * Zip cart click
 * @param string text
 */
export function clickZipCart(text) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Download center',
      action: 'Download Center ZIP',
      label: text,
      value: '0',
    },
  });
}

/**
 * Flipbook and Fileurl click
 * @param string text
 */
export function clickDownloadsLink(name, title) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Download center',
      action: `Download Center ${name}`,
      label: title,
      value: '0',
    },
  });
}

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { usePathname, useRouter } from 'next/navigation';
import { range } from 'lodash';
import axios from 'axios';
import QS from 'qs';

// utils
import { transformRows } from '../transform-rows';
import getEndpoint from 'utils/endpoints';
import { globalsServerSelector } from 'utils/selectors/globalsSelectors';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';

interface UseInsightsParams {
  page?: number;
  topics?: string[];
  years?: string[];
}

enum State {
  Idle,
  Loading,
  Ready,
}

export function useInsights({ page, topics, years }: UseInsightsParams) {
  const router = useRouter();
  const pathname = usePathname();
  const lang = useCurrentLanguage();
  const serverVars = useSelector(globalsServerSelector);

  const [state, setState] = useState(State.Idle);
  const key = QS.stringify({
    lang,
    topic: (topics?.join(',') ?? '') || undefined,
    year: (years?.join(',') ?? '') || undefined,
  });
  const queryKey = useMemo(() => ['insights', key], [key]);

  const fetchInsights = async ({ pageParam = 1 }) => {
    const query = `?${key}&page=${pageParam}`;

    if ((!page || page < pageParam) && pageParam > 1) {
      const searchParams = new URLSearchParams();
      if (topics?.length) {
        searchParams.append('topic', topics.join(','));
      }
      if (years?.length) {
        searchParams.append('year', years.join(','));
      }
      searchParams.append('page', `${pageParam}`);
      router.replace(`${pathname}?${searchParams.toString()}${window.location.hash}`, {
        scroll: false,
      });
    }
    if (!page || pageParam === page) {
      setState(State.Ready);
    }

    return (await axios.get(`${getEndpoint('insights', { server: serverVars })}${query}`)).data;
  };

  const queryClient = useQueryClient();

  const { data, hasNextPage, fetchNextPage } = useInfiniteQuery(queryKey, fetchInsights, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 0 ? pages.length + 1 : undefined;
    },
    initialDataUpdatedAt: Date.now(),
    initialData: () => {
      setState(State.Loading);
      if (page) {
        // prefill query data with empty data. query will auto refetch.
        const lastPage = page;
        const initialData = range(1, lastPage + 1);
        const data = {
          pageParams: initialData.map((page) => page),
          pages: initialData.map(() => []),
        };
        queryClient.setQueryData(queryKey, data);
      }
      return { pageParams: [], pages: [] };
    },
  });

  const rows = useMemo(() => {
    return transformRows(data?.pages?.flat() ?? []);
  }, [data]);

  return {
    rows,
    ready: state === State.Ready,
    hasNextPage: hasNextPage ?? false,
    fetchNextPage,
  };
}

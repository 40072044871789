import { Container, Col, Row } from '@geberit/gdds';

// styles
import styles from './full-grid-image.module.scss';

// components
import { Image } from 'components/image/image';

// utils
import { responsiveImage } from 'utils/responsiveImage';
import { classNameBuilder } from 'utils/classNameBuilder';
import { emptyStringFallback } from 'utils/is-empty';
import { useIsMobile } from 'components/App/SizeProvider';

export type FullGridImageProps = {
  imageObject: ImageObject;
  imageObjectMobile: ImageObjectMobile;
  image: string;
  previewId: string;
  isContainerChild?: boolean;
  pictureAlt?: string;
  backgroundColor?: string;
};

export function FullGridImage({
  imageObject,
  imageObjectMobile,
  pictureAlt,
  image,
  previewId,
  isContainerChild,
  backgroundColor = 'white',
}: Readonly<FullGridImageProps>) {
  const isMobile = useIsMobile();
  const imageObj = responsiveImage(isMobile, imageObjectMobile, imageObject);

  const renderContainer = (children) => {
    if (!isMobile) {
      return (
        <Container maxContentWidth="78rem">
          <Row>
            <Col>{children}</Col>
          </Row>
        </Container>
      );
    }

    return children;
  };

  return (
    <div
      className={classNameBuilder(
        styles.fullgridImage,
        styles[`${backgroundColor}Background`],
        isContainerChild && styles.isContainerChild,
      )}
    >
      {renderContainer(
        <div className={styles.imageWrapper}>
          <Image
            data={imageObj}
            src={emptyStringFallback(imageObj?.url, image)}
            alt={pictureAlt}
            previewId={previewId}
          />
        </div>,
      )}
    </div>
  );
}

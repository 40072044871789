import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import key from 'weak-key';

// types
import type { Dispatch } from 'redux';

// components
import { SingleTile } from '../../Product/Tile/SingleTile';
import SearchPagination from '../SearchPagination/SearchPagination';

// utils
import { currentCatalogSelector } from '../../../utils/selectors/productCatalogSelectors';
import {
  selectProducts,
  selectParamsFromState,
  searchIsFetchingSelector,
} from '../../../utils/selectors/searchSelectors';
import { SEARCH_PAGE_SIZE } from '../constants';
import { setSearchOffset } from '../actions';
import { clickSearchResult } from '../trackingActions';
import { useTracking } from '../../../utils/hooks/useTracking';
import { Loader } from '../../Loader/Loader';

function ProductResult() {
  const params = useSelector(selectParamsFromState) as any;
  const { offset } = params;

  const track = useTracking();
  const dispatch: Dispatch<any> = useDispatch();
  const currentOffset = offset || 0;
  const handleNextPage = useCallback(() => {
    dispatch(setSearchOffset(currentOffset + SEARCH_PAGE_SIZE));
  }, [dispatch, currentOffset]);

  const isFetching = useSelector(searchIsFetchingSelector);
  const catalogLanguage = useSelector(currentCatalogSelector) as any;
  const productResults = useSelector(selectProducts) as any;

  if (!productResults) return null;
  const { results, last, total } = productResults;
  if (!results) return null;

  const tracking = (title) => {
    track.trackEvent(clickSearchResult(title, 'Result Page Product Clicks'));
  };

  return (
    <div className="c-cart cell small-12">
      <div className="c-product-tiles c-product-tiles__four-columns">
        {results.map((item) => {
          if (item.constructor === Object && item.gallery && item.gallery.list.length > 0) {
            return (
              <div
                className="cell small-12 medium-6 large-3 c-product-tiles__column"
                key={key(item)}
              >
                <SingleTile
                  key={key(item)}
                  product={item}
                  language={catalogLanguage}
                  linkType="product_detail_page"
                  tracking={tracking}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
      {isFetching && (
        <div className="grid-container global_search">
          <Loader size="large" />
        </div>
      )}
      <SearchPagination
        isLast={last}
        current={last ? total : currentOffset + SEARCH_PAGE_SIZE}
        total={total}
        handleNextPage={handleNextPage}
      />
    </div>
  );
}

export default ProductResult;

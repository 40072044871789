'use client';

// components
import Footer from 'components/Footer/Footer';
import { ContentArea } from 'components/ContentArea/ContentArea';

// types
import type { ContentElementPayload } from 'components/ContentElements/content-elements-payload';

// utils
import { isEmpty } from 'utils/is-empty';
import { useContent } from 'components/Page/page-hooks';

type LandingPageProps = {
  contentAreas: {
    landingpage_content: ContentElementPayload[];
  };
};

export function Landingpage() {
  const page = useContent<LandingPageProps, {}>();

  if (isEmpty(page)) {
    return null;
  }

  const content = page?.contentAreas?.landingpage_content || [];
  return (
    <>
      <main>
        <ContentArea content={content} contentType="landingpage_content" />
      </main>
      <Footer />
    </>
  );
}

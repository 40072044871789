// utils
import { getStringSpecificId } from 'utils/slug';
import hashCode from 'utils/hash';

interface MiVideoViewProps {
  videoId: string;
  previewImageDisplay: string;
  domId?: string;
  className?: string;
}

export default function MiVideoView({
  videoId,
  previewImageDisplay,
  domId,
  className,
}: Readonly<MiVideoViewProps>) {
  const str = getStringSpecificId('video-content', videoId, '');
  const hashStr = hashCode(str);

  return (
    <div
      key={videoId}
      id={domId ?? hashStr}
      className={
        className !== 'c-text-video' &&
        className !== 'c-video-content' &&
        className !== 'hero-image-c'
          ? `video-player ${previewImageDisplay}`
          : ''
      }
      mi24-video-player="true"
      disable-auto-creation="true"
      video-id={videoId}
      player-id="F2-cZcup6X4DpJuXo-bCod"
      config-type="vmpro"
      flash-path="//e.video-cdn.net/v2/"
      api-url="//d.video-cdn.net/play"
    />
  );
}

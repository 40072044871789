import PaginationButtons from './PaginationButtons';
import { ICON_COLORS, Icon } from '../Icon/Icon';
import { BUTTON_TYPES, Button } from '../Button/Button';

interface PaginationArrowsProps {
  length: number;
  currentPageIndex: number;
  handleClickButtons: (value: number) => void;
  handleClickDots: (index: number) => void;
}

function PaginationArrows({
  currentPageIndex,
  handleClickButtons,
  length,
  handleClickDots,
}: Readonly<PaginationArrowsProps>) {
  const lastCol = length - 1;

  const handleClickButtonLeft = () => {
    if (currentPageIndex > 0) {
      handleClickButtons(-1);
    }
  };

  const handleClickButtonRight = () => {
    if (currentPageIndex < lastCol) {
      handleClickButtons(1);
    }
  };

  return (
    <>
      <Button
        type={BUTTON_TYPES.SEAMLESS}
        className={`${currentPageIndex <= 0 ? 'left' : 'left is--active'}`}
        onClick={handleClickButtonLeft}
      >
        <Icon symbol="arrow-link-bold-left" color={ICON_COLORS.PRIMARY} />
      </Button>
      <PaginationButtons
        index={currentPageIndex}
        length={length}
        handleClickIndex={handleClickDots}
        withPagination
      />
      <Button
        type={BUTTON_TYPES.SEAMLESS}
        className={`${currentPageIndex >= lastCol ? 'right' : 'right is--active'}`}
        onClick={handleClickButtonRight}
      >
        <Icon symbol="arrow-link-bold-right" color={ICON_COLORS.PRIMARY} />
      </Button>
    </>
  );
}

export default PaginationArrows;

import { useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';
import { useSearchParams } from 'next/navigation';

// components
import InsightsTeaserList from '../../Teaser/insights-teaser-list';

// utils
import { isInViewport, useEnteredViewport } from 'utils/hooks/useLazyLoad';
import { ensureScrollYToPosition } from 'utils/scrollTo';
import { useInsights } from '../hooks/use-insights';
import { isBrowser } from 'utils/is-browser';

const scrollToHash = async (container: Element | null | undefined) => {
  if (isBrowser) {
    let posY = Number(window.location.hash.replace('#', '') || 0);
    if (location.search && container) {
      const headerOffset = 140;
      const containerOffsetTop = container?.getBoundingClientRect().top - headerOffset;
      posY = Math.max(posY, containerOffsetTop);
    }
    if (posY > 0) {
      return await ensureScrollYToPosition({ y: posY, timeout: 400 });
    }
    return true;
  }
  return false;
};

type ListProps = {
  selectedTopics: string[];
  selectedYears: string[];
};

function List({ selectedTopics, selectedYears, ...props }: Readonly<ListProps>) {
  const mounted = useRef(false);
  const searchParams = useSearchParams();
  const page = searchParams.get('page');
  const { rows, ready, hasNextPage, fetchNextPage } = useInsights({
    page: page ? parseInt(page) : undefined,
    topics: selectedTopics,
    years: selectedYears,
  });
  // check whether new items need to be loaded
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const loadMore = useEnteredViewport({
    ref: scrollRef,
    offset: 1000,
    continueObserve: true,
  });
  const isScrolledToHash = useRef(false);

  const asyncEffect = async () => {
    if (
      ready &&
      !isScrolledToHash.current &&
      mounted.current &&
      scrollRef?.current?.parentElement
    ) {
      await scrollToHash(scrollRef.current.parentElement);
      isScrolledToHash.current = true;
    }
  };

  useEffect(() => {
    asyncEffect();
  }, [ready, rows]);

  // save scroll position
  useEffect(() => {
    mounted.current = true;

    const onScrollChange = debounce(() => {
      if (mounted.current) {
        window.location.hash = String(Math.round(window.scrollY));
      }
    }, 100);

    const handleScroll = async () => {
      await ensureScrollYToPosition({ y: 0 });

      // scrolling still works weirdly in some browsers without the timeout
      setTimeout(() => {
        window.addEventListener('scroll', onScrollChange);
        window.addEventListener('resize', onScrollChange);
      }, 100);
    };
    handleScroll();

    return () => {
      mounted.current = false;
      window.removeEventListener('scroll', onScrollChange);
      window.removeEventListener('resize', onScrollChange);
    };
  }, []);

  useEffect(() => {
    // since the viewport hook only reacts on scroll or resize we double check
    if (loadMore && isInViewport(scrollRef.current, 1000) && hasNextPage) {
      fetchNextPage();
    }
  }, [loadMore, hasNextPage, fetchNextPage]);

  return (
    <>
      <InsightsTeaserList rows={rows} {...props} />
      <div ref={scrollRef} />
    </>
  );
}

export default List;

import key from 'weak-key';

// components
import LinkBlock from './LinkBlock';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { SearchIndexOff } from 'components/SearchIndex/SearchIndexOff';

// utils
import { isEmpty } from 'utils/is-empty';
import { generateSlug } from 'utils/slug';

function RelatedLinks({ title = '', anchor = '', categories = [] }: Readonly<RelatedLinksProps>) {
  if (isEmpty(title) || isEmpty(categories)) {
    return null;
  }

  return (
    <SearchIndexOff>
      <section className="c-related-links" id={generateSlug(anchor ?? title ?? '')}>
        <div className="grid-container grid-x">
          <div className="small-12 medium-12 large-3 cell">
            <InnerHtml as="div" content={title ?? ''} />
          </div>
          <div className="grid-container grid-x list-block-column small-12 medium-12 large-9">
            {categories?.map((items) => <LinkBlock linkBlock={items} key={key(items)} />)}
          </div>
        </div>
      </section>
    </SearchIndexOff>
  );
}

export default RelatedLinks;

import React, { useEffect } from 'react';

export function SetScrollbarWidth({ children }) {
  useEffect(() => {
    document.body.style.setProperty(
      '--scrollbar-width',
      `${window.innerWidth - document.body.clientWidth}px`,
    );
  }, []);

  return <>{children}</>;
}

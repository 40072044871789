// components
import { Image } from 'components/image/image';

/**
 * Render single PDP slide thumbnail image
 *
 * @param image  Image source
 * @param imageAlt  Image alt text
 * @param format  Image format
 * @param props Additional properties
 * @returns {*}
 * @constructor
 */

interface ProductDetailSlideProps {
  image: string;
  imageAlt: string;
  format: string;
}

const ProductDetailSlide = ({
  image,
  imageAlt,
  format,
  ...props
}: Readonly<ProductDetailSlideProps>) => (
  <div className="c-product-detail__slide">
    <Image {...props} src={image} alt={imageAlt} format={format} />
  </div>
);

export default ProductDetailSlide;

import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/navigation';
import key from 'weak-key';
import {
  Container,
  Col,
  Row,
  ExpressiveTile,
  Tiles,
  Tlea1Props,
  CarouselSSRWrapper,
} from '@geberit/gdds';

// styles
import styles from './tiles.module.scss';

// types
import type { HeroTilesProps } from './hero-tiles.types';

// utils
import { useIsMobile } from 'components/App/SizeProvider';
import { buildSize, gridSizes } from 'utils/gridSize';
import { decodingContent } from 'utils/decodingContent';
import { classNameBuilder } from 'utils/classNameBuilder';
import { handlePopup } from 'utils/openInPopup';

export function HeroTiles({ items = [] }: Readonly<HeroTilesProps>) {
  const isMobile = useIsMobile();
  const router = useRouter();

  const renderColOrSlider = (children: JSX.Element[]) => {
    if (children.length > 1) {
      return (
        <Col size={[4, 8, 12]} className={`${styles.heroTile__carousel}`}>
          <CarouselSSRWrapper
            autoSnap
            responsiveLayout={{
              small: { slides: 1, slidesToMove: 1 },
              medium: { slides: 1, slidesToMove: 1 },
              large: { slides: 1, slidesToMove: 1 },
            }}
            supportMouse
            customButtonRight
            customButtonLeft
            slideMargin={0}
            autoSliding
            loop
            slidingInterval={6}
          >
            {children}
          </CarouselSSRWrapper>
        </Col>
      );
    }

    return <Col size={[4, 8, 12]}>{children}</Col>;
  };

  const handleLinkOnClick = (item: HeroTile, link?: Link) => () => {
    if (link?.window === '_blank') {
      window.open(link?.target);
    } else if (link?.window === '_popup') {
      const windowId = key(item);
      handlePopup(link, windowId);
    } else if (link?.type === 'external_link') {
      window.location.href = link?.target;
    } else if (link?.type === 'mail_link') {
      window.open(link?.target, '_self');
    } else if (link?.target) router.push(link?.target);
  };

  const buildTile = (item: HeroTile): JSX.Element => {
    const { imageAspectRatios, image } = getImage(item);
    const backgroundType = item.textbox?.color === 'black' ? 'black' : 'light';
    const link = item.textbox?.link;

    const aspectRatios = { ...imageAspectRatios, medium: '16:9', large: '16:9' };

    return (
      <div key={key(item)} className={classNameBuilder(styles.herotile, styles[backgroundType])}>
        <Tiles gap="0" type="expressive">
          <ExpressiveTile
            type="tlea1"
            imageAspectRatios={aspectRatios}
            backgroundType={backgroundType}
            image={image}
            imageTitle={item.pictureAlt}
            altText={item.pictureAlt}
            title={decodingContent(item.textbox?.headline)}
            subTitle={decodingContent(item.textbox?.subline)}
            headlineVariant="h2"
            button={
              link && {
                text: link?.text ?? '',
                onClick: handleLinkOnClick(item, link),
              }
            }
          />
        </Tiles>
      </div>
    );
  };

  function getImage(item: HeroTile) {
    const image = (isMobile && item.imageObjectMobile?.url) || item.imageObject?.url || item.image;
    const { smallAspectRatio, mediumAspectRatio } = getAspectRatio(item);

    const imageAspectRatios = {
      small: smallAspectRatio,
      medium: mediumAspectRatio,
      large: mediumAspectRatio,
      xlarge: mediumAspectRatio,
    } as Tlea1Props['imageAspectRatios'];

    return { imageAspectRatios, image };
  }

  function getAspectRatio(item: HeroTile) {
    const ratioRegEx = new RegExp(/^\d+_\d+$/);

    const smallAspectRatio = ratioRegEx.test(item.imageObjectMobile?.aspectRatio)
      ? item.imageObjectMobile?.aspectRatio?.replace('_', ':')
      : undefined;
    const mediumAspectRatio = ratioRegEx.test(item.imageObjectMobile?.aspectRatio)
      ? item.imageObject?.aspectRatio?.replace('_', ':')
      : undefined;

    return { smallAspectRatio, mediumAspectRatio };
  }

  if (isEmpty(items)) {
    return null;
  }

  return (
    <Container maxContentWidth={buildSize(gridSizes.gddsFullGrid)}>
      <Row>{renderColOrSlider(items?.map(buildTile))}</Row>
    </Container>
  );
}

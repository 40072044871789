import { FormEventHandler } from 'react';
import key from 'weak-key';

// Components
import Filter from './Filter';
import { Translation } from '../Translation/Translation';

// Constants
import { SORTING_DATA } from './constants';

export type Data = {
  totalResults: number;
  aggregations: Record<string, unknown[]>;
  request: Partial<{
    sort: string[];
  }>;
};

type Filters = Record<string, string[]>;

interface SumAndSortAreaProps {
  data: Data;
  handleFilterChange: FormEventHandler<HTMLFormElement>;
  activeFilters: Filters;
}

export default function SumAndSortArea({
  data: { totalResults, request },
  handleFilterChange,
  activeFilters = {},
}: Readonly<SumAndSortAreaProps>) {
  return (
    <section className="c-filter-area grid-container">
      <form onSubmit={handleFilterChange} id="form1" key={key(activeFilters)}>
        <div className="c-filter-area__results grid-x grid-margin-x">
          <div className="cell small-12 medium-4 large-6 c-filter-area__results-sum">
            {totalResults !== undefined && (
              <h3 className="h4">
                {`${totalResults} `}
                <Translation id="web20_downloadcenter_filter_sum" />
              </h3>
            )}
          </div>
          <div className="cell small-12 medium-4 large-3 c-filter-area__sort">
            <h3 className="headline-right h4">
              <Translation id="web20_downloadcenter_sort" />
            </h3>
          </div>
          <div className="cell small-12 medium-4 large-3">
            <Filter
              activeItems={request?.sort as string[]}
              filterKey="sorting"
              options={SORTING_DATA.sorting}
              handleFilterChange={handleFilterChange}
            />
          </div>
        </div>
      </form>
    </section>
  );
}

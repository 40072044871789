// text is the translation key
export const MATERIAL_TYPES = [
  [
    {
      id: 'press_all_news',
      value: 'all',
      text: 'press_all_news',
    },
    {
      id: 'press_press_release',
      value: 'pressrelease',
      text: 'press_release',
    },
    {
      id: 'press_blog_post',
      value: 'blog_post',
      text: 'press_blog_post',
    },
    {
      id: 'press_news',
      value: 'news',
      text: 'press_news',
    },
  ],
  [
    {
      id: 'press_all_media',
      value: 'all',
      text: 'press_all_media',
    },
    {
      id: 'press_image',
      value: 'image',
      text: 'press_image',
    },
    {
      id: 'press_video',
      value: 'video',
      text: 'press_video',
    },
    {
      id: 'press_document',
      value: 'document',
      text: 'press_document',
    },
  ],
];

export const mediaTypeTranslationKeyMap = {
  video: 'press_download_video',
  image: 'press_download_image',
  pdf: 'press_download_pdf',
  file: 'press_download_file',
};

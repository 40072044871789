import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useRef } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { globalsSearchSegmentSelector } from 'utils/selectors/globalsSelectors';
import { fetcher } from './fetcher';

const DEFAULT_SORTING = 'date';
const DEFAULT_PAGE = 1;

export function useDownloadCenter(
  options: { prefilterTags: string[]; itemsPerPage?: number },
  initialData,
) {
  const ITEMS_PER_PAGE = options.itemsPerPage ?? 12;
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const {
    sort = DEFAULT_SORTING,
    q,
    filter,
    page: pageFromQuery,
  } = Object.fromEntries(searchParams);
  const page = pageFromQuery ? parseInt(pageFromQuery, 10) : 1;
  const segment = useSelector(globalsSearchSegmentSelector);

  const aggregations = useRef(undefined);
  const replaceSearchParams = (query: Record<string, string>) => {
    const newQueryWithEmptyFields = new URLSearchParams({
      ...Object.fromEntries(searchParams),
      ...query,
    });
    router.replace(`${pathname}?${newQueryWithEmptyFields.toString()}`, { scroll: false });
  };
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['downloadcenter', sort, filter, q, segment, options],
    fetcher({ page, sort, segment, options, ITEMS_PER_PAGE, filter, q, replaceSearchParams }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.aggregations) {
          aggregations.current = lastPage.aggregations;
        }
        const loadedItems = pages.reduce((acc, p) => acc + (p.results?.length || 0), 0) as number;
        const pagesLoaded = Math.ceil(loadedItems / ITEMS_PER_PAGE);
        return loadedItems < lastPage.totalResults ? pagesLoaded + 1 : undefined;
      },
      initialData,
    },
  );

  return {
    data:
      data?.pages && data?.pages.length > 0
        ? {
            ...data.pages[0],
            results: data?.pages.flatMap((page) => page.results),
          }
        : { results: [], aggregations: aggregations.current, request: { sort: [sort] } },
    loadMore: fetchNextPage,
    isLastPage: !hasNextPage,
    setSorting: (sort: string) => {
      replaceSearchParams({ sort, page: String(DEFAULT_PAGE) });
    },
    activeFilters: parseFilter(Object.fromEntries(searchParams).filter),
    setFilters: (newFilters: Record<string, string[]>) => {
      replaceSearchParams({
        filter: stringifyFilter(
          Object.fromEntries(Object.entries(newFilters).filter(([_, value]) => value?.length)),
        ),
        page: String(DEFAULT_PAGE),
      });
    },
    search: Object.fromEntries(searchParams).q || '',
    setSearch: (query: string) => {
      replaceSearchParams({ q: query, page: String(DEFAULT_PAGE) });
    },
  };
}

function stringifyFilter(filter: Record<string, string[]>): string {
  const filterString = Object.entries(filter)
    .map(([key, value]) => {
      return `${key}:${value.join('|')}`;
    })
    .join('.');

  return filterString;
}

function parseFilter(filterString: string): Record<string, string[]> {
  let filter = {};
  if (filterString) {
    filter = filterString.split('.').reduce((acc, f) => {
      const [key, value] = f.split(':');
      acc[key] = value.split('|');
      return acc;
    }, {});
  }

  return filter;
}

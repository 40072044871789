import { useEffect, useMemo, useState } from 'react';
import { usePathname } from 'next/navigation';

// utils
import { useIsMobile } from 'components/App/SizeProvider';
import { isEmpty } from 'utils/is-empty';

const skipFirst = (_: unknown, index: number): boolean => index >= 1;

export function useChannels({ searchQueryChannelId, currentLanguage, channels }) {
  const pathname = usePathname();
  const hash = pathname.split('#')?.[1];
  const [sharedVideo, setSharedVideo] = useState<string | undefined>();
  const isMobile = useIsMobile();
  const [currentLength, setCurrentLength] = useState(isMobile ? 6 : 12);

  function handleLoadMore() {
    if (!channel) return;

    if (currentLength < channel.videos.length - 3) {
      setCurrentLength(currentLength + 3);
    } else if (currentLength < channel.videos.length) {
      setCurrentLength(channel.videos.length);
    }
  }

  useEffect(() => {
    if (hash && !isEmpty(channels)) {
      const channel = channels.find((item: { id: string }) => item.id === searchQueryChannelId);
      if (!channel) return;

      const videoId = hash;
      const video = channel.videos
        .filter(skipFirst)
        .find((videoItem: { video: { videoId: string } }) => videoItem.video.videoId === videoId);

      if (isEmpty(video)) return;

      setSharedVideo(video.title);
      let videoLength = video.index + 1;
      if (currentLength < videoLength) {
        videoLength = videoLength % 3 === 0 ? videoLength : videoLength - (videoLength % 3) + 3;
        setCurrentLength(videoLength);
      }
    }
  }, [channels, currentLanguage, currentLength, hash, searchQueryChannelId]);

  const channel = channels.find((item: { id: string }) => item.id === searchQueryChannelId);
  const firstVideo = channel?.videos[0];
  const videos = useMemo(
    () => channel?.videos.slice(0, currentLength + 1),
    [channel, currentLength],
  );
  const loadable = channel && currentLength < channel.videos.length;

  return {
    videos,
    sharedVideo,
    handleLoadMore,
    firstVideo,
    loadable: loadable,
    channel,
    channels,
  };
}

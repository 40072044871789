// styles
import styles from './linkWrapper.module.scss';

type LinkWrapperProps = {
  translationLink?: string;
};

function LinkWrapper({ translationLink = '' }: Readonly<LinkWrapperProps>) {
  return (
    // eslint-disable-next-line react/no-danger
    <span className={styles.linkWrapper} dangerouslySetInnerHTML={{ __html: translationLink }} />
  );
}

export default LinkWrapper;

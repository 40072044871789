import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SessionContext } from '../../components/Session/SessionContext';
import { loadList } from '../../components/ShoppingBasket/actions';
import { webshopEnabledSelector } from '../selectors/globalsSelectors';
import { webshopMaintenanceEnabledSelector } from '../selectors/maintenanceSelectors';
import { currentCatalogSelector } from '../selectors/productCatalogSelectors';
import {
  shoppingBasketItemsSelector,
  shoppingBasketLoadingSelector,
} from '../selectors/shoppingBasketSelector';

export function useWebshopCartItem(productKey, paymentLimit) {
  const [loading, setLoading] = React.useState(true);
  const [result, setResult] = React.useState(true);
  const [isAtLimit, setIsAtLimit] = React.useState(true);

  const {
    state: { ciam, session, sessionIsLoading },
  } = React.useContext(SessionContext);

  const items = useSelector(shoppingBasketItemsSelector);
  const basketLoading = useSelector(shoppingBasketLoadingSelector);
  const lang = useSelector(currentCatalogSelector);
  const dispatch = useDispatch();
  const webshopEnabled = useSelector(webshopEnabledSelector);
  const webshopDowntime = useSelector(webshopMaintenanceEnabledSelector);

  React.useEffect(() => {
    if (session && lang && !sessionIsLoading && webshopEnabled && !webshopDowntime) {
      dispatch(loadList(session, lang, ciam));
    }
  }, [session, ciam, lang, sessionIsLoading, webshopEnabled, webshopDowntime]);

  React.useEffect(() => {
    if (items && !basketLoading) {
      const searchForProduct = items?.find((item) => String(item?.key).includes(productKey));
      const sum = items?.reduce((acc, cur) => acc + parseFloat(cur.totalPrice), 0) || 0;
      setResult(!!searchForProduct);
      setIsAtLimit(sum >= paymentLimit);
      setLoading(false);
    }
  }, [items, basketLoading]);

  return [loading, result, isAtLimit];
}

import Link from 'next/link';
import styled, { css } from 'styled-components';
import { Typo } from '@geberit/gdds';

// utils
import { useNord } from 'utils/hooks/use-nord';

export const StyledTilesContainer = styled.div`
  grid-area: tiles;
  margin-top: 4rem;

  @media ${({ theme }) => theme.device.large} {
    margin-top: 5rem;
  }

  @media print {
    display: none;
  }
`;

export const StyledTitle = styled(Typo)(() => {
  const isNord = useNord();

  return css`
    &&& {
      font-weight: ${isNord ? 400 : 700};
      text-transform: ${isNord ? 'uppercase' : 'inherit'};
    }

    margin-bottom: 1.5rem;
  `;
})

export const StyledTitleCount = styled.span`
  font-weight: 300;
`;

export const StyledImageGrid = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr;

  @media ${({ theme }) => theme.device.medium} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const StyledSeriesName = styled(Typo)`
  margin-top: 0.5rem;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  @media print {
    display: none;
  }
`;

export const StyledGridLink = styled(Link)`
  &,
  &:visited {
    color: ${({ theme }) => theme.colors.black};
  }
`;

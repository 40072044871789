import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import debounce from 'lodash.debounce';

interface TooltipOverlayProps {
  children: React.ReactNode;
  containerRef: React.RefObject<HTMLElement>;
  dismissable: boolean;
  portalClass: string;
  portalRef: React.RefObject<HTMLDivElement> | null;
  position: 'top' | 'bottom';
}

function TooltipOverlay({
  children,
  containerRef,
  dismissable,
  portalClass,
  portalRef = null,
  position,
}: Readonly<TooltipOverlayProps>) {
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [arrowLeft, setArrowLeft] = useState(0);
  const [ready, setReady] = useState(false);

  const overlay = useRef();
  const portal = useRef(document.createElement('div'));
  portalRef.current = portal.current;

  useEffect(() => {
    // Wait until browser has set dimensions
    setTimeout(() => calculatePosition());

    window.addEventListener('resize', debounce(calculatePosition), 50);
    document.body.appendChild(portal.current);

    return () => {
      window.removeEventListener('resize', debounce(calculatePosition), 50);

      if (portal) {
        document.body.removeChild(portal.current);
      }
    };
  }, []);

  const getAbsolutePosition = (element) => {
    const boundingBox = element.getBoundingClientRect();

    return {
      width: boundingBox.width,
      height: boundingBox.height,
      top: window.scrollY + boundingBox.top,
      right: window.scrollX + boundingBox.right,
      bottom: window.scrollY + boundingBox.bottom,
      left: window.scrollX + boundingBox.left,
    };
  };

  const calculatePosition = () => {
    const {
      top: containerTop,
      left: containerLeft,
      width: containerWidth,
    } = getAbsolutePosition(containerRef.current);

    const { width: overlayWidth, height: overlayHeight } = getAbsolutePosition(overlay.current);
    const bodyWidth = document.body.clientWidth;
    const centerLeft = containerLeft + containerWidth / 2;
    const optimisticLeft = centerLeft - overlayWidth / 2;
    const overflow =
      optimisticLeft > 0
        ? Math.max(0, optimisticLeft + overlayWidth - bodyWidth + 25)
        : optimisticLeft - 25; // 25 = safe space between tooltip and window
    // Arrow positioning
    const optimisticArrowLeft = overlayWidth / 2 - 5; // 5 = Arrow width / 2

    // calculate postioning on top or bottom
    setTop(position === 'top' ? containerTop - overlayHeight - 10 : containerTop + 30);
    setLeft(optimisticLeft - overflow);
    setArrowLeft(optimisticArrowLeft + overflow);
    setReady(true);
  };

  return createPortal(
    <div
      className={`${dismissable ? 'c-tooltip__content--dismissable' : ''}
        c-tooltip__content${portalClass}`}
      style={{
        top: `${top}px`,
        left: `${left}px`,
        visibility: ready ? 'visible' : 'hidden',
      }}
      ref={overlay}
    >
      {children}
      <span className={`c-tooltip__arrow${portalClass}`} style={{ left: `${arrowLeft}px` }} />
    </div>,
    portal.current,
  );
}

export default TooltipOverlay;

import { ReactNode, useState, createContext, useContext } from 'react';

function useValue() {
  const [openedDropdown, setOpenedDropdown] = useState<string | null>(null);

  return {
    openedDropdown,
    setOpenedDropdown,
  };
}

const FilterDropdownContext = createContext<ReturnType<typeof useValue> | null>(null);

export const FilterDropdownProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const value = useValue();
  return <FilterDropdownContext.Provider value={value}>{children}</FilterDropdownContext.Provider>;
};

export const useFilterDropdownContext = () => {
  const context = useContext(FilterDropdownContext);
  if (!context) {
    throw new Error('useFilterDropdownContext must be used within a FilterDropdownProvider');
  }
  return context;
};

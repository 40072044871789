import key from 'weak-key';

// styles
import styles from './technical-drawings.module.scss';

// components
import { Image } from 'components/image/image';

// utils
import {
  imageAltSubcategory,
  imageAlt,
  getImageAltSuffix,
  imageAltDrawings,
} from '../ProductImageAlt';

interface ProductDetailTechnicalDrawingsViewProps {
  headline: string;
  drawings: any[];
  imageAltPrefix?: string | null;
  imageAltSuffix?: string | null;
  translationProductDrawingsPrefix?: string | null;
  categories: string[];
  productName: string;
  isProductImage?: boolean;
}

function ProductDetailTechnicalDrawingsView({
  drawings,
  categories,
  productName,
  headline = 'Product Drawings',
  imageAltPrefix = null,
  imageAltSuffix = null,
  translationProductDrawingsPrefix = null,
  isProductImage = false,
}: Readonly<ProductDetailTechnicalDrawingsViewProps>) {
  const imageAltCategory = imageAltSubcategory(categories);
  const imagePrefix = imageAlt(imageAltPrefix);
  const imageSuffix = getImageAltSuffix(imageAltSuffix);

  const imageAltText = isProductImage
    ? `${imagePrefix}${productName} - ${imageAltCategory}${imageSuffix}`
    : `${imagePrefix}${productName} ${imageAltDrawings(headline, categories, imageAltSuffix)}`;

  return (
    <div className={styles.technicalDrawings}>
      <h5 className={styles.technicalDrawingsHeadline}>
        {translationProductDrawingsPrefix
          ? `${translationProductDrawingsPrefix} ${headline}`
          : headline}
      </h5>

      <div className="grid-x grid-margin-x">
        {drawings.map((drawing) => (
          <div key={key(drawing)} className="cell small-6 medium-4 large-2">
            <div className={styles.technicalDrawingsImgs}>
              <Image
                src={drawing.medium}
                alt={imageAltText}
                title={imageAltText}
                staticImage
                external
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductDetailTechnicalDrawingsView;

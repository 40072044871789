import { useSelector } from 'react-redux';
import { AccordionItem, Container, Row, Col } from '@geberit/gdds';

// styles
import styles from './anchorbar.module.scss';

// components
import { CmsLink } from 'components/Link/CmsLink';

// types
import type {
  ContentElementPayload,
  ShowroomLocatorTeaserPayload,
} from 'components/ContentElements/content-elements-payload';

// utils
import { buildSize, gridSizes } from 'utils/gridSize';
import { anchorBarSelector } from 'utils/selectors/globalsSelectors';
import { scrollYToElement } from 'utils/scrollTo';
import { generateSectionIdSlug } from 'utils/get-section-id';
import { emptyStringFallback, isEmpty } from 'utils/is-empty';

type AnchorBarProps = {
  content: Exclude<ContentElementPayload, ShowroomLocatorTeaserPayload>[];
  collapseAnchor?: boolean;
  pageAnchorTitle?: string;
};

export function getSectionId(title: string, anchor: string, contentIndex: number) {
  return anchor || title
    ? `section-${generateSectionIdSlug(anchor || title)}`
    : `section-index-${contentIndex}`;
}

export function AnchorBar({ content, collapseAnchor, pageAnchorTitle }: Readonly<AnchorBarProps>) {
  const anchorBarData = useSelector(anchorBarSelector);

  // Note: Reducing the content object (for the use inside the AnchorBar)
  // take only the results that have " titleInAnchor === 'true' "
  // Note: Usage of toString() because some data provide strings instead of booleans
  const contentInAnchorBar = content.filter((item) => item.titleInAnchor?.toString() === 'true');

  // we need only specific properties
  const anchorBarAllowedProperties = ['anchor', 'title'];

  // map the array of objects with the needed properties
  const anchorBarContent = contentInAnchorBar.map((item) => {
    const allKeys = Object.keys(item);

    return allKeys.reduce((next, key) => {
      if (anchorBarAllowedProperties.includes(key)) {
        return { ...next, [key]: item[key] };
      } else {
        return next;
      }
    }, {});
  });

  if (isEmpty(anchorBarContent)) return null;

  const { title } = anchorBarData ?? { title: '' };

  const getLink = (item: Content): { id: string | undefined; title: string | undefined } => {
    return {
      id: getSectionId(item.title ?? '', item.anchor ?? '', item.contentIndex ?? 0),
      title: item.anchor ? item.anchor : item.title,
    };
  };

  const anchorLinks = anchorBarContent.map(getLink);

  const onClick = (e, id) => {
    e.preventDefault();

    const element = document.getElementById(id);
    if (element) {
      scrollYToElement(element);
    }
  };

  return (
    <div className={styles.anchorbarWrapper}>
      <Container maxContentWidth={buildSize(gridSizes.gddsFullGrid)}>
        <Row justify="center">
          <Col size={[4, 8, 8]}>
            <div>
              <AccordionItem
                iconAlignment="right"
                title={emptyStringFallback(pageAnchorTitle, title)}
                fontSize="p1"
                opened={!collapseAnchor}
                titleBold={true}
              >
                <div className={styles.anchorbarLinkWrapper}>
                  {anchorLinks.map((item) => (
                    <div key={item.id}>
                      <CmsLink
                        link={{
                          type: 'internal_link',
                          text: item.title,
                        }}
                        stylingType="primary"
                        standardFontSize
                        onClick={(e) => onClick(e, item.id)}
                        icon="ArrowDown"
                        className={styles.anchorbarLink}
                      />
                    </div>
                  ))}
                </div>
              </AccordionItem>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

import { useState, useRef, useEffect, useCallback } from 'react';

// components
import TooltipOverlay from './TooltipOverlay';
import { BUTTON_TYPES, Button } from '../Button/Button';

interface TooltipProps {
  trigger: 'click' | 'hover';
  position?: 'top' | 'bottom';
  content: React.ReactNode;
  children: React.ReactNode;
  dismissable?: boolean;
  portalClass?: string;
}

function Tooltip({
  trigger,
  content,
  children,
  dismissable = false,
  portalClass = '',
  position = 'top',
}: Readonly<TooltipProps>) {
  const [show, setShow] = useState(false);
  const container = useRef<HTMLSpanElement | null>(null);
  const overlay = useRef<HTMLDivElement | null>(null);

  const closeTooltip = useCallback((event) => {
    if (event) {
      event.stopPropagation();
    }
    setShow(false);
  }, []);

  const handleBlur = useCallback(
    (event) => {
      if (
        event.target === container.current ||
        container.current?.contains(event.target) ||
        overlay.current?.contains(event.target)
      ) {
        return;
      }

      closeTooltip(event);
    },
    [closeTooltip],
  );

  useEffect(() => {
    if (trigger === 'click') {
      if (show) {
        document.body.addEventListener('click', handleBlur);
      } else {
        document.body.removeEventListener('click', handleBlur);
      }
    }
  }, [handleBlur, show, trigger]);

  const openTooltip = useCallback(() => {
    setShow(true);
  }, []);

  const handlers =
    trigger !== 'click'
      ? {
          onMouseEnter: openTooltip,
          onMouseLeave: closeTooltip,
        }
      : { onClick: openTooltip };

  return (
    <span className="c-tooltip" role="button" ref={container} {...handlers}>
      {show && (
        <TooltipOverlay
          containerRef={container}
          portalRef={overlay}
          dismissable={dismissable}
          portalClass={portalClass}
          position={position}
        >
          {content}

          {dismissable && (
            <Button
              type={BUTTON_TYPES.PLAIN}
              className="c-tooltip__close"
              aria-label="Close"
              onClick={closeTooltip}
              symbol="close"
            />
          )}
        </TooltipOverlay>
      )}
      {children}
    </span>
  );
}

export default Tooltip;

import { Fragment } from 'react';

// compontents
import { Translation } from '../../Translation/Translation';
import ProductDetailFiltersSelect from './ProductDetailFiltersSelect';
import ProductPrice from './ProductPrice';

// utils
import { MAX_FILTER, MAX_FILTER_LOOP, DIMENSION_SEPARATOR } from './constants';
import ProductDetailFiltersSelectView from './ProductDetailFiltersSelectView';

interface ProductDetailFiltersViewProps {
  currentOptions: (attr: any) => any[];
  filteredAttributes: any[];
  selectedFilter: any[];
  currentIds: any[];
  dimensions: any[] | null;
  dimensionsName: string[] | string | null;
  dimensionsPosition: any;
  defaultValue: string;
  artId: string;
  onArtIdSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  showPrice: boolean | null;
  articlesList: any[];
}

function ProductDetailFiltersView({
  currentOptions,
  onArtIdSelectChange,
  dimensions = null,
  dimensionsName = '',
  dimensionsPosition = null,
  defaultValue = '',
  artId = '',
  showPrice = false,
  ...props
}: Readonly<ProductDetailFiltersViewProps>) {
  const { filteredAttributes, currentIds, selectedFilter, articlesList } = props;

  const hasSingle = currentIds.length === 1 && articlesList.find((o) => o.key === currentIds[0]);

  return (
    <>
      {filteredAttributes.map((attr, i) => {
        const hasDimensionFilter = i + 1 === dimensionsPosition.current;

        // render Filterwrapper
        const renderWrapper = (children) => <div className="desc">{children}</div>;

        // render Options depending on filter type
        const renderOptions = (isDimension) => {
          const options = isDimension ? dimensions : currentOptions(attr);

          return options.length > 1 ? (
            <ProductDetailFiltersSelect
              attr={attr}
              options={options}
              index={isDimension ? i + 1 : i}
              defaultValue={defaultValue}
              {...props}
            />
          ) : (
            <span>{options[0]}</span>
          );
        };

        // render dimension field at the saved position
        const renderdimensionsFilter = () =>
          renderWrapper(
            <>
              <span className="filtername">
                <Translation id="web20_products_filters_dimension" /> (
                {dimensionsName.map((key, index) => (
                  <Fragment key={key}>
                    <Translation id={key} />
                    {dimensionsName.length !== index + 1 && DIMENSION_SEPARATOR}
                  </Fragment>
                ))}
                )
              </span>
              {renderOptions(dimensions)}
            </>,
          );

        return (
          <Fragment key={attr.key}>
            {((MAX_FILTER_LOOP >= i && !dimensionsPosition.current) ||
              (dimensionsPosition.current && MAX_FILTER >= i)) &&
              renderWrapper(
                <>
                  <span className="filtername">{attr.name}</span>
                  {renderOptions(dimensions)}
                </>,
              )}
            {MAX_FILTER_LOOP > i && hasDimensionFilter && renderdimensionsFilter()}
          </Fragment>
        );
      })}
      <div className="desc">
        {currentIds && currentIds.length === 1 && (
          <>
            <span className="name">
              <Translation id="web20_product_articles_art_no" />:
            </span>
            <span>{currentIds[0]}</span>
            {showPrice && hasSingle && hasSingle.state && hasSingle.state.buyable === true && (
              <div>
                <span className="name">
                  <Translation id="web20_webshop_basket_price" />:
                </span>
                <ProductPrice articleId={currentIds[0]} />
              </div>
            )}
          </>
        )}
        {selectedFilter.length === 4 && currentIds.length !== 1 && (
          <>
            <ProductDetailFiltersSelectView
              options={currentIds}
              defaultValue={defaultValue}
              currentValue={artId}
              onSelectChange={onArtIdSelectChange}
            />
            {showPrice && (
              <div>
                <span className="name">
                  <Translation id="web20_webshop_basket_price" />:
                </span>
                <ProductPrice articleId={artId} />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ProductDetailFiltersView;

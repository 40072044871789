import { Field } from 'formik';

// styles
import styles from './formikInputFieldStyles.module.scss';

// types
import type { Props } from './FormikInputField.types';

const FormikInputField = ({
  type = 'text',
  name = '',
  id = '',
  placeholder = '',
  margin = 0,
  error = '',
  isDisabled = false,
  currentValue = '',
  inputBlur = () => {},
  maxLength,
}: Readonly<Props>) => (
  <div className={styles.formikFieldWrapper} style={{ margin }}>
    <Field
      type={type}
      name={name}
      id={id}
      className={error ? styles.inputError : undefined}
      disabled={isDisabled}
      onBlur={() => (inputBlur ? inputBlur() : '')}
      autoComplete={id === 'zip' ? 'nope' : undefined}
      maxLength={maxLength}
    />
    <span
      className={`${styles.inputPlaceholder} ${
        currentValue.length ? styles.inputPlaceholderFilled : ''
      }`}
    >
      {placeholder}
    </span>
    {error && <div className={`Error ${styles.error}`}>{error}</div>}
  </div>
);

export default FormikInputField;

import { usePathname } from 'next/navigation';

// styles
import styles from './layout.module.scss';

// types
import { Course, CourseDateList } from 'components/Campus/types';

// components
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import CampusLayout from './CampusLayout';
import { RegistrationForm } from 'components/Campus/Registration/RegistrationForm';
import { CourseTeaser } from 'components/Campus/CourseTeaser/CourseTeaser';
import { InternalLink } from 'components/Link/InternalLink';
import { Translation } from 'components/Translation/Translation';

// utils
import { getMetatag } from 'components/Campus/metatags';
import { useCampusEnv, useCampusTypes, useClassifications } from 'utils/hooks/useCampus';
import { useCostsIncludedMetatags } from 'utils/hooks/useCostsIncludedMetatags';
import { useTranslationFunction } from 'utils/hooks/use-translations';

interface CampusCourseRegistrationProps extends Partial<Content> {
  courseId: number;
  course: Course;
  reloadDetails: () => void;
}

export function CampusCourseRegistration({
  courseId,
  course,
  reloadDetails,
}: Readonly<CampusCourseRegistrationProps>) {
  const { CAMPUS_CLASS_TARGETGROUPS, CAMPUS_META } = useCampusEnv();
  const [type, icon] = useCampusTypes(course?.topics);
  const translate = useTranslationFunction();
  const pathname = usePathname();

  // target groups
  const { getClassification } = useClassifications();
  const targetGroups = getClassification(CAMPUS_CLASS_TARGETGROUPS);
  const idInCourseTargetGroups = (id) =>
    !!course?.topics?.find((i) => parseInt(i?.id, 10) === parseInt(id, 10));
  const courseTargetGroups = targetGroups?.filter((i) => idInCourseTargetGroups(i?.id));

  const metaTags = course?.metaTags;
  const campusCostsIncluded = useCostsIncludedMetatags(metaTags);

  if (!course) return null;

  const languages = course?.contentLanguages.contentLanguage.map((item) => item.name);

  const dateStart = getMetatag(CAMPUS_META.START_DATE, metaTags);
  const dateEnd = getMetatag(CAMPUS_META.END_DATE, metaTags);
  const courseDates: CourseDateList = [];

  if (dateStart) {
    courseDates.push({
      day: dateStart.split(' ')[0],
      time: dateStart.split(' ')[1],
    });
  }
  if (dateEnd) {
    courseDates.push({
      day: dateEnd.split(' ')[0],
      time: dateEnd.split(' ')[1],
    });
  }

  return (
    <CampusLayout>
      {/* link to overview */}
      <div className={`grid-container ${styles.courseRegistrationBackLink}`}>
        <InternalLink
          content={{ showArrow: true, target: pathname.split('#')[0] }}
          className="web20-icon-arrow-link-light-left"
        >
          <Translation id="campus_back_course" />
        </InternalLink>
      </div>
      {/* title */}
      <PageHeadline
        Format={TitleFormats.h1}
        title={translate('campus_headline_course_registration')}
        subtitle={translate('campus_subline_course_registration')}
        pageHeadline
      />
      {/* teaser */}
      <div className={styles.courseBookingTeaser}>
        <CourseTeaser
          icon={icon}
          courseId={course.id}
          courseType={course.learningForm}
          courseHeadline={course.name}
          courseDate={courseDates.length > 0 ? courseDates : undefined}
          availableSeats={course.freePlaces > 0 ? course.freePlaces : undefined}
          courseDescription={getMetatag(CAMPUS_META.SUMMARY, metaTags)}
          seats={course.freePlaces > 0 && getMetatag(CAMPUS_META.MAX_CAPACITY, metaTags)}
          location={getMetatag(CAMPUS_META.LOCATION, metaTags)}
          language={languages.join(', ')}
          costs={getMetatag(CAMPUS_META.PRICE, metaTags)}
          costsIncluded={campusCostsIncluded}
          targetGroup={courseTargetGroups?.map((i) => i.name).join(', ')}
          expirationDate={type === 'event' ? undefined : course.endDate}
          isPopular={Boolean(course?.topics?.find((el) => el.id === CAMPUS_META.POPULAR))}
        />
      </div>
      {/* registration form */}
      <RegistrationForm
        termOfUse={course.termOfUse}
        courseId={courseId}
        courseType={type}
        reloadDetails={reloadDetails}
        isBooked={course.status !== 'NULL' && course.status !== 'CANCELLED'}
      />
    </CampusLayout>
  );
}

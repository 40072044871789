import styled from 'styled-components';

export const StyledButtons = styled.div`
  @media print {
    display: none;
  }

  grid-area: buttons;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 4rem;

  .print-button {
    display: none;
  }

  @media ${({ theme }) => theme.device.medium} {
    flex-direction: row;
    column-gap: 1rem;

    > button {
      flex-grow: 1;
    }
  }

  @media ${({ theme }) => theme.device.large} {
    margin-bottom: 0rem;

    > button {
      flex-grow: 0;
    }

    .print-button {
      display: initial;
    }
  }
`;

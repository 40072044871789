// styles
import styles from './orderDetailsBox.module.scss';

// components
import { Translation } from 'components/Translation/Translation';

type OrderDetailsBoxProps = {
  orderId: string | null;
  title: string;
  orderData: {
    orderNumber?: string;
    paymentType?: string;
    paymentMethod?: string;
  };
};

function OrderDetailsBox({
  orderData = {},
  orderId = null,
  title,
}: Readonly<OrderDetailsBoxProps>) {
  return (
    <div>
      <h4 className={styles.bottomMargin}>{title}</h4>
      <ul className={`${styles.orderDetailsBoxWrapper} ${styles.bottomMargin}`}>
        <li>
          <Translation id="web20_checkout_summary_order_details_payment" /> {orderData.paymentType}
        </li>
        {orderId && (
          <li>
            <Translation id="web20_checkout_summary_order_details_number" /> {orderId}
          </li>
        )}
      </ul>
    </div>
  );
}

export default OrderDetailsBox;

import key from 'weak-key';

// styles
import stylesDefault from './form.module.scss';
import stylesNordics from './nord/form.module.scss';

// components
import { type FieldProps, Field } from './field';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { useNord } from 'utils/hooks/use-nord';
import { isEmpty } from 'utils/is-empty';

interface FieldsetProps {
  id?: string;
  colorVariant?: string;
  type: 'container' | 'fieldset';
  fields: FieldProps[];
  legend?: string;
  withPreviewId?: boolean;
}

export function Fieldset({
  id,
  type,
  legend,
  colorVariant,
  fields,
  withPreviewId,
}: Readonly<FieldsetProps>) {
  const styles = useNord() ? stylesNordics : stylesDefault;

  const buildField = (field: FieldProps) => {
    const required =
      !isEmpty(field.rules) &&
      field.rules?.some((attribute) => attribute.formValidator.includes('NotEmpty'));

    return (
      <Field
        key={key(field)}
        // coding guideline exception: we pass down generic props since there are too many different fields
        {...field}
        index={withPreviewId ? field?.index : undefined}
        rules={field.rules ?? []}
        required={required}
      />
    );
  };

  if (type === 'container') {
    return (
      <div data-id="form-fields-group" className={styles.formFieldsGroup}>
        {fields.map(buildField)}
      </div>
    );
  }

  return (
    <fieldset
      id={id}
      className={classNameBuilder(styles.formFieldset, colorVariant && styles[colorVariant])}
    >
      <legend>{legend}</legend>
      {fields.map(buildField)}
    </fieldset>
  );
}

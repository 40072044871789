import { useCallback, useEffect, useRef } from 'react';
import { Form, FormikProps } from 'formik';
import { v4 as uuid } from 'uuid';
import { Button, FormField, Toggle, Divider } from '@geberit/gdds';

// styles
import styles from './registrationForm.module.scss';
import contentbooked from '../CourseBookedContent/coursebookedcontent.module.scss';

// types
import type { Participant, Participants } from '../types';

// components
import { PersonalData } from './PersonalData';
import SeminarEventData from './seminar-event-data/seminar-event-data';
import { Translation } from '../../Translation/Translation';
import { Loader } from '../../Loader/Loader';

// utils
import { useCreateFormLabel } from './hooks';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { removeArrayIndex } from 'utils/array';

const ciamSalutationsCampusCodesMap = {
  'Mr.': 1,
  'Ms.': 2,
  Divers: 3,
};

function RegistrationFormView({
  toggleItems,
  termsAndConditions,
  newParticipant,
  courseType,
  mandatoryErrorMessage,
  invalidMailErrorMessage,
  isBooked,
  cateringItems,
  roleItems = [],
  profile,
  removedCiamUser,
  isLoading,
  ...formikProps
}: {
  toggleItems: any[];
  termsAndConditions: any;
  newParticipant: Participant;
  courseType: string;
  removedCiamUser: boolean;
  isLoading: boolean;
  isBooked: boolean;
  mandatoryErrorMessage?: string;
  invalidMailErrorMessage?: string;
  cateringItems?: any[];
  roleItems?: any[];
  profile?: any;
} & FormikProps<any>) {
  const { values, setFieldValue, handleChange, handleBlur, setValues, errors, touched } =
    formikProps;

  const initialValues = useRef(values);
  const translate = useTranslationFunction();
  const createLabel = useCreateFormLabel();

  const handleAddParticipant = useCallback(() => {
    let newParticipants: Participants = [];

    const entries = {};
    const length = values.participants.length + 1;
    Object.entries(newParticipant).forEach(([entryKey, entry]) => {
      entries[`${entryKey}${length}`] = entry;
    });
    newParticipants = [...values.participants, { ...entries, key: uuid() }];

    setFieldValue('participants', newParticipants);
  }, [newParticipant, setFieldValue, values.participants]);

  useEffect(() => {
    if (profile) {
      setValues({
        ...initialValues.current, // cannot use values directly because it would cause an infinite loop
        firstname: profile.firstName,
        lastname: profile.lastName,
        salutation: String(ciamSalutationsCampusCodesMap[profile.salutation] ?? 0),
        email: profile.email,
      });
    }
  }, [profile, setValues]);

  useEffect(() => {
    if (!isLoading && removedCiamUser && values.participants < 1) {
      handleAddParticipant();
    }
  }, [handleAddParticipant, isLoading, removedCiamUser, values.participants]);

  const getParticipant = (index) => values.participants[index] || {};

  const handleParticipants = (index: number, data: any) => {
    const newParticipants = values.participants.map((obj, i) => {
      if (index === i) {
        return {
          ...obj,
          [data.target.name]: data.target.value,
        };
      }

      return obj;
    });

    setFieldValue('participants', newParticipants);
  };

  const handleDeleteParticipant = (index) => {
    const participants = removeArrayIndex(values.participants, index);
    setFieldValue('participants', participants);
  };

  return (
    <Form id="form" className={`campus-course-registration ${styles.registrationForm}`}>
      {isLoading && (
        <div className={contentbooked.loaderWrapper}>
          <Loader />
        </div>
      )}
      {(!removedCiamUser || !isBooked) && (
        <>
          <div className="grid-x headline-button-wrapper">
            <strong>{createLabel('participate', 'campus_headline_participante')}</strong>
          </div>

          <div className={`grid-x ${styles.radioToggle}`}>
            <div className="small-12 medium-12">
              <Toggle
                errors={touched.participate && errors.participate}
                onChange={(value) => {
                  setFieldValue('participate', value);
                  handleChange({ target: { name: 'participate', value } });
                  if (value === 'no' && values.participants.length < 1) {
                    handleAddParticipant();
                  }
                }}
                values={values}
                type="radio"
                name="participate"
                alignment="horizontal"
                items={toggleItems}
                errorMessage={mandatoryErrorMessage}
                onBlur={handleBlur}
              />
            </div>
          </div>
        </>
      )}
      {!removedCiamUser && values.participate === 'yes' && (
        <>
          <PersonalData
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            mandatoryErrorMessage={mandatoryErrorMessage}
            invalidMailErrorMessage={invalidMailErrorMessage}
            ciamData={!!profile}
            roleItems={roleItems}
          />
          {(courseType === 'seminar' || courseType === 'event') && (
            <SeminarEventData
              values={values}
              cateringItems={cateringItems}
              onChange={setFieldValue}
              handleBlur={handleBlur}
              touched={touched}
              handleChange={handleChange}
              errors={errors}
              mandatoryErrorMessage={mandatoryErrorMessage}
            />
          )}
        </>
      )}

      {values.participants.map((item, index) => (
        <div key={item.key}>
          <PersonalData
            index={index + 1}
            values={getParticipant(index)}
            handleBlur={handleBlur}
            touched={touched}
            handleChange={(data) => handleParticipants(index, data)}
            deleteParticipant={() => handleDeleteParticipant(index)}
            errors={errors}
            mandatoryErrorMessage={mandatoryErrorMessage}
            invalidMailErrorMessage={invalidMailErrorMessage}
            roleItems={roleItems}
            participateSelf={values.participate === 'yes'}
          />
          {(courseType === 'seminar' || courseType === 'event') && (
            <SeminarEventData
              values={getParticipant(index)}
              handleBlur={handleBlur}
              touched={touched}
              cateringItems={cateringItems}
              handleChange={(data) => handleParticipants(index, data)}
              index={index + 1}
              errors={errors}
              mandatoryErrorMessage={mandatoryErrorMessage}
            />
          )}
        </div>
      ))}
      {(courseType === 'seminar' || courseType === 'event') && (
        <div className={styles.commentWrapper}>
          <Divider alignment="horizontal" />
          <strong>
            <Translation id="campus_form_comment" />
          </strong>
          <FormField
            name="comment"
            values={values}
            onChange={handleChange}
            placeholder={`${translate('campus_form_comment')}`}
          />
        </div>
      )}
      <Divider alignment="horizontal" />
      <div className={`grid-x ${styles.checkboxToggle}`}>
        <div className="small-12 medium-12">
          <Toggle
            errors={touched.termsAndConditions && errors.termsAndConditions}
            errorMessage={mandatoryErrorMessage}
            items={termsAndConditions}
            values={{ termsAndConditions: values.termsAndConditions ? 'termsAndConditions' : '' }}
            type="checkbox"
            name="termsAndConditions"
            onChange={() => {
              setFieldValue('termsAndConditions', !values.termsAndConditions);
              handleChange({
                target: { name: 'termsAndConditions', value: !values.termsAndConditions },
              });
            }}
          />
        </div>
      </div>
      <div className={styles.buttonsSubmit}>
        <div className={styles.submitHint}>
          <Translation id="campus_form_mandatory_fields" />
        </div>
        <Button onClick={() => handleAddParticipant()} symbol="Plus" size="small">
          <Translation id="campus_add_participant" />
        </Button>
        <Button stylingType="primary" size="small" buttonType="submit">
          <Translation id="campus_book_now" />
        </Button>
      </div>
    </Form>
  );
}

export default RegistrationFormView;

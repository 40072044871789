import React from 'react';
import { Button } from '@geberit/gdds';

// components
import { Translation } from '../../Translation/Translation';

// styles
import styles from './sortpage.module.scss';

// settings
const SORT_MODE_OPTIONS: SortModeOption[] = [
  {
    id: 'date_asc',
    translation: 'campus_sort_date_asc',
    sort: 'startDate',
    order: 'asc',
    default: true,
  },
  {
    id: 'lastUpdated_desc',
    translation: 'campus_sort_last_updated_desc',
    sort: 'lastUpdated',
    order: 'desc',
  },
  // removed from functional description
  // keeping for reference
  // {
  //   id: 'date_asc',
  //   translation: 'campus_sort_date_asc',
  //   sort: 'startDate',
  //   order: 'asc',
  // },
  {
    id: 'freeseats',
    translation: 'campus_sort_freeseats',
    sort: 'freePlaces',
    order: 'desc',
  },
  {
    id: 'name_asc',
    translation: 'campus_sort_name_asc',
    sort: 'title',
    order: 'asc',
  },
  {
    id: 'name_desc',
    translation: 'campus_sort_name_desc',
    sort: 'title',
    order: 'desc',
  },
];

const DEFAULT_SORT = SORT_MODE_OPTIONS.find((o) => o.default === true);

interface SortPageProps {
  sortMode: SortModeOption;
  setSortMode: (value: SortModeOption) => void;
}

export default function SortPage({
  sortMode = SORT_MODE_OPTIONS[0],
  setSortMode,
}: Readonly<SortPageProps>) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(false);
  }, [sortMode]);

  return (
    <div className={`${styles.box} ${open && styles.open}`}>
      <button type="button" onClick={() => setOpen((prev) => !prev)}>
        <Translation id={sortMode?.translation || DEFAULT_SORT.translation} />
      </button>
      {open && (
        <ul className={styles.wrapper}>
          {SORT_MODE_OPTIONS.map((option) => (
            <li key={`sortmode_${option.id}`}>
              <Button
                buttonType="button"
                stylingType={
                  (!sortMode && option.id === DEFAULT_SORT.id) ||
                  (sortMode && sortMode.id === option.id)
                    ? 'flatHighlight'
                    : 'flat'
                }
                onClick={() => setSortMode(option)}
              >
                <Translation id={option.translation} />
              </Button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

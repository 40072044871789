import { useSelector } from 'react-redux';

// components
import SearchTabs from './SearchTabs/SearchTabs';
import SearchTabDropdown from './SearchTabs/SearchTabDropdown';

// utils
import { selectSoftContent, selectProducts } from '../../utils/selectors/searchSelectors';
import { useIsMobile } from 'components/App/SizeProvider';

interface SearchNavigationProps {
  tab: number;
  setTab: (tabId: number) => void;
}

function SearchNavigation({ tab, setTab }: Readonly<SearchNavigationProps>) {
  const isMobile = useIsMobile();
  const softContent = useSelector(selectSoftContent);
  const product = useSelector(selectProducts) as any;

  const totalContent = softContent ? softContent.totalResults : 0;
  const totalProducts = product ? product.total : 0;
  const Component = isMobile ? SearchTabDropdown : SearchTabs;

  return (
    <Component
      activeTab={tab}
      handleClick={setTab}
      softContentCount={totalContent}
      productsCount={totalProducts}
    />
  );
}

export default SearchNavigation;

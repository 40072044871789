'use client';

// components
import Layout from './Layout';
import HeroImage from 'components/ContentElementsGdds/hero-image/hero-image';
import { ContentArea } from 'components/ContentArea/ContentArea';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';

// utils
import { contentExists, useContent } from 'components/Page/page-hooks';

type LandingProps = {
  slider: HeroImageProps;
};

export function Landing() {
  const pageContent = useContent<{}, LandingProps>();

  if (!contentExists(pageContent)) {
    return null;
  }
  const {
    breadcrumbs,
    metaData,
    contentAreas: { content },
    page: { slider },
  } = pageContent;

  return (
    <Layout metaData={metaData}>
      <HeroImage {...slider} />
      <Breadcrumb items={breadcrumbs} />
      <ContentArea content={content} contentType="content" />
    </Layout>
  );
}

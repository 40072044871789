'use client';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

// components
import Footer from 'components/Footer/Footer';
import VideoGallery from 'components/VideoGallery/VideoGallery';
import { Slider } from 'components/ContentElements/slider';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import IntroText from 'components/ContentElements/IntroText/IntroText';
import VideocenterOverviewNordics from 'scenes/Nordics/videocenter-overview';

// utils
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import { setChannels } from 'components/VideoGallery/actions';
import { videoCenterChannelsSelector } from 'utils/selectors/videCenterSelector';
import { contentExists, useContent } from 'components/Page/page-hooks';

type VideocenterOverviewProps = {
  channels: any[];
  isNord: boolean;
};

export function VideocenterOverviewScene({
  channels: serverSideChannels,
  isNord,
}: Readonly<VideocenterOverviewProps>) {
  const dispatch = useDispatch();
  const page = useContent<{}, { slider: ISlider; hero: ISlider }>();

  useEffect(() => {
    if (serverSideChannels) {
      dispatch(setChannels(serverSideChannels));
    }
  }, [dispatch, serverSideChannels]);

  const channels = useSelector(videoCenterChannelsSelector);

  if (!contentExists(page)) {
    return null;
  }

  const {
    breadcrumbs,
    page: { slider, headline, subHeadline, text, showAnchor },
  } = page;

  if (isNord) {
    return <VideocenterOverviewNordics {...page} channels={channels ?? []} />;
  }

  return (
    <>
      <main>
        <Slider
          items={slider?.items}
          slideDuration={slider?.slideDuration}
          sliderType="homepage"
          sliderContext="homepage"
        />
        <Breadcrumb items={breadcrumbs} />
        <PageHeadline
          Format={TitleFormats.h1}
          title={headline}
          subtitle={subHeadline}
          pageHeadline
          hasIntroText
        />
        <IntroText text={text} grid />
        <VideoGallery showAnchor={showAnchor} pageHeadline={headline} channels={channels ?? []} />
      </main>
      <Footer />
    </>
  );
}

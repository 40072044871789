import { useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { Col, Container, Row, Logo } from '@geberit/gdds';

// styles
import {
  Grid,
  MapWrapper,
  OpeningHours,
  MapPlaceholder,
  LogoWrapper,
} from './locator-detail-page.styles';

// types
import type { IEntry } from '../locator.types';

// components
import { Tiles } from './tiles/tiles';
import { Header } from './header/header';
import { Details } from './details/details';
import { Buttons } from './buttons/buttons';
import { Categories } from './categories/categories';
import { Accordions } from './accordions/accordions';
import { Note } from './note/note';
import { LocatorConsent } from '../consent';

// utils
import { SetScrollbarWidth } from './set-scrollbar-width';
import { iconBuilder } from '../map/utils/icon-builder';
import { GoogleMapProvider } from '../overview-page/google-map-provider';
import { useLocatorConfiguration } from '../utils/use-locator-configuration';
import { useUCConsent } from 'features/cookie-consent';
import { logoSelector, catalogBrandNameSelector } from 'utils/selectors/globalsSelectors';
import { useNord } from 'utils/hooks/use-nord';
import { useTranslations } from 'utils/hooks/use-translations';
import { mapBrand } from '../utils/map-brand';

interface LocatorDetailPageProps extends IEntry {
  locatorPath?: string;
}

export function LocatorDetailPage(props: Readonly<LocatorDetailPageProps>) {
  const { locatorPath, lng, lat, products } = props;
  const consent = useUCConsent();
  const locatorConfig = useLocatorConfiguration();
  const customLogo = useSelector(logoSelector);
  // Here it will be decided later where the brand
  // name will be read from (config, CRM data etc.)
  const brandName = useSelector(catalogBrandNameSelector);
  // If brand name is read from CRM Data, then we
  // do not need the variable and the brand
  // attribute in the iconBuilder function argument object.
  // We would then read brand from the location attribute.
  const brand = mapBrand(brandName);

  const isNord = useNord();
  const translations = useTranslations();
  const showConsent = consent.Comfort === false && locatorConfig !== undefined;
  const showMap = consent.Comfort === true;

  useEffect(() => {
    window.scroll({ top: 0 });
  }, []);

  return (
    <SetScrollbarWidth>
      <Container>
        <Row>
          <Col size={[0, 0, 2]}></Col>
          <Col size={[4, 8, 8]}>
            <Grid>
              <LogoWrapper>
                <Logo
                  alt="logo just for print purpose"
                  src={
                    isNord
                      ? customLogo?.logoRef || '/images/nordics_tmp.svg'
                      : '/images/geberit_logo.svg'
                  }
                  language={translations?.web20_logo_claim_short || ''}
                />
              </LogoWrapper>
              <Header {...props} locatorPath={locatorPath} />
              <MapPlaceholder>
                <MapWrapper>
                  {showConsent && <LocatorConsent {...locatorConfig?.consent} />}
                  {showMap && (
                    <GoogleMapProvider>
                      <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        options={{
                          fullscreenControl: false,
                          streetViewControl: false,
                          mapTypeControl: false,
                        }}
                        center={{ lat, lng }}
                        zoom={locatorConfig.dealerPage.initZoomLevel || 10}
                      >
                        <Marker
                          position={{ lat, lng }}
                          icon={iconBuilder({
                            location: props,
                            mode: 'active',
                            brand,
                            showPartner: locatorConfig?.filter?.showPartnerSwitch,
                          })}
                          zIndex={0}
                        />
                      </GoogleMap>
                    </GoogleMapProvider>
                  )}
                </MapWrapper>
              </MapPlaceholder>
              <Details {...props} />
              <OpeningHours />
              <Categories products={products} />
              <Buttons {...props} />
              <Accordions products={products} />
              <Tiles products={products} />
              {products.length > 0 && <Note />}
            </Grid>
          </Col>
        </Row>
      </Container>
    </SetScrollbarWidth>
  );
}

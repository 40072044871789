import { Typo, AspectRatio, Button } from '@geberit/gdds';

// styles
import styles from './media-tiles.module.scss';

// types
import type { Media } from '../types/mynewsdesk.types';

// components
import Image from '../image';

// utils & hooks
import { downloadMedia } from '../utils';
import { mediaTypeTranslationKeyMap } from '../constants';
import { useMediaDetails } from '../hooks/use-media-details';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useTranslationByKey } from 'utils/hooks/use-translations';

interface MediaTileProps {
  media: Media;
  isMobile: boolean;
  onClick: () => void;
  isRelatedMaterial?: boolean;
  fallbackImg?: string;
  fallbackBackgroundColor?: string;
}

export function MediaTile({
  media,
  isMobile,
  isRelatedMaterial,
  fallbackImg,
  fallbackBackgroundColor,
  onClick,
}: Readonly<MediaTileProps>) {
  const details: string[] = useMediaDetails(media);

  let downloadTranslationKey = mediaTypeTranslationKeyMap[media.type_of_media];

  if (media.type_of_media === 'document') {
    if (media.document_format === '.pdf') {
      downloadTranslationKey = mediaTypeTranslationKeyMap.pdf;
    } else if (media.document_format.includes('.doc')) {
      downloadTranslationKey = mediaTypeTranslationKeyMap.file;
    }
  }
  const downloadTranslation = useTranslationByKey(downloadTranslationKey);

  function getImageUrl() {
    if (!media) {
      return undefined;
    }

    if (media.type_of_media === 'document') return media.document_thumbnail;
    if (media.type_of_media === 'video') return media.thumbnail;

    if (isMobile) return media.image_small;

    return media.image_medium;
  }

  function renderContent() {
    if (isMobile) return null;

    return (
      <>
        <Typo className={styles.mediaHeader} variant="h4" fontWeight={700}>
          {media.header}
        </Typo>
        <Typo variant="p2">
          {details.map((detail) => detail && <span key={detail}>{detail}</span>)}
        </Typo>
        <Button
          onClick={(e) => {
            e?.preventDefault();
            e?.stopPropagation();
            downloadMedia(media);
          }}
          symbol="Download"
          stylingType="secondaryInverted"
        >
          {downloadTranslation}
        </Button>
      </>
    );
  }

  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  return (
    <button
      className={classNameBuilder(styles.mediaTile, isRelatedMaterial && styles.relatedMaterial)}
      onClick={onClick}
      onKeyUp={onKeyUp}
    >
      <AspectRatio ratio="16:9">
        <div className={styles.pressImage}>
          <div className={classNameBuilder(!isMobile && styles.transparentLayer)}>
            {renderContent()}
          </div>
        </div>
        <Image
          src={getImageUrl()}
          fallbackSrc={fallbackImg}
          fallbackBackgroundColor={fallbackBackgroundColor}
        />
      </AspectRatio>
    </button>
  );
}

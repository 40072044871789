// styles
import styles from './errorMessageBox.module.scss';

// components
import { Icon } from 'components/Icon/Icon';

type ErrorMessageBoxProps = {
  children: React.ReactNode;
};

function ErrorMessageBox({ children }: Readonly<ErrorMessageBoxProps>) {
  return (
    <div className={`${styles.errorContainer} ${styles.bottomMargin}`}>
      <Icon symbol="alert" />
      {children}
    </div>
  );
}

export default ErrorMessageBox;

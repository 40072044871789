/**
 * Select the primary image, if there is no one select the first given image
 *
 * @param images Image List Array
 * @returns {*}
 */
export const getPrimaryImage = (images) => {
  if (images.length <= 0) {
    return null;
  }
  const primaryImage = images.find((image) => image.orientation === 'PrimaryImageJPG');
  return primaryImage || images[0];
};

/**
 * Render function that gives its children the primary or default image
 *
 * @param images Image List Array
 * @param children Function
 * @returns {*}
 * @constructor
 */

interface SelectPrimaryImageProps {
  images: any[];
  children: (image: any) => any;
}

const SelectPrimaryImage = ({ images, children }: SelectPrimaryImageProps) =>
  children(getPrimaryImage(images));

export default SelectPrimaryImage;

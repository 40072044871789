import Head from 'next/head';

// components
import { OverviewContainer } from '../Containers/overview/overview-container';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';

interface Step1Props {
  locale: string;
  ciamId: string;
  sessionId: string;
}

export function Step1({ locale, ciamId, sessionId }: Readonly<Step1Props>) {
  const translate = useTranslationFunction();
  const isLoggedIn = Boolean(ciamId && ciamId !== '');

  return (
    <>
      {translate('web20_checkout_address_breadcrumb') &&
        translate('web20_checkout_steps_overview') && (
          <Head>
            <title>{`${translate('web20_checkout_address_breadcrumb')} - ${translate(
              'web20_checkout_steps_overview',
            )}`}</title>
          </Head>
        )}
      <OverviewContainer
        locale={locale}
        ciamId={ciamId}
        isLogged={isLoggedIn}
        sessionId={sessionId}
      />
    </>
  );
}

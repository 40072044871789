import React from 'react';
import { Button } from '@geberit/gdds';

// components
import { Translation } from '../../Translation/Translation';

// styles
import styles from './courseList.module.scss';

// settings
const PER_PAGE_OPTIONS = [6, 12, 24];

interface PerPageProps {
  perPage: number;
  setPerPage: (value: number) => void;
  setLastPerPage: (value: number) => void;
}

export default function PerPage({ perPage, setPerPage, setLastPerPage }: Readonly<PerPageProps>) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(false);
  }, [perPage]);

  return (
    <div className={`${styles.resultsPerPage} ${open && styles.open}`}>
      <button type="button" onClick={() => setOpen((prev) => !prev)}>
        <Translation id="web20_results_per_page" /> <strong>{perPage}</strong>
      </button>
      {open && (
        <ul className={styles.resultsPerPageDropdown}>
          {PER_PAGE_OPTIONS.map((value) => (
            <li key={`per_page_${value}`}>
              <Button
                buttonType="button"
                stylingType={perPage === value ? 'flatHighlight' : 'flat'}
                onClick={() => {
                  setPerPage(value);
                  setLastPerPage(perPage);
                }}
              >
                {value}
              </Button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

import _isEmpty from 'lodash.isempty';

// types
import type { OKVCategory } from '../components/ContentElements/OnlineCatalogAssets/types';

/**
 * Create imageAlt Prefix with product name
 *
 * @returns {string}
 */
export function imageAlt(imageAltPrefix: string) {
  return !_isEmpty(imageAltPrefix) && !imageAltPrefix.includes('web20') ? `${imageAltPrefix} ` : '';
}

/**
 * Get subcategory name for image Alt
 *
 * @returns {*[]}
 */
export function imageAltSubcategory(categories: OKVCategory[]) {
  return (categories || []).filter((cat) => !_isEmpty(cat.name)).map((cat) => cat.name);
}

/**
 * Get image alt suffix
 *
 * @returns {string}
 */
export function getImageAltSuffix(imageAltSuffix: string) {
  return !_isEmpty(imageAltSuffix) && !imageAltSuffix.includes('web20')
    ? ` - ${imageAltSuffix}`
    : '';
}

/**
 * image alt for spare parts
 *
 * @param forTranslation  Translation key
 * @param name
 * @param imageAltSuffix
 * @returns {string}
 */
export function imageAltSpareParts(forTranslation: string, name: string, imageAltSuffix: string) {
  return `${forTranslation} ${name}${getImageAltSuffix(imageAltSuffix)}`;
}

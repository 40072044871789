import { RefObject } from 'react';

// types
import type { HeroImageThemedItem } from 'components/ContentElementsGdds/hero-image/components/hero-image-themed/hero-image-themed.types';

// utils
import { exhaustiveGuard } from 'utils/exhaustiveGuard';
import { isDefined } from 'utils/ts-utilities';

function getIndexListOfHeadingsWithTemplateStrings({
  items,
}: {
  items: HeroImageThemedItem[];
}): number[] {
  const boldRegex = /.*<b>.*<\/b>.*/;
  const newLineRegex = /.*<br>.*/;

  const indexes = items
    .map((item, index) => {
      if (item.headline && (boldRegex.test(item.headline) || newLineRegex.test(item.headline))) {
        return index;
      }
      return undefined;
    })
    .filter(isDefined);

  return indexes;
}

function getSelectorForHeading({
  heroImageType,
  index,
  ref,
}: {
  heroImageType: 'a' | 'b';
  index: number;
  ref: RefObject<HTMLDivElement>;
}): string {
  switch (heroImageType) {
    case 'a': {
      const isH1 = ref.current?.querySelector('h1 > span:nth-child(1)');
      return isH1 ? 'h1 > span:nth-child(1)' : 'h2 > span:nth-child(1)';
    }
    case 'b': {
      const headlineElement = ref.current?.querySelector(`[data-swiper-slide-index='${index}'] h1`);
      const isH1 = Boolean(headlineElement);

      const selector = isH1
        ? `[data-swiper-slide-index='${index}'] h1 > span:nth-child(1)`
        : `[data-swiper-slide-index='${index}'] h2 > span:nth-child(1)`;

      return selector;
    }
    default:
      return exhaustiveGuard(heroImageType);
  }
}

export function replaceHeadingsWithTemplateStrings({
  heroImageType,
  items,
  ref,
}: {
  heroImageType: 'a' | 'b';
  items: HeroImageThemedItem[];
  ref: RefObject<HTMLDivElement>;
}): void | boolean {
  if (!ref.current) return;

  if (!items || items.length === 0) return;

  const indexes = getIndexListOfHeadingsWithTemplateStrings({ items });
  if (!indexes || indexes.length === 0) return;

  indexes.forEach((index) => {
    const item = items[index];
    if (!item.headline) return;

    const selector = getSelectorForHeading({ index, heroImageType, ref });

    const heading = ref.current?.querySelectorAll(selector);

    if (!heading || heading.length === 0) return;

    heading.forEach((node) => {
      node.innerHTML = item.headline;
    });
  });

  return true;
}

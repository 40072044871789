// Components
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { BUTTON_TYPES, Button } from '../../Button/Button';
import type { ProductDetailAccordionItemProps } from './types';

export function ProductDetailAccordionItem({
  title,
  icon,
  children,
  uuid,
  id,
}: Readonly<ProductDetailAccordionItemProps>) {
  const Headline = uuid === 'detail' ? 'h3' : 'h4';

  return (
    <AccordionItem className="c-accordion__item" uuid={uuid} id={id ?? ''}>
      <AccordionItemHeading>
        <AccordionItemButton role="button" className="c-accordion__title grid-x">
          <Headline className="auto cell h4">
            {title}
            {icon !== null && icon}
          </Headline>
          <Button type={BUTTON_TYPES.PLAIN} className="large-1" />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="c-accordion__body">{children}</AccordionItemPanel>
    </AccordionItem>
  );
}

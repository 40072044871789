import { Row, Button } from '@geberit/gdds';

// styles
import { StyledCentredCol, StyledConsentContainer } from './consent.styles';

// types
import type { ICookieType } from 'features/cookie-consent/usercentrics';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { useUsercentrics } from 'features/cookie-consent';
import { decodingContent } from 'utils/decodingContent';

interface LocatorConsentProps {
  consentText?: string;
  buttonLabel?: string;
  type: ICookieType;
}

export function LocatorConsent({ consentText, buttonLabel, type }: Readonly<LocatorConsentProps>) {
  const uc = useUsercentrics();

  return (
    <StyledConsentContainer>
      <Row>
        <StyledCentredCol size={[4, 6, 6]}>
          <div className="consentBox">
            <InnerHtml as="p" content={consentText} />
            <Button
              stylingType="secondaryInverted"
              onClick={() => {
                uc?.enableSetting(type);
              }}
            >
              {decodingContent(buttonLabel)}
            </Button>
          </div>
        </StyledCentredCol>
      </Row>
    </StyledConsentContainer>
  );
}

import { MouseEvent } from 'react';

// types
import type { ForegroundVideoProps } from './foreground-video.types';

// components
import VideoLightbox from 'components/lightbox/video-lightbox';

function ForegroundVideo(props: Readonly<ForegroundVideoProps>) {
  const { video, onClose } = props;

  if (!video) return null;

  const handleClose = (e: MouseEvent<HTMLButtonElement, Event> | undefined) => {
    e?.preventDefault();
    onClose(false);
  };

  return (
    <VideoLightbox
      isModal
      key={`lightbox-${video.videoId}`}
      videoId={video.videoId}
      onClose={handleClose}
      isHeroImageForegroundVideoPlayer
    />
  );
}

export { ForegroundVideo };

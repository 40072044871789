import { useEffect, createRef } from 'react';
import key from 'weak-key';
import { usePathname } from 'next/navigation';

import { TextVideo } from '../ContentElements/TextVideo/TextVideo';

interface VideoItemLargeProps {
  video: any;
}

export default function VideoItemLarge({
  video,
  video: {
    title,
    description,
    video: { videoId, videoPreviewImage },
  },
}: Readonly<VideoItemLargeProps>) {
  const videoItemRef = createRef();
  const pathname = usePathname();

  useEffect(() => {
    const hash = pathname.split('#')?.[1];
    if (hash === videoId && videoItemRef.current) {
      videoItemRef.current.scrollIntoView();
    }
  }, []);

  return (
    <div
      className="c-video-center__item c-video-center__item--large"
      id={`${videoId}`}
      ref={videoItemRef}
    >
      <TextVideo
        headlinePosition="content"
        {...{ title, text: description, video: { videoId, videoPreviewImage } }}
        key={key(video)}
      />
    </div>
  );
}

// components
import Footer from 'components/Footer/Footer';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';

interface LayoutProps {
  metaData: MetaData;
  className?: string;
}

export default function Layout({
  children,
  className,
}: Readonly<React.PropsWithChildren<LayoutProps>>) {
  return (
    <>
      <main className={classNameBuilder(className, 'gdds')}>{children}</main>
      <Footer />
    </>
  );
}

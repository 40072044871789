import { useFormikContext } from 'formik';
import { default as ReactDatePicker } from 'react-datepicker';
import isEmpty from 'lodash.isempty';

// types
import type { DateOrTimePickerField } from '../date-or-time-picker';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';

interface TimePickerProps {
  field: DateOrTimePickerField;
  onChange: (date: Date | null) => void;
  className: string;
  selectedTime: Date | null;
}

export default function TimePicker({
  field: { name, rules, comment },
  onChange,
  className,
  selectedTime,
}: Readonly<TimePickerProps>) {
  const translate = useTranslationFunction();
  const { values, errors, touched, setFieldTouched } = useFormikContext<Record<string, string>>();

  return (
    <div className={className}>
      {/* prevent events so the timepicker closes when selecting a time */}
      {/* prettier-ignore */}
      <label htmlFor={name} onClick={(e) => e.preventDefault()} onKeyUp={(e) => e.preventDefault()}>{/* NOSONAR */}
        <ReactDatePicker
          autoComplete="off"
          title={comment}
          id={name}
          value={values[name]}
          name={name}
          selected={selectedTime}
          showTimeSelect
          showTimeSelectOnly
          timeCaption={translate('web20_forms_timepicker')}
          timeIntervals={15}
          timeFormat="HH:mm"
          dateFormat="HH:mm"
          onChange={onChange}
          onBlur={() => setFieldTouched(name, true)}
          preventOpenOnFocus
          popperPlacement="bottom-start"
        />
        <InnerHtml as="span" content="__:__" />
      </label>
      <InnerHtml
        as="p"
        className="form-item__error-message"
        content={
          (errors[name] &&
            touched[name] &&
            !isEmpty(rules) &&
            errors[name] !== 'keepErrorMessage' &&
            errors[name]) ||
          ''
        }
      />
    </div>
  );
}

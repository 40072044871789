import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type Listener<T = unknown> = (value: T) => void;
const listener = new Map<string, Set<Listener>>();

type StorageType = 'localStorage' | 'sessionStorage';

function notify<T>(storage: StorageType, key: string, newValue: T) {
  const listenKey = `${storage}.${key}`;
  listener.get(listenKey)?.forEach((cb) => cb(newValue));
}

function register<T>(storage: StorageType, key: string, cb: Listener<T>) {
  const listenKey = `${storage}.${key}`;
  if (!listener.has(listenKey)) {
    listener.set(listenKey, new Set());
  }
  listener.get(listenKey)!.add(cb);

  return () => {
    listener.get(listenKey)?.delete(cb);
  };
}

function useStorageState<T>(
  storage: 'localStorage' | 'sessionStorage',
  key: string,
  initialState: T,
) {
  const [value, setValue] = useState(() => {
    if (typeof window !== 'undefined') {
      const storedValue = window[storage].getItem(key);
      if (storedValue) {
        return JSON.parse(storedValue);
      }
    }
    return initialState;
  });

  useEffect(() => {
    return register(storage, key, setValue);
  }, [key]);

  return [
    value,
    (newState) => {
      const newValue = newState instanceof Function ? newState(value) : newState;
      window[storage].setItem(key, JSON.stringify(newValue));
      setValue(newValue);

      notify<T>(storage, key, newValue);
    },
  ] as [T, Dispatch<SetStateAction<T>>];
}

export function useSessionState<T>(key: string, initialState: T) {
  return useStorageState('sessionStorage', key, initialState);
}

export function useLocalState<T>(key: string, initialState: T) {
  return useStorageState('localStorage', key, initialState);
}

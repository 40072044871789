import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from '@geberit/gdds';

// styles
import styles from './search-products.module.scss';

// componsnts
import SearchLoadMore from './search-loadmore';
import { ProductTile } from '../Tiles/Product/product-tile';

// utils
import { getPrimaryImage } from '../../Product/DetailGallery/SelectPrimaryImage';
import { setSearchOffset } from '../../Search/actions';
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { currentCatalogSelector } from 'utils/selectors/productCatalogSelectors';

type Props = {
  products: any;
};

export default function SearchProducts({ products }: Readonly<Props>) {
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const catalogUrl = useTranslationByKey('web20_ocb_link_target');
  const catalogLanguage = useSelector(currentCatalogSelector);

  return (
    <Row className={styles.wrapper}>
      {products?.results?.map((result) => {
        let productLink = '';

        if (catalogUrl) {
          try {
            const catalogUri = new URL(catalogUrl).origin;

            if (result.key) {
              productLink = `${catalogUri}/${catalogLanguage.replace('_', '-')}/${result.key}.html`;
            } else {
              productLink = `${catalogUri}/${catalogLanguage.replace('_', '-')}/products/${
                result.categoryId
              }`;
            }
          } catch {
            productLink = '#';
          }
        }

        return (
          <Col size={[4, 4, 3]} key={result.key} className={styles.productTiles}>
            <ProductTile
              productLink={productLink}
              {...result}
              currentImage={getPrimaryImage(result.gallery.list)?.medium}
            />
          </Col>
        );
      })}
      <Col size={[4, 8, 12]} className={styles.productLoadMore}>
        <SearchLoadMore
          totalResults={products?.total || 0}
          showingAmount={12 + offset}
          handleNext={() => {
            const nextOffset = offset + 12;
            setOffset(nextOffset);
            dispatch(setSearchOffset(nextOffset));
          }}
        />
      </Col>
    </Row>
  );
}

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import qs from 'qs';
import { Icon } from '@geberit/gdds';

// styles
import styles from './social-icons.module.scss';

// utils
import SOCIAL_SHARE_DATA from 'components/SocialShare/constants';
import { baseURLSelector, socialMediaItemsSelector } from 'utils/selectors/globalsSelectors';
import { decodingContent as decodeContent } from 'utils/decodingContent';
import { useTracking } from 'utils/tracking/track';

interface SocialIconsProps {
  metaData: MetaData;
  hashtags?: string[];
  socialNetworks: string[];
  contentType: 'page' | 'video';
}

function whichIsIn(arr: string[]) {
  return (ele: { baseUrl: string; symbol: string; color: string }) => arr.indexOf(ele.symbol) >= 0;
}

function SocialIcons({
  metaData: { canonical, url, title },
  hashtags,
  socialNetworks = [],
  contentType = 'page',
}: Readonly<SocialIconsProps>) {
  const socialMediaItems = useSelector(socialMediaItemsSelector);
  const baseURL = useSelector(baseURLSelector);
  const { trackShareAction } = useTracking();
  const origin = url || `${baseURL}${canonical}`;

  const icons = useMemo(() => {
    function getIcon(symbol: string) {
      return [...(socialMediaItems || []), { type: 'service-mail', url: 'mailto:' }].find(
        (item) => item.type === symbol,
      );
    }
    return SOCIAL_SHARE_DATA.filter(whichIsIn(socialNetworks))
      .map(({ baseUrl, symbol }) => {
        let sharingBody = '';
        let shareUrl = baseUrl || '';
        let method = symbol;
        switch (symbol) {
          case 'facebook':
            sharingBody = qs.stringify({
              u: origin,
              hashtags: hashtags?.join(','),
            });
            break;

          case 'instagram':
          case 'youtube':
            {
              const maybeSocialIcon = getIcon(symbol);
              if (!maybeSocialIcon) {
                return undefined;
              }

              shareUrl = maybeSocialIcon.url;
            }
            break;

          case 'linkedin':
            sharingBody = qs.stringify({
              mini: 'true',
              url,
              title,
              summary: `\n${title}\n${url}`,
              provider: url,
            });
            break;

          case 'service-mail':
            {
              method = 'mail';
              const maybeSocialIcon = getIcon(symbol);
              if (!maybeSocialIcon || !url) {
                return undefined;
              }
              // this symbol is available in gdds for service-mail
              symbol = 'envelope';
              const titleParam = title ? `${decodeContent(title)}\n` : '';
              const encodedBody = encodeURIComponent(`${titleParam}${url}`);
              shareUrl = `${maybeSocialIcon.url}?body=${encodedBody}`;
            }
            break;

          default:
            return undefined;
        }

        return {
          name: `${symbol.charAt(0).toUpperCase()}${symbol.slice(1)}`,
          link: {
            href: `${shareUrl}${sharingBody}`,
            target: '_blank',
            rel: 'nofollow',
            type: 'external',
          },
          method,
        };
      })
      .filter(Boolean)
      .map((e) => {
        return (
          <a
            className={styles.socialPlatform}
            key={e?.name}
            href={e?.link.href}
            target={e?.link.target}
            onClick={() => {
              trackShareAction({
                content_type: contentType,
                method: e?.method ?? '',
                shareUrl: e?.link?.href,
              });
            }}
          >
            {e?.name && <Icon width="1.5rem" symbol={e?.name} />}
          </a>
        );
      });
  }, [socialMediaItems, origin, hashtags, url, title]);

  return <div className={styles.shareIcons}>{icons}</div>;
}

export default SocialIcons;

import styled from 'styled-components';

export const StyledHeader = styled.div`
  @media print {
    margin-bottom: 2rem;

    && .overview-link {
      display: none;
    }
  }

  grid-area: header;

  @media ${({ theme }) => theme.device.large} {
    margin-bottom: 2rem;
  }

  .overview-link {
    color: var(--gb-brand-primary-light);
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    > span svg {
      fill: currentColor;
    }
  }

  h1 {
    margin-top: 0.625rem;
    margin-bottom: 0.5rem;
  }
`;

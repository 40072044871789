import React from 'react';
import VideoPlayButton from '../VideoPlayButton';

/**
 * Youtube Video Image placeholder
 *
 * @param id  Video Id to get the thumbnail
 * @param onClick Function to open lightbox modal
 * @returns {*}
 * @constructor
 */

interface YoutubeThumbnailProps {
  id: string;
  onClick: () => void;
}

const YoutubeThumbnail = ({ id, onClick }: Readonly<YoutubeThumbnailProps>) => (
  <div
    style={{ backgroundImage: `url(https://img.youtube.com/vi/${id}/0.jpg)` }}
    role="button"
    tabIndex={0}
    onClick={onClick}
    onKeyPress={onClick}
    className="c-youtube-video-link"
  >
    <VideoPlayButton staticImage />
  </div>
);

export default YoutubeThumbnail;

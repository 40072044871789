import React from 'react';
import VideoItem from '../../Video/VideoItem';

interface VideoSliderItemProps {
  video: {
    video: {
      videoId: string;
      videoPreviewImage: {
        mobile: string;
        desktop: string;
      };
    };
    title: string;
    description: string;
    index: number;
  };
}

function VideoSliderItem({ video, ...props }: Readonly<VideoSliderItemProps>) {
  // copy props so we don't mutate the original reference afterwards
  // see also https://github.com/akiran/react-slick/issues/1012
  const slideProps = { ...props };
  delete slideProps.key;

  return (
    <div {...slideProps}>
      <VideoItem video={video} />
    </div>
  );
}

export default VideoSliderItem;

import { Button, Typo } from '@geberit/gdds';

// utils
import { Translation } from 'components/Translation/Translation';

// styles
import { SearchLoadMoreWrapper } from './search-loadmore-wrapper.styles';
import { useTranslationFunction } from 'utils/hooks/use-translations';

type Props = {
  totalResults: number;
  showingAmount: number;
  handleNext: () => void;
};

export default function SearchLoadMore({
  totalResults,
  showingAmount,
  handleNext,
}: Readonly<Props>) {
  const translate = useTranslationFunction();

  if (totalResults <= 0) return null;

  return (
    <SearchLoadMoreWrapper>
      {totalResults > showingAmount && (
        <Button symbol="Forwards" onClick={handleNext}>
          {translate('web20_more_results')}
        </Button>
      )}
      <Typo as="span" variant="p2">
        1-{Math.min(showingAmount, totalResults)} <Translation id="web20_of" /> {totalResults}{' '}
        <Translation id="web20_articles" />
      </Typo>
    </SearchLoadMoreWrapper>
  );
}

import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/navigation';

// types
import type { Dispatch } from 'redux';

// styles
import styles from './addressContainer.module.scss';

// components
import { Translation } from 'components/Translation/Translation';
import { Button, BUTTON_BACKGROUND, BUTTON_COLORS, BUTTON_TYPES } from 'components/Button/Button';
import { Loader } from 'components/Loader/Loader';
import CartTable from 'components/Checkout/Components/CartTable/CartTable';
import AddressForm from 'components/Checkout/Components/AddressForm';
import ErrorMessageBox from 'components/Checkout/Components/ErrorMessageBox/ErrorMessageBox';
import LinkWrapper from 'components/Checkout/Components/LinkWrapper/LinkWrapper';

// utils
import { isEmpty } from 'utils/is-empty';
import { getCartAction, fetchUserDataAction } from '../../actions';
import { currentLanguageSelector } from 'utils/selectors/languageSelectors';
import {
  activeStepSelector,
  cartErrorSelector,
  deliveryAddressSelector,
  deliveryZipCodeValidationDataSelector,
  getCartSelector,
  isCartFetchingSelector,
  isFetchingZipCodeValidationSelector,
  isUserDataFetchingSelector,
  userDataErrorSelector,
  userDataSelector,
  zipCodeValidationDataSelector,
  zipCodeValidationErrorSelector,
  zipCodeValidationFieldSelector,
} from '../../selectors';
import { translationKeysSelector } from 'utils/selectors/translationSelectors';
import {
  webshopBasketPageUrlSelector,
  webshopShippingInfoSelector,
} from 'utils/selectors/globalsSelectors';
import { hasSessionData, userType } from '../../util/helperFunctions';
import { ZipCodeValidationData } from '../../action.types';

interface AddressContainerProps {
  isLogged: boolean;
  ciamId?: string;
  locale: string;
  sessionId?: string;
}

function AddressContainer({
  isLogged,
  ciamId,
  locale,
  sessionId,
}: Readonly<AddressContainerProps>) {
  const dispatch: Dispatch<any> = useDispatch();
  const router = useRouter();

  const activeStep = useSelector(activeStepSelector);
  const cart = useSelector(getCartSelector);
  const userData = useSelector(userDataSelector);
  const isFetchingCart = useSelector(isCartFetchingSelector);
  const isFetchingUserData = useSelector(isUserDataFetchingSelector);
  const userDataError = useSelector(userDataErrorSelector);
  const cartError = useSelector(cartErrorSelector);
  const isFetchingZipCodeValidation = useSelector(isFetchingZipCodeValidationSelector);
  const zipCodeValidationData = useSelector(zipCodeValidationDataSelector);
  const deliveryZipCodeValidationData = useSelector(deliveryZipCodeValidationDataSelector);
  const zipCodeValidationError = useSelector(zipCodeValidationErrorSelector);
  const zipCodeValidationFieldName = useSelector(zipCodeValidationFieldSelector);
  const deliveryAddress = useSelector(deliveryAddressSelector);
  const basketPageUrl = useSelector(webshopBasketPageUrlSelector);
  const showShippingInfo = useSelector(webshopShippingInfoSelector);
  const translations = useSelector(translationKeysSelector);
  const lang = useSelector(currentLanguageSelector);

  const [isDeliveryCheckboxSelected, setIsDeliveryCheckboxSelected] = useState(
    deliveryAddress?.deliveryCheckbox || false,
  );

  const checkDisableButton = (validationData: Partial<ZipCodeValidationData> | null): boolean =>
    isEmpty(validationData)
      ? !(isLogged || hasSessionData(userType(isLogged)))
      : !validationData.valid;

  const disableButton = checkDisableButton(zipCodeValidationData);
  let disableButtonDelivery: boolean =
    isDeliveryCheckboxSelected && checkDisableButton(deliveryZipCodeValidationData);

  useEffect(() => {
    const id = isLogged ? ciamId : sessionId;
    if (isLogged) {
      dispatch(fetchUserDataAction(locale, id!));
    }
    dispatch(getCartAction(locale, id, isLogged));
  }, [ciamId, isLogged, locale, sessionId, dispatch, lang]);

  if (isFetchingCart || isFetchingUserData) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader size="medium" />
      </div>
    );
  }

  const submitAddressForm = () => {
    document.getElementById('addressFormSubmitBtn')?.click();
  };

  return (
    <div>
      <h1 className={styles.h1}>
        <Translation id="web20_checkout_address_heading_part1" />
        <span>
          <Translation id="web20_checkout_address_heading_part2" />
        </span>
      </h1>
      {isLogged && userData && userData.sapCustomerNumber && (
        <p>
          <Translation id="web20_checkout_address_logged_user_label" />
          <span className={styles.customerNumberText}>
            <Translation id="web20_checkout_address_logged_user_customer_nr" />{' '}
            {userData.sapCustomerNumber}
          </span>
        </p>
      )}
      <div className={`grid-x ${styles.bottomMargin}`}>
        <div className="cell small-12  medium-6 large-6">
          <h4 className={styles.formHeading}>
            {isLogged ? (
              <Translation id="web20_checkout_address_logged_user_invoice_form_heading" />
            ) : (
              <Translation id="web20_checkout_address_invoice_form_heading" />
            )}
          </h4>
        </div>
        <div className="cell small-12  medium-6 large-6">
          <div className={styles.formExplanationText}>
            <Translation id="web20_checkout_address_form_explanation_text" />
            {/* * this field is mandatory */}
          </div>
        </div>
      </div>
      <div className="grid-x">
        <AddressForm
          userData={userDataError || isEmpty(userData) ? {} : userData}
          sessionId={sessionId}
          isLogged={isLogged}
          userDataError={userDataError}
          locale={locale}
          ciamId={ciamId}
          zipCodeValidationData={zipCodeValidationData}
          deliveryZipCodeValidationData={deliveryZipCodeValidationData}
          zipCodeValidationError={zipCodeValidationError}
          disableButton={disableButton}
          disableButtonDelivery={disableButtonDelivery}
          isFetchingZipCodeValidation={isFetchingZipCodeValidation}
          zipCodeValidationFieldName={zipCodeValidationFieldName}
          setIsDeliveryCheckboxSelected={setIsDeliveryCheckboxSelected}
        />
      </div>
      <hr className={styles.basketLine} />
      <h3 className={`${styles.bottomMargin} ${styles.h3}`}>
        <Translation id="web20_checkout_address_basket_table_heading" />
      </h3>
      {!isEmpty(cart) || !cartError ? (
        <div className="grid-x">
          <div className="cell large-12">
            <CartTable data={cart?.lineItemList ?? []} vatInc />
          </div>
          <div className={`cell grid-x grid-padding-x ${styles.bottomMargin} ${styles.cartFooter}`}>
            <div
              className={`cell small-12  medium-8 large-8 align-self-middle ${styles.cartFooterText}`}
            >
              <div>
                <Translation id="web20_checkout_address_basket_table_footer_description" />
              </div>
              {showShippingInfo && (
                <div>
                  <Translation id="web20_webshop_shipping_info" />
                </div>
              )}
            </div>
            <div className="cell small-6 medium-2 large-2 align-self-middle">
              <Translation id="web20_checkout_address_basket_table_headings_price" />
            </div>
            <div className="cell small-6 medium-2 large-2 align-self-middle bold">
              {cart?.total} <Translation id="web20_checkout_country_default_currency" />
            </div>
          </div>
        </div>
      ) : (
        <ErrorMessageBox>
          {'700 - '}
          <Translation id="web20_checkout_address_cart_not_available_error" />
          <LinkWrapper translationLink={translations?.web20_checkout_link_customer_service} />
        </ErrorMessageBox>
      )}
      <div className={`${styles.buttonsWrapper} ${styles.bottomMargin}`}>
        {activeStep !== 3 && (
          <Button
            type={BUTTON_TYPES.DEFAULT}
            symbol="arrow-link-light-left"
            color={BUTTON_COLORS.BLACK}
            background={BUTTON_BACKGROUND.WHITE}
            onClick={() => router.push(basketPageUrl ?? '')}
            className={styles.editButton}
          >
            {translations?.web20_checkout_address_button_back}
          </Button>
        )}
        <Button
          disabled={
            isFetchingZipCodeValidation ||
            zipCodeValidationError ||
            disableButton ||
            disableButtonDelivery
          }
          className="form-item--button"
          type={BUTTON_TYPES.DEFAULT}
          symbol="arrow-link-light-right"
          color={BUTTON_COLORS.WHITE}
          background={BUTTON_BACKGROUND.PRIMARY}
          onClick={submitAddressForm}
        >
          {translations?.web20_checkout_address_button_next}
        </Button>
      </div>
    </div>
  );
}

export default AddressContainer;

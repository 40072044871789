import axios from 'axios';
import striptags from 'striptags';

// Utils
import { currentLanguageSelector } from 'utils/selectors/languageSelectors';
import { globalsSearchSegmentSelector } from 'utils/selectors/globalsSelectors';
import { extractFilters, chainOperators } from 'utils/filters';
import { getEndpoint } from 'utils/endpoints';

const DOWNLOAD_CENTER_DATA_REQUEST_PENDING = 'downloadCenter/DOWNLOAD_CENTER_DATA_REQUEST_PENDING';
const DOWNLOAD_CENTER_DATA_REQUEST_FULFILLED =
  'downloadCenter/DOWNLOAD_CENTER_DATA_REQUEST_FULFILLED';
const DOWNLOAD_CENTER_DATA_REQUEST_REJECTED =
  'downloadCenter/DOWNLOAD_CENTER_DATA_REQUEST_REJECTED';
const SEARCH_SUGGESTIONS_REQUESTED = 'search/SEARCH_SUGGESTIONS_REQUESTED';
const SEARCH_SUGGESTIONS_FULFILLED = 'search/SEARCH_SUGGESTIONS_FULFILLED';
const SEARCH_SUGGESTIONS_REJECTED = 'search/SEARCH_SUGGESTIONS_REJECTED';

const PREFILTER = [{ key: 'indexer_source', value: 'downloads' }];
const STATIC_FILTER = [
  { key: 'agg', value: 'filter1.filter2.filter3.filter4' },
  { key: 'agg_mode', value: 'all' },
];

// suggestion actions
function fetchSuggestionsRequested() {
  return {
    type: SEARCH_SUGGESTIONS_REQUESTED,
  };
}

function fetchSuggestionsFulfilled() {
  return {
    type: SEARCH_SUGGESTIONS_FULFILLED,
  };
}

function fetchSuggestionsRejected() {
  return {
    type: SEARCH_SUGGESTIONS_REJECTED,
  };
}

function getPreFilter() {
  return PREFILTER.map((prefilter) => `${prefilter.key}:${prefilter.value}`).join('.');
}

function getFilters(filters) {
  return filters !== undefined && typeof filters !== 'string' ? filters : extractFilters(filters);
}

function downloadCenterDataRequest(language) {
  return {
    type: DOWNLOAD_CENTER_DATA_REQUEST_PENDING,
    language,
  };
}

function downloadCenterDataRequestFulfilled(language, response) {
  return {
    type: DOWNLOAD_CENTER_DATA_REQUEST_FULFILLED,
    language,
    data: response.data,
  };
}

function downloadCenterDataRequestRejected(language, error) {
  return {
    type: DOWNLOAD_CENTER_DATA_REQUEST_REJECTED,
    language,
    error,
  };
}

function doFetchDownloadCenterData(dispatch, endpoint, language, params) {
  dispatch(downloadCenterDataRequest(language));
  return axios
    .get(endpoint, { params })
    .then((response) => {
      dispatch(downloadCenterDataRequestFulfilled(language, response));
      return response;
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        dispatch(downloadCenterDataRequestRejected(language, error.response));
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        dispatch(downloadCenterDataRequestRejected(language, error.request));
      } else {
        // Something happened in setting up the request that triggered an Error
        dispatch(downloadCenterDataRequestRejected(language, error.message));
      }

      return error;
    });
}

export function fetchDownloadCenterData(requestObject) {
  return (dispatch, getState) => {
    const state = getState();
    const language = currentLanguageSelector(state);
    const segment = globalsSearchSegmentSelector(state);
    const endpoint = getEndpoint('search', state);

    const sort = requestObject.sort || 'date';
    const query = requestObject.q || undefined;
    const filters = requestObject.filters || undefined;

    const params = {
      ...(filters !== undefined ? { filters: chainOperators(getFilters(filters)) } : {}),
      ...(query !== undefined ? { q: striptags(query) } : {}),
      sort,
      prefilter: getPreFilter(),
      segment,
      agg: STATIC_FILTER.find((filter) => filter.key === 'agg').value,
      agg_mode: STATIC_FILTER.find((filter) => filter.key === 'agg_mode').value,
      num: 10000,
    };
    return doFetchDownloadCenterData(dispatch, endpoint, language, params);
  };
}

export function fetchSuggestions(query) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchSuggestionsRequested());
    const segment = globalsSearchSegmentSelector(state);
    const endpoint = `${getEndpoint('suggest', state)}`;

    if (query) {
      dispatch(fetchSuggestionsFulfilled());
      return [];
    }

    try {
      const response = await axios.get(endpoint, {
        params: {
          segment,
          q: query,
        },
      });
      dispatch(fetchSuggestionsFulfilled());

      return response.data.suggestions;
    } catch (e) {
      dispatch(fetchSuggestionsRejected());
      return [];
    }
  };
}

export function setSessionStorageByType(type, value) {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(type, JSON.stringify(value));
  }
}

export function fetchDownloadZip(downloads, setDownloadProgress, brandName) {
  return async (dispatch, getState) => {
    const state = getState();
    const endpoint = `${getEndpoint('downloads', state)}/zip`;
    const config = {
      responseType: 'blob',
      onDownloadProgress: ({ loaded, currentTarget }) => {
        if (!navigator.userAgent.search('Firefox')) {
          const percentage = Math.round((loaded / currentTarget.response.size) * 100);
          if (percentage !== 0) {
            setDownloadProgress(percentage);
          }
        }
      },
    };

    // animation should start immadiately
    setDownloadProgress(0.1);

    const response = await axios.post(endpoint, { files: downloads.map((v) => v.url) }, config);
    if (navigator.userAgent.search('Firefox')) {
      setDownloadProgress(100);
    }

    if (typeof window !== 'undefined') {
      const blob = new Blob([response.data], { type: response.data.type });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      const fileName = `${brandName?.toLowerCase()}-downloads.zip`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };
}

import { useSelector } from 'react-redux';

// types
import type { FormikErrors, FormikProps, FormikTouched } from 'formik';
import { SelectOption } from '@geberit/gdds/dist/esm/form/Dropdown/types/Dropdown.types';

// components
import HotelData from './hotel-data';
import FactoryTour from './factory-tour';
import Catering from './catering';

// utils
import { campusShowFactoryTourSelector } from 'utils/selectors/globalsSelectors';
import useFoodFieldsEnabled from '../use-food-fields-enabled';

interface SeminarEventDataProps {
  mandatoryErrorMessage?: string;
  index?: number;
  cateringItems?: SelectOption[];
}

function SeminarEventData({
  index,
  mandatoryErrorMessage,
  cateringItems,
  touched: originalTouched,
  handleBlur,
  handleChange,
  errors,
  values,
}: SeminarEventDataProps &
  Pick<FormikProps<any>, 'errors' | 'values' | 'touched' | 'handleBlur' | 'handleChange'>) {
  const showFactoryTour = useSelector(campusShowFactoryTourSelector);
  const foodFieldsEnabaled = useFoodFieldsEnabled();

  const fieldErrors =
    index === undefined ? errors : ((errors?.participants?.[index - 1] ?? {}) as FormikErrors<any>);
  const touched =
    index === undefined
      ? originalTouched
      : ((originalTouched?.participants?.[index - 1] ?? {}) as FormikTouched<any>);
  const nameSuffix = index ? String(index) : '';

  return (
    <>
      {/* hotel */}
      <HotelData
        nameSuffix={nameSuffix}
        mandatoryErrorMessage={mandatoryErrorMessage}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        values={values}
        errors={fieldErrors}
      />

      {/* factory tour */}
      {showFactoryTour && (
        <FactoryTour nameSuffix={nameSuffix} handleChange={handleChange} values={values} />
      )}

      {/* catering */}
      {foodFieldsEnabaled && (
        <Catering
          nameSuffix={nameSuffix}
          handleChange={handleChange}
          values={values}
          touched={touched}
          errors={fieldErrors}
          cateringItems={cateringItems}
          handleBlur={handleBlur}
          mandatoryErrorMessage={mandatoryErrorMessage}
        />
      )}
    </>
  );
}

export default SeminarEventData;

import key from 'weak-key';
import sanitize from 'xss';
import { decode } from 'html-entities';

// components
import { InnerHtml } from '../../InnerHtml/inner-html';

// utils
import { decodingContent } from '../../../utils/decodingContent';
import { truncateMiddle } from '../../../utils/truncate';
import { useTracking } from '../../../utils/hooks/useTracking';
import { clickSearchResult } from '../trackingActions';

/**
 * Prepares the snippet of a search result - accepts both a single string or
 * and array of strings.
 * @param {string/array} snippet Either a string or an array of strings. Snippet
 * to render.
 * @returns {JSX} Snippet as JSX based on sanitized values.
 */

function generateSnippet(snippet) {
  if (typeof snippet === 'string') {
    return (
      <div>
        <InnerHtml
          content={snippet.endsWith('.') ? sanitize(snippet) : `${sanitize(snippet)} ...`}
          as="div"
        />
      </div>
    );
  }

  return (
    <div>
      {snippet.map((item) => (
        <InnerHtml
          key={key({ item })}
          as="div"
          content={item.endsWith('.') ? sanitize(item) : `${sanitize(item)} ...`}
        />
      ))}
    </div>
  );
}

interface SoftContentResultItemProps {
  title: string;
  url: string;
  snippet: string | string[];
}

function SoftContentResultItem({
  title = '',
  url = '',
  snippet = '',
}: Readonly<SoftContentResultItemProps>) {
  const track = useTracking();

  const onClick = () => {
    track.trackEvent(clickSearchResult(title, 'Result Page Clicks'));
  };

  return (
    <li key={key({ url })}>
      <a href={url} title={decodingContent(decode(title), true)} onClick={onClick}>
        {decodingContent(`<span>${decode(title)}</span>`)}
      </a>
      <div className="item-content">
        <span className="url-preview">{truncateMiddle(sanitize(url), 58, '...')}</span>
        <span className="snippet">{generateSnippet(snippet)}</span>
      </div>
    </li>
  );
}

export default SoftContentResultItem;

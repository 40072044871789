import { Row, Col } from '@geberit/gdds';

// styles
import styles from './intro-text.module.scss';

// utils
import { Formats, Headline, getFontWeight } from 'components/ContentElements/Headline';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useThemeName } from 'utils/hooks/use-theme';

interface IntroHeadlineProps {
  position?: string;
  colSize: number[];
  title: string;
  subtitle?: string;
  format: string;
  emphasize?: string;
  intro: string;
  text: string;
  titlePreviewId: string;
  subtitlePreviewId: string;
  disableInEdit: boolean;
}

export function IntroHeadline({
  position,
  colSize,
  title,
  subtitle,
  format,
  emphasize,
  intro,
  text,
  titlePreviewId,
  subtitlePreviewId,
  disableInEdit,
}: Readonly<IntroHeadlineProps>) {
  const theme = useThemeName();

  return (
    <Row className={classNameBuilder(position && styles[position], styles.row)}>
      <Col size={colSize}>
        <Headline
          title={title}
          subtitle={subtitle}
          tag={format}
          titleFontWeight={getFontWeight(emphasize === 'title', theme, format)}
          subtitleFontWeight={getFontWeight(emphasize === 'subtitle', theme, format)}
          format={Formats.h1}
          className={classNameBuilder(styles.headline, !intro && !text && styles.noMarginBottom)}
          isUppercase
          titlePreviewId={disableInEdit ? undefined : titlePreviewId}
          subtitlePreviewId={disableInEdit ? undefined : subtitlePreviewId}
        />
      </Col>
    </Row>
  );
}

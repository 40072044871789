import { trackTemplate } from 'utils/tracking';

/**
 * Flipbook and Fileurl click
 * @param string text
 */
export function clickDownloadsLink(name, title) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Download center',
      action: `Download Center ${name}`,
      label: title,
      value: '0',
    },
  });
}

import type { PaymentData } from './action.types';

// webshop base selector
const webShopSelector = (state: AppState) => state.webshop;

// example selector
export const activeStepSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  if (!shopState) return 0;
  return shopState.activeStep || 0;
};

export const getCartSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  if (!shopState) return null;
  return shopState.cart || null;
};

export const isCartFetchingSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  if (!shopState) return null;
  return shopState.isFetchingCart || false;
};

export const cartErrorSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  if (!shopState) return null;
  return shopState.cartError || false;
};

export const invoiceAddressSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  return shopState?.invoiceAddress || null;
};

export const deliveryAddressSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  return shopState?.deliveryAddress || null;
};

export const userDataSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  if (!shopState) return null;
  return shopState.userData || null;
};

export const isUserDataFetchingSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  if (!shopState) return null;
  return shopState.isFetchingUserData || false;
};

export const userDataErrorSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  if (!shopState) return null;
  return shopState.userDataError || false;
};
export const orderDataSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  if (!shopState) return null;
  return shopState.orderData || null;
};

export const isOrderDataFetchingSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  if (!shopState) return null;
  return shopState.isFetchingOrderData || false;
};

export const simulatedOrderDataSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  return shopState?.simulatedOrderData || null;
};

export const isSimulatedOrderDataFetchingSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  return shopState?.isFetchingSimulatedOrderData ?? false;
};

export const simulatedOrderErrorSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  return shopState?.simulatedOrderError || false;
};

export const orderDataErrorSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  if (!shopState) return null;
  return shopState.orderError || false;
};

export const isPaymentDataFetchingSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  return shopState?.isFetchingPaymentData || false;
};

export const paymentDataSelector = (state: AppState): Partial<PaymentData> | null => {
  const shopState = webShopSelector(state);
  return shopState?.paymentData || null;
};

export const paymentErrorSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  return shopState?.paymentDataError || false;
};

export const isFetchingZipCodeValidationSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  if (!shopState) return null;
  return shopState.isFetchingZipCodeValidation || false;
};

export const zipCodeValidationDataSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  if (!shopState) return null;
  return shopState.zipCodeValidationData || null;
};

export const deliveryZipCodeValidationDataSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  if (!shopState) return null;
  return shopState.deliveryZipCodeValidationData || null;
};

export const zipCodeValidationErrorSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  if (!shopState) return null;
  return shopState.zipCodeValidationError || false;
};

export const zipCodeValidationFieldSelector = (state: AppState) => {
  const shopState = webShopSelector(state);
  if (!shopState) return '';
  return shopState.zipCodeValidationField || '';
};

export default activeStepSelector;

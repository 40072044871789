import { AccordionItem, Typo } from '@geberit/gdds';
import { useTheme } from 'styled-components';

// styles
import { StyledAccordions } from './accordions.styles';

// types
import type { IEntry } from '../../locator.types';

// utils
import { useLocatorConfiguration } from '../../utils/use-locator-configuration';
import { decodingContent } from 'utils/decodingContent';
import { useNord } from 'utils/hooks/use-nord';

export function Accordions({ products }: Readonly<Pick<IEntry, 'products'>>) {
  const locatorConfig = useLocatorConfiguration();
  const theme = useTheme();
  const isNord = useNord();

  if (products.length === 0) {
    return null;
  }

  const H5_SIZES = {
    xsmall: theme.fontTokens.font09,
    medium: theme.fontTokens.font08,
    large: theme.fontTokens.font07,
    xlarge: theme.fontTokens.font07,
  };

  return (
    <StyledAccordions id={locatorConfig.dealerPage.detailPageHash}>
      <Typo
        variant="h2"
        size={H5_SIZES}
        fontWeight={isNord ? 400 : 700}
        isUppercase={isNord}
      >
        {decodingContent(locatorConfig?.dealerPage?.bathroomProducts)}
      </Typo>
      {products.map((product) => {
        return (
          <AccordionItem
            fontSize="p1"
            titleBold
            key={product.category}
            iconAlignment="right"
            title={`${product.category} (${product.series.length})`}
          >
            <Typo variant="p1">{product.series.join(', ')}</Typo>
          </AccordionItem>
        );
      })}
    </StyledAccordions>
  );
}

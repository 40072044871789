const size = 21;
const fontSize = 15;
const titleFontSize = 14;
const circleTop = 24;
const solid = 'solid';
const primaryColor = '#0a5a96';
const defaultColor = '#E0E0E0';
const highlightedColor = '#ccc';
const circleFontColor = '#FFF';
const defaultBorderWidth = 0;

export const getStyles = (width): Record<string, React.CSSProperties> => ({
  step: {
    width: `${width}%`,
    display: 'table-cell',
    position: 'relative',
    paddingTop: circleTop,
    paddingBottom: circleTop,
  },
  circle: {
    width: size,
    height: size,
    margin: '0 auto',
    backgroundColor: defaultColor,
    borderRadius: '50%',
    textAlign: 'center',
    padding: 1,
    fontSize,
    color: circleFontColor,
    display: 'block',
    opacity: 1,
    borderWidth: defaultBorderWidth,
    borderColor: '',
    borderStyle: solid,
  },
  activeCircle: {
    backgroundColor: primaryColor,
  },
  highlightedCircle: {
    backgroundColor: highlightedColor,
  },
  hoverStyle: {
    cursor: 'pointer',
    opacity: 0.8,
    transition: 'all .4s ease-out',
  },
  completedCircle: {
    backgroundColor: primaryColor,
  },
  index: {
    lineHeight: `${size + fontSize / 4}px`,
    color: circleFontColor,
    position: 'relative',
    bottom: '5px',
  },
  title: {
    marginTop: 8,
    fontSize: titleFontSize,
    fontWeight: '300',
    textAlign: 'center',
    display: 'block',
    color: '#757575',
    opacity: 1,
  },
  activeTitle: {
    color: primaryColor,
    fontWeight: '500',
  },
  highlightedTitle: {
    fontWeight: '500',
  },
  completedTitle: {
    color: primaryColor,
    fontWeight: '500',
  },
  leftBar: {
    position: 'absolute',
    top: circleTop + size / 2,
    height: 1,
    borderTopStyle: solid,
    borderTopWidth: 2,
    borderTopColor: defaultColor,
    left: 0,
    right: '50%',
    marginRight: size / 2 + 4,
    opacity: 1,
  },
  rightBar: {
    position: 'absolute',
    top: circleTop + size / 2,
    height: 1,
    borderTopStyle: solid,
    borderTopWidth: 2,
    borderTopColor: defaultColor,
    right: 0,
    left: '50%',
    marginLeft: size / 2 + 4,
    opacity: 1,
  },
  completedBar: {
    borderTopColor: primaryColor,
  },
  highlightedBar: {
    borderTopColor: highlightedColor,
  },
});

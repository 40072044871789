import { Button, Col } from '@geberit/gdds';

// styles
import styles from './filter.module.scss';

export default function ResetCol({ reset, resetLabel }) {
  return (
    <Col size={[4, 8, 6]} className={styles.resetButton}>
      <Button onClick={reset}>{resetLabel}</Button>
    </Col>
  );
}

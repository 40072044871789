'use client';

import { useNord } from '../../utils/hooks/use-nord';
import SearchContainer from '../ContentElementsGdds/search';
import XYSearchContainer from './SearchContainer';

export default function SearchWrapper() {
  const isNordics = useNord();

  if (isNordics) {
    return <SearchContainer />;
  }

  return <XYSearchContainer />;
}

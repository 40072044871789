import { Typo, List, Row, Col } from '@geberit/gdds';

// styles
import styles from './no-result.module.scss';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useGdds } from 'utils/hooks/use-gdds';

export function NoResults({ loading = false }) {
  const translate = useTranslationFunction();
  const noResultText = translate('press_search_no_result');
  const searchProposals = translate('press_search_proposals');
  const firstProposal = translate('press_search_first_proposal');
  const secondProposal = translate('press_search_second_proposal');
  const thirdProposal = translate('press_search_third_proposal');
  const isGdds = useGdds();

  if (loading) return null;

  return (
    <Row>
      <Col size={[0, 2, 4]}></Col>
      <Col size={[4, 4, 4]}>
        <div
          className={classNameBuilder(styles.noResultsWrapper, !isGdds && styles.documentFontSize)}
        >
          {noResultText && (
            <Typo variant="h3" className={styles.headline}>
              {noResultText}
            </Typo>
          )}
          {searchProposals && (
            <Typo variant="p1" fontWeight={700} className={styles.subline}>
              {searchProposals}
            </Typo>
          )}

          <List level="1" listItemMarginLeft={16} listItemMarginTop={12}>
            {firstProposal && (
              <List.Item>
                <Typo variant="p1" className={styles.text}>
                  {firstProposal}
                </Typo>
              </List.Item>
            )}
            {secondProposal && (
              <List.Item>
                <Typo variant="p1" className={styles.text}>
                  {secondProposal}
                </Typo>
              </List.Item>
            )}
            {thirdProposal && (
              <List.Item>
                <Typo variant="p1" className={styles.text}>
                  {thirdProposal}
                </Typo>
              </List.Item>
            )}
          </List>
        </div>
      </Col>
      <Col size={[0, 2, 4]}></Col>
    </Row>
  );
}

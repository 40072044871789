import { useState, useEffect, useMemo, useCallback, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { InnerHtml, Search, Typo, Container, Col, Row } from '@geberit/gdds';

// styles
import styles from './downloadcenter.module.scss';

// types
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';

// components
import DownloadcenterCategory from './dlc-category';
import DownloadcenterLightbox from './dlc-lightbox';
import FilterArea from './dlc-filter-area';
import BasketBox from './dlc-basket-box';
import DownloadcenterMediaSection from './dlc-media-section';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { Headline, Formats, FontWeights } from 'components/ContentElements/Headline';

// actions
import {
  fetchDownloadCenterData,
  fetchSuggestions,
  setSessionStorageByType,
  fetchDownloadZip,
} from './actions';

// selectors
import { downloadCenterDataSelector } from '../../selectors';
import {
  catalogBrandNameSelector,
  globalsSearchSegmentSelector,
} from 'utils/selectors/globalsSelectors';

// utils
import {
  returnFilteredItems,
  sortItemsByDate,
  returnMediaFilteredItems,
} from './utils/filterFunctions';
import { headingTextSize, resultsAmountSize } from './utils/sizes';
import { decodingContent } from 'utils/decodingContent';
import { useTranslationFunction } from 'utils/hooks/use-translations';

type FilterItems = { value: string; text: string }[];

function getParamsFromUrl(search) {
  return qs.parse(search, {
    ignoreQueryPrefix: true,
  });
}

function updateHistory(router: AppRouterInstance, pathname: string, search: string) {
  router.replace(`${pathname}${search}`, { scroll: false });
}

export interface DownloadcenterProps {
  page: Content['page'] & {
    filterTypeOrder: Record<string, number>;
    filterOrder: Record<string, number>;
    filterMediaOrder: Record<string, number>;
    mediaFilter: string;
  };
  breadcrumbs: Content['breadcrumbs'];
  contentAreas: Content['contentAreas'];
}

// Downloadcenter component
function Downloadcenter({
  page: { headline, subline, text, filterTypeOrder, filterOrder, filterMediaOrder, mediaFilter },
  breadcrumbs,
  contentAreas: { content: formContent },
}: Readonly<DownloadcenterProps>) {
  const DEFAULT_PAGE = 1;

  const router = useRouter();
  const currentSearchParams = useSearchParams();
  const pathname = usePathname();
  const dispatch = useDispatch();
  const downloadCenterData = useSelector(downloadCenterDataSelector);
  const segment = useSelector(globalsSearchSegmentSelector);
  const brandName = useSelector(catalogBrandNameSelector) ?? 'geberit';
  const [searchParams, setSearchParams] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [downloadCounter, setDownloadCounter] = useState(0);
  const [orderCounter, setOrderCounter] = useState(0);
  const [downloads, setDownloads] = useState([]);
  const [orders, setOrders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [itemsPerCategory, setItemsPerCategory] = useState([]);
  const [suggestions, setSuggestions] = useState();
  const [showLightbox, setShowLightbox] = useState(false);
  const [currentResultsAmount, setCurrentResultsAmount] = useState();
  const [filter1Value, setFilter1Value] = useState(new Date().getFullYear().toString());
  const [filter2Value, setFilter2Value] = useState<string[]>([]);
  const [remainingFilter2Items, setRemainingFilter2Items] = useState([]);
  const [mediaItems, setMediaItems] = useState([]);
  const [filter4Value, setFilter4Value] = useState('');
  const deeplinkExists = useRef(false);
  const [filterAreaDisabled, setFilterAreaDisabled] = useState(false);
  const filteredMediaItems = useMemo(
    () => returnMediaFilteredItems(filter4Value, mediaItems),
    [filter4Value, mediaItems],
  );
  const translate = useTranslationFunction();
  useEffect(() => {
    const params = getParamsFromUrl(location.search);
    setSearchParams(params);
  }, []);

  const [, setDownloadProgress] = useState(0);
  const customIds = useMemo(() => searchParams?.ids?.split(','), [searchParams]);
  const customTitle = useMemo(() => searchParams?.title, [searchParams]);
  const isCustom = useMemo(() => Boolean(customIds || customTitle), [customIds, customTitle]);

  useLayoutEffect(() => {
    if (currentSearchParams?.media && filteredMediaItems?.length && !deeplinkExists.current) {
      deeplinkExists.current = true;
      setFilter4Value(currentSearchParams.media as string);
      document.getElementById('media')?.scrollIntoView();
    }
  }, [currentSearchParams, filteredMediaItems]);

  const handleSearchSubmit = (autoSuggestSelection) => {
    const q = autoSuggestSelection || document.forms[1].elements[0].value;
    setFilterAreaDisabled(true);

    updateHistory(
      router,
      pathname,
      qs.stringify(
        {
          ...searchParams,
          q,
          page: DEFAULT_PAGE,
        },
        {
          addQueryPrefix: true,
        },
      ),
    );
    setSearchQuery(q);
  };

  const handleSearchChange = async (searchInput) => {
    if (searchInput.length === 0) {
      setSuggestions([]);
      setSearchQuery('');
      setFilterAreaDisabled(false);
    } else {
      const fetchedSuggestions = await dispatch(fetchSuggestions(searchInput));
      const suggestionObject = [];

      fetchedSuggestions.forEach((sugg) => {
        suggestionObject.push({ content: sugg, value: sugg });
      });

      setSuggestions(suggestionObject);
    }
  };

  const updateOrders = (item) => {
    const type = 'orders';
    let currentOrders = orders;

    const itemExists = currentOrders.find((element) => element.id === item.id);
    const input = itemExists ? -1 : 1;

    const newOrderCounter = orderCounter + parseInt(input, 10);
    setOrderCounter(newOrderCounter);
    if (newOrderCounter <= 0) setShowLightbox(undefined);

    if (itemExists) {
      if (input > 1) {
        itemExists.amount = input;
        setOrders([...currentOrders]);
      } else {
        currentOrders = currentOrders.filter((element) => element.id !== item.id);
        setOrders([...currentOrders]);
      }
    } else {
      currentOrders.push({ ...item, amount: 1 });
      setOrders([...currentOrders]);
    }

    setSessionStorageByType(type, currentOrders);
  };

  const updateAmount = (item) => {
    const currentOrders = orders;

    const itemExists = currentOrders.find((element) => element.id === item.id);
    if (itemExists) {
      itemExists.amount = item.amount;
      setOrders([...currentOrders]);
    }
  };

  const updateDownloads = (item, input) => {
    const type = 'downloads';
    const currentDownloadCounter = downloadCounter;
    setDownloadCounter(Math.max(currentDownloadCounter + input, 0));
    let currentDownloads = downloads;
    const currentCounterDownloads = downloadCounter;

    if (downloadAlreadySet(item)) {
      setDownloadCounter(currentCounterDownloads - 1);
      currentDownloads = currentDownloads.filter((dl) => dl.id !== item.id);
      if (currentDownloads.length <= 0) setShowLightbox(undefined);
      setDownloads([...currentDownloads]);
    } else {
      setDownloadCounter(currentCounterDownloads + 1);
      currentDownloads.push(item);
      setDownloads([...currentDownloads]);
    }

    setSessionStorageByType(type, currentDownloads);
  };

  const downloadAlreadySet = (item) => {
    return downloads.some((download) => download.id === item.id);
  };

  const downloadAsZip = async () => {
    const tempDownloads = JSON.parse(window.sessionStorage.getItem('downloads'));

    if (tempDownloads && tempDownloads.length > 0) {
      dispatch(fetchDownloadZip(tempDownloads, setDownloadProgress, brandName));
      setSessionStorageByType('downloads', []);
      setDownloads([]);
      setDownloadCounter(0);
      setShowLightbox(undefined);
    }
  };

  // set the items hook and assign them to the corresponding category
  // (for each category 1 array with all the corresponding items
  // - same index as the category in the category hook)
  const mapItemsToCategory = useCallback(
    async (dlcResults, dlcCategories) => {
      const tempItemsPerCategory = Array.from({ length: dlcCategories.length }, () => Array());

      if (isCustom) {
        const customItems = dlcResults.filter((item) =>
          customIds.includes(item.meta.downloadId[0]),
        );
        tempItemsPerCategory[0] = customItems;

        setCurrentResultsAmount(customItems.length);

        const sortedItems = await sortItemsByDate(tempItemsPerCategory);
        setItemsPerCategory([...sortedItems]);

        return;
      }

      dlcResults.forEach((item) => {
        if (item.meta?.filter3?.length !== 0) {
          item.meta?.filter3?.forEach((filterEntry) => {
            const decodedFilterEntry = decodingContent(filterEntry);

            if (dlcCategories.includes(decodedFilterEntry)) {
              tempItemsPerCategory[dlcCategories.indexOf(decodedFilterEntry)].push(item);
            } else if (decodedFilterEntry !== decodingContent(mediaFilter)) {
              tempItemsPerCategory[tempItemsPerCategory.length - 1].push(item);
            }
          });
        } else {
          tempItemsPerCategory[tempItemsPerCategory.length - 1].push(item);
        }
      });

      setCurrentResultsAmount(tempItemsPerCategory.reduce((sum, cur) => sum + cur.length, 0));

      const sortedItems = await sortItemsByDate(tempItemsPerCategory);

      setItemsPerCategory([...sortedItems]);
    },
    [customIds, isCustom, mediaFilter],
  );

  // set the category hook which includes all categories ordered by the filterOrder
  const filterCategories = useCallback(
    (dlcResults) => {
      const tempCategories = [];

      if (isCustom) {
        tempCategories.push(decodeURIComponent(customTitle));
        setCategories(tempCategories);

        if (dlcResults && !filterAreaDisabled) {
          mapItemsToCategory(dlcResults, tempCategories);
        }
        return;
      }

      if (filterOrder !== undefined) {
        Object.keys(filterOrder).forEach((cat) => {
          if (decodingContent(cat) !== mediaFilter) {
            tempCategories[filterOrder[cat]] = cat;
          }
        });
      }

      tempCategories[tempCategories.length] = translate('group_downloadcenter_other');
      setCategories(tempCategories);

      if (dlcResults) {
        mapItemsToCategory(dlcResults, tempCategories);
      }
    },
    [
      customTitle,
      filterAreaDisabled,
      filterOrder,
      isCustom,
      mapItemsToCategory,
      mediaFilter,
      translate,
    ],
  );

  // iterate over the items to find out, which filter2 items exist
  const getFilter2Items = useCallback(() => {
    if (downloadCenterData) {
      const uniqueFilter2Items: FilterItems = [];

      for (let result of downloadCenterData.results) {
        if (result.meta?.filter2?.length < 1) continue;
        for (let filter of result.meta.filter2) {
          const decodedFilter = decodingContent(filter);
          if (
            !uniqueFilter2Items.some(
              (filterItem) => decodingContent(filterItem.value) === decodedFilter,
            )
          ) {
            uniqueFilter2Items.push({
              value: decodedFilter,
              text: decodedFilter,
            });
          }
        }
      }

      uniqueFilter2Items.sort((a, b) => {
        if (filterTypeOrder[a.value] === undefined && filterTypeOrder[b.value] !== undefined) {
          return 1;
        }
        if (filterTypeOrder[b.value] === undefined && filterTypeOrder[a.value] !== undefined) {
          return -1;
        }
        if (filterTypeOrder[a.value] < filterTypeOrder[b.value]) {
          return -1;
        }
        if (filterTypeOrder[a.value] > filterTypeOrder[b.value]) {
          return 1;
        }
        return 0;
      });

      return uniqueFilter2Items;
    }

    return [];
  }, [downloadCenterData]);

  // iterate over the items to find out, which filter1 items exist
  const getFilter1Items = useCallback(() => {
    if (downloadCenterData) {
      const uniqueFilter1Items: FilterItems = [];

      downloadCenterData.results.forEach((item) => {
        item.meta?.filter1?.forEach((filterValues) => {
          if (!uniqueFilter1Items.some((filterItem) => filterItem.value === filterValues)) {
            const textValue =
              parseInt(filterValues, 10) === new Date().getFullYear()
                ? translate('web20_current_year')
                : filterValues;
            uniqueFilter1Items.push({ value: filterValues, text: textValue });
          }
        });
      });

      uniqueFilter1Items.sort((a, b) => {
        if (a.value > b.value) return -1;
        if (a.value < b.value) return 1;
        return 0;
      });

      return uniqueFilter1Items;
    }

    return [];
  }, [downloadCenterData, translate]);

  const getFilter4ItemsFromMediaItems = useCallback(
    (mediaItems) => {
      if (mediaItems.length) {
        const filter4Items: FilterItems = [];

        for (let item of mediaItems) {
          if (item.meta?.filter4?.length < 1) continue;
          for (let filter of item.meta.filter4) {
            const found = filter4Items.some((element) => element.value === filter);
            if (!found) {
              filter4Items.push({
                value: filter,
                text: filter,
              });
            }
          }
        }

        filter4Items.sort((a, b) => {
          if (filterMediaOrder[a.value] === undefined && filterMediaOrder[b.value] !== undefined) {
            return 1;
          }
          return filterMediaOrder[a.value] - filterMediaOrder[b.value];
        });

        return filter4Items;
      }

      return [];
    },
    [filterMediaOrder],
  );
  const getFilter4Items = () => getFilter4ItemsFromMediaItems(mediaItems);

  const handleChangeFilter4 = async (input) => {
    if (input.value) {
      setFilter4Value(input.value);
      window.sessionStorage.setItem('filter4', input.value);
    }
  };

  const handleFilterChange = useCallback(
    async (filter1ValueTemp, filter2ValueTemp) => {
      const filteredResults = isCustom
        ? downloadCenterData.results
        : await returnFilteredItems(filter1ValueTemp, filter2ValueTemp, downloadCenterData.results);

      filterCategories(filteredResults);
      if (filter1ValueTemp) {
        window.sessionStorage.setItem('filter1', filter1ValueTemp);
      }
      if (filter2ValueTemp) {
        window.sessionStorage.setItem('filter2', filter2ValueTemp);
      }
    },
    [downloadCenterData?.results, filterCategories, isCustom],
  );

  const resetFilters = async () => {
    let filter1ValueTemp = '';
    if (getFilter1Items().length > 0) {
      filter1ValueTemp = getFilter1Items().find(
        (item) => item.value === new Date().getFullYear().toString(),
      )?.value;
    }
    const filteredResults = await returnFilteredItems(
      filter1ValueTemp,
      [],
      downloadCenterData.results,
    );
    filterCategories(filteredResults, false);
    window.sessionStorage.removeItem('filter1');
    window.sessionStorage.removeItem('filter2');
  };

  // set the filter2 items which are not selected, to show only them in the dropdown
  const setRemainingF2Items = (f2Items) => {
    const filter2 = window.sessionStorage.getItem('filter2');
    let filter2Array: string[] = [];

    if (filter2) {
      filter2Array = filter2.split(',');
    }

    let remainingF2Items = f2Items;

    for (let filter of filter2Array) {
      remainingF2Items = remainingF2Items.filter((item) => item.value !== filter);
    }

    setRemainingFilter2Items(remainingF2Items);
  };

  // after loading: get the orders and downloads from the sessionstorage to set the hooks
  useEffect(() => {
    dispatch(fetchDownloadCenterData(searchParams));

    const ordersSS = JSON.parse(window.sessionStorage.getItem('orders'));
    const downloadsSS = JSON.parse(window.sessionStorage.getItem('downloads'));

    if (ordersSS) {
      setOrders(ordersSS);
      setOrderCounter(ordersSS.length);
    }

    if (downloadsSS) {
      setDownloads(downloadsSS);
      setDownloadCounter(downloadsSS.length);
    }
  }, [segment, dispatch]);

  function getFilter1(currentFilter1Items) {
    if (!currentFilter1Items || currentFilter1Items.length === 0) {
      return undefined;
    }

    const currentYear = new Date().getFullYear();
    const filter1Temp = currentFilter1Items.find((item) => item.value === String(currentYear));

    if (filter1Temp?.value) {
      return filter1Temp.value;
    }

    // get greatest date value as fallback
    return Math.max(...currentFilter1Items.map((e) => Number(e.value)));
  }

  // when downloadcenterData is loaded: get the filters from the sessionstorage and set the hooks
  useEffect(() => {
    if (downloadCenterData) {
      if (filterAreaDisabled) {
        mapItemsToCategory(downloadCenterData.results, categories);
      } else {
        const tempMediaItems = [];
        for (let result of downloadCenterData.results) {
          if (result.meta?.filter3?.length < 1) continue;
          for (let filter of result.meta.filter3) {
            if (decodingContent(filter) === mediaFilter) {
              tempMediaItems.push(result);
            }
          }
        }

        setMediaItems(tempMediaItems);

        const filter1 = window.sessionStorage.getItem('filter1');
        const filter2 = window.sessionStorage.getItem('filter2');

        const currentFilter1Items = getFilter1Items();
        const currentFilter4Items = getFilter4ItemsFromMediaItems(tempMediaItems);

        let filter2Array: string[] = [];
        let filter1Temp = getFilter1(currentFilter1Items);

        if (filter2) filter2Array = filter2.split(',');
        if (filter1) filter1Temp = filter1;
        const filter4Temp =
          currentFilter4Items && currentFilter4Items.length > 0 ? currentFilter4Items[0].value : '';

        setFilter1Value(filter1Temp);
        setFilter2Value(filter2Array);

        handleFilterChange(filter1Temp, filter2Array);
        if (!deeplinkExists.current) {
          handleChangeFilter4({ value: filter4Temp });
        }
      }
    }
  }, [downloadCenterData, isCustom]);

  // if searchQuery is set, fetch the data with the query as param
  useEffect(() => {
    const newSearchParams = searchParams;

    newSearchParams.q = searchQuery;

    dispatch(fetchDownloadCenterData(newSearchParams));
  }, [searchQuery, dispatch]);

  return (
    <>
      <Breadcrumb items={breadcrumbs} />
      <Container maxContentWidth="78rem" className={styles.dlcContainer}>
        <Row>
          <Col size={[4, 8, 8]} className={styles.headlineSubline}>
            <Headline
              title={headline}
              subtitle={subline}
              isUppercase
              format={Formats.h1}
              titleFontWeight={FontWeights.bold}
              subtitleFontWeight={FontWeights.light}
            />
            <Typo tag="div" size={headingTextSize}>
              <InnerHtml as="p" content={text} />
            </Typo>
          </Col>
          <Col size={[4, 8, 4]}>
            <BasketBox
              orderCounter={orderCounter}
              downloadCounter={downloadCounter}
              setShowLightbox={setShowLightbox}
              showLightbox={showLightbox}
            />
            {showLightbox && (
              <DownloadcenterLightbox
                type={showLightbox}
                orders={orders}
                downloads={downloads}
                form={formContent?.[0]}
                updateOrder={updateOrders}
                updateAmount={updateAmount}
                handleClose={() => setShowLightbox(undefined)}
                downloadAsZip={downloadAsZip}
                updateDownloads={updateDownloads}
              />
            )}
          </Col>
        </Row>
        {!isCustom && (
          <>
            <Row>
              <div className={styles.searchBar}>
                <Search
                  name="searchdlc"
                  placeholder={translate('web20_search_placeholder')}
                  onSubmit={handleSearchSubmit}
                  onChange={handleSearchChange}
                  suggestions={suggestions}
                  value={searchQuery}
                />
              </div>
            </Row>
            <FilterArea
              getFilter2Items={getFilter2Items}
              getFilter1Items={getFilter1Items}
              handleFilterChange={handleFilterChange}
              resetFilterCallback={resetFilters}
              filter1Value={filter1Value}
              filter2Value={filter2Value}
              setFilter1Value={setFilter1Value}
              setFilter2Value={setFilter2Value}
              remainingFilter2Items={remainingFilter2Items}
              setRemainingFilter2Items={setRemainingFilter2Items}
              setRemainingF2Items={setRemainingF2Items}
              filterAreaDisabled={filterAreaDisabled}
            />
          </>
        )}
        <div className={styles.resultCount}>
          <Typo tag="span" size={resultsAmountSize} fontWeight="600">
            {`${currentResultsAmount !== undefined ? currentResultsAmount : '-'} `}
          </Typo>
          <Typo tag="span" size={resultsAmountSize}>
            {translate('web20_downloadcenter_filter_sum')}
          </Typo>
        </div>
        <div className={styles.resultsContainer}>
          {categories.map((category) => (
            <DownloadcenterCategory
              items={
                itemsPerCategory[categories.indexOf(category)] !== undefined
                  ? itemsPerCategory[categories.indexOf(category)].filter(
                      (item) => !isCustom || customIds.includes(item.meta.downloadId[0]),
                    )
                  : []
              }
              updateDownloads={(item) => updateDownloads(item)}
              updateOrders={updateOrders}
              categoryName={category}
              key={category}
              downloads={downloads}
              orders={orders}
            />
          ))}
          {!isCustom && (
            <DownloadcenterMediaSection
              items={filteredMediaItems || []}
              updateDownloads={(item) => updateDownloads(item)}
              categoryName={mediaFilter}
              key={translate('web20_downloadcenter_media_section')}
              downloads={downloads}
              getFilter4Items={getFilter4Items}
              handleChangeFilter4={handleChangeFilter4}
              filter4Value={filter4Value}
            />
          )}
        </div>
      </Container>
    </>
  );
}

export default Downloadcenter;

import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { default as ReactDatePicker, registerLocale } from 'react-datepicker';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { DateOrTimePickerField } from '../date-or-time-picker';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useDateFormat } from 'utils/hooks/use-dateformat';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { isEmpty } from 'utils/is-empty';
import { useLocale } from 'utils/use-locale';

interface DatePickerProps {
  field: DateOrTimePickerField;
  onChange: (date: Date | null) => void;
  className: string;
  selectedTime: Date | null;
  language: string;
}

export default function DatePicker({
  field: { name, rules, comment },
  onChange,
  className,
  selectedTime,
}: Readonly<DatePickerProps>) {
  const { values, errors, touched, setFieldTouched } = useFormikContext<Record<string, string>>();
  const dateformat = useDateFormat();
  const lang = useCurrentLanguage();
  const locale = useLocale(lang);

  useEffect(() => {
    if (locale.code) registerLocale(locale.code, locale);
  }, [locale]);

  return (
    <div className={classNameBuilder(className, values[name] && 'form-item--filled')}>
      {/* prevent events so the datepicker closes when selecting a date */}
      {/* prettier-ignore */}
      <label htmlFor={name} onClick={(e) => e.preventDefault()} onKeyUp={(e) => e.preventDefault()}>{/* NOSONAR */}
        <ReactDatePicker
          locale={locale.code}
          title={comment}
          id={name}
          value={values[name]}
          name={name}
          selected={selectedTime}
          dateFormat={dateformat}
          onChange={onChange}
          onBlur={() => setFieldTouched(name, true)}
          autoComplete="off"
          popperPlacement="bottom-start"
        />
        {dateformat && <InnerHtml as="span" content={dateformat} />}
      </label>
      <InnerHtml
        as="p"
        className="form-item__error-message"
        content={
          (errors[name] &&
            touched[name] &&
            !isEmpty(rules) &&
            errors[name] !== 'keepErrorMessage' &&
            errors[name]) ||
          ''
        }
      />
    </div>
  );
}

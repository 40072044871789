'use client';

import { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import key from 'weak-key';
import { Divider, Button } from '@geberit/gdds';

// styles
import styles from './layout.module.scss';

// types
import { TitleFormats } from 'components/ContentElements/Title/title.types';

// components
import CampusLayout from './CampusLayout';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { Image } from 'components/image/image';
import { Loader } from 'components/Loader/Loader';
import { Translation } from 'components/Translation/Translation';
import { ContactModule } from 'components/Campus/Contact/ContactModule';
import CatalogCourseTeaserLoader from 'components/Campus/CourseTeaser/CatalogCourseTeaserLoader';

// utils
import { decodingContent } from 'utils/decodingContent';
import { useCiamLogin } from 'utils/hooks/useCiam';
import { usePublicCatalogList, useClassifications, useCampusEnv } from 'utils/hooks/useCampus';
import {
  campusCatalogUrlSelector,
  campusDashboardUrlSelector,
} from 'utils/selectors/globalsSelectors';
import { initSamlWorkflow } from 'components/Session/SessionContext';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { contentExists, useContent } from 'components/Page/page-hooks';

type Benefit = {
  text: string;
  previewId: string;
  type: string;
  headline: string;
};

export function CampusLanding(contentFromServer: Readonly<Content>) {
  const pageContent = useContent<{}, { content: string; benefits: Benefit[] }>();
  const { CAMPUS_CLASS_TARGETGROUPS } = useCampusEnv();
  const campusCatalogUrl = useSelector(campusCatalogUrlSelector);
  const campusDashboardUrl = useSelector(campusDashboardUrlSelector);
  const translate = useTranslationFunction();
  const { list: itemList, isLoading } = usePublicCatalogList();
  const language = useCurrentLanguage();
  const gigyaWindow = typeof window !== 'undefined' ? window['gigya'] : null;
  const { login, loggedIn, campusLoggedIn } = useCiamLogin(gigyaWindow);

  // target groups
  const { getClassification } = useClassifications();
  const targetGroups = getClassification(CAMPUS_CLASS_TARGETGROUPS);
  const country = language?.slice(language?.length - 2);

  if (!contentExists(pageContent)) {
    return null;
  }
  const { breadcrumbs } = contentFromServer;

  const {
    page: { headline, subHeadline, content, benefits },
  } = pageContent;

  const loginTo = (href: string) => (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (loggedIn) {
      // user may be logged to ciam but not to the campus
      if (!campusLoggedIn) {
        initSamlWorkflow(href, language);
      } else {
        Object.assign(document.createElement('a'), {
          target: '_self',
          href,
        }).click();
      }
    } else {
      login(language, `${country}_Campus`);
    }
  };

  return (
    <CampusLayout>
      <div className={styles.landingPage}>
        <div className={styles.banner}>
          {/* keeps the aspect ratio (Image component did not work) */}
          <img
            // eslint-disable-next-line max-len
            src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 719'%3E%3C/svg%3E"
            alt="Campus Banner"
            width="100%"
          />
        </div>
        <Breadcrumb items={breadcrumbs} />
        <PageHeadline
          Format={TitleFormats.h1}
          title={headline}
          subtitle={subHeadline}
          pageHeadline
        />
        <div className="grid-container">
          <InnerHtml as="div" content={content} className={styles.content} />

          {/* custom pictos */}
          <div className={`grid-x grid-margin-x ${styles.pictos}`}>
            {benefits.map((benefit, i) => (
              <div className="large-4 medium-6 small-12 cell" key={key(benefit)}>
                <Image src={`/images/campus/benefit_${i + 1}.png`} staticImage />
                <div className={styles.benefitContent}>
                  <b>{decodingContent(benefit.headline)}</b>
                  <InnerHtml as="p" content={benefit.text} />
                </div>
              </div>
            ))}
          </div>

          {/* login button */}
          <div className={styles.buttonContainer}>
            <Button
              stylingType="primary"
              target="_self"
              href="#"
              className={styles.loginButton}
              onClick={loginTo(campusDashboardUrl)}
            >
              <Translation id="campus_login" />
            </Button>
          </div>

          <Divider />

          <PageHeadline
            Format={TitleFormats.h2}
            title={translate('campus_landingpage_courseteaser_headline')}
          />
          {isLoading && <Loader />}
          {itemList?.length > 0 && (
            <>
              {itemList.map((course) => (
                <CatalogCourseTeaserLoader
                  key={key(course)}
                  courseId={course.componentId}
                  catalogData={course}
                  targetGroups={targetGroups || []}
                  isLandingPage
                />
              ))}
              <div className={styles.pagination}>
                <Button stylingType="secondary" onClick={loginTo(campusCatalogUrl)}>
                  <Translation id="campus_landingpage_courseteaser_viewmore" />
                </Button>
              </div>
            </>
          )}

          <Divider />

          {/* contact module */}
          <ContactModule />
        </div>
      </div>
    </CampusLayout>
  );
}

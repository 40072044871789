import { useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

export function useSearchParamsState<T extends string[]>(
  searchParamNames: [...T],
  defaultValues: string[] = [],
): readonly [
  searchParamsState: Record<[...T][number], string | undefined>,
  setSearchParamsState: (newState: PartialRecord<[...T][number], string | undefined>) => void,
] {
  const searchParams = useSearchParams();

  const searchParamsState = searchParamNames.reduce(
    (acc, searchParamName, index) => {
      const value = searchParams.get(searchParamName);
      acc[searchParamName as string] =
        value && value !== 'undefined' ? value : defaultValues[index];
      return acc;
    },
    {} as Record<[...T][number], string | undefined>,
  );

  const setSearchParamsState = useCallback(
    (values: PartialRecord<[...T][number], string | undefined>) => {
      const params = new URLSearchParams(searchParams);
      Object.keys(values).forEach((key) => {
        if (values[key]) {
          params.set(key, values[key]);
        } else {
          params.delete(key);
        }
      });
      window.history.pushState(null, '', `?${params.toString()}`);
    },
    [searchParams],
  );

  return [searchParamsState, setSearchParamsState];
}

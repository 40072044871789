import styled from 'styled-components';

export const StyledCategories = styled.div`
  grid-area: categories;
  max-width: 27.75rem;
  margin-top: 1.5rem;

  p {
    :first-of-type {
      margin-bottom: 0.5rem;
    }
  }

  .link {
    @media print {
      display: none;
    }

    display: flex;
    align-items: center;
    color: var(--gb-brand-primary-light);

    a {
      color: currentColor;
    }

    svg {
      fill: currentColor;
    }
  }
`;

import React from 'react';
import { Icon } from '@geberit/gdds';

// styles
import styles from './progressBar.module.scss';

// components
import LockedIcon from 'components/Icon/LockedIcon';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';

interface ProgressBarProps {
  isMobile: boolean;
  progress?: number;
  locked?: boolean;
}

function ProgressBar({ isMobile, progress = 0, locked = false }: Readonly<ProgressBarProps>) {
  return (
    <div className={`progress-bar ${styles.progressBar}`}>
      <div
        className={classNameBuilder(
          styles.progressCircleWrapper,
          `p${progress}`,
          locked && styles.locked,
        )}
      >
        <div className={styles.progressPercentage}>
          {progress > 99 && (
            <Icon symbol="Checkmark" width={isMobile ? '1rem' : '1.25rem'} color="#008800" />
          )}
          {progress <= 99 && !locked && <b>{progress}%</b>}
          {locked && <LockedIcon width={isMobile ? '1rem' : '1.25rem'} />}
        </div>
        <div className={styles.slice}>
          <div className={styles.bar}></div>
          <div className={styles.fill}></div>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;

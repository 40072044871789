import { useCampusCostsIncluded } from './useCampusCostsIncluded';
import { CourseMetaItem } from '../../components/Campus/types';
import { getMetatags } from '../../components/Campus/metatags';

export function useCostsIncludedMetatags(tags: CourseMetaItem[]): CourseMetaItem[] {
  const campusCostsIncluded = useCampusCostsIncluded();

  return getMetatags(
    campusCostsIncluded.split(',').map((id) => id.trim()),
    tags,
  ).filter((item) => item.rawContent === '1');
}

import { useState, useEffect } from 'react';

// styles
import styles from './scormplayer.module.scss';

// components
import { Loader } from 'components/Loader/Loader';

// utils
import { useScormAuth } from 'utils/hooks/useCampus';

interface ScormPlayerProps {
  courseId: number;
  content: any;
}

export function ScormPlayer({ courseId, content }: Readonly<ScormPlayerProps>) {
  const [loading, setLoading] = useState(true);
  const { openScorm } = useScormAuth(courseId);

  useEffect(() => {
    openScorm(content?.id);
  }, [content, openScorm]);

  return (
    <div className={styles.playerWrapper}>
      {loading && (
        <div className={styles.loadOverlay}>
          <Loader />
        </div>
      )}
      <iframe
        className={styles.player}
        title="SCORM"
        style={{ opacity: loading ? '0%' : '100%' }}
        name="scorm_target"
        id="scorm_target"
        width="100%"
        height="100%"
        src=""
        onLoad={() => setLoading(false)}
      />
    </div>
  );
}

import React, { useState } from 'react';
import key from 'weak-key';

// components
import YoutubeThumbnail from 'components/Video/youtube/YoutubeThumbnail';
import VideoLightbox from 'components/lightbox/video-lightbox';

// utils
import { isEmpty } from 'utils/is-empty';

interface ProductVideosProps {
  videos: any[] | null;
}

function ProductVideos({ videos = null }: Readonly<ProductVideosProps>) {
  const [hasOpenModal, setHasOpenModal] = useState(false);
  const [videoId, setVideoId] = useState(null);

  if (isEmpty(videos)) {
    return null;
  }

  return (
    <div className="grid-container">
      <div className="c-product-videos grid-x grid-margin-x">
        {videos?.map((video, index) => {
          if (index < 2) {
            return (
              <div className="cell medium-6" key={key(video)}>
                <div className="c-product-videos__item" key={key({ index })}>
                  <YoutubeThumbnail
                    key={key({ id: video.youtubeID })}
                    id={video.youtubeID}
                    onClick={() => {
                      setHasOpenModal(!hasOpenModal);
                      setVideoId(video.youtubeID);
                    }}
                  />
                </div>
              </div>
            );
          }
          return null;
        })}
        {hasOpenModal && (
          <VideoLightbox
            isModal
            key={`lightbox-${videoId}`}
            videoId={videoId}
            onClose={() => setHasOpenModal(false)}
            isYoutubeVideo
          />
        )}
      </div>
    </div>
  );
}

export default ProductVideos;

import React from 'react';
import key from 'weak-key';

// components
import { Translation } from '../../Translation/Translation';
import { BUTTON_TYPES, Button } from '../../Button/Button';

interface SoftContentFilterItemProps {
  active: boolean;
  value: string;
  count: number;
  handleClick: (filter: string) => void;
}

function SoftContentFilterItem({
  active = false,
  value,
  count,
  handleClick,
}: Readonly<SoftContentFilterItemProps>) {
  return (
    <li key={key({ value })}>
      <Button
        type={BUTTON_TYPES.PLAIN}
        className={active ? 'selected' : undefined}
        data-filter={`indexer_filetype:${value}`}
        onClick={() => handleClick(`indexer_filetype:${value}`)}
      >
        <Translation id={`web20_search_filter_${value}`} /> ({count})
      </Button>
    </li>
  );
}

export default SoftContentFilterItem;

import key from 'weak-key';

// types
import type { StepperProps } from './Stepper.types';

// components
import Step from './Step';

// utils
import { useMediaQuery } from 'components/ContentElementsGdds/PressPortal/hooks/use-media-query';

const styles = {
  root: ({ above1024, above982, above876 }) => {
    let left = '-6px';
    if (above1024) left = '-22px';
    if (above982) left = '-17px';
    if (above876) left = '-11px';

    return {
      width: '40%',
      minHeight: 0,
      padding: 0,
      position: 'relative' as const,
      left,
    };
  },
  stepper: {
    display: 'table',
    width: '100%',
    margin: '0 auto',
  },
};

function Stepper({ ciamId = '', steps = [] }: Readonly<StepperProps>) {
  const above1024 = useMediaQuery('(min-width: 1024px)');
  const above982 = useMediaQuery('(min-width: 982px)');
  const above876 = useMediaQuery('(min-width: 876px)');

  return (
    <div style={styles.root({ above1024, above982, above876 })}>
      <div style={styles.stepper}>
        {steps.map((step, index) => (
          <Step
            key={key(step)}
            ciamId={ciamId}
            width={100 / steps.length}
            title={step.title}
            icon={step.icon}
            first={index === 0}
            isLast={index === steps.length - 1}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}

export default Stepper;

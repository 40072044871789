import axios from 'axios';
import isEmpty from 'lodash.isempty';
import { Dispatch } from 'redux';

// types
import {
  DeliveryAddress,
  InvoiceAddress,
  OrderData,
  PaymentData,
  SimulatedOrderData,
  UserData,
  ZipCodeValidationData,
} from './action.types';

// utils
import { WebshopServiceCardResponse } from 'services/webshop';
import getEndpoint from 'utils/endpoints';
import { loadListSuccess, loadArticleCounterSuccess } from '../ShoppingBasket/actions';

export function changeActiveStepAction(step: number) {
  return {
    type: 'webshop/checkout/CHANGE_ACTIVE_STEP' as const,
    step,
  };
}

function isFetchingCart(isFetching: boolean) {
  return {
    type: 'webshop/checkout/IS_FETCHING_CART' as const,
    isFetching,
  };
}

function setCart(data: WebshopServiceCardResponse) {
  return {
    type: 'webshop/checkout/SET_CART_DATA' as const,
    data,
  };
}

function setCartError(error: boolean) {
  return {
    type: 'webshop/checkout/SET_CART_ERROR' as const,
    error,
  };
}

// this is integration of the service called Cart Integration API -> http://40.119.153.233/swagger-ui/index.html?url=/v3/api-docs&validatorUrl=#/Get%20cart/getCartById
export function getCartAction(locale, id, isLogged) {
  return async (dispatch, getState) => {
    const state = getState();
    const url = isLogged
      ? `${getEndpoint('webshop', state)}/customer/ciam/${id}/cart?lang=${locale}`
      : `${getEndpoint('webshop', state)}/cart/session/${id}?lang=${locale}`;

    try {
      dispatch(isFetchingCart(true));
      const response = await axios.get(url);
      if (!isEmpty(response) && response.status === 200) {
        const total = response.data.lineItemList.reduce(
          (accumulator, currentValue) => accumulator + Number(currentValue.totalPrice),
          0,
        );
        dispatch(setCart({ total: total.toFixed(2), ...response.data }));
        dispatch(isFetchingCart(false));
      }
    } catch (e) {
      dispatch(setCartError(true));
      dispatch(isFetchingCart(false));
    }
  };
}

export function setInvoiceAddressAction(address: InvoiceAddress) {
  return {
    type: 'webshop/checkout/SET_INVOICE_ADDRESS' as const,
    address,
  };
}

export function setDeliveryAddressAction(address: DeliveryAddress) {
  return {
    type: 'webshop/checkout/SET_DELIVERY_ADDRESS' as const,
    address,
  };
}

function isFetchingUserData(isFetching: boolean) {
  return {
    type: 'webshop/checkout/IS_FETCHING_USER_DATA' as const,
    isFetching,
  };
}

function setUserDataAction(data: UserData) {
  return {
    type: 'webshop/checkout/SET_USER_DATA' as const,
    data,
  };
}

function setUserErrorAction(error: boolean) {
  return {
    type: 'webshop/checkout/SET_USER_DATA_ERROR' as const,
    error,
  };
}

// this is integration of the service called CIAM (Gigya) Customer Service -> http://51.124.80.234/swagger-ui/index.html?url=/v3/api-docs
export function fetchUserDataAction(locale: string, ciamId: string) {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    try {
      const state = getState();
      dispatch(isFetchingUserData(true));
      const response = await axios.get(
        `${getEndpoint('webshop', state)}/customer/${ciamId}?lang=${locale}`,
      );

      if (!isEmpty(response) && response.status === 200) {
        dispatch(setUserDataAction(response.data));
      }
      dispatch(isFetchingUserData(false));
    } catch (e) {
      dispatch(setUserErrorAction(true));
      dispatch(isFetchingUserData(false));
    }
  };
}

function isFetchingOrderData(isFetching: boolean) {
  return {
    type: 'webshop/checkout/IS_FETCHING_ORDER_DATA' as const,
    isFetching,
  };
}

function setOrderData(data: OrderData) {
  return {
    type: 'webshop/checkout/SET_ORDER_DATA' as const,
    data,
  };
}

function setOrderError(error: boolean) {
  return {
    type: 'webshop/checkout/SET_ORDER_ERROR' as const,
    error,
  };
}

// this is integration of the service called Order Summary API -> http://40.114.183.29/swagger-ui/index.html?url=/v3/api-docs&validatorUrl=#/
export function fetchOrderDataAction(locale: string, orderId: string) {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    try {
      const state = getState();
      dispatch(isFetchingOrderData(true));
      const response = await axios.get(
        `${getEndpoint('webshop', state)}/order-summary/${orderId}?lang=${locale}`,
      );
      if (!isEmpty(response) && response.status === 200) {
        const quantityAllItems = response.data.lineItemsList.reduce(
          (accumulator, currentValue) => accumulator + Number(currentValue.quantity),
          0,
        );
        dispatch(setOrderData({ quantityAllItems, ...response.data }));
      }
      dispatch(isFetchingOrderData(false));
    } catch (e) {
      dispatch(setOrderError(true));
      dispatch(isFetchingOrderData(false));
    }
  };
}

function isFetchingSimulatedOrderData(isFetching: boolean) {
  return {
    type: 'webshop/checkout/IS_FETCHING_SIMULATED_ORDER_DATA' as const,
    isFetching,
  };
}

function setSimulatedOrderData(data: SimulatedOrderData) {
  return {
    type: 'webshop/checkout/SET_SIMULATED_ORDER_DATA' as const,
    data,
  };
}

function setSimulatedOrderError(error: boolean) {
  return {
    type: 'webshop/checkout/SET_SIMULATED_ORDER_ERROR' as const,
    error,
  };
}

// this is integration of the service called Checkout Workflow API -> http://51.137.29.222/swagger-ui/index.html?url=/v3/api-docs&validatorUrl=#/checkout-workflow/confirmCustomerAddressForExistingCustomer
export function postSimulatedOrderAction(
  locale: string,
  id: string,
  address: { invoiceAddress: InvoiceAddress; deliveryAddress: DeliveryAddress },
  isLogged: boolean,
  onRequestSucceded: () => void,
) {
  return async (dispatch, getState) => {
    const state = getState();
    const url = isLogged
      ? `${getEndpoint('webshop', state)}/address-form/customer/ciam/${id}?lang=${locale}`
      : `${getEndpoint('webshop', state)}/address-form/session/${id}?lang=${locale}`;

    try {
      dispatch(isFetchingSimulatedOrderData(true));
      const response = await axios.post(url, address);
      onRequestSucceded();
      if (!isEmpty(response) && response.status === 200) {
        const quantityAllItems = response.data.lineItemsList.reduce(
          (accumulator, currentValue) => accumulator + Number(currentValue.quantity),
          0,
        );
        dispatch(setSimulatedOrderData({ quantityAllItems, ...response.data }));
      }
      dispatch(isFetchingSimulatedOrderData(false));
    } catch (e) {
      dispatch(setSimulatedOrderError(true));
      dispatch(isFetchingSimulatedOrderData(false));
    }
  };
}

function isFetchingPaymentData(isFetching: boolean) {
  return {
    type: 'webshop/checkout/IS_FETCHING_PAYMENT_DATA' as const,
    isFetching,
  };
}

function setPaymentData(data: PaymentData) {
  return {
    type: 'webshop/checkout/SET_PAYMENT_DATA' as const,
    data,
  };
}

function setPaymentError(error: boolean) {
  return {
    type: 'webshop/checkout/SET_PAYMENT_ERROR' as const,
    error,
  };
}

// this is integration of the service called Place Order Workflow API -> http://51.137.29.222/swagger-ui/index.html?url=/v3/api-docs&validatorUrl=#/place-order-workflow/placeOrderByCiamId
export function placeOrderAction(
  locale: string,
  id: string,
  body: {
    addresses: { invoiceAddress: InvoiceAddress; deliveryAddress: DeliveryAddress };
    redirectUrls: { success: string; abort: string; fail: string };
  },
  isLoggedIn: boolean,
  paymentType: 'INVOICE' | 'PSP',
) {
  return async (dispatch, getState) => {
    const state = getState();
    const endpoint = getEndpoint('webshop', state);
    const url = `${endpoint}/place-order/customer/${
      isLoggedIn ? 'ciam' : 'session'
    }/${id}?paymentType=${paymentType}&lang=${locale}`;

    try {
      dispatch(isFetchingPaymentData(true));
      const response = await axios.post(url, body);
      if (!isEmpty(response) && response.status === 200) {
        dispatch(setPaymentData(response.data));
        dispatch(loadArticleCounterSuccess(0));
        dispatch(loadListSuccess([]));
      }
      dispatch(isFetchingPaymentData(false));
    } catch (e) {
      dispatch(setPaymentError(true));
      dispatch(isFetchingPaymentData(false));
    }
  };
}

function isFetchingZipCodeValidation(isFetching: boolean) {
  return {
    type: 'webshop/checkout/IS_FETCHING_ZIP_CODE_VALIDATION' as const,
    isFetching,
  };
}

function setZipCodeValidationData(data: ZipCodeValidationData) {
  return {
    type: 'webshop/checkout/SET_ZIP_CODE_VALIDATION_DATA' as const,
    data,
  };
}

function setDeliveryZipCodeValidationData(data: ZipCodeValidationData) {
  return {
    type: 'webshop/checkout/SET_DELIVERY_ZIP_CODE_VALIDATION_DATA' as const,
    data,
  };
}

function setZipCodeValidationFieldName(data: string) {
  return {
    type: 'webshop/checkout/SET_ZIP_CODE_VALIDATION_FIELD_NAME' as const,
    data,
  };
}

function setZipCodeValidationError(error: boolean) {
  return {
    type: 'webshop/checkout/SET_ZIP_CODE_VALIDATION_ERROR' as const,
    error,
  };
}

// this is integration of the service called Zip code validation -> https://apps.webshop.geberit.fyayc.com/ws-postal-code/swagger-ui/index.html?url=/ws-postal-code/v3/api-docs&validatorUrl=
export function zipCodeValidationAction(
  body: { code: string; country: string },
  fieldName: string,
) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(setZipCodeValidationError(false));
      dispatch(isFetchingZipCodeValidation(true));
      dispatch(setZipCodeValidationFieldName(fieldName));
      const response = await axios.post(
        `${getEndpoint('webshop', state)}/postal-code-validation`,
        body,
      );
      if (!isEmpty(response) && response.status === 200) {
        if (fieldName === 'zip') dispatch(setZipCodeValidationData(response.data));
        if (fieldName === 'deliveryZipCode')
          dispatch(setDeliveryZipCodeValidationData(response.data));
      }
      dispatch(isFetchingZipCodeValidation(false));
    } catch (e) {
      dispatch(setZipCodeValidationError(true));
      dispatch(isFetchingZipCodeValidation(false));
    }
  };
}

export function resetCheckoutStateAction() {
  return {
    type: 'webshop/checkout/RESET_CHECKOUT_STATE' as const,
  };
}

export type CheckoutAction =
  | ReturnType<typeof changeActiveStepAction>
  | ReturnType<typeof isFetchingCart>
  | ReturnType<typeof setCart>
  | ReturnType<typeof setCartError>
  | ReturnType<typeof setInvoiceAddressAction>
  | ReturnType<typeof setDeliveryAddressAction>
  | ReturnType<typeof isFetchingUserData>
  | ReturnType<typeof setUserDataAction>
  | ReturnType<typeof setUserErrorAction>
  | ReturnType<typeof isFetchingOrderData>
  | ReturnType<typeof setOrderData>
  | ReturnType<typeof setOrderError>
  | ReturnType<typeof isFetchingSimulatedOrderData>
  | ReturnType<typeof setSimulatedOrderData>
  | ReturnType<typeof setSimulatedOrderError>
  | ReturnType<typeof isFetchingPaymentData>
  | ReturnType<typeof setPaymentData>
  | ReturnType<typeof setPaymentError>
  | ReturnType<typeof isFetchingZipCodeValidation>
  | ReturnType<typeof setZipCodeValidationData>
  | ReturnType<typeof setDeliveryZipCodeValidationData>
  | ReturnType<typeof setZipCodeValidationFieldName>
  | ReturnType<typeof setZipCodeValidationError>
  | ReturnType<typeof resetCheckoutStateAction>;

import key from 'weak-key';

// Components
import Dropdown, { DropdownContent, DropdownTrigger } from 'react-simple-dropdown';

// Components
import FilterOption from './FilterOption';
import { Translation } from '../Translation/Translation';

type FilterProps = {
  activeItems: string[] | null;
  filterKey: string;
  options: any[];
  handleFilterChange: any;
  isCheckbox?: boolean;
};

function Filter({
  options,
  handleFilterChange,
  activeItems = null,
  filterKey = 'invalid or empty string',
  isCheckbox = false,
}: Readonly<FilterProps>) {
  const filterTranslation = <Translation id={`web20_downloadcenter_${filterKey}`} />;

  if (!options[0].isAllFilter && filterKey !== 'sorting') {
    options.unshift({
      value: 'web20_downloadcenter_all_filter',
      count: null,
      isAllFilter: true,
    });
  }

  const findSortValue = (items) =>
    activeItems && items.find((option) => option?.id === activeItems[0]);

  const dropdownName =
    activeItems && filterKey === 'sorting' ? findSortValue(options)?.value : filterTranslation;

  const isAllFilterSelected = (option) => {
    if (option.isAllFilter === undefined) {
      return activeItems?.some((sort) => sort === option.value);
    }

    return !activeItems || activeItems.length === 0;
  };

  return (
    <div className="form-item">
      <Dropdown>
        <DropdownTrigger>
          {typeof dropdownName === 'string' && dropdownName.includes('web20') ? (
            <Translation id={dropdownName} />
          ) : (
            dropdownName
          )}
        </DropdownTrigger>
        <DropdownContent>
          <div className={`form-items ${!isCheckbox ? 'form-items--radio-list' : ''}`}>
            {options && (
              <ul>
                {options.map((option, index) => (
                  <FilterOption
                    key={key(option)}
                    option={option}
                    index={index}
                    isCheckbox={isCheckbox}
                    filterKey={filterKey}
                    checked={isAllFilterSelected(option)}
                    handleFilterChange={handleFilterChange}
                    isAllFilter={option.isAllFilter !== undefined}
                  />
                ))}
              </ul>
            )}
          </div>
        </DropdownContent>
      </Dropdown>
    </div>
  );
}

export default Filter;

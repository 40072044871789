'use client';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// utils
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { TPP_TYPE_PAGE } from 'components/ContentCreator/tppConstants';
import { doSetPage } from 'components/Page/actions';
import { useDispatch as usePageDispatch } from 'components/Page/page-hooks';

export function PreviewIdSwitcher({ page }) {
  const isPreview = useIsPreview();
  const dispatch = useDispatch();
  const pageDispatch = usePageDispatch();

  useEffect(() => {
    dispatch(doSetPage(page));
  }, [dispatch, page]);

  useEffect(() => {
    const pageRefPreviewId = page?.pageRefPreviewId;
    const isInSubscription = String(page?.isInSubscription ?? 'false');

    const handler = () => {
      if (!window.TPP_SNAP) {
        return;
      }

      if (window.TPP_SNAP && page?.isNewPage) {
        window.TPP_SNAP.renderElement(page.previewId).then((pageFromTPP) => {
          setPreviewId(pageFromTPP.pageRefPreviewId);
          dispatch(doSetPage(pageFromTPP));
          pageDispatch(doSetPage(pageFromTPP));
        });
      }

      setPreviewId(pageRefPreviewId);

      function setPreviewId(pageRefPreviewId) {
        if (isPreview && pageRefPreviewId) {
          const root = document.body;
          if (root && root.dataset.previewId !== pageRefPreviewId) {
            root.dataset.previewId = pageRefPreviewId;
            root.dataset.editType = TPP_TYPE_PAGE;
            root.dataset.isInSubscription = isInSubscription;
            window.TPP_SNAP.setPreviewElement(pageRefPreviewId);
          }
        }
      }
    };

    document.addEventListener('snap:ready', handler);

    return () => {
      document.removeEventListener('snap:ready', handler);
    };
  }, [dispatch, isPreview, page, pageDispatch]);

  return null;
}

import styled from 'styled-components';

// styles
import { StyledLabelsWrapper } from '../dealer-label/dealer-label.styles';

// types
import { Point } from '../../locator.types';

// utils
import { mapConfig } from '../config';

export const StyledDealerPopup = styled.div<{ show: boolean; point?: Point; popupWidth: number }>`
  box-sizing: border-box;
  position: fixed;
  left: 0;
  right: 0;
  bottom: ${({ show }) => (show ? '0' : `-${mapConfig.mobile.popupHeight}px`)};
  height: ${mapConfig.mobile.popupHeight}px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 9;
  padding: 1.5rem 1rem;
  transition-property: bottom;
  transition-duration: ${mapConfig.mobile.transitionDuration / 10}s;
  transition-delay: ${({ show }) => (show ? `${mapConfig.mobile.transitionDuration / 10}s` : '0s')};

  p {
    margin: 0;
  }

  button {
    height: 2.75rem;
  }

  .close-button {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 2.75rem;
  }

  .distance-duration {
    display: flex;
    column-gap: 0.5rem;
    margin-bottom: 1rem;
  }

  ${StyledLabelsWrapper} {
    margin-bottom: 0.625rem;
  }

  .address {
    > p {
      margin: 0;
    }

    margin-bottom: 1rem;
  }

  .opening-hours {
    height: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  @media ${({ theme }) => theme.device.medium} {
    bottom: ${({ show }) => (show ? '0' : `-${mapConfig.mobile.popupHeight}px`)};
    padding: 1.5rem;
    height: ${mapConfig.tablet.popupHeight}px;

    .close-button {
      right: 0rem;
      top: 0rem;
    }

    .buttons {
      display: flex;
      flex-direction: row-reverse;
      column-gap: 1.5rem;

      > button {
        flex-grow: 1;
      }
    }
  }

  @media ${({ theme }) => theme.device.large} {
    display: ${({ show }) => (show ? 'initial' : 'none')};
    position: absolute;
    padding: 1rem;
    left: ${({ point, popupWidth }) => `calc(${point?.x || 0}px - ${popupWidth / 2}px)`};
    bottom: ${({ point }) =>
      `calc(100% -  ${point?.y - mapConfig.activePinHeight + mapConfig.desktop.popupPadding}px)`};
    right: unset;
    width: auto;
    height: auto;
    min-width: ${mapConfig.desktop.popupWidth}px;
    min-height: ${mapConfig.desktop.popupHeight}px;
    transition: none;

    .buttons {
      column-gap: 0;
      margin-top: 0.5rem;
    }

    .distance-duration,
    .opening-hours {
      display: none;
    }

    .address {
      > p {
        margin: 0;
      }

      margin: 0;
    }

    ${StyledLabelsWrapper} {
      margin-bottom: 0.25rem;
    }
  }
`;

import { Button } from '@geberit/gdds';

// styles
import { StyledButtons } from './buttons.styles';

// types
import type { IEntry } from '../../locator.types';

// utils
import { useLocatorConfiguration } from '../../utils/use-locator-configuration';
import { planRoute } from '../../map/utils/plan-route';
import { decodingContent } from 'utils/decodingContent';
import { useTracking } from 'utils/tracking/track';

export function Buttons({ name, address, zip, city, email }: Readonly<IEntry>) {
  const locatorConfig = useLocatorConfiguration();
  const destination = `${name} ${address} ${zip} ${city}`;
  const { trackLocator } = useTracking();

  return (
    <StyledButtons>
      {email && (
        <Button
          onClick={() => {
            window.open(`mailto:${email}`, '_self');
            trackLocator({
              click_intent: 'locator contact - button',
              search_term: name,
              value: 10,
            });
          }}
          stylingType="primary"
        >
          {decodingContent(locatorConfig.dealerPage?.requestAdvice)}
        </Button>
      )}
      <Button
        onClick={() => {
          planRoute(destination);
        }}
      >
        {decodingContent(locatorConfig.dealerPage?.calculateRoute)}
      </Button>
      <Button
        onClick={() => {
          window.print();
        }}
        className="print-button"
        stylingType="iconOutline"
        isIcon
        symbol="print"
      />
    </StyledButtons>
  );
}

import { useMemo } from 'react';

// types
import type { IShowroomData, NewProduct, NewSortStructure, Series } from './locator.types';

// utils
import { useIsoLang } from 'scenes/MetaData';

/**
 * Translate the new data according to the iso language
 * and then transform it to fit the old schema.
 * Old data remain unchanged.
 * @param {IShowroomData<string | undefined>} showroomData showroom locator data got from backend
 * @returns {IShowroomData<undefined>}
 */
export function useMapLocatorData(showroomData: IShowroomData<string | undefined>) {
  const iso = useIsoLang().split('_').join('-');
  return useMemo(() => mapLocatorData(showroomData, iso), [iso, showroomData]);
}

/**
 * Translate the new data according to the iso language
 * and then transform it to fit the old schema.
 * Old data remain unchanged.
 * @param {IShowroomData<string | undefined>} showroomData showroom locator data got from backend
 * @param {string} iso iso language
 * @returns {IShowroomData<undefined>}
 */
export function mapLocatorData(
  showroomData: IShowroomData<string | undefined>,
  iso: string,
): IShowroomData {
  const data = { ...showroomData } as IShowroomData;
  if (isNewVersion(showroomData)) {
    const entries = showroomData.entries.map((entry) => {
      const products = entry.products.map((product) => {
        const category =
          showroomData.sortStructure[0].find(byCategory(product))?.locales?.find(byLocale(iso))
            ?.localname ?? '';

        const series = product.series.map(
          (serie) =>
            showroomData.sortStructure[0]
              .find(byCategory(product))
              ?.series.find(bySeries(serie))
              ?.locales.find(byLocale(iso))?.series ?? '',
        );

        return {
          category,
          series,
        };
      });

      return {
        ...entry,
        products,
      };
    });
    const sortStructure = showroomData.sortStructure[0].map(
      (item) => item.locales?.find(byLocale(iso))?.localname ?? '',
    );
    data.entries = entries;
    data.sortStructure = sortStructure;

    return data;
  }

  return showroomData as IShowroomData;
}

const isNewVersion = (
  showroomData: IShowroomData<string | undefined>,
): showroomData is IShowroomData<string> => Number(showroomData?._version ?? 0) >= 3;

const byCategory = (product: NewProduct) => (category: NewSortStructure) =>
  category.catid === product.catid;

const bySeries = (serie: { serid: string }) => (ser: Series) => ser.serid === serie.serid;

const byLocale =
  (iso: string) =>
  (locale: { iso: string }): boolean =>
    locale.iso === iso;

import { Container, Row, Col, Divider } from '@geberit/gdds';

// styles
import styles from './intro-text.module.scss';

// types
import type { IntroTextProps } from './types';

// components
import { Formats } from 'components/ContentElements/Headline';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { IntroHeadline } from './intro-headline';

// utils
import { getClassName } from './utils';
import { classNameBuilder } from 'utils/classNameBuilder';
import { buildSize, gridSizes } from 'utils/gridSize';
import { useNord } from 'utils/hooks/use-nord';
import { useSectionId } from 'utils/hooks/use-section-id';

export function IntroText({
  className,
  title,
  emphasize,
  subtitle,
  intro,
  text,
  position,
  contentIndex,
  contentLength,
  spacing,
  border,
  backgroundColor,
  titlePreviewId = '#st_title',
  subtitlePreviewId = '#st_subtitle',
  disableInEdit = false,
  isGdds,
}: Readonly<IntroTextProps>) {
  const hasHeadline = Boolean(title || subtitle);
  const isNordics = useNord();
  const format = isNordics || !isGdds ? Formats.h1 : Formats.h2;
  const sectionId = useSectionId(title, '', false, contentIndex);
  const colSize = [4, 8, isNordics ? 8 : 12];

  let maxContentWidth;
  if (isNordics) {
    maxContentWidth = buildSize(gridSizes.gddsFullGrid);
  } else if (!isGdds) {
    maxContentWidth = '80rem';
  } else {
    maxContentWidth = buildSize(gridSizes.twoThird);
  }

  return (
    <div
      className={getClassName({
        hasHeadline,
        contentLength,
        contentIndex,
        spacing,
        backgroundColor,
        isNordics,
        className,
      })}
      id={sectionId}
    >
      <Container maxContentWidth={maxContentWidth}>
        {hasHeadline && (
          <IntroHeadline
            position={position}
            colSize={colSize}
            title={title}
            subtitle={subtitle}
            format={format}
            emphasize={emphasize}
            intro={intro}
            text={text}
            titlePreviewId={titlePreviewId}
            subtitlePreviewId={subtitlePreviewId}
            disableInEdit={disableInEdit}
          />
        )}
        {intro && (
          <Row className={classNameBuilder(position && styles[position], styles.row)}>
            <Col size={colSize}>
              <InnerHtml
                content={intro}
                as="div"
                className={styles.intro}
                isGdds
                previewId={disableInEdit ? undefined : '#st_content'}
              />
            </Col>
          </Row>
        )}
        {text && (
          <Row className={classNameBuilder(position && styles[position], styles.row)}>
            <Col size={colSize}>
              <InnerHtml
                content={text}
                as="div"
                className={classNameBuilder(styles.text, intro && styles.withMargin)}
                isGdds
              />
            </Col>
          </Row>
        )}
      </Container>
      {border !== 'disable-border' && (
        <Container maxContentWidth="78rem">
          <Row>
            <Col size={colSize} className={styles.border}>
              <Divider alignment="horizontal" />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

import type { Breadcrumb, MetaData } from 'types';
import type { Material, MediaType, Media, ContactPeople, Presscontact } from './mynewsdesk.types';

export enum Tabs {
  News,
  MediaLibrary,
}

export type PressroomRequestConfig = {
  locale: string;
};

export type MaterialRequestConfig = {
  locale: string;
  id?: number;
  type?: MediaType;
};

export type MaterialsRequestConfig = {
  search?: string;
  query?: string;
  mediaType?: MediaType;
  mediatype?: MediaType;
  mediaTypes?: MediaType[];
  limit?: number | ((iteration: number) => number);
  offset?: number;
  tags?: string[];
  locale?: string;
};

export type RequestConfig = Omit<MaterialsRequestConfig, 'tags' | 'mediaType' | 'mediaTypes'> & {
  tags?: string;
  type_of_media?: string;
  state?: any;
  pressroom?: string;
};

export type ExcutionResult = {
  done: boolean;
  items: Material[];
};
export type MaterialsGenerator<Z> = AsyncGenerator<Z, Z, unknown>[];

export type Reducer = (
  acc: ExcutionResult,
  meterial: IteratorResult<unknown, unknown>,
) => ExcutionResult;

type TilesBaseProps = {
  language: string;
  fallbackImg?: string;
  fallbackBackgroundColor?: string;
};
export type PressTilesProps = TilesBaseProps & {
  materials: Material[];
  withHeroTile?: boolean;
};

export type MediaTilesProps = TilesBaseProps & {
  mediaList: Omit<Media, 'ContactPeople'>[];
};

export type ContactTilesProps = {
  contacts: ContactPeople[] | Presscontact[];
  language?: string;
  withDivider?: boolean;
  isGdds?: boolean;
};

export type PressPortalDetailsPageProps = {
  metaData: MetaData;
  breadcrumbs: Partial<Breadcrumb>[];
  isGdds?: boolean;
};

export type PressPortalAssetDetailsProps = {
  breadcrumbs: Partial<Breadcrumb>[];
  isGdds?: boolean;
};

export type FilterSearchBarProps = {
  getMediaTypeChangehandler: (option: unknown) => void;
  materialTypes: {
    id: string;
    value: string;
    text: string;
  }[];
  getTagsChangehandler: (values: string[]) => void;
  getSearchChangehandler: (term?: string) => void;
  pressroomTags:
    | {
        id: string;
        value: string;
        text: string;
      }[]
    | undefined;
  query?: string;
  materialType?: MediaType;
};

export type FetchResult = {
  loading: boolean;
  error: string | null;
};

export type FetchPressroomResult<T> = FetchResult & {
  pressroom: T | null;
};

export type FetchMaterialResult<T> = FetchResult & {
  material: T | null;
};

export type FetchMaterialsResult<T extends Material | Media> = FetchResult & {
  materials: T[] | null;
  loadMore: () => void;
  loadable: boolean;
  total: number;
};

export type RelatedMaterialsDisplayMode = 'two_per_row' | 'three_per_row';

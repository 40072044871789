import React from 'react';
import { Button } from '@geberit/gdds';

// components
import { Translation } from '../../Translation/Translation';

import styles from './noresults.module.scss';

interface NoResultsProps {
  textKey: string;
  filterReset: (value: boolean) => void;
  buttonKey?: string;
}

function NoResults({ textKey, buttonKey, filterReset }: Readonly<NoResultsProps>) {
  return (
    <div className={styles.noResultsWrapper}>
      <p>
        <Translation id={textKey} />
      </p>
      {buttonKey && (
        <Button stylingType="primary" onClick={() => filterReset(true)}>
          <Translation id={buttonKey} />
        </Button>
      )}
    </div>
  );
}

export default NoResults;

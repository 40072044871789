// components
import { Translation } from 'components/Translation/Translation';
import LoadMoreButton from 'components/LoadMoreButton/LoadMoreButton';

interface SearchPaginationProps {
  isLast: boolean;
  current: number;
  total: number;
  handleNextPage: (type: string) => void;
}

export default function SearchPagination({
  isLast,
  current,
  total,
  handleNextPage,
}: Readonly<SearchPaginationProps>) {
  return (
    <div className={`c-load-more-block ${isLast ? 'last' : ''}`}>
      {isLast ? (
        <div className="buttonSpaceCover" />
      ) : (
        <LoadMoreButton handleLoadMore={() => handleNextPage?.('products')} />
      )}
      <span>
        1-{current} <Translation id="web20_of" />
        {` ${total} `}
        <Translation id="web20_articles" />
      </span>
    </div>
  );
}

// default sortingorder of productimages
const SORTING = [
  'PrimaryImageJPG',
  'PerspectiveDrawingJPG',
  'TopViewJPG',
  'FrontViewJPG',
  'SideViewJPG',
];

// Sort the images according of the incoming assignment
export function sortProductImages(gallery, sorting = SORTING) {
  // filter right product type
  const thumbnailsProduct = gallery?.list?.filter((item) => item.type === 'IMG_TYPE_Product');
  const thumbnailsArticle = gallery?.list?.filter((item) => item.type === 'IMG_TYPE_Article');

  const productsSorted = sorting
    .map((name) => thumbnailsProduct?.find((item) => item.orientation === name))
    .filter((item) => !!item);

  return [...productsSorted, ...thumbnailsArticle];
}

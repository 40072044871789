import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Divider, Typo } from '@geberit/gdds';

// styles
import { StyledArticle, LeftCol, RightCol, StyledButtonsWrapper } from './list-tile.styles';

// types
import { ILocation } from '../../locator.types';
import { IWithDistance } from '../utils/pins-manager';
import { IEntryWithKey } from '../providers/filter-provider';

// components
import { DealerLabel } from '../dealer-label';
import { DistanceAndDuration } from '../distance-and-duration';

// utils
import { useIsDesktop } from 'components/App/SizeProvider';
import { iconBuilder } from '../utils/icon-builder';
import { planRoute } from '../utils/plan-route';
import { useLocatorConfiguration } from '../../utils/use-locator-configuration';
import { decodingContent } from 'utils/decodingContent';
import { useGoToDealerPage } from '../../detail-page/use-goto-dealer-page';
import { PinAndListInteractionContext } from '../providers/pin-and-list-interaction-provider';
import { catalogBrandNameSelector } from 'utils/selectors/globalsSelectors';
import { mapBrand } from '../../utils/map-brand';
import { useCountryByIsoCode } from 'utils/hooks/use-country-by-isocode';

type ListTileProps = IWithDistance<IEntryWithKey> & {
  location: ILocation | undefined;
  onSelected: () => void;
};

export function ListTile(props: ListTileProps) {
  const { address, zip, city, name, distance, location, lat, lng, onSelected, index, country } =
    props;
  const locatorConfiguration = useLocatorConfiguration();
  // Here it will be decided later where the brand
  // name will be read from (config, CRM data etc.)
  const brandName = useSelector(catalogBrandNameSelector);
  // If brand name is read from CRM Data, then we
  // do not need the variable and the brand
  // attribute in the iconBuilder function argument object.
  // We would then read brand from the location attribute.
  const brand = mapBrand(brandName);

  const icon = iconBuilder({
    location: props,
    scope: 'list',
    brand,
    showPartner: locatorConfiguration.filter.showPartnerSwitch,
  });
  const [showDuration, setShowDuration] = useState(false);
  const tileRef = useRef<HTMLDivElement>(null);
  const isDesktop = useIsDesktop({ gdds: true });
  const destination = `${name} ${address} ${zip} ${city}`;
  const toDealerPage = useGoToDealerPage();
  const getCountryByCode = useCountryByIsoCode();

  const { activeIndex, setActiveIndex, hoveredIndex, setHoveredIndex } = useContext(
    PinAndListInteractionContext,
  );

  useEffect(() => {
    if (!tileRef.current) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShowDuration(true);
        } else {
          setShowDuration(false);
        }
      },
      { threshold: 0.1 },
    );

    observer.observe(tileRef.current);

    return () => {
      observer.disconnect();
    };
  }, [location.address, location.coordinates?.lat, location.coordinates?.lng]);

  function navigateToDealerPage() {
    if (isDesktop) {
      toDealerPage({ name, city });
    } else {
      onSelected();
    }
  }

  const handleOnClick = () => {
    if (isDesktop) {
      setActiveIndex(index);
      onSelected();
    }
  };

  const handleMouseEnter = () => {
    if (isDesktop) {
      setHoveredIndex(index);
    }
  };

  const handleMouseLeave = () => {
    if (isDesktop) {
      setHoveredIndex(undefined);
    }
  };

  return (
    <StyledArticle
      ref={tileRef}
      isActive={activeIndex === index}
      isHovered={hoveredIndex === index}
      id={`dealer-index-${index}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleOnClick}
    >
      <LeftCol>
        <DealerLabel entry={props} />
        <Typo variant="p1" fontWeight={700}>
          {name}
        </Typo>
        <Typo variant="p2">{`${address},`}</Typo>
        <Typo variant="p2">{`${zip} ${city}`}</Typo>
        <Typo variant="p2">{getCountryByCode(country)}</Typo>
      </LeftCol>
      <RightCol>
        <img src={icon} />
        <DistanceAndDuration
          showDuration={showDuration}
          entry={{ name, zip, address, distance, city, lat, lng }}
          location={location}
        />
      </RightCol>
      <StyledButtonsWrapper>
        <Button onClick={() => planRoute(destination)} stylingType="flatHighlight" symbol="Send">
          {decodingContent(locatorConfiguration.map?.routePlanner)}
        </Button>
        <Button onClick={navigateToDealerPage} stylingType="flatHighlight" symbol="Location">
          {decodingContent(
            isDesktop ? locatorConfiguration.map?.moreDetails : locatorConfiguration.map?.showOnMap,
          )}
        </Button>
      </StyledButtonsWrapper>
      <Divider />
    </StyledArticle>
  );
}

import axios, { AxiosInstance } from 'axios';
import key from 'weak-key';

//types
import type { Dispatch } from 'redux';
import type { CategoryResult } from './action.types';

// utils
import { isEmpty } from 'utils/is-empty';
import getConfig from 'config';
import { hashCode } from 'utils/hash';
import {
  productByKeySelector,
  currentCatalogSelector,
  categoryByIdSelector,
} from 'utils/selectors/productCatalogSelectors';
import { catalogBrandNameSelector } from 'utils/selectors/globalsSelectors';
import { isPreviewSelector } from 'utils/selectors/environmentSelectors';
import { getEndpoint } from 'utils/endpoints';
import { buildUrl } from 'utils/buildUrl';

/**
 * check each product id if it is already in the store
 * and if it is not too old
 */
function getMissingProductKeys(state: AppState, productKeys: string[], catalog: string | null) {
  const { timeToStale } = getConfig(state?.globals?.server);

  return productKeys.filter((productKey) => {
    const product = productByKeySelector(state, productKey, catalog);
    const isStale = product?.lastFetched ? Date.now() - product.lastFetched > timeToStale : false;

    return !product || isStale;
  });
}

/**
 * @param {Object} product
 * @param {String} catalog
 *
 * @returns {Object} action
 */
function addProduct(product, catalog) {
  return {
    type: 'ADD_PRODUCT' as const,
    product,
    catalog,
    lastFetched: Date.now(),
  };
}

/**
 * @returns {Object} action
 */
function productsFromState(productKeys?: string[], catalog?: string) {
  return {
    type: 'PRODUCTS_FROM_STATE' as const,
    productKeys,
    catalog,
  };
}

/**
 * @param {String} endpoint – endpoint
 *
 * @returns {Object} action
 */
function productsRequest(productKeys: string[], catalog: string, sectionId: string) {
  return {
    type: 'PRODUCTS_REQUEST_PENDING' as const,
    productKeys,
    catalog,
    sectionId,
  };
}

/**
 * @param {Object} response – response
 *
 * @returns {Object} action
 */
function productsRequestFulfilled(productKeys: string[], catalog: string, sectionId: string) {
  return {
    type: 'PRODUCTS_REQUEST_FULFILLED' as const,
    productKeys,
    catalog,
    sectionId,
    lastFetched: Date.now(),
  };
}

/**
 * @param {Object} error – error
 *
 * @returns {Object} action
 */
function productsRequestRejected(
  productKeys: string[],
  catalog: string,
  sectionId: string,
  error: RequestError,
) {
  return {
    type: 'PRODUCTS_REQUEST_REJECTED' as const,
    productKeys,
    catalog,
    sectionId,
    error,
  };
}

/**
 * @returns {Object} action
 */
function productsByCategoryFromState() {
  return {
    type: 'PRODUCTS_BY_CATEGORY_FROM_STATE' as const,
  };
}

/**
 * @param {String} categoryId
 * @param {String} catalog
 *
 * @returns {Object} action
 */
function productsByCategoryRequest(categoryId: string, catalog: string | null) {
  return {
    type: 'PRODUCTS_BY_CATEGORY_REQUEST_PENDING' as const,
    categoryId,
    catalog,
  };
}

/**
 * @param {String} categoryResult
 * @param {String} catalog
 * @param {String} productKeys
 * @param {Number} total
 *
 * @returns {Object} action
 */
function productsByCategoryRequestFulfilled(
  categoryResult: string | CategoryResult,
  catalog: string | null,
  productKeys: string[],
  total: number,
  catName: string,
) {
  // if its from the categorytiles the result is an object with additional text to render
  const categoryId =
    typeof categoryResult === 'string' ? categoryResult : categoryResult.categoryCatalogId;

  return {
    type: 'PRODUCTS_BY_CATEGORY_REQUEST_FULFILLED' as const,
    categoryResult,
    categoryId,
    catalog,
    productKeys,
    total,
    catName,
    lastFetched: Date.now(),
  };
}

/**
 * @param {String} categoryId
 * @param {String} catalog
 * @param {Object} error – error
 *
 * @returns {Object} action
 */
function productsByCategoryRequestRejected(
  categoryId: string,
  catalog: string | null,
  error: RequestError,
) {
  return {
    type: 'PRODUCTS_BY_CATEGORY_REQUEST_REJECTED' as const,
    categoryId,
    catalog,
    error,
  };
}

/**
 * @param {Function} dispatch
 * @param {Array} productKeys
 * @param {String} catalog
 * @param {Number} sectionId
 * @param {String} endpoint
 *
 * @returns {Promis} Promis
 */
function doFetchProducts(
  dispatch: Dispatch,
  productKeys: string[],
  catalog: string,
  sectionId: string,
  endpoint: string,
  axiosInstance: AxiosInstance | undefined = axios,
) {
  dispatch(productsRequest(productKeys, catalog, sectionId));
  return axiosInstance
    .get(endpoint)
    .then((response) => {
      /**
       * grap the result and save every product seperated
       */
      const {
        data: { results },
      } = response;
      results.map((product) => dispatch(addProduct(product, catalog)));

      dispatch(productsRequestFulfilled(productKeys, catalog, sectionId));
      return response;
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        dispatch(productsRequestRejected(productKeys, catalog, sectionId, error.response));
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        dispatch(productsRequestRejected(productKeys, catalog, sectionId, error.request));
      } else {
        // Something happened in setting up the request that triggered an Error
        dispatch(productsRequestRejected(productKeys, catalog, sectionId, error.message));
      }

      return error;
    });
}

/**
 * @param {Array} productKeys
 * @param {String} catalog
 * @param {String} previewId
 *
 * @returns {Promise} data
 */
export function fetchProducts(
  productKeys: string[],
  previewId: string,
  catalogLanguage?: string,
  axiosInstance?: AxiosInstance,
) {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const catalog = catalogLanguage ?? currentCatalogSelector(state);
    const brandName = catalogBrandNameSelector(state);
    if (!catalog) return false;
    if (productKeys.length === 0) return false;

    const sectionId = previewId ? hashCode(previewId) : key({ productKeys });
    const missingProductKeys = getMissingProductKeys(state, productKeys, catalog);
    const missingProductKeysExists = !isEmpty(missingProductKeys);

    if (missingProductKeysExists && !isEmpty(missingProductKeys)) {
      const productsEndpoint = `${getEndpoint('catalog', state)}/products`;
      const searchParams = {
        lang: catalog,
        productIds: missingProductKeys,
        brand: brandName,
        limit: `${missingProductKeys.length}`,
      };
      const endpoint = buildUrl(productsEndpoint, searchParams);

      return doFetchProducts(dispatch, productKeys, catalog, sectionId, endpoint, axiosInstance);
    }

    // abort request
    dispatch(productsFromState());
    return false;
  };
}

/**
 * @param {Function} dispatch
 * @param {Object} categoryItem
 * @param {String} categoryId
 * @param {String} catalog
 * @param {String} endpoint
 *
 * @returns {Promise} Promise
 */
function doFetchProductsByCategory(
  dispatch: Dispatch,
  categoryItem: CategoryResult | null,
  categoryId: string,
  catalog: string | null,
  endpoint: string,
  isTotalRequest?: boolean,
  axiosInstance: AxiosInstance | undefined = axios,
) {
  let id: string | undefined = categoryId;
  if (!id) id = categoryItem?.categoryCatalogId;

  if (!id) return false;
  dispatch(productsByCategoryRequest(id, catalog));

  return axiosInstance
    .get(endpoint)
    .then((response) => {
      /**
       * grap the result and save every Product seperated
       */
      const {
        data: { results, total },
      } = response;

      let productKeys = [];
      // dont get products if a request is send to get the total number
      // in this request are not all needed and sorted products
      if (!isTotalRequest) {
        productKeys = results?.map((product) => {
          dispatch(addProduct(product, catalog));
          return product.key;
        });
      }

      const singleCategory = results?.[0].categories.find((cat) => !isEmpty(cat.name));
      const catName = singleCategory?.name;

      const categoryResult = categoryItem || categoryId;

      dispatch(
        productsByCategoryRequestFulfilled(categoryResult, catalog, productKeys, total, catName),
      );
      return response;
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        dispatch(productsByCategoryRequestRejected(categoryId, catalog, error.response));
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        dispatch(productsByCategoryRequestRejected(categoryId, catalog, error.request));
      } else {
        // Something happened in setting up the request that triggered an Error
        dispatch(productsByCategoryRequestRejected(categoryId, catalog, error.message));
      }

      return error;
    });
}

/**
 * @param {String} categoryId
 * @param {String} catalog
 * @param {Number} limit
 * @param {Number} offset
 * @param {String} hints
 *
 * @returns {Promise} data
 */
export function fetchLimitedProductsByCategory(
  categoryId: string,
  catalog: string,
  limit: number,
  offset: number,
  hints: string,
) {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const catalogLanguage = currentCatalogSelector(state);
    const category = categoryByIdSelector(state, categoryId);
    const categoryExists = Boolean(category);
    const hasAllProducts = categoryExists && (category?.productKeys?.length ?? 0) < offset;
    const isPreview = isPreviewSelector(state);
    const brandName = catalogBrandNameSelector(state);

    const endpoint =
      `${getEndpoint('catalog', state)}/category/${categoryId}/products?responseSize=list` +
      `&brand=${brandName}&lang=${catalogLanguage}&limit=${limit || 0}&offset=${
        offset || 0
      }${hints}`;

    if (
      isPreview ||
      Boolean(categoryExists && category?.error) ||
      !categoryExists ||
      !hasAllProducts
    ) {
      return doFetchProductsByCategory(
        dispatch,
        null,
        categoryId,
        catalogLanguage,
        endpoint,
        false,
      );
    }

    // abort request
    dispatch(productsByCategoryFromState());
    return false;
  };
}

/**
 * @param {Object} categoryItem
 * @param {String} catalog
 *
 * @returns {Promise} data
 */
export function fetchProductsByCategory(
  categoryItem: CategoryResult,
  axiosInstance?: AxiosInstance,
) {
  return (dispatch: Dispatch, getState: () => AppState) => {
    if (isEmpty(categoryItem)) return false;
    const state = getState();
    const catalogLanguage = currentCatalogSelector(state);
    const category = categoryByIdSelector(state, categoryItem.categoryCatalogId);
    const categoryExists = Boolean(category);
    const isPreview = isPreviewSelector(state);
    const brandName = catalogBrandNameSelector(state);

    const endpoint =
      `${getEndpoint(
        'catalog',
        state,
      )}/products?lang=${catalogLanguage}&brand=${brandName}&responseSize=list&limit=1&filter.query` +
      `=categories.id:+subtree("${categoryItem.categoryCatalogId}")`;

    if (isPreview || Boolean(categoryExists && category?.error) || !categoryExists) {
      return doFetchProductsByCategory(
        dispatch,
        categoryItem,
        categoryItem.categoryCatalogId,
        catalogLanguage,
        endpoint,
        true,
        axiosInstance,
      );
    }

    // abort request
    dispatch(productsByCategoryFromState());
    return false;
  };
}

export type ProductAction =
  | ReturnType<typeof addProduct>
  | ReturnType<typeof productsFromState>
  | ReturnType<typeof productsRequest>
  | ReturnType<typeof productsRequestFulfilled>
  | ReturnType<typeof productsRequestRejected>
  | ReturnType<typeof productsByCategoryFromState>
  | ReturnType<typeof productsByCategoryRequest>
  | ReturnType<typeof productsByCategoryRequestFulfilled>
  | ReturnType<typeof productsByCategoryRequestRejected>;

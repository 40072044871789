// styles
import styles from './layout.module.scss';

// components
import Footer from 'components/Footer/Footer';

interface CampusLayoutProps extends React.PropsWithChildren {
  noErrorBoundary?: boolean;
}

export default function CampusLayout({
  children,
  noErrorBoundary = false,
}: Readonly<CampusLayoutProps>) {
  return (
    <div className={styles.campusLayout}>
      <main className={`${styles.campusMain} ${noErrorBoundary ? styles.errorPage : ''}`}>
        {children}
      </main>
      <Footer isCampus />
    </div>
  );
}

import React from 'react';
import key from 'weak-key';
import { useSelector } from 'react-redux';

import ProductDetailTechnicalDrawingsView from './ProductDetailTechnicalDrawingsView';

// Utils
import { translationByKeySelector } from '../../../utils/selectors/translationSelectors';
import { sortProductImages } from '../../../utils/sortProductImages';

interface ProductDetailTechnicalDrawings {
  productDrawings?: any[] | null;
  articleDrawings?: any[] | null;
}

function ProductDetailTechnicalDrawings({
  productDrawings = null,
  articleDrawings = null,
  ...props
}: Readonly<ProductDetailTechnicalDrawings>) {
  // order of product drawings
  const SORTING = ['PerspectiveDrawingJPG', 'TopViewJPG', 'FrontViewJPG', 'SideViewJPG'];

  // Using useSelector to fetch translations
  const translationProductDrawings = useSelector((appState: AppState) =>
    translationByKeySelector(appState, 'web20_product_technical_drawings_product_dawings'),
  );
  const translationProductDrawingsPrefix = useSelector((appState: AppState) =>
    translationByKeySelector(appState, 'web20_product_technical_drawings_prefix'),
  );

  if (articleDrawings) {
    return articleDrawings.map((drawing) => (
      <ProductDetailTechnicalDrawingsView
        key={key(drawing)}
        headline={drawing.key}
        drawings={sortProductImages(drawing.gallery, SORTING)}
        translationProductDrawingsPrefix={translationProductDrawingsPrefix}
        {...props}
      />
    ));
  }

  return (
    <ProductDetailTechnicalDrawingsView
      headline={translationProductDrawings}
      drawings={sortProductImages(productDrawings, SORTING)}
      isProductImage
      {...props}
    />
  );
}

export default ProductDetailTechnicalDrawings;

/**
 * @param {string} endpoint
 * @param {Object} searchParams
 *
 * @returns {String} url
 */
export function buildUrl(endpoint: string, searchParams: Record<string, string>) {
  const urlSearchParams = new URLSearchParams();
  Object.entries(searchParams).forEach(([name, value]) => {
    if (value) {
      urlSearchParams.append(name, value);
    }
  });

  return `${endpoint}${endpoint.endsWith('/') ? '' : '/'}?${urlSearchParams.toString()}`;
}

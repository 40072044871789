import { format } from 'date-fns';
import { Icon as GddsIcon, Typo, Col } from '@geberit/gdds';

// styles
import styles from './tile.module.scss';

// types
import type { TileProps } from './tile.types';

// components
import { Translation } from 'components/Translation/Translation';
import { Image as StaticImage } from 'components/image/image';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import {
  clickAddToOrderCart,
  clickAddToDownloadCart,
  clickDownloadsLink,
} from 'features/download-center/tracking';
import { formatBytes } from 'utils/format-bytes';
import { useLocale } from 'utils/use-locale';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useKolo } from 'utils/hooks/use-kolo';
import { isEmpty } from 'utils/is-empty';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useTracking as useTracking2 } from 'utils/tracking/track';
import { useTranslationFunction } from 'utils/hooks/use-translations';

function Tile({
  dateFormat,
  updateOrder,
  updateDownload,
  downloads,
  tileData,
  hasOrder,
}: Readonly<TileProps>) {
  const isKolo = useKolo();
  const track = useTracking();
  const { trackClick } = useTracking2();
  const translate = useTranslationFunction();
  const {
    size: fileSize,
    date: dateString,
    // eslint-disable-next-line camelcase
    meta: { title, fileUrl, flipbookUrl, indexer_filetype, picture, order: showOrder },
  } = tileData;
  const language = useCurrentLanguage();
  const locale = useLocale(language);
  const formatedDateString = locale
    ? format(new Date(dateString), dateFormat, {
        locale,
      })
    : '';

  const handleClickOrder = () => {
    // add tile product with default amount 1 to order section in cart
    const order = { ...tileData, amount: 1, title: title[0] };
    track.trackEvent(clickAddToOrderCart(title[0]));
    updateOrder(order);
  };

  const handleClickDownload = () => {
    // add tile product to download section in cart
    track.trackEvent(clickAddToDownloadCart(tileData.title));
    updateDownload(tileData);
  };

  const hasDownload = downloads.find((download) => download.id === tileData.id);

  const handleDownloadsLinkClick = (name: 'Open' | 'Flipbook') => {
    track.trackEvent(clickDownloadsLink(name, tileData.title));
    if (name === 'Open')
      trackClick({ click_intent: 'download-center_open', click_element: 'download_center' });
  };

  const checkBooleanInArray = (array) => array.some((string) => string !== 'false');

  return (
    <Col size={[4, 4, 4]}>
      <div className={styles.tile}>
        <div className={styles.badges}>
          {hasDownload && (
            <span>
              <GddsIcon symbol="Download" color="#FFFFFF" />
            </span>
          )}
          {hasOrder && (
            <span>
              <GddsIcon symbol="Onlineshop" color="#FFFFFF" />
            </span>
          )}
        </div>
        <div className={styles.tileContent}>
          {!isEmpty(picture) && !isEmpty(picture[0]) && (
            <StaticImage
              src={picture[0]}
              alt={title[0]}
              title={title[0]}
              staticImage
              external
              className={styles.image}
            />
          )}
          <div className={styles.information}>
            {!isEmpty(dateString) && dateString !== '1970-01-01T00:00:00Z' && (
              <Typo variant="p2" as="span" className={styles.date}>
                {formatedDateString}
              </Typo>
            )}

            {!isEmpty(title) && !isEmpty(title[0]) && (
              <Typo
                variant="p1"
                as="span"
                className={classNameBuilder(styles.title, isKolo && styles.titleKolo)}
              >
                <InnerHtml as="span" content={title[0]} />
              </Typo>
            )}

            <div className={styles.flags}>
              {!isEmpty(indexer_filetype) && !isEmpty(indexer_filetype[0]) && (
                <Typo variant="p2" as="span">
                  <GddsIcon symbol="FileWithType" />
                  <div className={styles.filename}>{String(indexer_filetype[0]).toUpperCase()}</div>
                </Typo>
              )}
              {!isEmpty(fileUrl) && !isEmpty(fileUrl[0]) && (
                <Typo variant="p2" as="span">
                  <GddsIcon symbol="HardDrive" />
                  {formatBytes(fileSize * 1000, 2)}
                </Typo>
              )}
            </div>
          </div>
        </div>
        <div className={styles.overlay}>
          <div className={styles.overlayContent}>
            {!isEmpty(fileUrl) && !isEmpty(fileUrl[0]) && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={fileUrl[0]}
                onClick={() => handleDownloadsLinkClick('Open')}
              >
                <GddsIcon symbol="FileWithType" />
                {translate('web20_downloadcenter_open')}
              </a>
            )}
            {!isEmpty(fileUrl) && !isEmpty(fileUrl[0]) && (
              <button
                type="button"
                onClick={handleClickDownload}
                className={classNameBuilder(hasDownload && styles.selected)}
              >
                <GddsIcon symbol="Download" />
                {translate('web20_downloadcenter_download')}
              </button>
            )}
            {!isEmpty(showOrder) && checkBooleanInArray(showOrder) && (
              <button
                type="button"
                onClick={handleClickOrder}
                className={classNameBuilder(hasOrder && styles.selected)}
              >
                <GddsIcon symbol="Onlineshop" />
                {translate('web20_downloadcenter_order')}
              </button>
            )}
            {!isEmpty(flipbookUrl) && !isEmpty(flipbookUrl[0]) && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={flipbookUrl[0]}
                onClick={() => handleDownloadsLinkClick('Flipbook')}
              >
                <GddsIcon symbol="DownloadBook" />
                <Translation id="web20_downloadcenter_flipbook" />
              </a>
            )}
          </div>
        </div>
      </div>
    </Col>
  );
}

export default Tile;

import axios from 'axios';
import isEmpty from 'lodash.isempty';
import sanitize from 'xss';
import { Dispatch } from 'redux';

import {
  catalogBrandNameSelector,
  globalsSearchSegmentSelector,
} from 'utils/selectors/globalsSelectors';
import getEndpoint from 'utils/endpoints';
import { currentCatalogSelector } from 'utils/selectors/productCatalogSelectors';
import { selectParamsFromState } from 'utils/selectors/searchSelectors';

// suggestion actions
function fetchSuggestionsRequested() {
  return {
    type: 'search/SEARCH_SUGGESTIONS_REQUESTED' as const,
  };
}

function fetchSuggestionsFulfilled() {
  return {
    type: 'search/SEARCH_SUGGESTIONS_FULFILLED' as const,
  };
}

function fetchSuggestionsRejected() {
  return {
    type: 'search/SEARCH_SUGGESTIONS_REJECTED' as const,
  };
}

/**
 * Fetch suggestions for string
 * @param String query
 */
export function fetchSuggestions(query: string) {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(fetchSuggestionsRequested());
    const state = getState();
    const segment = globalsSearchSegmentSelector(state);
    const endpoint = `${getEndpoint('suggest', state)}`;

    if (!query) {
      dispatch(fetchSuggestionsFulfilled());
      return [];
    }

    try {
      const response = await axios.get(endpoint, {
        params: {
          segment,
          q: query,
        },
      });
      dispatch(fetchSuggestionsFulfilled());

      return response.data.suggestions;
    } catch (e) {
      dispatch(fetchSuggestionsRejected());
      return [];
    }
  };
}

// search result actions
/**
 * Fetch search results by requesting content and products
 */
export function fetchSearchResults(axiosInstance) {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const params = selectParamsFromState(state);
    const catalogLanguage = currentCatalogSelector(state);
    const segment = globalsSearchSegmentSelector(state);

    if (!catalogLanguage && !segment) {
      dispatch(
        searchResultsRejected({
          status: 500,
          statusText: 'No catalog or segment provided',
        }),
      );
      dispatch(searchFulfilled());
      return Promise.resolve();
    }

    try {
      await Promise.all(
        [
          segment ? dispatch(fetchSoftcontentResults(params, segment, axiosInstance)) : null,
          catalogLanguage
            ? dispatch(fetchProductResults(params, catalogLanguage, axiosInstance))
            : null,
        ].filter((i) => i !== null),
      );
      dispatch(searchFulfilled());

      return Promise.resolve();
    } catch (e) {
      dispatch(searchFulfilled());
      return Promise.resolve();
    }
  };
}

function searchPending() {
  return {
    type: 'search/SEARCH_PENDING' as const,
  };
}
function searchFulfilled() {
  return {
    type: 'search/SEARCH_FULFILLED' as const,
  };
}

/**
 * Reject search results
 * @param {*} error
 */
function searchResultsRejected(error: RequestError) {
  return {
    type: 'search/SEARCH_RESULTS_REJECTED' as const,
    error,
  };
}

// soft content
/**
 * Fetch soft content result from elastic search
 * @param {*} params
 * @param {*} segment
 */
function fetchSoftcontentResults(
  { query: q, filter: filters, ...params },
  segment,
  axiosInstance = axios,
) {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const endpoint = `${getEndpoint('search', state)}`;

    try {
      const response = await axiosInstance.get(endpoint, {
        params: {
          q,
          filters,
          ...params,
          segment,
          agg_mode: 'all',
          agg: 'indexer_filetype',
          num: 12,
        },
      });
      if (typeof response.data === 'string') {
        response.data = JSON.parse(response.data);
      }
      dispatch(softContentResultsFulfilled(response.data));

      return Promise.resolve();
    } catch (e) {
      dispatch(softContentResultsRejected(e));
      return e;
    }
  };
}

function softContentResultsFulfilled(response: any) {
  return {
    type: 'search/SEARCH_SOFTCONTENT_FULFILLED' as const,
    response,
  };
}

function softContentResultsRejected(response: RequestError) {
  return {
    type: 'search/SEARCH_SOFTCONTENT_REJECTED' as const,
    response,
  };
}

// products
/**
 * Fetch products from commerce tools
 * @param {*} params
 * @param {*} catalogLanguage
 */
function fetchProductResults({ q, offset }, catalogLanguage: string, axiosInstance = axios) {
  const limit = 12;
  const queryString = isEmpty(q) ? '' : q;
  const useOffset = offset || 0;
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const brandName = catalogBrandNameSelector(state);
    dispatch(searchPending());
    const endpoint = `${getEndpoint(
      'catalog',
      state,
    )}/products?lang=${catalogLanguage}&limit=${limit}&offset=${useOffset}&text=${encodeURIComponent(
      queryString,
    )}&responseSize=list&brand=${brandName}`;

    try {
      const response = await axiosInstance.get(endpoint);
      if (typeof response.data === 'string') {
        response.data = JSON.parse(response.data);
      }
      dispatch(productResultsFulfilled(response.data));
      return Promise.resolve();
    } catch (e) {
      if (e.response.status === 404) {
        dispatch(productResultsFulfilled(e.response.data));
        return e;
      }
      dispatch(productResultsRejected(e));
      return e;
    }
  };
}

function productResultsFulfilled(response: any) {
  return {
    type: 'search/SEARCH_PRODUCTS_FULFILLED' as const,
    response,
  };
}

function productResultsRejected(error: RequestError) {
  return {
    type: 'search/SEARCH_PRODUCTS_REJECTED' as const,
    error,
  };
}

/**
 * Params for SSR
 */
export function setSearchParamsSSR(
  params: { q: string; filter: string; offset: number },
  axiosInstance,
) {
  return async (dispatch: Dispatch) => {
    dispatch(setSearchQuery(params.q));
    dispatch(setSearchFilter(params.filter));
    dispatch(setSearchOffset(params.offset));
    await dispatch(fetchSearchResults(axiosInstance));

    return Promise.resolve();
  };
}

/**
 * Update search query
 * @param String query
 */
export function setSearchQuery(q: string) {
  return {
    type: 'search/SET_SEARCH_QUERY' as const,
    q: sanitize(q).replace(/&amp;/g, '&').replace(/&gt;/g, '>'),
  };
}

/**
 * Update search filter
 * @param String filter
 */
export function setSearchFilter(filter?: string) {
  return {
    type: 'search/SET_SEARCH_FILTER' as const,
    filter,
  };
}

/**
 * Update search offset
 * @param String offset
 */
export function setSearchOffset(offset?: string) {
  return {
    type: 'search/SET_SEARCH_OFFSET' as const,
    offset,
  };
}

export type SearchAction =
  | ReturnType<typeof fetchSuggestionsRequested>
  | ReturnType<typeof fetchSuggestionsFulfilled>
  | ReturnType<typeof fetchSuggestionsRejected>
  | ReturnType<typeof searchPending>
  | ReturnType<typeof searchFulfilled>
  | ReturnType<typeof searchResultsRejected>
  | ReturnType<typeof softContentResultsFulfilled>
  | ReturnType<typeof softContentResultsRejected>
  | ReturnType<typeof productResultsFulfilled>
  | ReturnType<typeof productResultsRejected>
  | ReturnType<typeof setSearchQuery>
  | ReturnType<typeof setSearchFilter>
  | ReturnType<typeof setSearchOffset>;

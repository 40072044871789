// utils
import { MyNewsDesk } from '../types';
import { useTranslationFunction } from '../../../../utils/hooks/use-translations';

export function useMediaDetails(material: MyNewsDesk.Media | null, extended?: boolean) {
  const translate = useTranslationFunction();
  const licenseTranslation = translate('press_license');
  const allRightsReservedTranslation = translate('press_all_rights_reserved');
  const madeByTranslation = translate('press_made_by');
  const fileFormatTranslation = translate('press_file_format');
  const sizeTranslation = translate('press_file_size');

  if (!material) return [];

  const details: string[] = [];
  if (material.type_of_media === 'image') {
    licenseTranslation && details.push(`${licenseTranslation}: ${material.image_license}`);
    madeByTranslation &&
      material.photographer &&
      details.push(`${madeByTranslation}: ${material.photographer}`);
    extended &&
      fileFormatTranslation &&
      material.image_format &&
      details.push(`${fileFormatTranslation}: ${material.image_format}`);
    extended && sizeTranslation && details.push(`${sizeTranslation}: ${material.size}`);
  }

  if (material.type_of_media === 'video') {
    licenseTranslation &&
      material.video_license &&
      details.push(`${licenseTranslation}: ${material.video_license}`);
    fileFormatTranslation &&
      details.push(`${fileFormatTranslation}: .${material?.video_name?.split('.').pop()}`);
  }

  if (material.type_of_media === 'document') {
    licenseTranslation &&
      allRightsReservedTranslation &&
      details.push(`${licenseTranslation}: ${allRightsReservedTranslation}`);
    fileFormatTranslation &&
      material?.document_format &&
      details.push(`${fileFormatTranslation}: ${material.document_format}`);
  }
  return details;
}

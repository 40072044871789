// styles
import styles from './product-tiles.module.scss';

// types
import type { ProductTileProps } from './product-tiles.types';

// components
import { CmsLink } from 'components/Link/CmsLink';
import { Image } from 'components/image/image';
import { Translation } from 'components/Translation/Translation';

// utils
import { imageAltSubcategory, imageAlt, getImageAltSuffix } from 'utils/imageAltTag';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';

export function ProductTile({
  name,
  articles,
  currentImage,
  categories,
  productLink,
  state,
}: Readonly<ProductTileProps>) {
  const translate = useTranslationFunction();
  const imageCategory = imageAltSubcategory(categories);
  const imageAltCategory = !isEmpty(imageCategory) ? ` - ${imageCategory}` : '';
  const imagePrefix = imageAlt(translate('web20_products_image_of'));
  const imageSuffix = getImageAltSuffix(translate('web20_products_imagealt_suffix'));
  const imageContent = `${imagePrefix}${name}${imageAltCategory}${imageSuffix}`;

  return (
    <div className={styles.productTile}>
      <CmsLink link={{ target: productLink }}>
        <div className={styles.imageWrapper}>
          <Image format="1_1" src={currentImage} alt={imageContent} title={imageContent} />
        </div>
        {state?.indicator && (
          <div className={styles.newLabel}>
            <Translation id="web20_new_label" />
          </div>
        )}
        <div className={styles.content}>
          <span className={styles.name}>{name}</span>
          <span className={styles.amount}>
            {articles?.articlesListSize} &nbsp;
            <Translation id="web20_products_articles" />
          </span>
        </div>
      </CmsLink>
    </div>
  );
}

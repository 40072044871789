import key from 'weak-key';
import { useRouter } from 'next/navigation';
import { Col, Row, Divider } from '@geberit/gdds';

// styles
import styles from './media-tiles.module.scss';

// types
import type { MediaTilesProps, RelatedMaterialsDisplayMode } from '../types/press-portal.types';

// components
import { MediaTile } from './media-tile';
import Title from '../../title/title';

// utils
import { getMaterialUniqueIdentifier } from '../utils';
import { useIsMobile } from 'components/App/SizeProvider';
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';
import { classNameBuilder } from 'utils/classNameBuilder';

export default function RelatedMediaTiles({ mediaList }: Readonly<MediaTilesProps>) {
  const isMobile = useIsMobile();
  const router = useRouter();
  const pressRelatedMaterials = useTranslationByKey('press_related_materials');

  if (isEmpty(mediaList)) return null;

  const relatedMaterialsDisplayMode: RelatedMaterialsDisplayMode =
    mediaList.length > 2 ? 'three_per_row' : 'two_per_row';

  return (
    <>
      <Divider />
      <Title title={pressRelatedMaterials} />
      <Row className={styles.relatedMaterial}>
        {relatedMaterialsDisplayMode === 'two_per_row' && <Col size={[0, 0, 2]}></Col>}
        <Col size={[4, 8, relatedMaterialsDisplayMode === 'two_per_row' ? 8 : 12]}>
          <div
            className={classNameBuilder(
              styles.mediaTilessWrapper,
              relatedMaterialsDisplayMode === 'three_per_row' && styles.threeTilesPerRow,
            )}
          >
            {mediaList.map((media) => {
              return (
                <MediaTile
                  isRelatedMaterial
                  onClick={() => {
                    const pathname = `?page=asset-detailpage&title=${getMaterialUniqueIdentifier(
                      media,
                    )}`;
                    router.push(pathname, undefined, { shallow: true });
                  }}
                  key={key(media)}
                  isMobile={isMobile}
                  media={media}
                />
              );
            })}
          </div>
        </Col>
        {relatedMaterialsDisplayMode === 'two_per_row' && <Col size={[0, 0, 2]}></Col>}
      </Row>
    </>
  );
}

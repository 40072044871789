import { FormikProps } from 'formik';
import { Dropdown, FormField, GenericStringProps } from '@geberit/gdds';

// styles
import styles from '../registrationForm.module.scss';

// types
import type { SelectOption } from '@geberit/gdds/dist/esm/form/Dropdown/types/Dropdown.types';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useCreateFormLabel } from '../hooks';

interface CateringProps {
  nameSuffix: string;
  cateringItems?: SelectOption[];
  mandatoryErrorMessage?: string;
}

export default function Catering({
  nameSuffix,
  handleChange,
  values,
  cateringItems,
  touched,
  errors,
  handleBlur,
  mandatoryErrorMessage,
}: CateringProps &
  Pick<FormikProps<any>, 'touched' | 'errors' | 'values' | 'handleBlur' | 'handleChange'>) {
  const createLabel = useCreateFormLabel();
  const translate = useTranslationFunction();
  const setErrors = (field): GenericStringProps =>
    ((touched?.[`${field}${nameSuffix}`] && errors?.[`${field}${nameSuffix}`] && errors) ||
      {}) as GenericStringProps;

  return (
    <div className="grid-x">
      {cateringItems && (
        <div
          className={classNameBuilder(
            'small-12 medium-6 left',
            touched?.[`catering${nameSuffix}`] &&
              errors?.[`catering${nameSuffix}`] &&
              styles.errorBorder,
          )}
        >
          {/* catering speciality */}
          <Dropdown
            errors={setErrors('catering')}
            name={`catering${nameSuffix}`}
            values={values}
            placeholder={translate('campus_please_select')}
            onChange={(item: SelectOption) =>
              handleChange({ target: { name: `catering${nameSuffix}`, value: item.value } })
            }
            onBlur={handleBlur}
            items={cateringItems}
            label={createLabel('catering')}
            outlined
            errorMessage={mandatoryErrorMessage}
          />
        </div>
      )}
      <div
        className={classNameBuilder(
          'small-12 medium-6 right',
          touched?.[`foodcomment${nameSuffix}`] &&
            errors?.[`foodcomment${nameSuffix}`] &&
            styles.errorBorder,
        )}
      >
        {/* foodcomment */}
        <FormField
          errors={setErrors('foodcomment')}
          name={`foodcomment${nameSuffix}`}
          values={values}
          label={createLabel('foodcomment', 'campus_form_food_comment')}
          placeholder=""
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
}

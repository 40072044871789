import { Container, Row, Col } from '@geberit/gdds';

// styles
import styles from './filter.module.scss';

// types
import type { FilterChips as FilterChipsType, FilterProps } from './filter.types';

// components
import FilterDropdown from './filter-dropdown';
import FilterChips from './filter-chips';
import ResetCol from './reset-col';

// utils
import { useIsDesktop } from 'components/App/SizeProvider';
import { useInsightFilters } from 'utils/hooks/useInsights';

export default function Filter(props: Readonly<FilterProps>) {
  const { selectedTopics, setSelectedTopics, selectedYears, setSelectedYears, reset, labels } =
    props;
  const isDesktop = useIsDesktop();
  const { filters } = useInsightFilters({
    selectedTopics,
    setSelectedTopics,
    selectedYears,
    setSelectedYears,
  });
  const {
    year: { label: yearsLabel, placeholder: yearsPlaceholder },
    topic: { label: topicsLabel, placeholder: topicsPlaceholder },
    reset: { label: resetLabel },
  } = labels || { year: {}, topic: {}, reset: {} };

  // prepare dropdown items for filter chips and dropdowns
  const restructureForDropdown = (items): FilterChipsType =>
    (items || []).map((i) => ({ text: i, value: i, title: i }));
  const topics = restructureForDropdown(filters?.topics);
  const years = restructureForDropdown(filters?.years);

  return (
    <Container maxContentWidth="78rem" className={styles.wrapper}>
      <Row>
        {/* selects for topics and years */}
        <Col size={[4, 8, 3]}>
          <FilterDropdown
            id="topics"
            label={topicsLabel}
            allItems={topics}
            selected={selectedTopics}
            setSelected={setSelectedTopics}
            placeholder={topicsPlaceholder}
          />
        </Col>
        <Col size={[4, 8, 3]}>
          <FilterDropdown
            id="years"
            label={yearsLabel}
            allItems={years}
            selected={selectedYears}
            setSelected={setSelectedYears}
            placeholder={yearsPlaceholder}
          />
        </Col>

        {/* reset button desktop */}
        {isDesktop && <ResetCol reset={reset} resetLabel={resetLabel} />}

        {/* filter chips */}
        {(selectedTopics.length > 0 || selectedYears.length > 0) && (
          <Col size={[4, 8, 12]} className={styles.chips}>
            <FilterChips
              topics={topics}
              years={years}
              selectedTopics={selectedTopics}
              setSelectedTopics={setSelectedTopics}
              selectedYears={selectedYears}
              setSelectedYears={setSelectedYears}
            />
          </Col>
        )}

        {/* reset button mobile */}
        {!isDesktop && <ResetCol reset={reset} resetLabel={resetLabel} />}
      </Row>
    </Container>
  );
}

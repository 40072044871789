import React from 'react';

// components
import SoftContentResult from './SoftContentResult/SoftContentResult';
import ProductResult from './ProductResult/ProductResult';
import { TAB_CONTENT } from './constants';

interface SearchResultsProps {
  tab: number;
}

function SearchResults({ tab }: Readonly<SearchResultsProps>) {
  return (
    <div className="grid-x grid-margin-x">
      {tab === TAB_CONTENT ? <SoftContentResult /> : <ProductResult />}
    </div>
  );
}

export default SearchResults;

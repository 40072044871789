'use client';

// styles
import styles from './content.module.scss';

// types
import type {
  ContentElementPayload,
  ShowroomLocatorTeaserPayload,
} from 'components/ContentElements/content-elements-payload';

// components
import Layout from './layout';
import HeroImageC from 'components/ContentElementsGdds/hero-image/hero-image-c';
import { HeroImageGdds } from 'components/ContentElementsGdds/hero-image/hero-image-gdds';
import { ContentArea } from 'components/ContentArea/ContentArea';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { IntroText } from 'components/ContentElementsGdds/IntroText/intro-text';
import { AnchorBar } from 'components/ContentElementsGdds/anchorbar/anchorbar';

// utils
import { isEmpty } from 'utils/is-empty';
import { useContent } from 'components/Page/page-hooks';

type ContentProps = {
  hero: ISlider;
  collapseAnchor?: boolean;
  pageAnchorTitle?: string;
  slider: ISlider;
};

export function Content() {
  const page = useContent<{}, ContentProps>();

  if (isEmpty(page) || isEmpty(page.contentAreas)) {
    return null;
  }

  const {
    breadcrumbs,
    metaData,
    page: {
      slider,
      intro,
      text,
      headline,
      subHeadline,
      hero,
      showAnchor,
      collapseAnchor,
      pageAnchorTitle,
    },
    contentAreas: { content },
    ...props
  } = page;
  const heroTypeC = !isEmpty(hero?.items) && hero.items[0].type === 'hero_c_item';
  return (
    <Layout {...props} metaData={metaData}>
      {!isEmpty(hero?.items) && !heroTypeC && <HeroImageGdds {...hero} isSeoConformCarousel />}
      <Breadcrumb items={breadcrumbs} className={styles.breadcrumbs} />
      <HeroImageGdds {...slider} />
      {heroTypeC && <HeroImageC {...hero.items?.[0]} />}
      {(headline || subHeadline) && (
        <IntroText
          className={styles.intro}
          title={headline}
          subtitle={subHeadline}
          text={text}
          intro={intro}
          border="disable-border"
          disableInEdit
          // -> InEdit not working on page level because of #327791
          // can possibly be activated later again
          // titlePreviewId="#pt_contentHeadline"
          // subtitlePreviewId="#pt_contentSubHeadline"
        />
      )}
      {showAnchor && (
        <AnchorBar
          content={content as Exclude<ContentElementPayload, ShowroomLocatorTeaserPayload>[]}
          collapseAnchor={collapseAnchor}
          pageAnchorTitle={pageAnchorTitle}
        />
      )}
      <ContentArea content={content} contentType="content" {...props} />
    </Layout>
  );
}

import type { Dispatch } from 'react';
import produce from 'immer';

// utils
import { currentLanguageSelector } from 'utils/selectors/languageSelectors';

// actions
import { footerPreviewIdSelector } from 'utils/selectors/footerNavigationSelectors';
import { navigationMetaPreviewIdSelector } from 'utils/selectors/navigationSelectors';
import { headerLinksFulfilled } from '../Navigation/actions';

/**
 * @return {Object}
 */
export function doUpdateSectionOnPage(section: { content: any; previewId: string }) {
  return {
    type: 'page/UPDATE_SECTION_ON_PAGE' as const,
    previewId: section.previewId,
    content: section.content,
  };
}

/**
 * @return {Object}
 */
function doAddSectionToPage(newSection: any) {
  return {
    type: 'page/ADD_SECTION_TO_PAGE' as const,
    newSection,
  };
}

/**
 * @return {Object}
 */
function doDeleteSection(previewId: string) {
  return {
    type: 'page/DELETE_SECTION_ON_PAGE' as const,
    previewId,
  };
}

/**
 * @return {Object}
 */
export function doUpdatePage(page: any, isNewPage = false) {
  return {
    type: 'page/UPDATE_PAGE' as const,
    page,
    lastFetched: Date.now(),
    isNewPage,
  };
}

/**
 * @return {Object}
 */
function doUpdateImage(contentAreas: any) {
  return {
    type: 'page/UPDATE_IMAGE' as const,
    contentAreas,
  };
}

/**
 * @return {Object}
 */
export function updateSectionOnPage(updatedSection: any) {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const language = currentLanguageSelector(state);
    const footerId = footerPreviewIdSelector(state);
    const metaId = navigationMetaPreviewIdSelector(state);

    if (updatedSection.previewId === footerId) {
      dispatch({
        type: 'globals/UPDATE_FOOTER',
        navigation: updatedSection.content.footer.navigation,
        socialMedia: updatedSection.content.footer.social_links,
        previewId: updatedSection.content.previewId,
        language,
      });
    } else if (updatedSection.previewId === metaId) {
      dispatch(headerLinksFulfilled(updatedSection.content, language));
    }
  };
}

/**
 * @return {Object}
 */
export function updatePage(updatedPage: any) {
  let pageContent;

  if (Object.prototype.hasOwnProperty.call(updatedPage, 'content')) {
    pageContent = updatedPage.content;
  } else {
    pageContent = updatedPage;
  }

  return doUpdatePage(pageContent);
}

/**
 * Loop through json to find imageobject
 *
 * @param {Array} haystack
 * @param {String} searchProperty
 * @param {String} searchValue
 * @param {Array} replaceKeys
 * @param {String} replaceWith
 */
function deepReplace(
  haystack: string | Record<string, Record<string, string> | string>,
  searchProperty: string,
  searchValue: string,
  replaceKeys: string[],
  replaceWith: string,
) {
  Object.keys(haystack).forEach((key) => {
    if (haystack[key] !== null && typeof haystack[key] === 'object') {
      deepReplace(haystack[key], searchProperty, searchValue, replaceKeys, replaceWith);
      return;
    }
    if (key === searchProperty && haystack[searchProperty] === searchValue) {
      replaceKeys.forEach((repl) => {
        if (haystack[repl]) {
          // eslint-disable-next-line
          haystack[repl] = replaceWith;
        }
      });
    }
  });
}

/**
 * @return {Object}
 */
export function updateImage(updatedImage, pageContent) {
  const { contentAreas } = pageContent;
  const timeStamp = new Date().getTime();
  const updatedImagePath = `${updatedImage.reference}?${timeStamp}`;

  const newContent = produce(contentAreas, (draft) => {
    deepReplace(
      draft,
      'previewId',
      updatedImage.previewId,
      ['url', 'url2x', 'url3x', 'picture'],
      updatedImagePath,
    );
  });

  return doUpdateImage(newContent);
}

/**
 * @return {Object}
 */
export function addSectionToPage(newSection: any) {
  return doAddSectionToPage(newSection);
}

export function doMoveSection(sectionPreviewId: string, direction: string) {
  return {
    type: 'page/MOVE_SECTION_ON_PAGE' as const,
    sectionPreviewId,
    direction,
    step: 1,
  };
}

/**
 * @return {Object}
 */
export function deleteSection(sectionPreviewId: string) {
  return doDeleteSection(sectionPreviewId);
}

export function doSetPage(page: any) {
  return {
    type: 'page/SET_PAGE' as const,
    page,
  };
}

export type PageAction =
  | ReturnType<typeof doUpdateSectionOnPage>
  | ReturnType<typeof doAddSectionToPage>
  | ReturnType<typeof doDeleteSection>
  | ReturnType<typeof doUpdatePage>
  | ReturnType<typeof doUpdateImage>
  | ReturnType<typeof doMoveSection>
  | ReturnType<typeof doSetPage>;

import { trackTemplate } from '../../../utils/tracking';

/**
 * Insights filter click
 * @param string text
 */
export function clickInsightsFilter(url) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Filter',
      action: 'Insights',
      label: url,
      value: '0',
    },
  });
}

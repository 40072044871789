import { useState, useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';

import { Translation } from '../../Translation/Translation';
import { fetchPrice } from './actions';
import { currentCatalogSelector } from '../../../utils/selectors/productCatalogSelectors';
import { Loader } from '../../Loader/Loader';

interface ProductPriceProps {
  articleId: string | null;
}

function ProductPrice({ articleId = null }: Readonly<ProductPriceProps>) {
  const [price, setPrice] = useState('');
  const state = useStore();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const currentCatalog = useSelector(currentCatalogSelector);

  useEffect(() => {
    if (articleId && currentCatalog) {
      setLoading(true);
      fetchPrice(articleId, currentCatalog, state)
        .then((fetchedPrice) => {
          setPrice(Number(fetchedPrice).toFixed(2));
          setError(false);
          setLoading(false);
        })
        .catch(() => {
          setError(true);
          setLoading(false);
        });
    } else {
      setError(true);
      setLoading(false);
    }
  }, [articleId, currentCatalog, state]);

  if (loading) {
    return <Loader size="small" />;
  }

  if (error) {
    return (
      <span>
        <Translation id="web20_pdp_price_error" />
      </span>
    );
  }

  return price ? (
    <span>
      {price} <Translation id="web20_checkout_country_default_currency" />
    </span>
  ) : null;
}

export default ProductPrice;

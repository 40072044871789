import key from 'weak-key';

// components
import VideoSlider from '../Slider/VideoSlider/VideoSlider';
import ChannelCarousel from '../ContentElementsGdds/video-channel/channel-carousel';
import { ContentAreaPlaceholder } from '../ContentArea/ContentAreaPlaceholder';
import TableOfContents from '../TableOfContents/TableOfContents';
import { EditWrapper } from '../ContentCreator/EditWrapper';

// utils
import { TPP_TYPE_VIDEOCHANNEL } from '../ContentCreator/tppConstants';
import { TOC_DATA_VIDEOCENTER } from '../TableOfContents/tocDataAdapter';
import { useNord } from 'utils/hooks/use-nord';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { isEmpty } from 'utils/is-empty';

interface VideoGalleryProps {
  pageHeadline: string;
  showAnchor?: boolean;
  previewId?: string;
  channels: any[];
  metaData?: MetaData;
}

export default function VideoGallery({
  pageHeadline,
  showAnchor = false,
  previewId,
  channels,
  metaData,
}: Readonly<VideoGalleryProps>) {
  const isPreview = useIsPreview();
  const isNordics = useNord();
  const SliderComponent = isNordics ? ChannelCarousel : VideoSlider;

  return (
    <>
      {showAnchor && (
        <TableOfContents type={TOC_DATA_VIDEOCENTER} headline={pageHeadline} content={channels} />
      )}
      <div className={classNameBuilder(!isNordics && 'grid-container', 'c-video-center')}>
        {!isEmpty(channels) &&
          channels
            .filter((channel) => isPreview || !isEmpty(channel.videos))
            .sort((channelA, channelB) => channelA.index - channelB.index)
            .map((channel) => {
              const replaceSlashInPathFnc = (pathname) =>
                pathname.slice(-1) === '/' ? pathname.replace(/\/$/, '') : pathname;
              const url = `${replaceSlashInPathFnc(channel.url)}/?channel=${channel.id}`;
              return (
                <EditWrapper
                  editType={TPP_TYPE_VIDEOCHANNEL}
                  previewId={channel.previewId}
                  key={key(channel)}
                >
                  <SliderComponent
                    title={channel.title}
                    url={url}
                    videos={channel.videos}
                    metaData={metaData}
                  />
                </EditWrapper>
              );
            })}
        {isEmpty(channels) && isPreview && (
          <ContentAreaPlaceholder
            editType={TPP_TYPE_VIDEOCHANNEL}
            contentType="videos"
            previewId={`custom:newSection:${previewId}:videos`}
            editArea="Video Channels"
          />
        )}
      </div>
    </>
  );
}

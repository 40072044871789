import { useEffect, useState } from 'react';

const SCRIPT_ID = 'gigya-script';

export function useScript(config: { scriptUrl?: string; apiKey?: string; locale: string }) {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (config.scriptUrl && config.apiKey && config.locale) {
      const [lang] = config.locale.split('_');

      const script: HTMLScriptElement = document.createElement('script');

      script.id = SCRIPT_ID;
      script.src = `${config.scriptUrl}?apiKey=${config.apiKey.trim()}&lang=${lang}`;
      script.async = true;
      script.type = 'text/javascript';
      script.onload = () => setIsLoaded(true);
      document.head.appendChild(script);
    }
    return () => {
      setIsLoaded(false);
      document.querySelector(`#${SCRIPT_ID}`)?.remove();
    };
  }, [config.scriptUrl, config.apiKey, config.locale]);

  return { isLoaded };
}

export const getLanguage = (lang: string): string => {
  const langParams = lang.split('_');
  return langParams.length > 1 ? langParams[0] : lang;
};

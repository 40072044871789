import styled from 'styled-components';
import { Container, Row, Col } from '@geberit/gdds';

export const StyledConsentContainer = styled(Container)`
  height: 30rem;
  position: relative;
  display: flex;
  z-index: 1;

  &:before {
    background-color: ${({ theme }) => theme.colors.grey15};
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
  }

  ${Row} {
    z-index: 1;
    flex: 1;
  }

  @media ${({ theme }) => theme.device.medium} {
    height: 34.5rem;
  }

  @media ${({ theme }) => theme.device.large} {
    height: 43.25rem;
    margin: 5rem auto;
    max-width: 75rem;
    padding: 0;
  }
`;

export const StyledCentredCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  & .consentBox {
    color: ${({ theme }) => theme.colors.white};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 2rem;
      text-align: center;

      // category link in consent box
      a {
        color: currentColor;
        white-space: nowrap;
        font-size: 1rem;
        font-weight: 500;

        :before {
          font-size: 1rem;
          top: -1px;
        }

      }

      // data privacy link in consent box
      span a {
        color: currentColor !important; // necessary
        display: inline !important; // necessary

        :hover {
          color: currentColor;
          text-decoration: underline;
        }

        span {
          border-bottom: 0 !important; // necessary
        }
      }
    }
  }
`;

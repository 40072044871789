import { useRouter } from 'next/navigation';
import { Breadcrumb, Container, Row, Col } from '@geberit/gdds';

// style
import styles from './breadcrumb.module.scss';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { breadcrumbTrackingAction } from '../Navigation/trackingActions';
import { gridSizes, buildSize } from 'utils/gridSize';
import { useNord } from 'utils/hooks/use-nord';
import { classNameBuilder as classNamesBuilder } from 'utils/classNameBuilder';
import { truncateAtSpecificChar } from 'utils/truncate';
import { isEmpty } from 'utils/is-empty';
import { useIsTablet } from 'components/App/SizeProvider';
import { decodingContent } from 'utils/decodingContent';

interface GddsBreadcrumbProps {
  items: Partial<Breadcrumb>[];
  className?: string;
}

export default function GddsBreadcrumb({ items, className }: Readonly<GddsBreadcrumbProps>) {
  const isTablet = useIsTablet();
  const track = useTracking();
  const router = useRouter();
  const isNordics = useNord();

  if (isEmpty(items)) return null;

  items[0].label = '';

  function truncateItems(items) {
    const allowedChars = isTablet ? 24 : 40;
    const dottedItems = items.map((item, index) => {
      if (index !== items.length - 1 && item.label.length > allowedChars) {
        return {
          ...item,
          label: truncateAtSpecificChar(item.label, allowedChars),
        };
      } else return item;
    });

    return dottedItems;
  }

  const breadcrumbItems = isNordics ? truncateItems(items) : items;

  const onClick = (e) => {
    e.preventDefault();

    track.trackEvent(breadcrumbTrackingAction(e.currentTarget.text));
    router.push(e.currentTarget.pathname);
  };

  return (
    <Container
      className={classNamesBuilder(
        styles.breadcrumb,
        className ?? '',
        styles.gdds,
        isNordics && 'nord',
        isNordics && styles.nord,
      )}
      maxContentWidth={buildSize(gridSizes[isNordics ? 'gddsFullGrid' : 'full'])}
    >
      <Row>
        <Col>
          <Breadcrumb
            items={breadcrumbItems.map((i) => ({
              text: decodingContent(i.label),
              url: !i.breadcrumbDisabled && i.url,
              onClick,
            }))}
          />
        </Col>
      </Row>
    </Container>
  );
}

import { useSelector } from 'react-redux';

// components
import { Translation } from '../Translation/Translation';

// utils
import { selectSearchQuery } from '../../utils/selectors/searchSelectors';

function NoResults() {
  const query = useSelector(selectSearchQuery) || '';

  return (
    <>
      <p className="page-infotext">
        <Translation id="web20_search_no-matches" placeholder={{ query }} />
      </p>
      <h4>
        <Translation id="web20_search_suggestions" />
      </h4>
      <ul>
        <li>
          <Translation id="web20_search_spelled-correctly" />
        </li>
        <li>
          <Translation id="web20_search_different-keywords" />
        </li>
        <li>
          <Translation id="web20_search_general-keywords" />
        </li>
        <li>
          <Translation id="web20_search_less-keywords" />
        </li>
      </ul>
    </>
  );
}

export default NoResults;

import isEmpty from 'lodash.isempty';

/**
 * Get subcategory name for image Alt
 *
 * @returns {*[]}
 */
export function imageAltSubcategory(categories) {
  return (categories || []).filter((cat) => !isEmpty(cat.name)).map((cat) => cat.name);
}

/**
 * Get image alt suffix
 *
 * @returns {string}
 */
export function getImageAltSuffix(imageAltSuffix) {
  return !isEmpty(imageAltSuffix) && !imageAltSuffix.includes('web20')
    ? ` - ${imageAltSuffix}`
    : '';
}

/**
 * Create ImageAlt Prefix with product name
 *
 * @returns {string}
 */
export function imageAlt(imageAltPrefix) {
  return !isEmpty(imageAltPrefix) && !imageAltPrefix.includes('web20') ? `${imageAltPrefix} ` : '';
}

/**
 * Create image Alt for technical drawings and image in article tables
 *
 * @returns {string}
 */
export function imageAltDrawings(productNr, categories, imageAltSuffix) {
  return ` - ${productNr} - ${imageAltSubcategory(categories)}${getImageAltSuffix(imageAltSuffix)}`;
}

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from '@geberit/gdds';

// styles
import styles from './search-softcontent.module.scss';

// components
import SoftContentFilters from './softcontent-filters';
import SoftContentItem from './softcontent-item';
import SearchLoadMore from './search-loadmore';

// utils
import { setSearchFilter, setSearchOffset } from '../../Search/actions';

type Props = {
  softContent: {
    aggregations: {
      indexer_filetype: {
        value: string;
        count: number;
      }[];
    };
    request: {
      filters: string[];
      num: string[];
      offset: string[];
      segment: string[];
    };
    results: {
      id: string;
      snippet: string[];
      title: string;
      url: string;
    }[];
    totalResults: number;
  };
};

export default function SearchSoftContent({ softContent }: Readonly<Props>) {
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);

  const [selectedContentFilter, setSelectedContentFilter] = useState('all');

  useEffect(() => {
    dispatch(setSearchOffset(0));
    dispatch(setSearchFilter(selectedContentFilter === 'all' ? '' : selectedContentFilter));
  }, [selectedContentFilter]);

  return (
    <Row className={styles.softcontentResult}>
      <SoftContentFilters
        request={softContent?.request}
        aggregations={softContent?.aggregations}
        setSelectedContentFilter={setSelectedContentFilter}
      />
      <Col size={[4, 8, 6]}>
        {softContent?.results?.map((result) => (
          <SoftContentItem
            key={result.id}
            title={result.title}
            url={result.url}
            snippet={result.snippet?.[0]}
          />
        ))}
        <SearchLoadMore
          totalResults={softContent?.totalResults || 0}
          showingAmount={12 + offset}
          handleNext={() => {
            const nextOffset = offset + 12;
            setOffset(nextOffset);
            dispatch(setSearchOffset(nextOffset));
          }}
        />
      </Col>
    </Row>
  );
}

import axios from 'axios';

// utils
import getEndpoint from 'utils/endpoints';
import { Log } from 'services/log';

export async function fetchOrders(lang, ciamId, page, perPage, state) {
  try {
    const endpoint = `${getEndpoint(
      'webshop',
      state,
    )}/customer/ciam/${ciamId}/orders?page=${page}&limit=${perPage}&lang=${lang}`;

    const result = await axios.get(endpoint);
    return result.data;
  } catch (e) {
    Log.error('Failed to fetch orders', e);
    return [];
  }
}

export async function fetchOrder(lang, orderId, state) {
  try {
    const endpoint = `${getEndpoint('webshop', state)}/order/${orderId}/details?lang=${lang}`;

    const result = await axios.get(endpoint);
    return result.data;
  } catch (e) {
    Log.error('Failed to fetch order', e);
    return [];
  }
}

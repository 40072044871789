import { useSelector } from 'react-redux';

// styles
import styles from './social-share.module.scss';

// components
import { SearchIndexOff } from 'components/SearchIndex/SearchIndexOff';
import SocialShareIcon from './share-icon';
import CopyIcon from './copy-icon';

// utils
import { baseURLSelector } from 'utils/selectors/globalsSelectors';
import SOCIAL_SHARE_DATA from 'components/SocialShare/constants';
import { isEmpty } from 'utils/is-empty';

type SocialShareViewProps = {
  metaData: any;
  types: string[] | null;
  headline: JSX.Element | null;
  hasCopyLink: boolean;
  hashtags?: string[];
};

function SocialShareView({
  hashtags,
  metaData,
  types = null,
  headline = null,
  hasCopyLink = false,
  ...props
}: Readonly<SocialShareViewProps>) {
  const socialSharingTypes = SOCIAL_SHARE_DATA.filter((icon) => types?.includes(icon.symbol));
  const baseURL = useSelector(baseURLSelector);

  if (isEmpty(types)) {
    return null;
  }

  return (
    <SearchIndexOff>
      <div className={styles.socialShare}>
        {headline && <span className={styles.socialShareHeadline}>{headline}</span>}
        <div className={styles.socialShareIcons}>
          {socialSharingTypes.map((item) => (
            <SocialShareIcon
              key={`${item.symbol}-icon`}
              baseUrl={item.baseUrl}
              url={metaData.url || `${baseURL}${metaData.canonical}`}
              symbol={item.symbol}
              hashtags={hashtags}
              title={metaData.title ?? ''}
              {...props}
            />
          ))}
          {hasCopyLink && <CopyIcon url={metaData.url || `${baseURL}${metaData.canonical}`} />}
        </div>
      </div>
    </SearchIndexOff>
  );
}

export default SocialShareView;

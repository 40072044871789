/**
 * Method transforming a given filter string based on a signature format to an
 * object.
 *
 * Filter chaining:
 *  metatag1:Fachkunde --> metatag1 EQUALS Fachkunde
 *  metatag1:Fachkunde|Client professionnel;Client privé --> metatag 1 EQUALS
 *    Fachkunde OR Client professionnel;Client privé
 *  metatag1:Fachkunde.metatag2:Sanitär --> metatag 1 EQUALS Fachkunde AND
 *    metatag 2 EQUALS Sanitär
 *
 * The operators can be chained freely to construct complexe filter scenarios.
 *
 * @example
 *  from:
 *  filter1:Fachkunde|Client professionnel;Client privé.filter2:Systèmes
 *  d'évacuation / drainage du sol;Gratte-ciel;
 *
 *  to:
 *  {
 *    filter1: [Fachkunde, Client professionnel;Client privé],
 *    filter2: [Systèmes d'évacuation / drainage du sol;Gratte-ciel;]
 *  }
 * @export
 * @param {string} filterString Filter string taken from the URL.
 * @returns {object} object holding all selected filters in the format of
 *  key: filter id / value: array of selected filter values
 */
function extractFilters(filterString: string) {
  const decodedFilterString = decodeURIComponent(decodeURIComponent(filterString));

  const filter: Record<string, string[]> = {};

  if (decodedFilterString.indexOf('.') !== -1) {
    // The dot represents a chain of filters. Therefore there must be more than
    // one selected filter group.
    // F.e. filter1:Client professionnel;Client privé|Fachkunde.filter2:test
    const filterArray = decodedFilterString.split('.');
    filterArray.forEach((singleFilter) => {
      // filter1:Client professionnel;Client privé|Fachkunde
      // filter2:test
      const [key, values] = singleFilter.split(':');
      filter[key] = values.split('|');
    });
  } else if (decodedFilterString.indexOf('|') !== -1) {
    // The filter is selected only represents one filter panel. The OR values
    // are seperated by pipes.
    // F.e. filter1:Client professionnel;Client privé|Fachkunde
    // filter1:Client professionnel;Client privé|Fachkunde
    const [key, values] = decodedFilterString.split(':');
    filter[key] = values.split('|');
  } else {
    // Only one filter is selected belonging to one filter panel.
    const [key, value] = decodedFilterString.split(':');
    filter[key] = [value];
  }

  return filter;
}

/**
 * Internal function being used to concat different filter definitions to a
 * single filter string. It iterates the filterObject keys and constructs the OR
 * filter chain first before combining it with the already existing parameter.
 *
 * @param {object} filterObject Object of filters containing
 * @returns {string} String of chained parameters to represent all filters with
 *  their AND and OR relations.
 */
function chainOperators(filterObject = {}) {
  let urlParameter = '';

  Object.keys(filterObject).forEach((metatagFilter, filterIndex) => {
    const ORFilter =
      filterObject[metatagFilter].length !== 0
        ? filterObject[metatagFilter].reduce((prev, curr) => `${prev}|${curr}`)
        : '';

    urlParameter = `${urlParameter}${metatagFilter}:${ORFilter}`;

    if (filterIndex < Object.keys(filterObject).length - 1) {
      urlParameter = `${urlParameter}.`;
    }
  });

  return urlParameter.replace(/ /g, '%20');
}

export { extractFilters, chainOperators };

import { useEffect, useRef, useState, useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import { useStore } from 'react-redux';

// types
import { ListResult, Material, Media, MediaType, SearchResult } from '../types/mynewsdesk.types';
import {
  ExcutionResult,
  FetchMaterialsResult,
  MaterialsGenerator,
  Reducer,
} from '../types/press-portal.types';

// utils
import { sortByDate } from '../utils';
import { listGenerator, listResultsReducder } from './helpers';

// constants
import { MATERIAL_TYPES } from '../constants';
import { useApiKey } from './use-api-key';

function useFetchMaterialsByTag<T extends Material | Media>({
  tag,
  locale,
}: {
  tag?: string;
  locale: string;
}): FetchMaterialsResult<T> {
  const buffer = useRef(new Set<T>());
  const loading = useRef(false);
  const [loadable, setLoadable] = useState<boolean>(false);
  const [materials, setMaterials] = useState<T[] | null>([]);
  const generators = useRef<MaterialsGenerator<ListResult | SearchResult>>([]);
  const [error, setError] = useState<string | null>(null);
  const state = useStore();
  const apiKey = useApiKey();

  const consumeGenerators = useCallback(async (reducer: Reducer) => {
    const resultsPromises = generators.current.map((generator) => generator.next());
    const results = await Promise.all(resultsPromises);
    const result: ExcutionResult = results.reduce(reducer, { done: true, items: [] });
    const allMaterials = [...result.items, ...Array.from(buffer.current)].sort(sortByDate);
    setMaterials((mat) => (mat ? [...mat, ...(allMaterials as T[])] : (allMaterials as T[])));
    setLoadable(!result.done);
  }, []);

  const buildGenerators = useCallback(
    async (key: string) => {
      const mediaTypes = MATERIAL_TYPES.flatMap(([_, ...rest]) => rest).map(
        (e) => e.value as MediaType,
      );
      generators.current = mediaTypes.map((type_of_media: MediaType) =>
        listGenerator(
          {
            type_of_media,
            tags: tag,
            limit: 100,
            locale: locale.toLowerCase(),
            pressroom: locale,
            state,
          },
          key,
        ),
      );
    },
    [locale, state, tag],
  );

  const handleGenerators = useCallback(async () => {
    if (loading.current) return;

    loading.current = true;
    setError(null);
    try {
      await consumeGenerators(listResultsReducder);
    } catch (err: unknown) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        setError(`api error: ${error.message}`);
      } else {
        setError(`internal error: ${error.message}`);
      }
    } finally {
      loading.current = false;
    }
  }, [consumeGenerators]);

  useEffect(() => {
    if (!tag || loading.current || !apiKey) return;

    setMaterials([]);
    buildGenerators(apiKey);
    handleGenerators();
  }, [apiKey, buildGenerators, handleGenerators, tag]);

  return {
    total: materials?.length || 0,
    loading: loading.current,
    error,
    materials,
    loadMore: handleGenerators,
    loadable: loadable,
  };
}

export { useFetchMaterialsByTag };

import styled from 'styled-components';

import { StyledConsentContainer } from '../consent';

export const LogoWrapper = styled.div`
  display: none;

  @media print {
    margin-bottom: 1.5rem;
    display: initial;
  }
`;

export const Grid = styled.div`
  .logoWrapper {
    display: none;
    margin-bottom: 1.5rem;
  }

  @media print {
    @page {
      size: portrait;
      margin: 2.75rem 2rem;
    }

    grid-template-areas:
      'logo logo'
      'header header'
      'details details'
      'categories categories'
      'map map'
      'note-wrapper note-wrapper' !important;
  }

  margin: 1.5rem 0 6.5rem 0;
  display: grid;
  position: relative;
  grid-template-areas:
    'header'
    'map'
    'details'
    'opening-hours'
    'categories'
    'buttons'
    'accordions'
    'tiles'
    'note-wrapper';

  a {
    :before {
      content: none;
    }
  }

  p {
    margin: 0;
    max-width: unset;
  }

  @media ${({ theme }) => theme.device.large} {
    margin: 2.5rem auto 7.5rem;
    grid-template-columns: 5fr 1fr;
    grid-template-areas:
      'header header'
      'details opening-hours'
      'categories opening-hours'
      'buttons opening-hours'
      'map map'
      'accordions accordions'
      'tiles tiles'
      'note-wrapper note-wrapper';
  }
`;

export const MapPlaceholder = styled.div`
  grid-area: map;
  position: relative;
  height: 22.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  @media print {
    margin-top: 5rem !important;
    margin-bottom: 2rem !important;
  }

  @media ${({ theme }) => theme.device.large} {
    margin-top: 5.5rem;
    margin-bottom: 5rem;
  }
`;

export const MapWrapper = styled.div`
  @media print {
    width: 100% !important;
    margin-left: unset !important;
    position: static !important;
  }

  --map-width: calc(100vw - var(--scrollbar-width));
  position: absolute;
  height: 22.5rem;
  width: 100vw;
  margin-left: -1rem;

  ${StyledConsentContainer} {
    height: 100%;
  }

  @media ${({ theme }) => theme.device.medium} {
    margin-left: -1.5rem;

    ${StyledConsentContainer} {
      height: 100%;
    }
  }

  @media ${({ theme }) => theme.device.large} {
    width: var(--map-width);
    margin-left: calc(calc(var(--map-width) - 100%) / -2);

    ${StyledConsentContainer} {
      height: 100%;
      margin: 0;
      max-width: unset;
    }
  }
`;

export const OpeningHours = styled.div`
  @media print {
    display: none;
  }

  grid-area: opening-hours;
  display: none;

  @media ${({ theme }) => theme.device.large} {
    justify-self: flex-end;
  }

  > p {
    margin-bottom: 0.5rem;
  }

  .hours {
    display: flex;
    column-gap: 2.5rem;

    p {
      margin: 0;
    }
  }
`;

// styles
import styles from './counter.module.scss';

// components
import { BUTTON_TYPES, Button } from '../Button/Button';

interface CounterProps {
  countInput: string | number;
  decreaseItem: () => void;
  increaseItem: () => void;
  setItem: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string | null;
  onInputBlur?: () => void;
}

function Counter({
  className = null,
  countInput,
  decreaseItem,
  increaseItem,
  onInputBlur,
  setItem,
}: Readonly<CounterProps>) {
  const decresableDisabledClass = Number(countInput) <= 1 ? styles.disabled : '';
  const increasableDisabledClass = Number(countInput) >= 99 ? styles.disabled : '';

  return (
    <div className={`${styles.counter} ${className}`}>
      <Button
        symbol="minus"
        type={BUTTON_TYPES.PLAIN}
        className={`${styles.buttonDecrease} ${decresableDisabledClass}`}
        disabled={!!decresableDisabledClass}
        onClick={decreaseItem}
      />
      <input type="text" value={countInput} onChange={setItem} onBlur={onInputBlur} />
      <Button
        symbol="plus"
        type={BUTTON_TYPES.PLAIN}
        className={`${styles.buttonIncrease} ${increasableDisabledClass}`}
        disabled={!!increasableDisabledClass}
        onClick={increaseItem}
      />
    </div>
  );
}

export default Counter;

import { InnerHtml } from '@geberit/gdds';
import Link from 'next/link';

// styles
import styles from './teaser.module.scss';

// components
import { Image } from 'components/image/image';
import { Headline, Formats, FontWeights } from 'components/ContentElements/Headline';
import { CmsLink } from 'components/Link/CmsLink';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { useKolo } from 'utils/hooks/use-kolo';

interface TeaserLargeProps {
  content: string;
  headline: string;
  subline: string;
  link: {
    label: string;
    url: string;
  };
  isMobile: boolean;
  imageForLargeObject: any;
  imageForLargeObjectMobile: any;
  imageAltText: string;
  side: string;
  animated: boolean;
  visible: boolean;
}

function TeaserLarge({
  content,
  headline,
  subline,
  link,
  isMobile,
  imageForLargeObject,
  imageForLargeObjectMobile,
  imageAltText,
  side = 'left',
  animated = false,
  visible = true,
}: Readonly<TeaserLargeProps>) {
  const isKolo = useKolo();

  const imageObj =
    isMobile && imageForLargeObjectMobile.url ? imageForLargeObjectMobile : imageForLargeObject;
  const cmsLinkObj = {
    text: link?.label,
    target: link?.url,
    showArrow: true,
    window: '_self',
    type: 'internal_link',
  };

  // css zoom (we can't use css because transform doesn't take absolute values)
  const imageMouseOver = (e) => {
    if (animated) {
      const factor = (e.target.offsetWidth + 16) / (e.target.offsetWidth || 1);
      e.target.style.transform = `scale(${factor})`;
    }
  };
  const imageMouseOut = (e) => {
    if (animated) {
      e.target.style.transform = 'initial';
    }
  };

  return (
    <div
      className={classNameBuilder(
        styles.teaserLarge,
        styles[side],
        animated && styles.animated,
        visible && styles.visible,
      )}
    >
      <div className={styles.imageWrapper}>
        <Link href={link?.url}>
          {imageObj?.url ? (
            <Image
              data={imageObj}
              alt={imageAltText}
              title={imageAltText}
              onMouseOver={imageMouseOver}
              onMouseOut={imageMouseOut}
            />
          ) : (
            // workaround to keep the aspect ratio without image
            <svg viewBox="0 0 4 3"></svg>
          )}
        </Link>
      </div>
      <div className={styles.teaserContentWrapper}>
        <div className={styles.contentInner}>
          <Headline
            title={headline}
            subtitle={subline}
            titleFontWeight={FontWeights.bold}
            subtitleFontWeight={isKolo ? FontWeights.bold : undefined}
            format={Formats.h1}
            tag={Formats.h4}
            isUppercase
          />
          <InnerHtml as="span" content={content} />
          {link?.label && link?.url && (
            <CmsLink
              link={cmsLinkObj}
              alignByContent="left"
              className={styles.link}
              standardFontSize={false}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TeaserLarge;

import { useCallback } from 'react';

// utils
import useFoodFieldsEnabled from './use-food-fields-enabled';
import { VALIDATION_FIELD_RULES } from './validationFields';
import { useTranslationFunction } from 'utils/hooks/use-translations';

export const useIsMandatory = () => {
  const foodFieldsEnabled = useFoodFieldsEnabled();

  return useCallback(
    (field) => {
      return VALIDATION_FIELD_RULES.mandatory
        .filter((key) => {
          if (!foodFieldsEnabled) {
            return key !== 'catering';
          }
          return true;
        })
        .includes(field);
    },
    [foodFieldsEnabled],
  );
};

const usePrintIsMandatory = () => {
  const isMandatory = useIsMandatory();

  return useCallback(
    (field, character = '*') => (isMandatory(field) ? character : ''),
    [isMandatory],
  );
};

export const useCreateFormLabel = () => {
  const translate = useTranslationFunction();
  const printIsMandatory = usePrintIsMandatory();
  return useCallback(
    (field, translation: string | undefined = undefined) => {
      const fallbackField = `campus_form_${field}`;
      return `${translate(translation ?? fallbackField)}${printIsMandatory(field)}`;
    },
    [translate, printIsMandatory],
  );
};

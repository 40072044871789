import { Col, Row } from '@geberit/gdds';
import key from 'weak-key';
import { useRouter } from 'next/navigation';

// styles
import styles from './media-tiles.module.scss';

// types
import type { MediaTilesProps } from '../types/press-portal.types';
import type { Media } from '../types/mynewsdesk.types';

// components
import { MediaTile } from './media-tile';

// utils
import { getMaterialUniqueIdentifier } from '../utils';
import { useIsMobile } from 'components/App/SizeProvider';

function MediaTiles({
  mediaList,
  fallbackImg,
  fallbackBackgroundColor,
}: Readonly<MediaTilesProps>) {
  const isMobile = useIsMobile();
  const router = useRouter();

  return (
    <Row>
      <Col>
        <div className={styles.mediaTilessWrapper}>
          {mediaList.map((media) => {
            return (
              <MediaTile
                onClick={() => {
                  const pathname = `?page=asset-detailpage&title=${getMaterialUniqueIdentifier(
                    media as Media,
                  )}`;
                  router.push(pathname, undefined, { shallow: true });
                }}
                key={key(media)}
                isMobile={isMobile}
                media={media as Media}
                fallbackImg={fallbackImg}
                fallbackBackgroundColor={fallbackBackgroundColor}
              />
            );
          })}
        </div>
      </Col>
    </Row>
  );
}

export default MediaTiles;

import { Field } from 'formik';

// styles
import styles from './formikSelectStyles.module.scss';

type FormikSelectProps = {
  children: React.ReactNode;
  setFieldValue: (name: string, value: string) => void;
  name: string;
  value?: string;
  onChange?: (value: string) => void;
};

const FormikSelect = ({
  name,
  children,
  value = '',
  onChange = () => {},
  setFieldValue = () => {},
}: Readonly<FormikSelectProps>) => (
  <div className={styles.selectWrapper}>
    <Field
      id="ads"
      component="select"
      name={name}
      value={value}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
        if (onChange && typeof onChange === 'function') onChange(e.target.value);
      }}
    >
      {children}
    </Field>
    <i className="web20-icon-chevron-large-down"></i>
  </div>
);

export default FormikSelect;

import { useState, useRef, useEffect } from 'react';
import key from 'weak-key';

// types
import type { ArticleListingArticle } from 'components/Product/DetailArticleListing/types';
import type { AttributeList, ProductComparisonArticle, ProductComparisonAttribute } from './types';

// components
import SliderNavigation from 'components/SliderNavigation/SliderNavigation';
import { Translation } from 'components/Translation/Translation';
import ImageThumbnail from 'components/Product/DetailArticleListing/ImageThumbnail';
import Actions from 'components/Product/DetailArticleListing/Actions';
import { Image } from 'components/image/image';
import { ICON_TYPES, Icon } from 'components/Icon/Icon';
import { CmsLink } from 'components/Link/CmsLink';
import {
  imageAlt as generateImageAlt,
  imageAltDrawings as generateImageAltDrawings,
} from 'components/Product/ProductImageAlt';
import { ImageEditWrapper } from 'components/ContentCreator/ImageEditWrapper';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { decodingContent } from 'utils/decodingContent';
import { useIsomorphicLayoutEffect } from 'utils/hooks/use-isomorphic-layout-effect';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';

const mapOrderMetaToTranslation = {
  articleNumber: 'web20_webshop_basket_art_no',
  quantity: 'web20_webshop_basket_amount',
  shortDescription: 'web20_webshop_basket_product',
};

interface ResponsiveTableViewProps {
  getLength: (attList: AttributeList) => number;
  isComparison?: boolean;
  attributesList?: AttributeList;
  articlesList?: (ProductComparisonArticle | ArticleListingArticle)[];
  categories?: any;
  grayTable?: boolean;
  hasActionColumn?: boolean;
  productKey?: string;
  orderData?: any;
  isOrderHistory?: boolean;
}

export function ResponsiveTableView({
  getLength,
  isComparison = false,
  attributesList = [],
  articlesList = [],
  categories,
  grayTable = false,
  hasActionColumn = false,
  productKey,
  orderData = [],
  isOrderHistory = false,
}: Readonly<ResponsiveTableViewProps>) {
  const [page, setPage] = useState(null);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const translate = useTranslationFunction();
  const imageAltPrefix = translate('web20_products_image_of');
  const imageAltSuffix = translate('web20_products_imagealt_suffix');

  const getNumbOfAllColumns = (attList: AttributeList) => {
    if (isOrderHistory) return 3;
    if (isComparison) return attList.length + 1;

    const add = hasActionColumn ? 3 : 2;
    return attList.length + add;
  };

  const getNumbOfAllVisibleColumns = (attList: AttributeList) => {
    const setVisibleColsLength = getLength(attList);
    const add = hasActionColumn ? 3 : 2;

    if (isOrderHistory) return 2;

    return setVisibleColsLength + add;
  };

  useEffect(() => {
    updateCellHeight();
  }, [page]);

  useEffect(() => {
    window.addEventListener('resize', updateCellHeight);

    return () => {
      window.removeEventListener('resize', updateCellHeight);
    };
  }, []);

  useIsomorphicLayoutEffect(() => {
    updateCellHeight();
  });

  const updateCellHeight = () => {
    if (tableRef.current) {
      setCellHeight();
    }
  };

  if (isComparison) {
    // sort list regarding its priority
    (attributesList as ProductComparisonAttribute[]).sort((a, b) => a.priority - b.priority);
  }

  function renderResponsiveTableColumn(nextIndex) {
    const tableColumnFixed = (
      <>
        <div className="pd-table__column__head" data-cell-number={0}>
          {isComparison ? (
            <Translation id="web20_productcomparison_header" />
          ) : (
            <InnerHtml as="span" content="&nbsp;" />
          )}
        </div>
        {articlesList.map((article, index) => {
          let articleKey = 'key' in article ? article.key : '';
          let description = 'description' in article ? article.description : '';
          let pictureObject = 'pictureObject' in article ? article.pictureObject : null;
          let link = 'link' in article ? article.link : null;

          const imageAlt =
            `${generateImageAlt(imageAltPrefix)}${description}` +
            `${generateImageAltDrawings(articleKey, categories, imageAltSuffix)}`;

          const setImageWrapper = (children) => {
            if (!isEmpty(pictureObject) && pictureObject.uid) {
              const { uid, previewId, format, remote, format2x, format3x } = pictureObject;

              return (
                <ImageEditWrapper
                  previewId={previewId}
                  format={format}
                  format2x={format2x}
                  format3x={format3x}
                  isRemoteMedia={remote}
                  key={uid}
                >
                  {children}
                </ImageEditWrapper>
              );
            }

            return children;
          };

          return (
            <div data-cell-number={index + 1} className="pd-table__column__cell" key={key(article)}>
              <div
                className="pd-table__column__cell__fixed-wrapper"
                key={`${key(article)}-wrapper`}
              >
                {isComparison ? (
                  <>
                    {setImageWrapper(
                      <Image
                        key={`${key(article)}-image`}
                        data={pictureObject}
                        alt={!isComparison ? decodingContent(imageAlt) : ''}
                        title={!isComparison ? decodingContent(imageAlt) : ''}
                        staticImage
                        external
                      />,
                    )}
                    {link && <CmsLink link={link} key={`${key(article)}-link`} />}
                  </>
                ) : (
                  <ImageThumbnail
                    article={article}
                    imgAlt={decodingContent(imageAlt)}
                    title={decodingContent(imageAlt)}
                  />
                )}
              </div>
            </div>
          );
        })}
        {orderData?.map((article, index) => {
          const imageAlt = `${generateImageAlt(imageAltPrefix)}${article.productName}`;

          return (
            <div data-cell-number={index + 1} className="pd-table__column__cell" key={key(article)}>
              <div
                className="pd-table__column__cell__fixed-wrapper"
                key={`${key(article)}-wrapper`}
              >
                <div style={{ height: 50, width: 50 }}>
                  {article.imageUri && (
                    <Image
                      key={`${key(article)}-image`}
                      src={article.imageUri}
                      alt={decodingContent(imageAlt)}
                      title={decodingContent(imageAlt)}
                      staticImage
                      external
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );

    // add a empty placeholder to array for art no
    let columns = isComparison ? attributesList : [{}, ...attributesList];
    if (isOrderHistory) columns = ['articleNumber', 'shortDescription', 'quantity'];
    const dynamicColumns = columns.map((attributeMeta, index) => (
      <div
        className="pd-table__column pd-table__column--middle"
        style={{ width: `${100 / getNumbOfAllColumns(attributesList)}%` }}
        key={key(isOrderHistory ? { attributeMeta } : attributeMeta)}
      >
        <div
          className="pd-table__column__head"
          key={`${key(isOrderHistory ? { attributeMeta } : attributeMeta)}-head`}
          data-cell-number={0}
        >
          {!isOrderHistory && isComparison && !isEmpty(attributeMeta.pictureObject.url) && (
            <Image
              key={key(attributeMeta)}
              data={attributeMeta.pictureObject}
              alt={attributeMeta.name}
              title={attributeMeta.name}
              staticImage
              external
            />
          )}
          {!isOrderHistory && index === 0 && !isComparison ? (
            <span key={`${key(attributeMeta)}-art-no`}>
              <Translation
                id="web20_product_articles_art_no"
                key={`${key(attributeMeta)}-art-no-translation`}
              />
            </span>
          ) : (
            <span key={`${key(isOrderHistory ? { attributeMeta } : attributeMeta)}-name`}>
              {isOrderHistory ? (
                <Translation id={mapOrderMetaToTranslation[attributeMeta]} />
              ) : (
                attributeMeta.name
              )}
            </span>
          )}
        </div>
        {articlesList.map((article, i) => {
          const attribute = () => {
            if (!isComparison) {
              const findMatch = article.attributeValues.find(
                (item) => item.key === attributeMeta.key.replace('_display', ''),
              );
              if (findMatch && findMatch.renderReact === true) {
                return findMatch.value;
              }
              return (
                findMatch && (
                  <InnerHtml as="span" content={findMatch.value} key={attributeMeta.key} />
                )
              );
            }

            const hasHit =
              article?.attributeValues?.some((item) => item.value === attributeMeta.name) ?? false;

            return (
              hasHit && <Icon symbol="close" key={`${key(article)}-icon`} type={ICON_TYPES.WEB20} />
            );
          };

          return (
            <div className="pd-table__column__cell" key={key(article)} data-cell-number={i + 1}>
              {index === 0 && !isComparison ? article.key : attribute()}
            </div>
          );
        })}

        {orderData &&
          orderData.map((item, i) => (
            <div key={key(item)} className="pd-table__column__cell" data-cell-number={i + 1}>
              <span>
                {attributeMeta === 'quantity'
                  ? parseInt(item[attributeMeta], 10)
                  : item[attributeMeta]}
              </span>
            </div>
          ))}
      </div>
    ));

    const actionColumn = (
      <>
        <div className="pd-table__column__head" data-cell-number={0}>
          <Translation id="web20_product_articles_actions" />
        </div>
        {articlesList.map((article, index) => (
          <div className="pd-table__column__cell" key={key(article)} data-cell-number={index + 1}>
            <Actions article={article} productKey={productKey} />
          </div>
        ))}
      </>
    );

    return (
      <div style={{ overflowX: 'hidden', width: '100%', position: 'relative' }}>
        <div
          className="pd-table__column pd-table__column--fixed"
          style={{
            width: `${100 / getNumbOfAllVisibleColumns(attributesList)}%`,
          }}
        >
          {tableColumnFixed}
        </div>
        <div
          className="grid-x"
          style={{
            width: `${
              getNumbOfAllColumns(attributesList) *
              (100 / getNumbOfAllVisibleColumns(attributesList))
            }%`,
            transition: 'transform 0.5s ease-in 0s',
            transform: `translateX(${
              100 / getNumbOfAllColumns(attributesList) -
              nextIndex *
                (getLength(attributesList) + 1) *
                (100 / getNumbOfAllColumns(attributesList))
            }%)`,
          }}
        >
          {dynamicColumns}
          {hasActionColumn && (
            <div
              className="pd-table__column pd-table__column--middle"
              style={{ width: `${100 / getNumbOfAllColumns(attributesList)}%` }}
            >
              {actionColumn}
            </div>
          )}
        </div>
      </div>
    );
  }

  const setCellHeight = () => {
    let rowCount = isOrderHistory ? 3 : articlesList.length + 2;
    if (isComparison) rowCount = articlesList.length + 1;

    for (let count = 0; rowCount > count; count += 1) {
      const rowCells = [...tableRef.current.querySelectorAll(`[data-cell-number='${count}']`)];

      rowCells.forEach((element) => {
        element.style.display = 'block'; // ie11 fix
        element.style.height = 'auto';
      });

      const highestElement =
        !isEmpty(rowCells) &&
        rowCells.reduce((highest, element) =>
          element.offsetHeight < highest.offsetHeight ? highest : element,
        );
      const highestElementHeight = highestElement.offsetHeight;

      rowCells.forEach((element) => {
        element.style.display = 'flex'; // ie11 fix
        element.style.height = `${highestElementHeight + 1}px`; // +1px because ie rounds number down
      });
    }
  };

  /**
   * EDITED Calculation: all attributlist-columns / all attributlist-columns
   * shown in specific viewport
   * getNumbOfAllColumns(attributesList)-2 because there are always 2 more columns
   * added to attributlist-columns
   * getNumbOfAllVisibleColumns(attributesList)-1 because fixed column is always
   * added to attributlist-columns
   * e.g. 11(attributlist-columns) / 2(displayed attributlist-columns on mobile) = 5,..
   * = Math.ceil(5,..) = 6 dots
   */
  const addOrSubstract = isComparison || isOrderHistory ? -1 : 1;
  const numberOfDots = Math.ceil(
    (getNumbOfAllColumns(attributesList) - 1) /
      (getNumbOfAllVisibleColumns(attributesList) + addOrSubstract),
  );

  return (
    <div
      ref={tableRef}
      className={`pd-table${isComparison ? ' pd-table--comparison' : ''}${
        isOrderHistory ? ' pd-table--orderhistory' : ''
      }${grayTable ? ' pd-table--striped' : ''}`}
    >
      <SliderNavigation
        isComparison={isComparison || isOrderHistory}
        length={numberOfDots}
        onChange={setPage}
        renderResponsiveTableColumn={renderResponsiveTableColumn}
      />
    </div>
  );
}

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useStore } from 'react-redux';
import axios from 'axios';

// utils
import getEndpoint from '../endpoints';

// hooks
import { useCurrentLanguage } from './useCurrentLanguage';

export function useInsightFilters({
  selectedTopics,
  setSelectedTopics,
  selectedYears,
  setSelectedYears,
}: {
  selectedTopics: string[];
  selectedYears: string[];
  setSelectedTopics: Dispatch<SetStateAction<string[]>>;
  setSelectedYears: Dispatch<SetStateAction<string[]>>;
}) {
  const [filters, setFilters] = useState<
    | {
        years: string[];
        topics: string[];
      }
    | undefined
  >();
  const state = useStore();
  const lang = useCurrentLanguage();
  const { data, isLoading, isError } = useQuery({
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    queryKey: ['insightFilters', lang],
    queryFn: () => axios.get(`${getEndpoint('insights', state)}/filters?lang=${lang}`),
  });

  useEffect(() => {
    let topics: string[] = [];
    let years: string[] = [];

    if (data?.data) {
      // check years
      if (data.data.topics && selectedTopics.length > 0) {
        selectedTopics.forEach((topic) => {
          if (data.data.topics[topic]) {
            years = [...years, ...data.data.topics[topic]];
          }
        });
        years = [...new Set(years)];
      } else {
        years = Object.keys(data.data.years);
      }
      years.sort().reverse();

      // check topics
      if (data.data.years && selectedYears.length > 0) {
        selectedYears.forEach((year) => {
          if (data.data.years[year]) {
            topics = [...topics, ...data.data.years[year]];
          }
        });
        topics = [...new Set(topics)];
      } else {
        topics = Object.keys(data.data.topics);
      }
      topics.sort();

      // remove impossible combinations
      const filteredTopics = selectedTopics.filter((topic) => topics.includes(topic));
      if (filteredTopics.length !== selectedTopics.length) {
        setSelectedTopics(filteredTopics);
      }
      const filteredYears = selectedYears.filter((year) => years.includes(year));
      if (filteredYears.length !== selectedYears.length) {
        setSelectedYears(filteredYears);
      }
    }

    setFilters({ topics, years });
  }, [selectedTopics, selectedYears, data]);

  return { filters, error: isError, isLoading };
}

import { VideoChannel } from './reducer.types';

export const addChannel = (payload: VideoChannel) => ({
  type: 'ADD_CHANNEL' as const,
  payload,
});

export const deleteChannel = (payload: Pick<VideoChannel, 'previewId'>) => ({
  type: 'DELETE_CHANNEL' as const,
  payload,
});

export const updateChannel = (payload: VideoChannel) => ({
  type: 'UPDATE_CHANNEL' as const,
  payload,
});

export const setChannels = (payload: VideoChannel[]) => ({
  type: 'SET_CHANNELS' as const,
  payload,
});

export type VideoGalleryAction =
  | ReturnType<typeof addChannel>
  | ReturnType<typeof deleteChannel>
  | ReturnType<typeof updateChannel>
  | ReturnType<typeof setChannels>;

import { useState } from 'react';
import striptags from 'striptags';
import { Button, Typo, Divider } from '@geberit/gdds';

// styles
import styles from './cart.module.scss';

// types
import type { CartOrdersProps } from './cart.types';

// components
import { Translation } from 'components/Translation/Translation';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { FormController } from 'components/ContentElements/Form/form-controller';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { decodingContent } from 'utils/decodingContent';
import { useIsMobile } from 'components/App/SizeProvider';

function CartOrders({
  orders,
  form,
  updateOrder,
  updateAmount,
  getSessionStorage,
  onFinished,
}: Readonly<CartOrdersProps>) {
  const isMobile = useIsMobile();
  const [isSuccess, setIsSuccess] = useState(false);

  const onInputChange = (e, order) => {
    const {
      currentTarget: { value },
    } = e;
    let amount = value;

    if (value < 0) {
      amount = 0;
    } else if (value > 100) {
      amount = 100;
    }

    updateAmount({
      ...order,
      amount: parseInt(amount, 10) || '',
    });
  };

  const onInputBlur = (order) => {
    if (!order.amount) {
      updateAmount({
        ...order,
        amount: 1,
      });
    }
  };

  const renderTable = () => {
    const tableData = orders.map((order) => (
      <tr key={order.id}>
        <td>
          <InnerHtml as="span" content={order.title} />
        </td>
        <td>
          <input
            type="number"
            value={order.amount}
            onChange={(e) => onInputChange(e, order)}
            onBlur={() => onInputBlur(order)}
          />
        </td>
        <td>
          <Button
            stylingType="flat"
            symbol="Trash"
            onClick={() => {
              updateOrder(order);
            }}
          >
            <Translation id="web20_downloadcenter_delete" />
          </Button>
        </td>
      </tr>
    ));

    return (
      <table className={classNameBuilder('c-cart-orders__table', styles.orders, 'nord')}>
        <tbody>{tableData}</tbody>
        <thead>
          <tr>
            <th>
              <Translation id="web20_downloadcenter_cartmodal_cell_designation" />
            </th>
            <th>
              <Translation id="web20_downloadcenter_cartmodal_cell_amount" />
            </th>
            <th />
          </tr>
        </thead>
      </table>
    );
  };

  const renderTableMobile = () => {
    const tableData = orders.map((order, index) => (
      <>
        {index === 0 && <Divider />}
        <div className={styles.order} key={order.id}>
          <div className={styles.order__designation}>
            <Typo variant="p1">{decodingContent(order.title)}</Typo>
          </div>
          <div className={styles.order__actions}>
            <input
              type="number"
              value={order.amount}
              onChange={(e) => onInputChange(e, order)}
              onBlur={() => onInputBlur(order)}
            />
            <Button
              stylingType="flat"
              symbol="Trash"
              onClick={() => {
                updateOrder(order);
              }}
            >
              <Translation id="web20_downloadcenter_delete" />
            </Button>
          </div>
        </div>
        <Divider />
      </>
    ));

    return (
      <div className={styles.orders}>
        <Typo variant="p1" fontWeight={700}>
          <Translation id="web20_downloadcenter_cartmodal_cell_designation" />
        </Typo>
        {tableData}
      </div>
    );
  };

  const orderInformation = orders
    .map((order) => `${order.amount}x ${striptags(order.title)}`)
    .join('\r\n');

  const onSuccess = () => {
    setIsSuccess(true);
    if (typeof onFinished === 'function') onFinished();
  };

  return (
    <div>
      {!isSuccess && (isMobile ? renderTableMobile() : renderTable())}
      {!isSuccess && (
        <Typo className={styles.form__title} variant="h4" fontWeight={500}>
          <Translation id="web20_downloadcenter_your_data" />
        </Typo>
      )}
      <FormController
        {...form}
        formType="downloadCenter"
        orderInformation={orderInformation}
        getSessionStorage={getSessionStorage}
        afterSubmit={onSuccess}
        isModal
      />
    </div>
  );
}

export default CartOrders;

// types
'use client';

// components
import { ContentArea } from 'components/ContentArea/ContentArea';
import { Slider } from 'components/ContentElements/slider';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import RelatedLinks from 'components/RelatedLinks/RelatedLinks';
import SocialShare from 'components/SocialShare/SocialShare';
import TableOfContents from 'components/TableOfContents/TableOfContents';

// utils
import { TOC_DATA_CONTENTPAGE } from 'components/TableOfContents/tocDataAdapter';
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import { isEmpty } from 'utils/is-empty';
import { contentExists, useContent } from 'components/Page/page-hooks';

export function Frameless() {
  const content = useContent<{}, { heroTileItems: SliderItemProps[] }>();

  if (!contentExists(content)) {
    return null;
  }

  const {
    breadcrumbs,
    metaData,
    page: { subHeadline, headline, heroTileItems, socialSharing, showAnchor },
    contentAreas,
    relatedLinks,
  } = content;
  const hasLocatorSection =
    !isEmpty(contentAreas.content) && contentAreas.content[0].type === 'locator';

  return (
    <main>
      {!isEmpty(heroTileItems) && (
        <Slider
          items={heroTileItems}
          sliderType="above-breadcrumb"
          sliderContext="above-breadcrumb"
        />
      )}
      <Breadcrumb items={breadcrumbs} />
      <PageHeadline Format={TitleFormats.h1} title={headline} subtitle={subHeadline} pageHeadline />

      {showAnchor && !hasLocatorSection && (
        <TableOfContents
          type={TOC_DATA_CONTENTPAGE}
          headline={headline}
          content={contentAreas.content}
        />
      )}

      <ContentArea content={contentAreas.content} contentType="content" />
      {socialSharing === 'true' ? (
        <section className="grid-container">
          <SocialShare contentType="page" metaData={metaData} useInContentPage />
        </section>
      ) : null}
      <RelatedLinks
        title={relatedLinks.title}
        anchor={relatedLinks.anchor}
        categories={relatedLinks.categories}
      />
    </main>
  );
}

export const NO_FILTER_KEYS = [
  'ATT_11288',
  'ATT_11289',
  'ATT_11287',
  'ATT_10933',
  'ATT_368784',
  'ATT_10953',
  'ATT_384113',
  'ATT_384116',
  'ATT_384117',
  'ATT_384119',
];
export const IS_DIMENSION_FIELD_KEY = ['ATT_10931', 'ATT_380239', 'ATT_10966', 'ATT_10951'];
export const DIMENSIONS_TRANSLATIONS = {
  ATT_10931: 'web20_products_filters_dimension_width',
  ATT_380239: 'web20_products_filters_dimension_depth',
  ATT_10966: 'web20_products_filters_dimension_length',
  ATT_10951: 'web20_products_filters_dimension_height',
};

// max filter = 4, loop is starting at index 0 so it is 3 here
export const MAX_FILTER_LOOP = 3;
export const MAX_FILTER = 4;

export const DIMENSION_SEPARATOR = ' x ';

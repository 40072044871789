import { Col, Row, Typo } from '@geberit/gdds';

// styles
import styles from './title.module.scss';

// utils
import { useGdds } from 'utils/hooks/use-gdds';

type TitleProps = {
  title: string;
};

function Title({ title }: Readonly<TitleProps>) {
  const isGdds = useGdds();

  return (
    <Row className={styles.titleHeader}>
      <Col size={[0, 0, 2]}></Col>
      <Col size={[4, 8, 8]}>
        {title && (
          <Typo
            className={styles.headline}
            variant="h2"
            {...(!isGdds && { isUppercase: true, fontWeight: 700 })}
          >
            {title}
          </Typo>
        )}
      </Col>
      <Col size={[0, 0, 2]}></Col>
    </Row>
  );
}

export default Title;

// styles
import styles from './addressBox.module.scss';

// types
import type { AdressBoxProps } from './AddressBox.types';

function AddressBox({ title, address, isDelivery, salutation, country }: Readonly<AdressBoxProps>) {
  return (
    <div className="addressBox">
      <h4 className={styles.bottomMargin}>{title}</h4>
      <ul className={`${styles.addressBoxWrapper} ${styles.bottomMargin}`}>
        <li>
          {address.salutation && salutation} {address.firstName} {address.lastName}
        </li>
        {!isDelivery && <li>{address.email}</li>}
        {!isDelivery && <li>{address.phone}</li>}
        <li>{address.company}</li>
        <li>{address.street}</li>
        <li>{address.zip}</li>
        <li>{address.city}</li>
        <li>{country}</li>
        {!isDelivery && address.sapCustomerNumber && <li>ID {address.sapCustomerNumber}</li>}
      </ul>
    </div>
  );
}

export default AddressBox;

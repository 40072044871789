// types
import type { AttributeList, ResponsiveTableProps } from './types';

// components
import { ResponsiveTableView } from './ResponsiveTableView';

// utils
import { getArticleList, getArticlesLength, getAttributesList } from './utils';

export function ResponsiveTable({
  numberOfVisibleCols,
  groupItems,
  productItems,
  grayTable,
  articlesList,
  attributesList,
  hasActionColumn,
  orderData,
  productKey,
  categories,
  isOrderHistory = false,
  isComparison = false,
}: Readonly<ResponsiveTableProps>) {
  const getLength = (attributeList?: AttributeList) =>
    getArticlesLength(isOrderHistory, numberOfVisibleCols, attributeList);

  if (isComparison && productItems && groupItems) {
    const attributesListComparison = getAttributesList(groupItems, productItems);
    const articleList = getArticleList(productItems);

    return (
      <ResponsiveTableView
        attributesList={attributesListComparison}
        articlesList={articleList}
        grayTable={grayTable}
        getLength={getLength}
        isComparison
      />
    );
  }

  if (isOrderHistory) {
    return <ResponsiveTableView orderData={orderData} getLength={getLength} isOrderHistory />;
  }

  return (
    <ResponsiveTableView
      attributesList={attributesList}
      articlesList={articlesList}
      hasActionColumn={hasActionColumn}
      categories={categories}
      productKey={productKey}
      getLength={getLength}
    />
  );
}

'use client';

import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import Head from 'next/head';

// styles
import styles from './checkout.module.scss';

// components
import { Icon } from '../Icon/Icon';
import PaymentError from './Containers/Payment/PaymentError';
import Breadcrumb from './Components/Breadcrumb/Breadcrumb';
import Stepper from './Components/Stepper';
import { Loader } from '../Loader/Loader';
import { Step0 } from './steps/step0';
import { Step1 } from './steps/step1';
import { Step2 } from './steps/step2';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { urlChange } from 'scenes/trackingActions';
import activeStepSelector from './selectors';
import { changeActiveStepAction as changeActiveStep, resetCheckoutStateAction } from './actions';
import { MY_DATA } from './constants';
import { classNameBuilder } from 'utils/classNameBuilder';

interface CheckoutProps {
  locale: string;
  ciamId?: string;
  sessionId?: string;
}

export function Checkout({ locale, ciamId = '', sessionId = '' }: Readonly<CheckoutProps>) {
  const activeStep = useSelector(activeStepSelector);
  const [step, setStep] = useState(activeStep);
  const [isLoading, setIsLoading] = useState(true);
  const [isErrorPage, setIsErrorPage] = useState(false);
  const [errorCode, setErrorCode] = useState<number | undefined>();
  const [isSuccessPage, setIsSuccessPage] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const translate = useTranslationFunction();
  const dispatch = useDispatch();
  const track = useTracking();

  useEffect(() => {
    const hasErrorPage = window.location.href.includes('error');
    setIsErrorPage(hasErrorPage);

    if (hasErrorPage) {
      const { error } = queryString.parse(window.location.search);
      setErrorCode(Number(error));
    }
    const hasSuccessPage = window.location.href.includes('success');
    setIsSuccessPage(hasSuccessPage);

    if (hasSuccessPage) {
      dispatch(changeActiveStep(3));
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    let timeout: Timeout | undefined;
    setStep(activeStep);
    if (isInitial === false) {
      timeout = setTimeout(() => {
        track.trackEvent(urlChange(document.title, document.location.pathname));
      }, 0);
    }

    if (activeStep === 3) {
      window.sessionStorage.removeItem(MY_DATA);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [activeStep, isInitial, track]);

  useEffect(() => {
    setIsInitial(false);

    return () => {
      dispatch(resetCheckoutStateAction());
    };
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (isErrorPage) {
    return (
      <div className={`grid-container ${styles.container}`}>
        {translate('web20_checkout_summary_breadcrumb') &&
          translate('web20_checkout_payment_error_heading') && (
            <Head>
              <title>{`${translate('web20_checkout_summary_breadcrumb')} - ${translate(
                'web20_checkout_payment_error_heading',
              )}`}</title>
            </Head>
          )}
        <PaymentError errorCode={errorCode} />
      </div>
    );
  }

  return (
    <div className={classNameBuilder('grid-container', styles.container)}>
      {translate('web20_checkout_address_breadcrumb') &&
        translate('web20_checkout_steps_address') && (
          <Head>
            <title>{`${translate('web20_checkout_address_breadcrumb')} - ${translate(
              'web20_checkout_steps_address',
            )}`}</title>
          </Head>
        )}
      <div>
        <Breadcrumb
          item={
            isSuccessPage
              ? translate('web20_checkout_summary_breadcrumb')
              : translate('web20_checkout_address_breadcrumb')
          }
        />
        <div className="hide-for-small-only">
          <Stepper
            ciamId={ciamId}
            steps={[
              {
                title: translate('web20_checkout_steps_address'),
                icon: <Icon symbol="check" />,
              },
              {
                title: translate('web20_checkout_steps_overview'),
                icon: <Icon symbol="check" />,
              },
              {
                title: translate('web20_checkout_steps_payment'),
                icon: <Icon symbol="check" />,
              },
              {
                title: translate('web20_checkout_steps_summary'),
                icon: <Icon symbol="check" />,
              },
            ]}
          />
        </div>
        {step === 0 && <Step0 locale={locale} ciamId={ciamId} sessionId={sessionId} />}
        {step === 1 && <Step1 locale={locale} ciamId={ciamId} sessionId={sessionId} />}
        {step === 3 && <Step2 />}
      </div>
    </div>
  );
}

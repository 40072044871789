import React, { PropsWithChildren } from 'react';
import { AspectRatio, Button } from '@geberit/gdds';
import { useTheme } from 'styled-components';

// styles
import {
  StyledButtonContainer,
  StyledGridLink,
  StyledImage,
  StyledImageGrid,
  StyledSeriesName,
  StyledTilesContainer,
  StyledTitle,
  StyledTitleCount,
} from './tiles.styles';

// types
import { IEntry } from '../../locator.types';
type TilesProps = Pick<IEntry, 'products'>;

// utils
import { useLocatorConfiguration } from '../../utils/use-locator-configuration';
import { useTiles } from './tiles.hooks';
import { decodingContent } from 'utils/decodingContent';

export const Tiles = ({ products }: TilesProps) => {
  const locatorConfig = useLocatorConfiguration();
  const { items: series, hasMore, showAll, itemsCount } = useTiles(products);
  const theme = useTheme();

  if (series.length === 0) {
    return null;
  }

  const H5_SIZES = {
    xsmall: theme.fontTokens.font09,
    medium: theme.fontTokens.font08,
    large: theme.fontTokens.font07,
    xlarge: theme.fontTokens.font07,
  };

  return (
    <StyledTilesContainer>
      <StyledTitle variant="h2" size={H5_SIZES}>
        {decodingContent(locatorConfig?.dealerPage?.productSeries)}&nbsp;
        <StyledTitleCount>({itemsCount})</StyledTitleCount>
      </StyledTitle>
      <StyledImageGrid>
        {series.map((s) => {
          const Wrapper = (s.url ? StyledGridLink : `div`) as React.FC<
            PropsWithChildren<{
              href: string | undefined;
            }>
          >;
          return (
            <Wrapper href={s.url} key={s.name}>
              <AspectRatio ratio="16:9">
                <StyledImage src={s.image} title={s.title} alt={s.title} />
              </AspectRatio>
              <StyledSeriesName>{s.name}</StyledSeriesName>
            </Wrapper>
          );
        })}
      </StyledImageGrid>
      {hasMore && (
        <StyledButtonContainer>
          <Button onClick={showAll}>{locatorConfig.dealerPage?.showAll}</Button>
        </StyledButtonContainer>
      )}
    </StyledTilesContainer>
  );
};

import { useEffect } from 'react';

// styles
import styles from './campusvideo.module.scss';

// utils
import { useCampusAuthGate, useCampusMediaState } from 'utils/hooks/useCampus';
import { emptyStringFallback } from 'utils/is-empty';

interface CampusVideoProps {
  courseId: number;
  mediaId: number;
  content: any;
}

export function CampusVideo({ courseId, mediaId, content }: Readonly<CampusVideoProps>) {
  const { employeeToken, ciamToken } = useCampusAuthGate(courseId);
  const { setCompleted } = useCampusMediaState(courseId);

  const accessToken = emptyStringFallback(ciamToken, employeeToken);

  useEffect(() => {
    if (accessToken) {
      setCompleted({ mediaId, accessToken });
    }
  }, [accessToken, mediaId, setCompleted]);

  return (
    <div className={styles.videoBg}>
      <iframe
        title={String(courseId)}
        src={content?.source}
        width="100%"
        height="100%"
        allowFullScreen={true}
      />
    </div>
  );
}

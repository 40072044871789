import { useState, useRef, useEffect, useCallback } from 'react';
import { FilterChips, Button, Dropdown, Col, Row } from '@geberit/gdds';

// styles
import styles from './downloadcenter.module.scss';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { clickDlcFilter } from './tracking-actions';
import { useIsMobile } from 'components/App/SizeProvider';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useGroup } from 'utils/hooks/use-group';

interface FilterAreaProps {
  getFilter2Items: () => any[];
  getFilter1Items: () => any[];
  handleFilterChange: (filter1: string, filter2: string[]) => void;
  resetFilterCallback: () => void;
  filter1Value: string;
  filter2Value: string[];
  setFilter1Value: (value: string) => void;
  setFilter2Value: (value: string[]) => void;
  remainingFilter2Items: any[];
  setRemainingFilter2Items: (value: any[]) => void;
  setRemainingF2Items: (value: any[]) => void;
  filterAreaDisabled: boolean;
}

function FilterArea({
  getFilter2Items,
  getFilter1Items,
  handleFilterChange,
  resetFilterCallback,
  filter1Value,
  filter2Value,
  setFilter1Value,
  setFilter2Value,
  remainingFilter2Items,
  setRemainingFilter2Items,
  setRemainingF2Items,
  filterAreaDisabled,
}: Readonly<FilterAreaProps>) {
  const isMobile = useIsMobile();
  const track = useTracking();
  const isGroup = useGroup();

  const [filter2Items, setFilter2Items] = useState([]);
  const [filter1Key, setFilter1Key] = useState(0);
  const [filter2Key, setFilter2Key] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const filterRef = useRef();
  const translate = useTranslationFunction();

  const handleChangeFilter1 = (selectedOption) => {
    if (isGroup) {
      track.trackEvent(clickDlcFilter(window.location.href));
    }
    setFilter1Value(selectedOption.value);
    handleFilterChange(selectedOption.value, filter2Value);
  };

  const handleDismissFilter1Chip = () => {
    // force component to recreate
    const newFilter1Key = filter1Key + 1;
    setFilter1Key(newFilter1Key);

    // reset filter1
    const newValue = getFilter1Items()?.find(
      (item) => item.value === new Date().getFullYear().toString(),
    )?.value;
    setFilter1Value(newValue);
    handleFilterChange(newValue, filter2Value);
  };

  const handleChangeFilter2 = (selectedOption) => {
    if (isGroup) {
      track.trackEvent(clickDlcFilter(window.location.href));
    }
    // force component to recreate
    const newFilter2Key = filter2Key + 1;
    setFilter2Key(newFilter2Key);

    const currentFilterItems = filter2Value;
    currentFilterItems.push(selectedOption.value);

    setFilter2Value([...currentFilterItems]);
    handleFilterChange(filter1Value, [...currentFilterItems]);

    const remainingFilterItems = filter2Items.filter(
      (item) => !currentFilterItems.includes(item.value),
    );
    setRemainingFilter2Items(remainingFilterItems);
  };

  const handleDismissFilter2Chip = (selectedOption) => {
    // force component to recreate
    const newFilter2Key = filter2Key + 1;
    setFilter2Key(newFilter2Key);

    const newFilter2Values = filter2Value.filter((value) => value !== selectedOption);

    handleFilterChange(filter1Value, newFilter2Values);
    setFilter2Value([...newFilter2Values]);

    const remainingFilterItems = remainingFilter2Items;
    remainingFilterItems.push({ value: selectedOption, text: selectedOption });

    setRemainingFilter2Items(remainingFilterItems);
  };

  const resetFilters = useCallback(() => {
    // force component to recreate
    const newFilter1Key = filter1Key + 1;
    setFilter1Key(newFilter1Key);

    // reset remaining filter2 options
    const tempFilter2Value = filter2Value;
    const tempRemainingFilterItems = remainingFilter2Items;
    tempFilter2Value.forEach((item) => tempRemainingFilterItems.push({ value: item, text: item }));
    setRemainingFilter2Items(tempRemainingFilterItems);

    // reset filter values
    const newValue = getFilter1Items().find(
      (item) => item.value === new Date().getFullYear().toString(),
    )?.value;
    setFilter1Value(newValue);
    setFilter2Value([]);
    resetFilterCallback();
  }, [
    filter1Key,
    filter2Value,
    getFilter1Items,
    remainingFilter2Items,
    resetFilterCallback,
    setFilter1Value,
    setFilter2Value,
    setRemainingFilter2Items,
  ]);

  // when filter 2 options loaded: set them and set the remaining options
  useEffect(() => {
    if (filter2Items.length === 0) {
      const items = getFilter2Items();
      setFilter2Items(items);
      setRemainingFilter2Items(items);
      setRemainingF2Items(items);
    }
  }, [getFilter2Items]);

  useEffect(() => {
    if (filterAreaDisabled) {
      resetFilters();
    }
  }, [filterAreaDisabled]);

  useEffect(() => {
    setDisabled(
      parseInt(filter1Value, 10) === new Date().getFullYear() && filter2Value.length === 0,
    );
  }, [filter1Value, filter2Value]);

  return (
    <>
      <Row className={styles.filterArea}>
        <Col size={[4, 3, 3]}>
          <Dropdown
            name="group_downloadcenter_filter1"
            items={getFilter1Items()}
            label={translate('group_downloadcenter_filter1')}
            outlined
            onChange={handleChangeFilter1}
            values={{ group_downloadcenter_filter1: filter1Value }}
            placeholder={
              parseInt(filter1Value, 10) === new Date().getFullYear()
                ? translate('web20_current_year')
                : filter1Value
            }
            key={filter1Key}
            disabled={filterAreaDisabled}
          />
        </Col>
        {isMobile && (
          <div
            className={
              disabled
                ? `${styles.filterChipWrapperMobile} ${styles.disable}`
                : styles.filterChipWrapperMobile
            }
          >
            <div className={styles.filterChip}>
              {filter1Value && (
                <FilterChips
                  ref={filterRef}
                  type="A"
                  isStrechedWidth={false}
                  chipMinWidth={0}
                  chips={[
                    {
                      value: filter1Value,
                      title:
                        parseInt(filter1Value, 10) === new Date().getFullYear()
                          ? translate('web20_current_year')
                          : filter1Value,
                    },
                  ]}
                  onChange={handleDismissFilter1Chip}
                  defaultSelectedChips={[filter1Value]}
                  selectedItems={[filter1Value]}
                  gap={16}
                />
              )}
            </div>
          </div>
        )}
        <Col size={[4, 3, 3]}>
          <Dropdown
            name="group_downloadcenter_filter2"
            items={remainingFilter2Items}
            label={translate('group_downloadcenter_filter2')}
            outlined
            onChange={handleChangeFilter2}
            values={{ group_downloadcenter_filter2: filter2Value }}
            key={filter2Key}
            disabled={filterAreaDisabled}
          />
        </Col>
        {isMobile && (
          <div className={styles.filterChipWrapperMobile}>
            {filter2Value.map((filter2ValueSelected) => (
              <div className={styles.filterChip} key={filter2ValueSelected}>
                {!filterAreaDisabled && (
                  <FilterChips
                    ref={filterRef}
                    type="A"
                    gap={16}
                    isStrechedWidth={false}
                    chips={[{ value: filter2ValueSelected, title: filter2ValueSelected }]}
                    onChange={() => handleDismissFilter2Chip(filter2ValueSelected)}
                    defaultSelectedChips={[filter2ValueSelected]}
                    selectedItems={[filter2ValueSelected]}
                    chipMinWidth={0}
                  />
                )}
              </div>
            ))}
          </div>
        )}
        <Col size={[4, 2, 2]} className={styles.resetButton}>
          <Button onClick={resetFilters} disabled={filterAreaDisabled || disabled}>
            {translate('group_downloadcenter_reset_filter')}
          </Button>
        </Col>
      </Row>
      {!isMobile && (
        <Row className={styles.filterChipsArea}>
          <div className={styles.filterChipsWrapper}>
            <div className={styles.filterChip}>
              {filter1Value && !filterAreaDisabled && (
                <FilterChips
                  ref={filterRef}
                  type="A"
                  gap={16}
                  isStrechedWidth={false}
                  chips={[
                    {
                      value: filter1Value,
                      title:
                        parseInt(filter1Value, 10) === new Date().getFullYear()
                          ? translate('web20_current_year')
                          : filter1Value,
                    },
                  ]}
                  onChange={handleDismissFilter1Chip}
                  defaultSelectedChips={[filter1Value]}
                  selectedItems={[filter1Value]}
                  chipMinWidth={0}
                />
              )}
            </div>
            {filter2Value.map((filter2ValueSelected) => (
              <div className={styles.filterChip} key={filter2ValueSelected}>
                <FilterChips
                  ref={filterRef}
                  type="A"
                  gap={16}
                  isStrechedWidth={false}
                  chips={[{ value: filter2ValueSelected, title: filter2ValueSelected }]}
                  onChange={() => handleDismissFilter2Chip(filter2ValueSelected)}
                  defaultSelectedChips={[filter2ValueSelected]}
                  selectedItems={[filter2ValueSelected]}
                  chipMinWidth={0}
                />
              </div>
            ))}
          </div>
        </Row>
      )}
    </>
  );
}

export default FilterArea;

'use client';

// components
import Layout from './layout';
import { HeroImageGdds } from 'components/ContentElementsGdds/hero-image/hero-image-gdds';
import { HeroTiles as HeroTilesGDDS } from 'components/ContentElementsGdds/Tiles/hero-tiles';
import { ContentArea } from 'components/ContentArea/ContentArea';

// utils
import { isEmpty } from 'utils/is-empty';
import { hasContentAreas, useContent } from 'components/Page/page-hooks';

export function Home() {
  const pageContent = useContent<{}, { heroTile: HeroTile; hero: HeroImageGddsProps }>();

  if (!hasContentAreas(pageContent)) {
    return null;
  }

  const {
    metaData,
    page: { heroTile, hero },
    contentAreas: { content },
  } = pageContent;
  return (
    <Layout metaData={metaData}>
      {!isEmpty(hero) && <HeroImageGdds {...hero} isSeoConformCarousel />}
      {!isEmpty(heroTile) && <HeroTilesGDDS {...heroTile} />}
      <ContentArea content={content} contentType="content" />
    </Layout>
  );
}

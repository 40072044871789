import styled from 'styled-components';

export const ZipEntryContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-image: url(/images/locator/maps-placeholder.png);
  background-size: cover;

  @media ${({ theme }) => theme.device.large} {
    top: 1rem;
    padding: 0 1rem;
    position: initial;
    left: initial;
    top: initial;
    bottom: initial;
  }

  .notification {
    top: 0;

    @media ${({ theme }) => theme.device.medium} {
      top: 1.5rem;
      padding: 0 1.5rem;
    }

    @media ${({ theme }) => theme.device.large} {
      top: 1rem;
      padding: 0 1rem;
      right: 0;
    }
  }
`;

export const ZipEntryBoxContainer = styled.div`
  background-color: white;
  width: 100%;
  min-height: 17rem;
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  align-self: center;
  margin-left: 1rem;
  margin-right: 1rem;

  h1,
  h2 {
    margin-bottom: 0.75rem;
  }
  h2 + p {
    margin-bottom: 2rem;
  }

  @media ${({ theme }) => theme.device.medium} {
    width: 23.5rem;
    margin-left: 3rem;
  }

  @media ${({ theme }) => theme.device.large} {
    width: 35rem;
    margin: 7.375rem;
    padding: 2rem 2.5rem 2.5rem 2.5rem;
  }
`;

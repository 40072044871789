'use client';
import { useSelector } from 'react-redux';
import { useRouter, usePathname } from 'next/navigation';
import striptags from 'striptags';
import Head from 'next/head';
import { Divider } from '@geberit/gdds';

// styles
import styles from './layout.module.scss';

// types
import { CourseDateList } from 'components/Campus/types';

// components
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import CampusLayout from './CampusLayout';
import { InternalLink } from 'components/Link/InternalLink';
import { Translation } from 'components/Translation/Translation';
import { ContactModule } from 'components/Campus/Contact/ContactModule';
import { CourseInformation } from 'components/Campus/CourseInformation/CourseInformation';
import CampusDetailWorkflowWrapper from './CampusDetailWorkflowWrapper';
import CourseDescription from 'components/Campus/CourseDescription/CourseDescription';
import { Loader } from 'components/Loader/Loader';

// utils
import {
  useCampusEnv,
  useClassifications,
  useCourseDetails,
  useCourseParticipants,
  useCourseStatus,
  useEmployeeToken,
  useSelfUser,
} from 'utils/hooks/useCampus';
import {
  campusReferenceUrlSelector,
  campusCatalogUrlSelector,
} from 'utils/selectors/globalsSelectors';
import { generateSlug } from 'utils/slug';
import { truncateAtWordBoundary } from 'utils/truncate';
import { useCampusToken } from 'utils/hooks/useCiam';
import { useCostsIncludedMetatags } from 'utils/hooks/useCostsIncludedMetatags';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { emptyStringFallback } from 'utils/is-empty';
import { getMetatag } from 'components/Campus/metatags';

interface CampusCourseDetailsProps extends Content {
  courseId: string;
}

export function CampusCourseDetails({
  courseId: courseIdString,
  fs_language: language,
}: Readonly<CampusCourseDetailsProps>) {
  const courseId = parseInt(courseIdString);
  const { CAMPUS_CLASS_TARGETGROUPS, CAMPUS_META } = useCampusEnv();
  const router = useRouter();
  const pathname = usePathname();

  const { loading: ciamIsLoading, campusToken: ciamToken } = useCampusToken();
  const { token: employeeToken } = useEmployeeToken(courseId);
  const { data, reload, isLoading } = useCourseDetails(courseId);
  const { selfData: participantData } = useSelfUser(courseId);
  const participantId = participantData?.id;
  const { isLoading: bookedLoading, participantCount } = useCourseParticipants(courseId);
  const translate = useTranslationFunction();
  const referenceUrl = useSelector(campusReferenceUrlSelector);
  const campusCatalogUrl = useSelector(campusCatalogUrlSelector);
  const [courseStatus] = useCourseStatus(courseId, participantId);

  if (data) {
    data.status = courseStatus;
  }

  const isBooked = !isLoading && !bookedLoading && participantCount > 0;
  // target groups
  const { getClassification } = useClassifications(Boolean(ciamToken || employeeToken));
  const targetGroups = getClassification(CAMPUS_CLASS_TARGETGROUPS);

  const idInCourseTargetGroups = (id) =>
    !!data?.topics?.find((i) => parseInt(i?.id, 10) === parseInt(id, 10));

  const courseTargetGroups = targetGroups?.filter((i) => idInCourseTargetGroups(i?.id));
  const metaTags = data?.metaTags;
  const campusCostsIncluded = useCostsIncludedMetatags(metaTags);

  if (ciamIsLoading || !data || (employeeToken && !participantData)) {
    return (
      <main className={styles.campusMain}>
        <div className={`grid-container ${styles.loaderWrapper}`}>
          <Loader />
        </div>
      </main>
    );
  }

  if (
    participantData &&
    getMetatag(CAMPUS_META.NAME, metaTags) &&
    participantId &&
    (employeeToken || !ciamToken)
  ) {
    const redirectToBookedPage = `${referenceUrl}${generateSlug(
      getMetatag(CAMPUS_META.NAME, metaTags),
    )}/crs_${courseId}_pro_${participantId}-${language}`;

    router.replace(redirectToBookedPage);
    return null;
  }

  // get teaser data
  const dateStart = getMetatag(CAMPUS_META.START_DATE, metaTags);
  const dateEnd = getMetatag(CAMPUS_META.END_DATE, metaTags);
  const courseDates: CourseDateList = [];

  if (dateStart) {
    courseDates.push({
      day: dateStart.split(' ')[0],
      time: dateStart.split(' ')[1],
    });
  }
  if (dateEnd) {
    courseDates.push({
      day: dateEnd.split(' ')[0],
      time: dateEnd.split(' ')[1],
    });
  }

  const courseContents = metaTags
    ?.filter((item) => CAMPUS_META.COURSE_CONTENTS.includes(item?.id))
    ?.filter((item) => item.content.trim() !== '');

  const courseBenefits = metaTags
    ?.filter((item) => CAMPUS_META.COURSE_BENEFITS.includes(item?.id))
    ?.filter((item) => item.content.trim() !== '');

  let backLink;
  if (pathname.includes('?')) {
    backLink = `${campusCatalogUrl}?${pathname.split('?')?.[1]}`;
  } else {
    backLink = campusCatalogUrl;
  }

  return (
    <CampusDetailWorkflowWrapper reload={reload} subitems={[]} courseId={courseId} data={data}>
      <Head>
        <title>{striptags(getMetatag(CAMPUS_META.NAME, metaTags))}</title>
      </Head>
      <CampusLayout>
        <div className={styles.courseDetails}>
          {/* section: header */}
          <div className={`grid-container ${styles.courseDetailsHeader}`}>
            {/* link to overview */}
            <InternalLink
              content={{ target: backLink, showArrow: true }}
              className="web20-icon-arrow-link-light-left"
            >
              <Translation id="campus_back_catalog" />
            </InternalLink>
            {/* learning form */}
            <div className={styles.learningForm}>{data?.learningForm}</div>
            {/* headline */}
            <PageHeadline
              Format={TitleFormats.h1}
              title={truncateAtWordBoundary(getMetatag(CAMPUS_META.NAME, metaTags), 60) as string}
              className={styles.gddsHeadlineH1}
              pageHeadline
            />
            {/* description */}
            <div className={styles.courseTeaser}>
              {truncateAtWordBoundary(striptags(getMetatag(CAMPUS_META.SUMMARY, metaTags)), 190)}
            </div>
          </div>
          {/* section: course information */}
          <CourseInformation
            image={emptyStringFallback(
              getMetatag(CAMPUS_META.IMAGE, metaTags),
              emptyStringFallback(data?.moocImage, undefined),
            )}
            courseHeadline={getMetatag(CAMPUS_META.NAME, metaTags)}
            courseDate={courseDates.length > 0 ? courseDates : undefined}
            availableSeats={data?.freePlaces > 0 ? data?.freePlaces : undefined}
            seats={data?.freePlaces > 0 && getMetatag(CAMPUS_META.MAX_CAPACITY, metaTags)}
            location={getMetatag(CAMPUS_META.LOCATION, metaTags)}
            language={getMetatag(CAMPUS_META.LANGUAGE, metaTags)}
            costs={getMetatag(CAMPUS_META.PRICE, metaTags)}
            costsIncluded={campusCostsIncluded}
            duration={getMetatag(CAMPUS_META.DURATION, metaTags)}
            isBooked={isBooked}
            isCiam={!ciamIsLoading && !!ciamToken}
            isAvailable={data?.freePlaces !== 0}
            isCanceled={data?.status === 'CANCELLED'}
            info={getMetatag(CAMPUS_META.INFOBOX, metaTags)}
            targetGroup={courseTargetGroups?.map((i) => i.name).join(', ')}
            noParticipantLimit={data?.freePlaces < 0}
          />
          {/* section: description */}
          <div className={`grid-container ${styles.courseDetailsDescription}`}>
            <PageHeadline
              Format={TitleFormats.h2}
              className={`${styles.gddsHeadlineH2} ${styles.descriptionHeadline}`}
              title={translate('campus_course_description')}
            />
            <CourseDescription
              description={getMetatag(CAMPUS_META.DESCRIPTION, metaTags)}
              tutors={data?.tutors}
              courseContent={courseContents}
              benefits={courseBenefits}
            />
            <Divider />
          </div>
          {/* section: contact module or course users */}
          <ContactModule locationId={getMetatag(CAMPUS_META.LOCATION, metaTags, true)} />
        </div>
      </CampusLayout>
    </CampusDetailWorkflowWrapper>
  );
}

import { Dropdown } from '@geberit/gdds';

// types
import type { FilterDropdownProps } from './filter.types';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { clickInsightsFilter } from '../tracking-actions';

function FilterDropdown({
  id,
  label,
  allItems,
  selected,
  setSelected,
  placeholder,
}: Readonly<FilterDropdownProps>) {
  const track = useTracking();
  const allItemsWithPlaceholder = [
    { text: placeholder, value: '', id: 'placeholder' },
    ...allItems,
  ];

  const handleChange = ({ value }) => {
    track.trackEvent(clickInsightsFilter(window.location.href));

    if (value && !selected.includes(value)) {
      setSelected([...selected, value]);
    }
  };

  return (
    <Dropdown
      label={label}
      items={allItemsWithPlaceholder}
      onChange={handleChange}
      id={id}
      name={id}
      values={{ [id]: '' }}
      defaultValue=""
      outlined
    />
  );
}

export default FilterDropdown;

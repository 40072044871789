import { Dialogue, DialogueProvider, Typo, Container, Row, Col } from '@geberit/gdds';

// styles
import styles from '../dlc-lightbox-nordics.module.scss';

// types
import type { LightboxNordicsProps } from './cart.types';

// components
import CartDownloads from './cart-downloads';
import CartOrders from './cart-orders';
import { Translation } from 'components/Translation/Translation';

// utils
import { buildSize, gridSizes } from 'utils/gridSize';
import { classNameBuilder as classes } from 'utils/classNameBuilder';

export function Lightbox({
  openDownloads,
  openOrders,
  downloads,
  handleClose,
  handleDownloadChange,
  handleDownloadZip,
  orders,
  updateOrder,
  updateAmount,
  form,
  getSessionStorage,
  handleFinished,
  isFinished,
}: Readonly<LightboxNordicsProps>) {
  return (
    <DialogueProvider>
      <Container maxContentWidth={buildSize(gridSizes.gddsFullGrid)}>
        <Row>
          <Col className={styles.lightboxWrapper}>
            <Dialogue
              body={[
                !isFinished && (
                  <div
                    key={'headline'}
                    className={classes(styles.lightbox__headline, openDownloads && styles.orders)}
                  >
                    <Typo variant="h3" fontWeight={400}>
                      <Translation
                        id={
                          openDownloads
                            ? 'web20_downloadcenter_cart_headline_your_downloads'
                            : 'web20_downloadcenter_cart_headline_your_order'
                        }
                      />
                    </Typo>
                    <Typo variant="h4" fontWeight={500}>
                      <Translation id="web20_downloadcenter_your_order" />
                    </Typo>
                  </div>
                ),
                openDownloads && (
                  <CartDownloads
                    downloads={downloads}
                    updateDownload={handleDownloadChange}
                    downloadZipHandler={handleDownloadZip}
                  />
                ),
                openOrders && (
                  <CartOrders
                    orders={orders}
                    updateOrder={updateOrder}
                    updateAmount={updateAmount}
                    form={form}
                    getSessionStorage={getSessionStorage}
                    onFinished={handleFinished}
                  />
                ),
              ]}
              closeDialogue={handleClose}
              showCloseBtn
              size="l"
              type="custom"
            />
          </Col>
        </Row>
      </Container>
    </DialogueProvider>
  );
}

import { useEffect, useRef, useState } from 'react';

// styles
import {
  StyledCol,
  StyledContainer,
  StyledImage,
  StyledVideo,
  StyledVideoPlayer,
  StyledVideoPlayerContent,
  StyledVideoPlayerThemeNordics,
} from './VideoPlayer.styles';

// types
import type { VideoPlayerProps, VideoPlayerStatus } from '.';

// components
import { Image } from 'components/image/image';
import { CmsLink } from 'components/Link/CmsLink';
import { BUTTON_COLORS, BUTTON_TYPES, Button } from 'components/Button/Button';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { ContentBox } from './components/ContentBox';

// utils
import { useGetParent } from 'scenes/parent-provider';
import { Log } from 'services/log';
import { VIDEO_PLAYER_STATUS } from '.';
import {
  addDefaultValuesToMissingPropsValues,
  addVideoPlayerScript,
  getIsVideoPlayerScriptAvailable,
  getVideoPlayer,
  getIsVideoPlayerInitialized,
  removeVideoPlayerControls,
} from './VideoPlayer.utils';
import { classNameBuilder } from 'utils/classNameBuilder';
import { getHeadlineTag } from 'components/ContentElements/slider';
import { useThemeName } from 'utils/hooks/use-theme';
import { decodingContent } from 'utils/decodingContent';

const DURATION_TO_WAIT_FOR_VIDEO_PLAYER_INITIALIZATION = 10000; // 10 seconds

export const VideoPlayer = (props: VideoPlayerProps) => {
  const {
    cssID,
    inView,
    subheading,
    heading,
    title: pictureTitle,
    button,
    color,
    fallbackImg,
    loopType,
    hasPreviewId = true,
    position = 'top',
    animation = false,
    isHeroElement,
  } = props;

  const [isVideoPlayerScriptAvailable, setIsVideoPlayerScriptAvailable] = useState<boolean>(
    getIsVideoPlayerScriptAvailable,
  );
  const [isVideoPlayerInitialized, setIsVideoPlayerInitialized] = useState<boolean>(() => {
    return false;
  });
  const [hasVideoReachedEnd, setHasVideoReachedEnd] = useState<boolean>(false);

  const [hasControls, setHasControls] = useState<boolean>(true);

  const [status, setStatus] = useState<VideoPlayerStatus>(VIDEO_PLAYER_STATUS.LOADING);
  const [showPreviewImage, setShowPreviewImage] = useState<boolean>(true);
  const count = useRef<number>(0);
  const videoPlayer = useRef<any>(null);
  const shouldLoop = loopType === 'loop';
  const sliderContext = useGetParent();
  const headlineTag = getHeadlineTag(sliderContext);

  // this should be used for conditionally rendering the headline/contentbox;
  // edge case: if the headline has only new line tags, then this will
  // evaluate to empty string and the condition for contentbox will work;
  const _textOnlyHeading = decodingContent(heading, true);

  const themeName = useThemeName();

  useEffect(() => {
    if (!isVideoPlayerScriptAvailable) return;
    setIsVideoPlayerScriptAvailable(false);
  }, []);

  useEffect(() => {
    const shouldShowPreviewImage =
      status === VIDEO_PLAYER_STATUS.LOADING || status === VIDEO_PLAYER_STATUS.ERROR;

    setShowPreviewImage(shouldShowPreviewImage);
  }, [status]);

  useEffect(() => {
    if (isVideoPlayerScriptAvailable) return;
    if (getIsVideoPlayerScriptAvailable()) {
      setIsVideoPlayerScriptAvailable(true);
      return;
    }

    const videoPlayerProps = addDefaultValuesToMissingPropsValues(props);
    addVideoPlayerScript(videoPlayerProps);

    const wasAddingSuccessful = getIsVideoPlayerScriptAvailable();

    if (wasAddingSuccessful) {
      setIsVideoPlayerScriptAvailable(true);
    }
  }, [isVideoPlayerScriptAvailable, props]);

  useEffect(() => {
    if (videoPlayer.current && props?.video?.videoId)
      videoPlayer.current.switchVideo(props.video.videoId);
  }, [props?.video?.videoId]);

  useEffect(() => {
    // wait for video player to be initialized

    if (!isVideoPlayerScriptAvailable) return;
    if (isVideoPlayerInitialized) return;
    if (count.current > DURATION_TO_WAIT_FOR_VIDEO_PLAYER_INITIALIZATION) return;

    const intervalID = setInterval(() => {
      Log.info(`⏳ (${count.current}) waiting for video player to be initialized...`);

      if (getIsVideoPlayerInitialized(cssID)) {
        setIsVideoPlayerInitialized(true);
        clearInterval(intervalID);
      }

      if (count.current > DURATION_TO_WAIT_FOR_VIDEO_PLAYER_INITIALIZATION) {
        setIsVideoPlayerInitialized(false);
        clearInterval(intervalID);
      }

      count.current += 1000;
    }, 1000);

    return () => clearInterval(intervalID);
  });

  useEffect(() => {
    if (!isVideoPlayerScriptAvailable) return;
    if (isVideoPlayerInitialized) return;

    const requiredProps = addDefaultValuesToMissingPropsValues(props);
    addVideoPlayerScript(requiredProps);
  }, []);

  useEffect(() => {
    if (!isVideoPlayerScriptAvailable || !isVideoPlayerInitialized) {
      setStatus(VIDEO_PLAYER_STATUS.LOADING);
      setShowPreviewImage(true);
      return;
    }

    if (isVideoPlayerInitialized && isVideoPlayerScriptAvailable) {
      setStatus(VIDEO_PLAYER_STATUS.DONE);
      setShowPreviewImage(false);
      return;
    }

    setStatus(VIDEO_PLAYER_STATUS.DONE);
    setShowPreviewImage(false);
  }, [isVideoPlayerInitialized, isVideoPlayerScriptAvailable]);

  const initVideoPlayer = () => {
    if (!videoPlayer.current) {
      const player = getVideoPlayer(cssID);
      if (!player) {
        throw new Error('Video player not initialized');
      }
      videoPlayer.current = player;
    }
  };

  const configureVideoPlayer = () => {
    if (hasControls) {
      removeVideoPlayerControls(cssID);
      setHasControls(false);
    }

    // add event handlers
    if (shouldLoop) {
      const loopEventHandler = () => videoPlayer.current.play();
      inView
        ? videoPlayer.current.registerEventListener('ended', loopEventHandler)
        : videoPlayer.current.unregisterEventListener('ended', loopEventHandler);
    }

    if (!shouldLoop && !hasVideoReachedEnd) {
      const playOnlyOnceEndedEventHandler = () => {
        const _hasReachedEndOfVideo =
          videoPlayer.current.getDuration() - videoPlayer.current.getCurrentTime() < 0.5;

        if (_hasReachedEndOfVideo) {
          setHasVideoReachedEnd(true);
          setShowPreviewImage(true);
          return;
        }
        videoPlayer.current.play();
      };
      inView
        ? videoPlayer.current.registerEventListener('ended', playOnlyOnceEndedEventHandler)
        : videoPlayer.current.unregisterEventListener('ended', playOnlyOnceEndedEventHandler);
    }
  };

  const startVideo = () => {
    if (inView) {
      videoPlayer.current.play();
    }

    if (!inView) {
      videoPlayer.current.pause();
    }
  };

  useEffect(() => {
    // play / pause video in player
    if (status !== VIDEO_PLAYER_STATUS.DONE) return;

    initVideoPlayer();
    configureVideoPlayer();
    startVideo();
  }, [status, inView, shouldLoop, cssID, hasVideoReachedEnd, hasControls]);

  const renderLink = () => (
    <CmsLink link={button}>
      <Button
        type={BUTTON_TYPES.DEFAULT}
        color={color === 'white' ? BUTTON_COLORS.WHITE : BUTTON_COLORS.BLACK}
      >
        {button?.text}
      </Button>
    </CmsLink>
  );

  if (themeName === 'GDDS') {
    return (
      <StyledVideoPlayer className="video-player-mi24" isHeroElement={isHeroElement}>
        <StyledVideoPlayerContent color={color}>
          {(!!subheading || !!_textOnlyHeading) && (
            <>
              {!!subheading && (
                <InnerHtml
                  as="span"
                  content={subheading}
                  className={classNameBuilder('subheadline', headlineTag === 'span' && 'block')}
                  previewId={hasPreviewId ? '#st_subline' : undefined}
                />
              )}
              {!!_textOnlyHeading && (
                <InnerHtml
                  as={headlineTag}
                  content={heading}
                  previewId={hasPreviewId ? '#st_headline' : undefined}
                  className={classNameBuilder('h2', headlineTag === 'span' && 'block')}
                />
              )}
              {!!button && <span>{renderLink()}</span>}
            </>
          )}
        </StyledVideoPlayerContent>

        {showPreviewImage && <Image src={fallbackImg} alt={pictureTitle} title={pictureTitle} />}
        <div
          id={cssID}
          style={{
            visibility: showPreviewImage ? 'hidden' : 'visible',
            display: showPreviewImage ? 'none' : 'block',
          }}
        ></div>
      </StyledVideoPlayer>
    );
  }

  return (
    <StyledVideoPlayerThemeNordics className="video-player-mi24">
      <StyledContainer>
        <StyledCol>
          <ContentBox
            button={button}
            heading={heading}
            subheading={subheading}
            color={color}
            position={position}
            hasPreviewId={hasPreviewId}
            headlineTag={headlineTag}
            animation={animation}
          />
        </StyledCol>
      </StyledContainer>

      {showPreviewImage && (
        <StyledImage
          className="video-player-mi24__preview-image"
          src={fallbackImg}
          alt={pictureTitle}
          title={pictureTitle}
        />
      )}

      <StyledVideo
        className="video-player-mi24__video-player"
        id={cssID}
        showVideo={!showPreviewImage}
      />
    </StyledVideoPlayerThemeNordics>
  );
};

import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import YouTube from 'react-youtube';
import { ResizeObserver as PolyfillResizeObserver } from '@juggle/resize-observer';

// types
import type { ConfigurableYoutubeProps } from './types';

// utils
import { useUCConsent, VideoConsent } from 'features/cookie-consent';
import { youtubeSettingsSelector } from 'utils/selectors/globalsSelectors';

export default function ConfigurableYoutube({
  videoId = '',
  playerControl,
  language,
  showLink,
}: Readonly<ConfigurableYoutubeProps>) {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<ResizeObserver | null>(null);
  const [videoWidth, setVideoWidth] = useState<number | undefined>();
  const [videoHeight, setVideoHeight] = useState<number | undefined>();
  const globalYoutubeData = useSelector(youtubeSettingsSelector);
  const { Youtube: hasConsent } = useUCConsent();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return () => {};
    }

    if (!globalYoutubeData) {
      return () => {};
    }

    if (observerRef.current === null) {
      return () => {};
    }

    const ResizeObserver = window.ResizeObserver || PolyfillResizeObserver;
    observerRef.current = new ResizeObserver(resizeAspectRatio);
    observerRef.current.observe(document.body);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [hasConsent]);

  if (!globalYoutubeData) {
    return null;
  }
  const { autoplay, playInline, removeRelated, showAnnotations, showFullscreen, videoResolution } =
    globalYoutubeData;

  const resizeAspectRatio = () => {
    if (videoContainerRef.current) {
      const divWidth = videoContainerRef.current.getBoundingClientRect().width;
      const factor = videoResolution === '16:9' ? 9 / 16 : 1;
      setVideoWidth(divWidth);
      setVideoHeight(divWidth * factor);
    }
  };

  return (
    <div className="c-text-video__video" ref={videoContainerRef}>
      {hasConsent && videoWidth && videoHeight && (
        <YouTube
          videoId={videoId}
          opts={{
            height: String(videoHeight),
            width: String(videoWidth),
            playerVars: {
              autoplay: autoplay ? 1 : 0,
              controls: playerControl ? 1 : 0,
              modestbranding: showLink ? undefined : 1,
              hl: language,
              rel: removeRelated ? 0 : 1,
              fs: showFullscreen ? 1 : 0,
              iv_load_policy: showAnnotations ? 1 : 3,
              playsinline: playInline ? 1 : 0,
            },
          }}
        />
      )}
      {!hasConsent && <VideoConsent type="youtube" />}
    </div>
  );
}

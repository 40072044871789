import { Icon, Link } from '@geberit/gdds';

// styles
import styles from './social-share.module.scss';

interface CopyIconProps {
  url: string;
}

function CopyIcon({ url }: Readonly<CopyIconProps>) {
  function copyUrl() {
    navigator.clipboard.writeText(url);
  }

  return (
    <Link className={styles.copyIcon} onClick={copyUrl} hasArrow={false} stylingType="secondary">
      <Icon symbol="Anchor" width="1.5rem" color="white" />
    </Link>
  );
}

export default CopyIcon;

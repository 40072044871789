import styled, { css } from 'styled-components';
import { Container, Row } from '@geberit/gdds';
import { ZipEntryBoxContainer, ZipEntryContainer } from '../zip-entry/zip-entry.styles';

export const LocatorContainer = styled(Container)<{ isSection?: boolean }>(
  ({ isSection, theme }) => css`
    display: flex;
    min-height: 30rem;
    max-width: 78rem;
    margin-bottom: ${isSection ? '0' : '4rem'};

    ${Row} {
      flex: 1;
    }

    @media ${theme.device.medium} {
      min-height: ${isSection ? '20rem' : '34.5rem'};
    }

    @media ${theme.device.large} {
      min-height: ${isSection ? '21rem' : '43.25rem'};
      margin: ${isSection ? '0 auto' : '5rem auto'};
      max-width: 80rem;
    }

    ${ZipEntryContainer} {
      ${isSection && 'position: static'};

      ${ZipEntryBoxContainer} {
        ${isSection &&
        css`
          min-height: 13.25rem;
          padding: 1.5rem 1.5rem 2rem 1.5rem;

          > p {
            margin-bottom: 1.5rem;
          }

          > h3 {
            margin-bottom: 0.5rem;
          }

          @media ${theme.device.medium} {
            margin: 0 0 0 3rem;
          }

          @media ${theme.device.large} {
            min-height: 12.75rem;
            margin: 0 0 0 5rem;
            padding: 1.5rem 3rem 2.5rem 3rem;
          }
        `}
      }
    }

    .notification {
      box-sizing: border-box;
      animation: none;
      position: absolute;
      z-index: 99;

      padding: ${isSection ? '0' : '1rem'};
      left: ${isSection ? '2.5rem' : '0'};
      ${isSection && 'top: 1rem'};
      ${isSection && 'right: 2.5rem'};
      ${isSection && 'width: unset'};
    }
  `,
);

import key from 'weak-key';

// Components
import Tiles from './tiles';
import { Translation } from '../../../../../components/Translation/Translation';
import LoadMoreButton from '../../../../../components/LoadMoreButton/LoadMoreButton';

interface TilesAreaProps {
  results: any[];
  orders: any[];
  updateOrder: (order: any) => void;
  updateDownload: (download: any) => void;
  downloads: any[];
  handleLoadMore: () => void;
  lastPage: boolean;
  dateFormat: string;
  containerRef: any;
}

function TilesArea({
  results,
  orders,
  updateOrder,
  updateDownload,
  downloads,
  handleLoadMore,
  dateFormat,
  lastPage = false,
  containerRef = null,
}: Readonly<TilesAreaProps>) {
  return (
    <section className="dc-tiles-area grid-container">
      <div className="dc-tiles-area__tiles grid-x grid-margin-x" ref={containerRef}>
        {results.map((tile) => (
          <Tiles
            key={key(tile)}
            dateFormat={dateFormat}
            updateOrder={updateOrder}
            updateDownload={updateDownload}
            downloads={downloads}
            data={tile}
            hasOrder={!!orders.find((order) => order.id === tile.id)}
          />
        ))}
      </div>
      <div
        className="grid-x grid-margin-x dc-tiles-area__wrapper"
        style={lastPage ? { visibility: 'hidden' } : undefined}
      >
        <div className="small-12 medium-4 large-offset">
          <LoadMoreButton handleLoadMore={handleLoadMore}>
            <Translation id="web20_downloadcenter_more_results" />
          </LoadMoreButton>
        </div>
      </div>
    </section>
  );
}

export default TilesArea;

import { useRef, memo } from 'react';

// styles
import styles from './insights-teaser-list.module.scss';

// types
import type { Label } from './filter/filter.types';

// components
import Filter from './filter/filter';
import List from './list/list';

// utils
import { useLocationFilters } from './hooks/use-location-filters';

function InsightsTeaserList({
  labels,
}: Readonly<{
  labels: {
    year: Label;
    topic: Label;
    reset: Omit<Label, 'placeholder'>;
  };
}>) {
  const filters = useLocationFilters();
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div ref={ref} className={styles.insightsWrapper}>
      <Filter {...filters} labels={labels} />
      <List {...filters} />
    </div>
  );
}

export default memo(InsightsTeaserList);

import { Button } from '@geberit/gdds';
import { useRouter } from 'next/navigation';
import striptags from 'striptags';

// styles
import styles from './coursebookedcontent.module.scss';

// components
import ProgressBar from '../Course/ProgressBar';
import { Loader } from 'components/Loader/Loader';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';
import {
  useCourseSubitemDescription,
  useScormAuth,
  useCampusMediaState,
  useCampusAuthGate,
  useCampusEnv,
} from 'utils/hooks/useCampus';
import { emptyStringFallback } from 'utils/is-empty';
import { classNameBuilder } from 'utils/classNameBuilder';

interface CourseModuleProps {
  progress: number;
  courseId: number;
  subItemId: number;
  language: string;
  forceStartable: boolean;
}

export function CourseModule({
  courseId,
  subItemId,
  language,
  progress,
  forceStartable = false,
}: Readonly<CourseModuleProps>) {
  const router = useRouter();
  const { openScorm } = useScormAuth(courseId);
  const translate = useTranslationFunction();
  const [data, isLoading] = useCourseSubitemDescription(courseId, subItemId, language);
  const startLabel = translate(
    data?.media?.contentType === 'FILE' ? 'campus_open' : 'campus_start',
  );
  const isLocked = !forceStartable && progress === 0;
  const isDone = progress > 99;
  const { setCompleted } = useCampusMediaState(courseId);
  const { employeeToken, ciamToken } = useCampusAuthGate(courseId);
  const { CAMPUS_BASE_URL } = useCampusEnv();
  if (isLoading && !data) {
    return <Loader />;
  }

  const accessToken = emptyStringFallback(ciamToken, employeeToken);

  return (
    <li
      className={classNameBuilder(
        styles.module,
        isDone && styles.moduleFinished,
        isLocked && styles.moduleLocked,
      )}
    >
      <ProgressBar progress={progress} locked={isLocked} isMobile={false} />
      <div className={styles.textBody}>
        <strong>{data.media.name}</strong>
        <p>{striptags(data.media.description)}</p>
      </div>
      <div className="actions">
        {data.media?.contentType !== 'LINK' ? (
          <Button
            buttonType="button"
            disabled={isLocked}
            onClick={() => {
              if (data.media.contentType === 'MEDIA_FILE') {
                router.push(`#content-${data.media.id}`);
              } else if (data.media.contentType === 'FILE') {
                const mediaId = data.media.id;
                Object.assign(document.createElement('a'), {
                  target: '_blank',
                  href: `${CAMPUS_BASE_URL}${data.media.mediaFileLink}`,
                }).click();
                setCompleted({ mediaId, accessToken });
              } else {
                openScorm(data.media.id);
              }
            }}
          >
            {!forceStartable && !isDone && !isLocked && translate('campus_continue')}
            {!isDone && (forceStartable || isLocked) && startLabel}
            {isDone && translate('campus_repeat')}
          </Button>
        ) : (
          <Button
            buttonType="button"
            disabled={isLocked}
            onClick={() => {
              Object.assign(document.createElement('a'), {
                target: '_blank',
                href: data.media.source,
              }).click();
            }}
          >
            {startLabel}
          </Button>
        )}
      </div>
    </li>
  );
}

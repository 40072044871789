'use client';

import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter, usePathname } from 'next/navigation';
import Head from 'next/head';
import striptags from 'striptags';

// styles
import styles from './layout.module.scss';

// types
import { CourseDateList, CourseMetaItem } from 'components/Campus/types';

// components
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import CampusLayout from './CampusLayout';
import { InternalLink } from 'components/Link/InternalLink';
import { Translation } from 'components/Translation/Translation';
import { ContactModule } from 'components/Campus/Contact/ContactModule';
import { CourseInformation } from 'components/Campus/CourseInformation/CourseInformation';
import { CourseUserList } from 'components/Campus/CourseUserList/CourseUserList';
import { CourseDownloads } from 'components/Campus/CourseDownloads/CourseDownloads';
import CourseBookedContent from 'components/Campus/CourseBookedContent/CourseBookedContent';
import CampusDetailWorkflowWrapper from './CampusDetailWorkflowWrapper';
import { Loader } from 'components/Loader/Loader';

// utils
import {
  useCampusAuthGate,
  useCampusEnv,
  useCampusTypes,
  useClassifications,
  useCourseMedia,
  useCourseUserProgress,
} from 'utils/hooks/useCampus';
import { useCampusToken } from 'utils/hooks/useCiam';
import {
  campusReferenceUrlSelector,
  campusDashboardUrlSelector,
} from 'utils/selectors/globalsSelectors';
import { generateSlug } from 'utils/slug';
import { getMetatag } from 'components/Campus/metatags';
import { truncateAtWordBoundary } from 'utils/truncate';
import { useCostsIncludedMetatags } from 'utils/hooks/useCostsIncludedMetatags';
import { emptyStringFallback } from 'utils/is-empty';

interface CampusCourseDetailsBookedProps extends Content {
  courseId: string;
  participantId: string;
}

export function CampusCourseDetailsBooked({
  courseId: courseIdString,
  participantId,
  fs_language: language,
}: Readonly<CampusCourseDetailsBookedProps>) {
  const { CAMPUS_META, CAMPUS_CLASS_TARGETGROUPS } = useCampusEnv();
  const courseId = parseInt(courseIdString);
  const { loading: ciamIsLoading, campusToken: ciamToken } = useCampusToken();
  const { progress, data, reload } = useCourseUserProgress({ userId: participantId, courseId });
  const { items: subitems, isLoading: subitemsLoading } = useCourseMedia(courseId, language);
  const [type] = useCampusTypes(data?.topics);
  const coursePath = useRef<string | undefined>();
  const router = useRouter();
  const pathname = usePathname();

  const { getClassification } = useClassifications();
  const referenceUrl = useSelector(campusReferenceUrlSelector);
  const campusDashboardUrl = useSelector(campusDashboardUrlSelector);
  const { employeeToken, isLoading } = useCampusAuthGate(courseId);

  useEffect(() => {
    if (data) {
      if (!coursePath.current) {
        coursePath.current = `${referenceUrl}${generateSlug(
          data.name,
        )}/crs_${courseId}_pro_${participantId}-${language}/`;
      }
    }
  }, [data]);

  const metaTags = data?.metaTags as CourseMetaItem[];
  const campusCostsIncluded = useCostsIncludedMetatags(metaTags);

  if (
    pathname.indexOf('#') === -1 &&
    coursePath.current &&
    pathname &&
    coursePath.current !== pathname &&
    pathname.toLowerCase().indexOf(`crs_${courseId}_pro_${participantId}-`) > -1
  ) {
    router.replace(coursePath.current);
    return null;
  }

  if (ciamIsLoading || isLoading) {
    return (
      <main className={styles.campusMain}>
        <div className={`grid-container ${styles.loaderWrapper}`}>
          <Loader />
        </div>
      </main>
    );
  }

  // get teaser data
  const dateStart = getMetatag(CAMPUS_META.START_DATE, metaTags);
  const dateEnd = getMetatag(CAMPUS_META.END_DATE, metaTags);
  const courseDates: CourseDateList = [];

  if (dateStart) {
    courseDates.push({
      day: dateStart.split(' ')[0],
      time: dateStart.split(' ')[1],
    });
  }
  if (dateEnd) {
    courseDates.push({
      day: dateEnd.split(' ')[0],
      time: dateEnd.split(' ')[1],
    });
  }

  // target groups
  const targetGroups = getClassification(CAMPUS_CLASS_TARGETGROUPS);
  const idInCourseTargetGroups = (id) =>
    !!data?.topics?.find((i) => parseInt(i?.id, 10) === parseInt(id, 10));
  const courseTargetGroups = targetGroups?.filter((i) => idInCourseTargetGroups(i?.id));
  const courseContents = data?.metaTags
    ?.filter((item) => CAMPUS_META.COURSE_CONTENTS.includes(item?.id))
    ?.filter((item) => item.content.trim() !== '');
  const courseBenefits = data?.metaTags
    ?.filter((item) => CAMPUS_META.COURSE_BENEFITS.includes(item?.id))
    ?.filter((item) => item.content.trim() !== '');

  return (
    <CampusDetailWorkflowWrapper
      key={`wrapper-${subitems?.length}`}
      courseId={courseId}
      reload={reload}
      subitems={subitems}
      data={data}
    >
      <Head>
        <title>{striptags(getMetatag(CAMPUS_META.NAME, data?.metaTags))}</title>
      </Head>
      <CampusLayout>
        {/* section: header */}
        <div className={`grid-container ${styles.courseDetailsHeader}`}>
          {/* link to overview */}
          {ciamToken && (
            <InternalLink
              content={{ target: campusDashboardUrl, showArrow: true }}
              className="web20-icon-arrow-link-light-left"
            >
              <Translation id="campus_back_dashboard" />
            </InternalLink>
          )}
          {/* learning form */}
          <div className={styles.learningForm}>{data?.learningForm}</div>
          {/* headline */}
          <PageHeadline
            Format={TitleFormats.h1}
            title={truncateAtWordBoundary(getMetatag(CAMPUS_META.NAME, metaTags), 60) as string}
            className={styles.gddsHeadlineH1}
            pageHeadline
          />
          {/* description */}
          <div className={styles.courseTeaser}>
            {truncateAtWordBoundary(striptags(getMetatag(CAMPUS_META.SUMMARY, metaTags)), 190)}
          </div>
        </div>
        {/* section: course information */}
        <CourseInformation
          image={emptyStringFallback(
            getMetatag(CAMPUS_META.IMAGE, metaTags),
            emptyStringFallback(data?.moocImage, undefined),
          )}
          courseHeadline={getMetatag(CAMPUS_META.NAME, metaTags)}
          courseDate={courseDates.length > 0 ? courseDates : undefined}
          availableSeats={data?.freePlaces > 0 ? data?.freePlaces : undefined}
          seats={data?.freePlaces > 0 && getMetatag(CAMPUS_META.MAX_CAPACITY, metaTags)}
          location={getMetatag(CAMPUS_META.LOCATION, metaTags)}
          language={getMetatag(CAMPUS_META.LANGUAGE, metaTags)}
          costs={getMetatag(CAMPUS_META.PRICE, metaTags)}
          costsIncluded={campusCostsIncluded}
          isBooked
          info={getMetatag(CAMPUS_META.INFOBOX, metaTags)}
          targetGroup={courseTargetGroups?.map((i) => i.name).join(', ')}
          isBookedPage
          isEmployee={!ciamToken}
          duration={getMetatag(CAMPUS_META.DURATION, metaTags)}
          isCiam={!ciamIsLoading && !!ciamToken}
        />
        <CourseBookedContent
          courseId={courseId}
          description={getMetatag(CAMPUS_META.DESCRIPTION, metaTags)}
          courseBenefits={courseBenefits}
          courseContents={courseContents}
          language={language}
          tutors={data?.tutors}
          data={data}
          participantId={participantId}
          subitems={subitems}
          subitemsLoading={subitemsLoading}
          progress={progress}
        >
          {(ciamToken || employeeToken) && (
            <CourseDownloads
              details={data}
              courseId={courseId}
              participantId={participantId}
              language={language}
            />
          )}
          {ciamToken && (
            <CourseUserList
              courseId={courseId}
              courseTitle={getMetatag(CAMPUS_META.NAME, metaTags)}
              isWebinar={type === 'webinar'}
            />
          )}
        </CourseBookedContent>
        {!ciamToken && <ContactModule />}
      </CampusLayout>
    </CampusDetailWorkflowWrapper>
  );
}

import { useFormikContext } from 'formik';
import { FormField, TextField } from '@geberit/gdds';

// styles
import styles from '../../form.module.scss';

// types
import type { InputFieldField } from './input-field';

// utils
import { isEmpty } from 'utils/is-empty';

type InputTypes = 'number' | 'time' | 'text' | 'date' | 'password';

interface InputFieldViewGddsProps {
  field: InputFieldField;
  handleOnChange: (
    e?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    maxFileCount?: number,
  ) => void;
}

export function InputFieldViewGdds({
  field: { type, name, placeholder, label, readonly, required },
  handleOnChange,
}: Readonly<InputFieldViewGddsProps>) {
  const { values, errors, touched } = useFormikContext<Record<string, string>>();

  const hasErrors = !isEmpty(errors[name]) && touched?.[name];
  const isTextarea = type === 'textarea';
  const Field = isTextarea ? TextField : FormField;
  const inputType = isTextarea ? undefined : (type as InputTypes);

  return (
    <div className={hasErrors ? `form-item--error ${styles.hasError}` : undefined}>
      <Field
        type={inputType}
        name={name}
        placeholder={placeholder}
        label={`${label}${required ? '*' : ''}`}
        errors={(touched?.[name] && (errors as Record<string, string>)) || undefined}
        onChange={handleOnChange}
        values={values}
        readOnly={readonly}
        autoHeight={isTextarea}
      />
    </div>
  );
}

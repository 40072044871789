import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// utils
import { useScript } from 'components/ContentElements/Ciam/use-script';
import { ciamScriptSelector, ciamKeySelector } from 'utils/selectors/globalsSelectors';
import { useIsoLang } from 'scenes/MetaData';

export function useGigya() {
  const [gigya, setGigya] = useState<any>(undefined);
  const locale = useIsoLang();
  const scriptUrl = useSelector(ciamScriptSelector);
  const apiKey = useSelector(ciamKeySelector);

  const { isLoaded } = useScript({
    scriptUrl,
    apiKey,
    locale,
  });

  const onReady = useCallback(() => {
    setGigya(window.gigya);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      onReady();
    }
  }, [isLoaded, onReady]);

  useEffect(() => {
    if (window.gigya) {
      onReady();
      return;
    }

    window.addEventListener('gigya.session.ready', onReady);

    return () => {
      window.removeEventListener('gigya.session.ready', onReady);
    };
  }, [onReady]);

  return { gigya };
}

import { Datepicker, Toggle } from '@geberit/gdds';
import { FormikProps } from 'formik';

// styles
import styles from '../registrationForm.module.scss';

// utils
import { useToggleItems } from './utils';
import { useCreateFormLabel } from '../hooks';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { classNameBuilder } from 'utils/classNameBuilder';

interface HotelDataProps {
  nameSuffix: string;
  mandatoryErrorMessage?: string;
}

export default function HotelData({
  nameSuffix,
  mandatoryErrorMessage,
  touched,
  handleBlur,
  handleChange,
  values,
  errors,
}: HotelDataProps &
  Pick<FormikProps<any>, 'touched' | 'errors' | 'values' | 'handleBlur' | 'handleChange'>) {
  const createLabel = useCreateFormLabel();
  const toggleItems = useToggleItems();
  const translate = useTranslationFunction();

  return (
    <>
      <div className="grid-x">
        <div
          className={classNameBuilder(
            'small-12 medium-12',
            touched?.[`needhotel${nameSuffix}`] &&
              errors?.[`needhotel${nameSuffix}`] &&
              styles.errorBorder,
          )}
        >
          <span className={styles.hotelLabel}>{createLabel('needhotel', 'campus_form_hotel')}</span>
          <Toggle
            errors={touched?.[`needhotel${nameSuffix}`] && errors?.[`needhotel${nameSuffix}`]}
            onChange={(value) =>
              handleChange({ target: { name: `needhotel${nameSuffix}`, value } })
            }
            onBlur={handleBlur}
            values={values}
            type="radio"
            name={`needhotel${nameSuffix}`}
            alignment="horizontal"
            items={toggleItems}
            errorMessage={mandatoryErrorMessage}
          />
        </div>
      </div>
      {values?.[`needhotel${nameSuffix}`] === 'yes' && (
        <div className="grid-x">
          <div className="small-6 medium-3 left">
            <Datepicker
              label={translate('campus_arrival_date')}
              date={new Date()}
              onDateSelected={({ date }) =>
                handleChange({
                  target: { name: `arrival${nameSuffix}`, value: date?.toISOString() ?? '' },
                })
              }
            />
          </div>
          <div className="small-6 medium-3 right">
            <Datepicker
              label={translate('campus_depature_date')}
              date={new Date()}
              onDateSelected={({ date }) =>
                handleChange({
                  target: { name: `departure${nameSuffix}`, value: date?.toISOString() ?? '' },
                })
              }
            />
          </div>
        </div>
      )}
    </>
  );
}

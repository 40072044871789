import { Col, Row, Button } from '@geberit/gdds';

// styles
import styles from './gdds-loadmore-button.module.scss';

// utils
import { useTranslationFunction } from '../../../../utils/hooks/use-translations';

interface GDDSLoadmoreButtonProps {
  disabled: boolean;
  onClick: () => void;
}

export function GDDSLoadmoreButton(props: Readonly<GDDSLoadmoreButtonProps>) {
  const translate = useTranslationFunction();

  return (
    <Row>
      <Col>
        <div className={styles.loadmoreButton} key="button-item-wrapper">
          <Button {...props} stretched key="button" symbol="Reload">
            {translate('web20_more_results')}
          </Button>
        </div>
      </Col>
    </Row>
  );
}

import { Log } from 'services/log';

export const humanizeFileSize = (bytes: number, si = false, dp = 1, locale = '') => {
  const thresh = si ? 1000 : 1024;
  let currentBytes = bytes;

  if (Math.abs(currentBytes) < thresh) {
    return `${currentBytes} B`;
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    currentBytes /= thresh;
    u += 1;
  } while (Math.round(Math.abs(currentBytes) * r) / r >= thresh && u < units.length - 1);
  let fixedSize = currentBytes.toFixed(dp);
  if (locale) {
    try {
      fixedSize = parseFloat(fixedSize).toLocaleString(locale.replace('_', '-'));
    } catch (e) {
      Log.warn('Failed to format file size', e);
    }
  }

  return `${fixedSize} ${units[u]}`;
};

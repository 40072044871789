import { useRouter } from 'next/navigation';

// styles
import styles from './tags.module.scss';

// types
import type { TagsProps } from './tag.types';
import type { Tag } from '../PressPortal/types/mynewsdesk.types';

// components
import TagElement from './tag';
import { Headline, FontWeights, Formats } from 'components/ContentElements/Headline';

// utils
import { encodeUmlaute } from '../PressPortal/utils';
import { FocusVisibleManager } from 'utils/focus-visible';
import { useKolo } from 'utils/hooks/use-kolo';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';

export default function Tags({ tags }: Readonly<TagsProps>) {
  const router = useRouter();
  const translate = useTranslationFunction();
  const isKolo = useKolo();

  function getOnClickHandler(
    tag: Tag,
  ): (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void {
    return () => {
      const url = new URL(window.location.href);
      url.searchParams.set('tag', encodeUmlaute(tag.name.split(/\s+/).join('-').toLowerCase()));
      url.searchParams.delete('page');
      url.searchParams.delete('title');
      router.push(`${url.pathname}${url.search}`);
    };
  }

  if (isEmpty(tags)) return null;

  return (
    <FocusVisibleManager>
      <div className={styles.tagSection}>
        <Headline
          format={Formats.h4}
          title={translate('press_all_tags')}
          titleFontWeight={FontWeights.bold}
          subtitleFontWeight={isKolo ? FontWeights.bold : undefined}
        />
        <div className={styles.tagWrapper}>
          {tags.map((el, i) => {
            return (
              <TagElement onClick={getOnClickHandler(el)} key={i} name={el.name} url={el.url} />
            );
          })}
        </div>
      </div>
    </FocusVisibleManager>
  );
}

import { useRef } from 'react';
import Dropdown, { DropdownContent, DropdownTrigger } from 'react-simple-dropdown';

// components
import { Translation } from 'components/Translation/Translation';
import { BUTTON_TYPES, Button } from 'components/Button/Button';

interface ResultsPerPageProps {
  resultsPerPage: number;
  setResultsPerPage: (resultsPerPage: number) => void;
  total: number;
}

export default function ResultsPerPage({
  resultsPerPage,
  setResultsPerPage,
  total,
}: Readonly<ResultsPerPageProps>) {
  const defaultPerPage = useRef<number>(resultsPerPage);
  const dropdown = useRef<Dropdown | null>();

  /**
   * Update DropDownTrigger text and close dropdown
   * @param e Event
   */
  const dropDownClick = (e) => {
    setResultsPerPage(parseInt(e.currentTarget.innerHTML, 10));
    dropdown.current.hide();
  };

  const list: number[] = [];

  // Array of available resultsPerPage
  for (let i = 0; i < 3; i += 1) {
    list.push(2 ** i * defaultPerPage.current);
  }

  // render Results per list dropdown and only show options which have a effect
  // don't show 48 if there are only 13 results
  const options = list.map((item, index) => {
    if (item < total || (item > total && list[index - 1] < total)) {
      return (
        <li key={item}>
          <Button type={BUTTON_TYPES.PLAIN} onClick={dropDownClick}>
            {2 ** index * defaultPerPage.current}
          </Button>
        </li>
      );
    }

    return null;
  });

  return (
    <div className="pagination-right">
      <span>
        <Translation id="web20_results_per_page" />
      </span>
      <Dropdown className="c-pagination__filter" ref={dropdown}>
        <DropdownTrigger className="c-pagination__trigger">{resultsPerPage}</DropdownTrigger>
        <DropdownContent className="c-pagination__dropdown-content">
          <ul>{options}</ul>
        </DropdownContent>
      </Dropdown>
      <span>
        <Translation id="web20_results_per_page_to_total" /> {total}
      </span>
    </div>
  );
}

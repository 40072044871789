import { useCallback } from 'react';
import { Typo, Icon, Divider } from '@geberit/gdds';

// styles
import { StyledDetails } from './details.styles';

// types
import type { IEntry } from '../../locator.types';

// utils
import { useLocatorConfiguration } from '../../utils/use-locator-configuration';
import { useIsMobile } from 'components/App/SizeProvider';
import { decodingContent } from 'utils/decodingContent';
import { useCountryByIsoCode } from 'utils/hooks/use-country-by-isocode';
import { useTracking } from 'utils/tracking/track';

export function Details({
  address,
  zip,
  city,
  phone,
  email,
  website,
  name,
  country,
}: Readonly<IEntry>) {
  const locatorConfig = useLocatorConfiguration();
  const isMobile = useIsMobile();
  const getCountryByCode = useCountryByIsoCode();
  const { trackLocator } = useTracking();

  const track = useCallback(
    (clickIntent: string) => {
      return () => {
        trackLocator({
          click_intent: clickIntent,
          search_term: name,
          value: 10,
        });
      };
    },
    [trackLocator, name],
  );

  return (
    <StyledDetails>
      <Typo variant="p1" fontWeight={700}>
        {decodingContent(locatorConfig.dealerPage?.contact)}
      </Typo>
      <Typo variant="p1">{address}</Typo>
      <Typo variant="p1">{`${zip} ${city}`}</Typo>
      <Typo variant="p1">{getCountryByCode(country)}</Typo>
      <div className="contact-info">
        {phone && (
          <div>
            <div className="links">
              <Icon withHitSpace symbol="telephone" />
              <a href={`tel:${phone}`} onClick={track('locator contact - tel')}>
                {phone}
              </a>
            </div>
            <Divider alignment={isMobile ? 'horizontal' : 'vertical'} />
          </div>
        )}
        {email && (
          <div>
            <div className="links">
              <Icon withHitSpace symbol="envelope" />
              <a href={`mailto:${email}`} onClick={track('locator contact - mail')}>
                <span className="printable">{email}</span>
                <span>{decodingContent(locatorConfig.dealerPage?.email)}</span>
              </a>
            </div>
            <Divider alignment={isMobile ? 'horizontal' : 'vertical'} />
          </div>
        )}
        {website && (
          <div>
            <div className="links">
              <Icon withHitSpace symbol="globe" />
              <a
                href={website}
                target="_blank"
                onClick={track('locator contact - website')}
                rel="noreferrer"
              >
                <span className="printable">{website}</span>
                <span>{decodingContent(locatorConfig.dealerPage?.website)}</span>
              </a>
            </div>
          </div>
        )}
      </div>
    </StyledDetails>
  );
}

/**
 * Custom slick settings for contact module
 *
 * @returns {Object}
 */
export const slickSettings = {
  infinite: false,
  autoplay: false,
  swipeToSlide: true,
  dots: true,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 991, // Breakpoint "large"
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767, // Breakpoint "small"
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

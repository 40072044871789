import { useState, useEffect, useRef } from 'react';
import Querystring from 'qs';
import sanitize from 'xss';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { useRouter, useSearchParams } from 'next/navigation';

// components
import SearchBar from '../SearchBar/SearchBar';
import { Translation } from '../Translation/Translation';
import SearchInfo from './SearchInfo/SearchInfo';
import SearchNavigation from './SearchNavigation';
import SearchResults from './SearchResults';
import NoResults from './NoResults';

// types
import type { AnimatedLogoProps } from '../Logo/types';

// utils
import {
  selectParamsFromState,
  searchIsFetchingSelector,
  searchNoResultsSelector,
} from 'utils/selectors/searchSelectors';
import { fetchSearchResults, setSearchQuery, setSearchOffset, setSearchFilter } from './actions';
import {
  globalsSearchResultContent,
  globalsSearchSegmentSelector,
  globalsSelector,
} from 'utils/selectors/globalsSelectors';
import { currentCatalogSelector } from 'utils/selectors/productCatalogSelectors';
import { classNameBuilder } from 'utils/classNameBuilder';

export default function SearchContainer() {
  const dispatch = useDispatch();
  const contentDisabled = useSelector(globalsSearchResultContent);
  const params = useSelector(selectParamsFromState);
  const segment = useSelector(globalsSearchSegmentSelector);
  const catalogLanguage = useSelector(currentCatalogSelector);
  const isFetching = useSelector(searchIsFetchingSelector);
  const noResults = useSelector(searchNoResultsSelector);
  const router = useRouter();
  const query = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(contentDisabled ? 1 : 0);
  const prevParams = useRef(params);
  const animatedLogo: AnimatedLogoProps = useSelector(globalsSelector)?.['animatedLogos'];
  const isAnimatedLogoEnabled = animatedLogo?.enableAnimatedLogos === 'enabled';

  // reset offset on tab change
  const setTab = (tabChange) => {
    if (params.offset) dispatch(setSearchOffset());
    if (params.filter) dispatch(setSearchFilter());
    setSelectedTab(tabChange);
  };

  const handleSearchChange = (newQuery: string) => {
    // we need to pass down the raw query because it will be sanitized again in the action
    dispatch(setSearchQuery(newQuery.replace('&amp;', '&')));
  };

  // use client params on mount
  useEffect(() => {
    const q = query.get('q');
    const filter = query.get('filter');
    const offset = query.get('offset');

    // if search page is loaded without a query set the default query
    if (!q) {
      router.replace('?q=');
    }
    if (q) dispatch(setSearchQuery(q));
    if (filter) dispatch(setSearchFilter(filter));
    if (offset) dispatch(setSearchOffset(offset));
  }, [dispatch]);

  // fetch new results on param update
  useEffect(() => {
    dispatch(fetchSearchResults());

    if (!isEqual(prevParams.current, params)) {
      const newParams = { ...params };
      if (newParams.query) {
        newParams.q = newParams.query;
        delete newParams.query;
      }
      const paramQuery = Querystring.stringify(newParams);
      router.replace(`?${paramQuery}`, { scroll: false });
    }
  }, [segment, catalogLanguage, params, dispatch]);

  return (
    <div className="grid-container global_search">
      <div
        className={classNameBuilder(
          'c-result-page',
          isAnimatedLogoEnabled && 'animated-logo-space',
        )}
      >
        <div className="c-search-navigation">
          <SearchBar
            initialQuery={sanitize(params.q || '')
              .replace(/&amp;/g, '&')
              .replace(/&gt;/g, '>')}
            customHandler={handleSearchChange}
          />
        </div>
        <h1 className="page-headline">
          <Translation id="web20_search_headline" />
        </h1>
        {!isFetching && noResults && <NoResults />}
        {!noResults && (
          <div className="c-product-result-wrapper">
            <SearchNavigation tab={selectedTab} setTab={setTab} />
            <SearchInfo tab={selectedTab} />
            <SearchResults tab={selectedTab} />
          </div>
        )}
      </div>
    </div>
  );
}

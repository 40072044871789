'use client';

// styles
import { HiddenH1 } from './home.styles';

// types
import type { HomeProps } from './home.types';

// components
import Footer from 'components/Footer/Footer';
import { Slider } from 'components/ContentElements/slider';
import { ContentArea } from 'components/ContentArea/ContentArea';
import SearchBarHome from 'components/SearchBar/SearchBarHome';
import { HeroImageGdds } from 'components/ContentElementsGdds/hero-image/hero-image-gdds';

// utils
import { isEmpty } from 'utils/is-empty';
import { getHeroImageItems } from './Home.utils';
import { useContent } from 'components/Page/page-hooks';

export function Home() {
  const page = useContent<{}, HomeProps>();

  if (isEmpty(page?.contentAreas)) {
    return null;
  }

  const {
    metaData,
    page: { slider, heroTile },
    contentAreas,
  } = page;

  const sliderItems = getHeroImageItems({ items: slider?.items });

  return (
    <>
      <main>
        {metaData.title && <HiddenH1>{metaData.title}</HiddenH1>}
        <HeroImageGdds items={sliderItems} />
        <SearchBarHome />
        {!isEmpty(heroTile?.items) && (
          <div className="grid-container">
            <Slider
              items={heroTile.items}
              slideDuration={heroTile?.slideDuration || 6000}
              sliderContext="homepage"
              sliderType="hero-tile"
            />
          </div>
        )}
        <ContentArea content={contentAreas?.tile} contentType="tile" from="homepage" />
      </main>
      <Footer />
    </>
  );
}

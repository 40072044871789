import { IEntry } from '../../locator.types';

// utils
import { getDealerCategory, isDealerPartner, isDealerGeberit } from '../../utils/dealer-helpers';

type Brand = 'ifo' | 'ido' | 'porsgrund' | 'geberit';
export type IconMode = 'default' | 'hover' | 'active' | 'focus';
type IconScope = 'map' | 'list';

export function iconBuilder({
  location,
  brand = 'geberit',
  mode = 'default',
  scope = 'map',
  showPartner,
}: Readonly<{
  location: IEntry;
  brand?: Brand;
  scope?: IconScope;
  mode?: IconMode;
  showPartner: boolean | undefined;
}>) {
  const nord = {
    map: {
      installer: {
        default: {
          default: '/images/locator/nordics/map/installer.png',
          hover: '/images/locator/nordics/map/installer-hover.png',
          active: '/images/locator/nordics/map/installer-active.png',
          focus: '/images/locator/nordics/map/installer-focus.png',
        },
        partner: {
          default: '/images/locator/nordics/map/installer-partner.png',
          hover: '/images/locator/nordics/map/installer-partner-hover.png',
          active: '/images/locator/nordics/map/installer-partner-active.png',
          focus: '/images/locator/nordics/map/installer-partner-focus.png',
        },
      },
      showroom: {
        default: {
          default: '/images/locator/nordics/map/showroom.png',
          hover: '/images/locator/nordics/map/showroom-hover.png',
          active: '/images/locator/nordics/map/showroom-active.png',
          focus: '/images/locator/nordics/map/showroom-focus.png',
        },
        partner: {
          default: '/images/locator/nordics/map/showroom-partner.png',
          hover: '/images/locator/nordics/map/showroom-partner-hover.png',
          active: '/images/locator/nordics/map/showroom-partner-active.png',
          focus: '/images/locator/nordics/map/showroom-partner-focus.png',
        },
      },
      'installer-with-showroom': {
        default: {
          default: '/images/locator/nordics/map/installer-with-showroom.png',
          hover: '/images/locator/nordics/map/installer-with-showroom-hover.png',
          active: '/images/locator/nordics/map/installer-with-showroom-active.png',
          focus: '/images/locator/nordics/map/installer-with-showroom-focus.png',
        },
        partner: {
          default: '/images/locator/nordics/map/installer-with-showroom-partner.png',
          hover: '/images/locator/nordics/map/installer-with-showroom-partner-hover.png',
          active: '/images/locator/nordics/map/installer-with-showroom-partner-active.png',
          focus: '/images/locator/nordics/map/installer-with-showroom-partner-focus.png',
        },
      },
    },
    list: {
      installer: {
        default: {
          default: '/images/locator/nordics/list/installer.png',
        },
        partner: {
          default: '/images/locator/nordics/list/installer-partner.png',
        },
      },
      showroom: {
        default: {
          default: '/images/locator/nordics/list/showroom.png',
        },
        partner: {
          default: '/images/locator/nordics/list/showroom-partner.png',
        },
      },
      'installer-with-showroom': {
        default: {
          default: '/images/locator/nordics/list/installer-with-showroom.png',
        },
        partner: {
          default: '/images/locator/nordics/list/installer-with-showroom-partner.png',
        },
      },
    },
  };
  const icons = {
    geberit: {
      map: {
        brand: {
          partner: {
            default: '/images/locator/map/geberit.jpg',
            hover: '/images/locator/map/geberit-hover.jpg',
            active: '/images/locator/map/geberit-active.jpg',
            focus: '/images/locator/map/geberit-focus.jpg',
          },
        },
        installer: {
          default: {
            default: '/images/locator/map/installer.jpg',
            hover: '/images/locator/map/installer-hover.jpg',
            active: '/images/locator/map/installer-active.jpg',
            focus: '/images/locator/map/installer-focus.jpg',
          },
          partner: {
            default: '/images/locator/map/installer-partner.jpg',
            hover: '/images/locator/map/installer-partner-hover.jpg',
            active: '/images/locator/map/installer-partner-active.jpg',
            focus: '/images/locator/map/installer-partner-focus.jpg',
          },
        },
        showroom: {
          default: {
            default: '/images/locator/map/showroom.jpg',
            hover: '/images/locator/map/showroom-hover.jpg',
            active: '/images/locator/map/showroom-active.jpg',
            focus: '/images/locator/map/showroom-focus.jpg',
          },
          partner: {
            default: '/images/locator/map/showroom-partner.jpg',
            hover: '/images/locator/map/showroom-partner-hover.jpg',
            active: '/images/locator/map/showroom-partner-active.jpg',
            focus: '/images/locator/map/showroom-partner-focus.jpg',
          },
        },
        'installer-with-showroom': {
          default: {
            default: '/images/locator/map/installer-with-showroom.jpg',
            hover: '/images/locator/map/installer-with-showroom-hover.jpg',
            active: '/images/locator/map/installer-with-showroom-active.jpg',
            focus: '/images/locator/map/installer-with-showroom-focus.jpg',
          },
          partner: {
            default: '/images/locator/map/installer-with-showroom-partner.jpg',
            hover: '/images/locator/map/installer-with-showroom-partner-hover.jpg',
            active: '/images/locator/map/installer-with-showroom-partner-active.jpg',
            focus: '/images/locator/map/installer-with-showroom-partner-focus.jpg',
          },
        },
      },
      list: {
        brand: {
          partner: {
            default: '/images/locator/list/geberit.jpg',
          },
        },
        installer: {
          default: {
            default: '/images/locator/list/installer.jpg',
          },
          partner: {
            default: '/images/locator/list/installer-partner.jpg',
          },
        },
        showroom: {
          default: {
            default: '/images/locator/list/showroom.jpg',
          },
          partner: {
            default: '/images/locator/list/showroom-partner.jpg',
          },
        },
        'installer-with-showroom': {
          default: {
            default: '/images/locator/list/installer-with-showroom.jpg',
          },
          partner: {
            default: '/images/locator/list/installer-with-showroom-partner.jpg',
          },
        },
      },
    },
    ifo: {
      map: {
        ...nord.map,
        brand: {
          partner: {
            default: '/images/locator/nordics/map/ifo.png',
            hover: '/images/locator/nordics/map/ifo-hover.png',
            active: '/images/locator/nordics/map/ifo-active.png',
            focus: '/images/locator/nordics/map/ifo-focus.png',
          },
        },
      },
      list: {
        ...nord.list,
        brand: {
          partner: {
            default: '/images/locator/nordics/list/ifo.png',
          },
        },
      },
    },
    ido: {
      map: {
        ...nord.map,
        brand: {
          partner: {
            default: '/images/locator/nordics/map/ido.png',
            hover: '/images/locator/nordics/map/ido-hover.png',
            active: '/images/locator/nordics/map/ido-active.png',
            focus: '/images/locator/nordics/map/ido-focus.png',
          },
        },
      },
      list: {
        ...nord.list,
        brand: {
          partner: {
            default: '/images/locator/nordics/list/ido.png',
          },
        },
      },
    },
    porsgrund: {
      map: {
        ...nord.map,
        brand: {
          partner: {
            default: '/images/locator/nordics/map/porsgrund.png',
            hover: '/images/locator/nordics/map/porsgrund-hover.png',
            active: '/images/locator/nordics/map/porsgrund-active.png',
            focus: '/images/locator/nordics/map/porsgrund-focus.png',
          },
        },
      },
      list: {
        ...nord.list,
        brand: {
          partner: {
            default: '/images/locator/nordics/list/porsgrund.png',
          },
        },
      },
    },
  };

  const isPartner = showPartner && isDealerPartner(location.partnertype);
  const category = getDealerCategory(location);
  const isInstallerWithShowroom = Array.isArray(category);

  if (isDealerGeberit(location.partnertype)) {
    return icons[brand][scope].brand.partner[mode];
  }

  if (isInstallerWithShowroom) {
    if (isPartner) {
      return icons[brand][scope]['installer-with-showroom'].partner[mode];
    }

    return icons[brand][scope]['installer-with-showroom'].default[mode];
  }

  if (category === 'i') {
    if (isPartner) {
      return icons[brand][scope].installer.partner[mode];
    }

    return icons[brand][scope].installer.default[mode];
  }

  if (category === 's') {
    if (isPartner) {
      return icons[brand][scope].showroom.partner[mode];
    }

    return icons[brand][scope].showroom.default[mode];
  }

  return icons[brand][scope].installer.default[mode];
}

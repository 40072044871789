import Link from 'next/link';
import { usePathname } from 'next/navigation';

// styles
import styles from './breadcrumb.module.scss';

// components
import { Icon } from 'components/Icon/Icon';

// utils
import useShowBreadcrumbs from 'utils/hooks/use-show-breadcrumbs';

export default function Breadcrumb({ item = '' }: Readonly<{ item?: string }>) {
  const showBreadcrumbs = useShowBreadcrumbs();
  const pathname = usePathname();

  if (!showBreadcrumbs) return null;

  return (
    <div className={styles.c_breadcrumb}>
      <ol>
        <li className={styles.c_breadcrumb__item}>
          <div className={styles.c_breadcrumb__item__home}>
            <Link href="/">
              <Icon symbol="breadcrumb-home" />
            </Link>
          </div>
        </li>
        <li className={styles.c_breadcrumb__item}>
          <Link href={pathname}>{item}</Link>
        </li>
      </ol>
    </div>
  );
}

import { ReactNode } from 'react';
import { Button, Carousel } from '@geberit/gdds';

// styles
import styles from './hero-image.module.scss';

type HeroImageCarouselProps = {
  items: Array<{ colorVariant: string }>;
  children: ReactNode;
};

function HeroImageCarousel({ children }: Readonly<HeroImageCarouselProps>) {
  return (
    <div className={styles.heroimageCarousel}>
      <Carousel
        autoSnap
        responsiveLayout={{
          small: { slides: 1, slidesToMove: 1 },
          medium: { slides: 1, slidesToMove: 1 },
          large: { slides: 1, slidesToMove: 1 },
        }}
        autoSliding
        loop
        slideMargin={0}
        customButtonRight
        customButtonLeft
        nextEl=".carousel-right-button"
        prevEl=".carousel-left-button"
      >
        {children}
      </Carousel>
      <div className={styles.buttonCarouselWrapper}>
        <Button className="carousel-left-button" rounded symbol="ChevronLeft" isIcon />
        <Button className="carousel-right-button" rounded symbol="ChevronRight" isIcon />
      </div>
    </div>
  );
}

export default HeroImageCarousel;

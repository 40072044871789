import { currentLanguageSelector } from 'utils/selectors/languageSelectors';

const downloadCenterSelector = (state) => {
  const currentLanguage = currentLanguageSelector(state);

  if (!currentLanguage || !state.downloadCenter) return undefined;

  return state.downloadCenter[currentLanguage] || undefined;
};

export const downloadCenterDataSelector = (state) => {
  const downloadCenter = downloadCenterSelector(state);

  if (!downloadCenter) return undefined;

  return downloadCenter.data || undefined;
};

const downloadCenterEditingSelector = (state: AppState) => {
  if (!state.downloadCenter?.editing) return undefined;

  return state.downloadCenter.editing;
};

export const downloadCenterEditingLoadingSelector = (state) => {
  const editing = downloadCenterEditingSelector(state);

  if (!editing) return false;

  return editing.isLoading;
};

export const downloadCenterEditingItemsSelector = (state: AppState) => {
  const editing = downloadCenterEditingSelector(state);

  if (!editing) return undefined;

  return editing.items;
};

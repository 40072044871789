export function normalizeFilename(filename: string): string {
  return filename
    .normalize('NFKC')
    .replace('ü', 'ue')
    .replace('ö', 'oe')
    .replace('ä', 'ae')
    .replace('ß', 'ss')
    .replace(/Ü(?=[a-zäöüß ])/g, 'Ue')
    .replace(/Ö(?=[a-zäöüß ])/g, 'Oe')
    .replace(/Ä(?=[a-zäöüß ])/g, 'Ae')
    .replace('Ü', 'UE')
    .replace('Ö', 'OE')
    .replace('Ä', 'AE')
    .replace(/[\\:/?#[\]@!$&'()*+,;=%\s]/g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\x00-\x7F]/g, '-'); //NOSONAR
}

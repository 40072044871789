// types
import type { LightboxHeaderProps } from './lightbox.types';

// components
import { Translation } from '../Translation/Translation';
import { InnerHtml } from '../InnerHtml/inner-html';
import { BUTTON_TYPES, Button } from '../Button/Button';

export function LightboxHeader({ title, handleClose }: Readonly<LightboxHeaderProps>) {
  return (
    <div className="c-lightbox__title">
      <InnerHtml as="h4" content={title} />
      <Button
        symbol="close"
        type={BUTTON_TYPES.PLAIN}
        onClick={handleClose}
        className="c-lightbox__close"
        noTracking
      >
        <span>
          <Translation id="web20_lightbox_close" />
        </span>
      </Button>
    </div>
  );
}

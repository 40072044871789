export const SORTING_DATA = {
  sorting: [
    {
      id: 'date',
      value: 'web20_downloadcenter_filter_date',
    },
    {
      id: 'meta:title',
      value: 'web20_downloadcenter_filter_title',
    },
  ],
};

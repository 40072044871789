import { defaultTheme as theme } from '@geberit/gdds';

export const headingTextSize = {
  xsmall: theme.fontTokens.font13,
  medium: theme.fontTokens.font13,
  large: theme.fontTokens.font13,
  xlarge: theme.fontTokens.font13,
};

export const resultsAmountSize = {
  xsmall: theme.fontTokens.font10,
  medium: theme.fontTokens.font10,
  large: theme.fontTokens.font10,
  xlarge: theme.fontTokens.font10,
};

export const categoryHeadingSize = {
  xsmall: theme.fontTokens.font11,
  medium: theme.fontTokens.font11,
  large: theme.fontTokens.font11,
  xlarge: theme.fontTokens.font11,
};

import isEmpty from 'lodash.isempty';
import { generateSlug } from './slug';

/**
 * Create pdp seo conform url path
 *
 * @param product
 * @param productUrl   Product url from navigation
 * @param language
 * @param categories
 * @param subCategoryName
 * @param subCategoryId
 * @returns string
 */
export function seoConformProductUrl(
  product,
  productUrl,
  language,
  categories,
  isCategoryPage = false,
  subCategoryName = '',
  subCategoryId = '',
) {
  const firstUrlParam = isEmpty(productUrl) ? '/' : productUrl;
  let category;
  let categoryName;
  let categoryId;

  const { key, categories: productCategory, name } = product;

  if (!isEmpty(subCategoryId) && !isEmpty(subCategoryName)) {
    categoryId = subCategoryId;
    categoryName = generateSlug(subCategoryName);
  } else {
    category = productCategory
      ? productCategory.find((item) => !isEmpty(item.name))
      : categories.find((item) => !isEmpty(item.name));
    categoryName = category && generateSlug(category.name);
    categoryId = category?.key;
  }

  const productKey = key;
  const productName = generateSlug(name);

  if (!categoryName && !categoryId) {
    return `${firstUrlParam}${productName}/${productKey}-${language}/`;
  }

  return `${firstUrlParam}${categoryName}/${categoryId}-${language}/${productName}/${productKey}-${language}/`;
}

/**
 * Create PLP seo conform url path
 *
 * @param {Object} product
 * @param {String} productUrl
 * @param {String} language
 * @param {*} categories placeholder right now
 * @param {Bool} isCategoryPage
 * @param {String} subCategoryName
 */
export function seoConformPLPUrl(
  product,
  productUrl,
  language,
  categories = undefined,
  isCategoryPage = undefined,
  catName = undefined,
) {
  let categoryName;
  let categoryId;
  const firstUrlParam = isEmpty(productUrl) ? '/' : productUrl;

  const { results, categoryId: id, categoryName: name } = product;

  const category =
    !isCategoryPage &&
    !isEmpty(results) &&
    results[0].categories.find((item) => !isEmpty(item.name));

  if (isEmpty(id) && isEmpty(name)) {
    categoryName = generateSlug(isCategoryPage ? catName : category.name);
    categoryId = isCategoryPage ? product.categoryCatalogId : category.key;
  } else {
    categoryName = generateSlug(name || catName);
    categoryId = id;
  }

  return `${firstUrlParam}${categoryName}/${categoryId}-${language}/`;
}

import { useEffect } from 'react';
import { Col, Container, Row, Tabs } from '@geberit/gdds';
import { useRouter, useSearchParams } from 'next/navigation';

// styles
import styles from './navigation-tabs.module.scss';

// utils
import { buildSize, gridSizes } from 'utils/gridSize';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useGdds } from 'utils/hooks/use-gdds';

const categoryTabMap = {
  news: 0,
  media: 1,
};

interface NavigationTabsProps {
  onChange: (selectedTab: number) => void;
  count?: { materials: number; media: number };
}

export function NavigationTabs({ onChange, count }: Readonly<NavigationTabsProps>) {
  const router = useRouter();
  const params = useSearchParams();
  const isGdds = useGdds();
  const translate = useTranslationFunction();

  let selectedTab = 0;

  if (params.has('category') && params.get('category')) {
    selectedTab = categoryTabMap[params.get('category') as string] ?? 0;
  }

  useEffect(() => {
    onChange(selectedTab);
  }, [onChange, selectedTab]);

  return (
    <div className={styles.tabsWrapper}>
      <Container maxContentWidth={isGdds ? buildSize(gridSizes.gddsFullGrid) : '80rem'}>
        <Row>
          <Col>
            <Tabs
              initialSelected={selectedTab}
              alignment="horizontal"
              items={[
                {
                  title: `${translate('press_tab_label1')} ${
                    count?.materials !== undefined ? `(${count.materials})` : ''
                  }`,
                },
                {
                  title: `${translate('press_tab_label2')} ${
                    count?.media !== undefined ? `(${count.media})` : ''
                  }`,
                },
              ]}
              onTabChange={(tab) => {
                const url = new URL(window.location.href);
                url.searchParams.set('category', Object.keys(categoryTabMap)[tab.index]);
                router.push(`${url.pathname}${url.search}`, undefined, { shallow: true });
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

import { useRef } from 'react';
import { useFormikContext } from 'formik';

// types
import type { ToggleButtonField } from './toggle-button';

// components
import { Image } from 'components/image/image';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { isEmpty } from 'utils/is-empty';

interface ToggleButtonViewProps {
  field: ToggleButtonField;
  className: string;
  withImage: boolean;
  handleToggleChange: (value: string) => void;
}

export default function ToggleButtonView({
  field: { options, name, type, rules, comment },
  className,
  withImage,
  handleToggleChange,
}: Readonly<ToggleButtonViewProps>) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { values, errors, touched, handleBlur } =
    useFormikContext<Record<string, string | string[]>>();

  return (
    <div className={className} id={name} ref={ref}>
      <div className="form-items--wrapped">
        {options?.map((option, i) => (
          <label
            htmlFor={`${name}-${i}`}
            key={`${name}-${option.title || (option.label ?? '') || option.value || ''}`}
          >
            {withImage && (
              <Image
                format={option.imageObject?.format}
                data={option.imageObject}
                alt={option.title}
                aspectRatio="1_1"
              />
            )}
            <input
              title={comment}
              id={`${name}-${i}`}
              type={type}
              name={name}
              value={type === 'checkbox' ? values[name][i] : values[name] || ''}
              onBlur={handleBlur}
              checked={
                type === 'checkbox'
                  ? values[name].includes(option.value)
                  : values[name] === option.value
              }
              onChange={() => handleToggleChange(option.value)}
            />
            {typeof option.title === 'string' || typeof option.label === 'string' ? (
              <InnerHtml as="span" content={option.title || option.label} />
            ) : (
              <span>{option.label}</span>
            )}
          </label>
        ))}
      </div>
      <InnerHtml
        as="p"
        className="form-items__error-message"
        content={(errors[name] && touched[name] && !isEmpty(rules) && errors[name]) || ''}
      />
    </div>
  );
}

// components
import { Translation } from 'components/Translation/Translation';
import { BUTTON_BACKGROUND, BUTTON_COLORS, BUTTON_TYPES, Button } from 'components/Button/Button';
import { Image } from 'components/image/image';
import { CmsLink } from 'components/Link/CmsLink';

// utils
import { isEmpty } from 'utils/is-empty';
import { imageAlt, imageAltSpareParts } from 'utils/imageAltTag';
import { useTranslationFunction } from 'utils/hooks/use-translations';

interface ProductDetailSparePartsProps {
  content: {
    key: string;
    name: string;
    image: {
      large: string;
    };
    manufactureFrom: string;
  }[];
  catalogLanguage: string;
}

export default function ProductDetailSpareParts({
  content,
  catalogLanguage,
}: Readonly<ProductDetailSparePartsProps>) {
  const translate = useTranslationFunction();
  const linkCatalogLanguage = catalogLanguage.replace('_', '-');

  if (isEmpty(content)) {
    return null;
  }

  return (
    <div className="grid-container">
      <div className="c-product-spare-parts">
        {content.map((item) => {
          const imageTitle = `${imageAlt(translate('web20_products_image_of'))}${imageAltSpareParts(
            translate('web20_products_spare_parts_for'),
            item.name,
            translate('web20_products_imagealt_suffix'),
          )}`;
          return (
            <div key={item.key} className="c-product-spare-parts__item grid-x grid-margin-x">
              <div className="cell medium-4">
                <div className="c-product-spare-parts__image">
                  <Image format="1_1" src={item.image.large} alt={imageTitle} title={imageTitle} />
                </div>
              </div>

              <div className="cell medium-8">
                <dl className="c-product-spare-parts__list">
                  <dt>
                    <Translation id="web20_products_spare_parts_for" />
                  </dt>
                  <dd>{item.name}</dd>
                  <dt>
                    <Translation id="web20_products_spare_parts_generation" />
                  </dt>
                  <dd>{item.manufactureFrom}</dd>
                </dl>

                <CmsLink
                  className="noArrow"
                  link={{
                    target: `https://${translate(
                      'web20_products_spare_parts_hostname',
                    )}/${linkCatalogLanguage}/spare-part/${item.key}-noVariant.html`,
                    window: '_blank',
                    text: '',
                  }}
                >
                  <Button
                    background={BUTTON_BACKGROUND.PRIMARY}
                    color={BUTTON_COLORS.WHITE}
                    type={BUTTON_TYPES.DEFAULT}
                    symbol="arrow-link-bold-right"
                  >
                    <Translation id="web20_products_browse_details" />
                  </Button>
                </CmsLink>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

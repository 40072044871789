import { useRef } from 'react';

// types
import type { BackgroundImageProps } from './background-image.types';

// components
import { ImageEditWrapper } from 'components/ContentCreator/ImageEditWrapper';

// utils
import { isEmpty } from 'utils/is-empty';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { decodingContent } from 'utils/decodingContent';
import { useLazyLoad } from 'utils/hooks/useLazyLoad';
import { useRetinaSrc } from 'utils/hooks/useRetinaSrc';

export function BackgroundImage({
  imagePath,
  format,
  previewId = '',
  format2x,
  format3x,
  isRemoteMedia = false,
  children,
  className = '',
  tabIndex = -1,
  style = {},
  title,
  retinaSrc,
  type = '',
}: Readonly<BackgroundImageProps>) {
  const restProps = {
    ...(isEmpty(className) ? {} : { className }),
    ...(isEmpty(tabIndex) ? {} : { tabIndex }),
  };

  const ref = useRef(null);
  const retinaImage = useRetinaSrc(retinaSrc);
  const lazyImagePath = useLazyLoad(ref, typeof retinaImage === 'string' ? retinaImage : imagePath);
  const isPreview = useIsPreview();
  const finalImagePath = isPreview ? `${lazyImagePath}?v=${new Date().getTime()}` : lazyImagePath;
  const backgroundLoaded = lazyImagePath ? { backgroundImage: `url(${finalImagePath})` } : {};
  const decodedTitle = decodingContent(title);

  return (
    <div
      ref={ref}
      style={{ ...backgroundLoaded, ...style }}
      title={decodedTitle}
      role="img"
      aria-label={decodedTitle}
      className={className || undefined}
      {...restProps}
    >
      <ImageEditWrapper
        previewId={previewId}
        format={format}
        format2x={format2x}
        format3x={format3x}
        isRemoteMedia={isRemoteMedia}
        tppIsPage={type === 'above-breadcrumb'}
      >
        {children}
      </ImageEditWrapper>
    </div>
  );
}

import { useState } from 'react';
import key from 'weak-key';
import { AccordionBody, AccordionItem, AccordionTitle } from '@geberit/gdds';

// style
import styles from './participantsTable.module.scss';

// types
import { Participants } from '../types';

// components
import { Translation } from 'components/Translation/Translation';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { useUserAttributes } from 'utils/hooks/useCampus';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useIsMobile, useIsTablet } from 'components/App/SizeProvider';

interface ParticipantsTableProps {
  participants: Participants;
  isEvent?: boolean;
}

export function ParticipantsTable({ participants, isEvent }: Readonly<ParticipantsTableProps>) {
  const translate = useTranslationFunction();
  const [cateringItems] = useUserAttributes('EATING_HABITS');
  const [roleItems] = useUserAttributes('ROLEZWEI');
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const [opened, setOpened] = useState<number | undefined>();

  const matchRoleItems = (input) => roleItems?.find((i) => i.value === input)?.text || '';
  const matchCateringItems = (input) => cateringItems?.find((i) => i.value === input)?.text || '';
  participants.sort((a, b) => a.firstname.localeCompare(b.firstname));

  return (
    <div className={styles.participantsTableWrapper}>
      <InnerHtml
        as="h3"
        className={styles.gddsHeadlineH3}
        content={translate('campus_headline_participants')}
      />
      {isMobile || isTablet ? (
        participants.map((item, index) => (
          <AccordionItem key={key(item)} title={`${item.firstname} ${item.lastname}`}>
            <>
              <AccordionTitle
                opened={opened === index}
                stylingType="withBackground"
                iconAlignment="right"
                title={`${item.firstname} ${item.lastname}`}
                onTitleClick={() => setOpened((prev) => (prev === index ? undefined : index))}
                tabIndex={index}
              />
              <AccordionBody opened={opened === index}>
                <div className={styles.accordionBodyWrapper}>
                  <div className={styles.accordionContent}>
                    <span>
                      <Translation id="campus_form_email" />
                    </span>
                    <span>{item.email}</span>
                  </div>
                  <div className={styles.accordionContent}>
                    <span>
                      <Translation id="campus_form_role" />
                    </span>
                    <span>{matchRoleItems(item.role)}</span>
                  </div>
                  {isEvent && (
                    <>
                      <div className={styles.accordionContent}>
                        <span>
                          <Translation id="campus_form_hotel" />
                        </span>
                        <span>
                          {item.needhotel === 'yes'
                            ? translate('campus_form_yes')
                            : translate('campus_form_no')}
                        </span>
                      </div>
                      <div className={styles.accordionContent}>
                        <span>
                          <Translation id="campus_form_catering" />
                        </span>
                        <span>{matchCateringItems(item.catering)}</span>
                      </div>
                      <div className={styles.accordionContent}>
                        <span>
                          <Translation id="campus_form_food_comment" />
                        </span>
                        <span>{item.foodcomment}</span>
                      </div>
                    </>
                  )}
                </div>
              </AccordionBody>
            </>
          </AccordionItem>
        ))
      ) : (
        <table className={styles.participantsTable}>
          <tr>
            <th>
              <Translation id="campus_form_firstname" />
            </th>
            <th>
              <Translation id="campus_form_lastname" />
            </th>
            <th>
              <Translation id="campus_form_email" />
            </th>
            <th>
              <Translation id="campus_form_role" />
            </th>
            {isEvent && (
              <>
                <th>
                  <Translation id="campus_form_hotel" />
                </th>
                <th>
                  <Translation id="campus_form_catering" />
                </th>
                <th>
                  <Translation id="campus_form_food_comment" />
                </th>
              </>
            )}
          </tr>
          {participants.map((item) => (
            <tr key={key(item)}>
              <td>{item.firstname}</td>
              <td>{item.lastname}</td>
              <td>{item.email}</td>
              <td>{matchRoleItems(item.role)}</td>
              {isEvent && (
                <>
                  <td>
                    {item.needhotel === 'yes'
                      ? translate('campus_form_yes')
                      : translate('campus_form_no')}
                  </td>
                  <td>{matchCateringItems(item.catering)}</td>
                  <td>{item.foodcomment}</td>
                </>
              )}
            </tr>
          ))}
        </table>
      )}
    </div>
  );
}

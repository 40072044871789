import { useContext, useMemo } from 'react';
import { ContentContext } from '../../components/ContentArea/ContentContext';
import { getSectionId } from '../get-section-id';
import { emptyStringFallback } from '../is-empty';

export function useSectionId(
  title: string,
  anchor?: string,
  titleInAnchor?: boolean,
  contentIndex?: number,
) {
  const {
    state: { tocData },
  } = useContext(ContentContext) || { state: {} };

  const sectionId = useMemo(() => {
    if (typeof contentIndex === 'number' && tocData && titleInAnchor) {
      const id = getSectionId(emptyStringFallback(anchor, title), tocData, contentIndex);
      return id;
    }
    return undefined;
  }, [anchor, tocData, titleInAnchor, title, contentIndex]);

  return sectionId;
}

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// types
import type { StepProps } from './Stepper.types';

// utils
import { hasSessionData, userType } from '../../util/helperFunctions';
import activeStepSelector from '../../selectors';
import { changeActiveStepAction } from '../../actions';
import { getStyles } from './utils';

function Step({ ciamId, width, title, icon, first, isLast, index = 0 }: Readonly<StepProps>) {
  const [hover, setHover] = useState(false);
  const dispatch = useDispatch();
  const activeStep = useSelector(activeStepSelector);
  const active = index === activeStep;
  const completed = index < activeStep;
  const highlighted = activeStep === 0 && index === 1 && hasSessionData(userType(ciamId));
  const isRightBarHighlighted = activeStep === 0 && index === 0 && hasSessionData(userType(ciamId));
  const hasHover =
    hover && ((activeStep === 1 && index === 0 && hasSessionData(userType(ciamId))) || highlighted);

  const onClickHandler = () => {
    if (hasSessionData(userType(ciamId))) {
      if (index == 1 && activeStep === 0) {
        dispatch(changeActiveStepAction(activeStep + 1));
      }
      if (index == 0 && activeStep === 1) {
        dispatch(changeActiveStepAction(activeStep - 1));
      }
    }
  };

  const styles = getStyles(width);
  const circleStyle = Object.assign(
    styles.circle,
    completed ? styles.completedCircle : {},
    active ? styles.activeCircle : {},
    highlighted ? styles.highlightedCircle : {},
    hasHover ? styles.hoverStyle : {},
  );
  const titleStyle = Object.assign(
    styles.title,
    completed ? styles.completedTitle : {},
    active ? styles.activeTitle : {},
    highlighted ? styles.highlightedTitle : {},
  );
  const leftStyle = Object.assign(
    styles.leftBar,
    active || completed ? styles.completedBar : {},
    highlighted ? styles.highlightedBar : {},
  );
  const rightStyle = Object.assign(
    styles.rightBar,
    completed ? styles.completedBar : {},
    isRightBarHighlighted ? styles.highlightedBar : {},
  );

  const stepContent = icon || index + 1;

  return (
    <div style={styles.step} onClick={onClickHandler} onKeyDown={onClickHandler}>
      <div
        style={circleStyle}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <span style={styles.index}>{stepContent}</span>
      </div>
      <span style={titleStyle}>{title}</span>
      {!first && <div style={leftStyle} />}
      {!isLast && <div style={rightStyle} />}
    </div>
  );
}

export default Step;

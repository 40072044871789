import { Participant } from '../types';

export function buildParticipant({
  courseType,
  isBooked,
  showFactoryTour,
  values,
}: {
  isBooked: boolean;
  values: any;
  courseType: string;
  showFactoryTour: boolean;
}) {
  let inquirerParticipant: Participant | undefined;
  if (!isBooked && values.participate === 'yes') {
    inquirerParticipant = {
      salutation: values.salutation,
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      role: values.role,
    };
    if (courseType === 'seminar' || courseType === 'event') {
      inquirerParticipant.needhotel = values.needhotel;
      inquirerParticipant.catering = values.catering;
      inquirerParticipant.foodcomment = values.foodcomment;
      inquirerParticipant.arrival = values.arrival;
      inquirerParticipant.departure = values.departure;
      if (showFactoryTour) {
        inquirerParticipant.factory = values.factory;
      }
    }
  }

  return inquirerParticipant;
}

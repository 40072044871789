import axios from 'axios';
import getEndpoint from '../../../utils/endpoints';

export const fetchPrice = (article, lang, state) => {
  const endpoint = `${getEndpoint(
    'webshop',
    state,
  )}/article/${article}/price?lang=${lang}&quantity=1`;

  return new Promise((resolve, reject) => {
    if (!lang || !article) reject(new Error('No lang or article provided'));

    axios
      .get(endpoint)
      .then((response) => {
        resolve(response.data.price);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

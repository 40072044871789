import { useState } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import queryString from 'qs';

/*
  This file contains hooks directly used by the frontend. Connection hooks
  which are more likely to be reused by other components can be found in
  /src/utils/hooks/useInsights.js
*/

export function useLocationFilters() {
  const router = useRouter();
  const pathname = usePathname();
  const path = pathname.slice(-1) !== '/' ? `${pathname}/` : pathname;
  const searchParams = useSearchParams();
  const topic = searchParams.get('topic') ?? '';
  const year = searchParams.get('year') ?? '';
  const [selectedTopics, setSelectedTopics] = useState(topic.split(',').filter((i) => i));
  const [selectedYears, setSelectedYears] = useState(year.split(',').filter((i) => i));

  const buildQueryString = (topics: string[], years: string[]) =>
    queryString.stringify({
      topic: topics.length > 0 ? topics.join(',') : undefined,
      year: years.length > 0 ? years.join(',') : undefined,
    });

  const handleSelectedTopics = (topics: string[]) => {
    setSelectedTopics(topics);

    router.replace(path + `?${buildQueryString(topics, selectedYears)}`, { scroll: false });
  };

  const handleSelectedYears = (years: string[]) => {
    setSelectedYears(years);

    router.replace(path + `?${buildQueryString(selectedTopics, years)}`, { scroll: false });
  };

  const reset = () => {
    setSelectedTopics([]);
    setSelectedYears([]);

    router.replace(path, { scroll: false });
  };

  return {
    selectedTopics,
    setSelectedTopics: handleSelectedTopics,
    selectedYears,
    setSelectedYears: handleSelectedYears,
    reset,
  };
}

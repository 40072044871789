// types
import { MobileProps, MobileSelectorProps } from './mobile.types';

// components
import { Tablet } from './';

// utils
import { useIsMobile, useIsTablet } from 'components/App/SizeProvider';

function Mobile({
  isGdds,
  and,
  children,
}: React.PropsWithChildren<MobileProps & MobileSelectorProps>) {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  if (isTablet && and === 'tablet') {
    return <Tablet isGdds={isGdds}>{children}</Tablet>;
  }

  if (isMobile) {
    return children;
  }

  return null;
}

export default Mobile;

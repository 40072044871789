'use client';

import { useSearchParams } from 'next/navigation';

// Components
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Footer from 'components/Footer/Footer';
import VideocenterChannelWrapper from 'components/VideocenterChannel';

// utils
import { useNord } from 'utils/hooks/use-nord';
import { contentExists, useContent } from 'components/Page/page-hooks';

export function VideocenterChannel({
  channels,
}: Readonly<{
  channels: any[];
}>) {
  const isNord = useNord();
  const params = useSearchParams();
  const page = useContent();

  if (!contentExists(page)) {
    return null;
  }

  const { breadcrumbs } = page;
  const searchQueryChannelId = (params.get('channel') as string) || '';

  return (
    <>
      <main>
        {isNord && <Breadcrumb items={breadcrumbs} />}
        <VideocenterChannelWrapper
          searchQueryChannelId={searchQueryChannelId}
          channels={channels}
          page={page}
        />
      </main>
      <Footer />
    </>
  );
}

import React, { useRef } from 'react';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';

// components
import { Translation } from '../../Translation/Translation';
import { InnerHtml } from '../../InnerHtml/inner-html';
import { BUTTON_TYPES, Button } from '../../Button/Button';

// utils
import { TAB_PRODUCT, TAB_CONTENT } from '../constants';

interface SearchTabDropdownProps {
  activeTab: number;
  handleClick: (tab: number) => void;
  softContentCount: number;
  productsCount: number;
}

function SearchTabDropdown({
  handleClick,
  activeTab = 0,
  softContentCount = 0,
  productsCount = 0,
}: Readonly<SearchTabDropdownProps>) {
  const dropDownRef = useRef(null);

  const contentLabel = (
    <>
      <Translation id="web20_content" /> (
      <InnerHtml as="div" className="inline" content={`${softContentCount}`} />)
    </>
  );

  const productsLabel = (
    <>
      <Translation id="web20_products_articles" /> (
      <InnerHtml as="div" className="inline" content={`${productsCount}`} />)
    </>
  );

  const placeholder =
    activeTab === TAB_PRODUCT ? <span>{productsLabel}</span> : <span>{contentLabel}</span>;

  return (
    <div className="grid-x grid-margin-x">
      <div className="cell small-12 medium-6">
        <Dropdown ref={dropDownRef}>
          <DropdownTrigger>{placeholder}</DropdownTrigger>
          <DropdownContent>
            <Button
              type={BUTTON_TYPES.PLAIN}
              onClick={() => {
                dropDownRef.current.hide();
                handleClick(TAB_CONTENT);
              }}
            >
              {contentLabel}
            </Button>

            <Button
              type={BUTTON_TYPES.PLAIN}
              onClick={() => {
                dropDownRef.current.hide();
                handleClick(TAB_PRODUCT);
              }}
            >
              {productsLabel}
            </Button>
          </DropdownContent>
        </Dropdown>
      </div>
    </div>
  );
}

export default SearchTabDropdown;

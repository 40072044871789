'use client';

import { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';
import key from 'weak-key';
import { Search } from '@geberit/gdds';

// styles
import styles from './layout.module.scss';
import courseList from 'components/Campus/Course/courseList.module.scss';

// components
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import CampusLayout from './CampusLayout';
import { Translation } from 'components/Translation/Translation';
import { FilterWrapper } from 'components/Campus/Filter/FilterWrapper';
import CatalogCourseTeaserLoader from 'components/Campus/CourseTeaser/CatalogCourseTeaserLoader';
import PaginationArrows from 'components/Pagination/PaginationArrows';
import NoResults from 'components/Campus/NoResults/NoResults';
import SortPage from 'components/Campus/SortPage/SortPage';
import { Loader } from 'components/Loader/Loader';

// utils
import { isEmpty } from 'utils/is-empty';
import {
  useClassifications,
  useCatalogList,
  useFilterCatalog,
  useCampusEnv,
} from 'utils/hooks/useCampus';
import { useCampusToken } from 'utils/hooks/useCiam';
import { campusReferenceUrlSelector } from 'utils/selectors/globalsSelectors';
import { useParams } from 'components/ContentElementsGdds/insights-teaser-list/hooks/use-params';
import { useTranslationFunction } from 'utils/hooks/use-translations';

export function CampusCatalog({ metaData }: Readonly<Content>) {
  const { CAMPUS_CLASS_TARGETGROUPS } = useCampusEnv();
  const router = useRouter();
  const { loading: ciamLoading, campusToken: ciamToken } = useCampusToken();
  const [sortMode, setSortMode] = useParams();
  const [filters, setFilters] = useParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState<number>(1);
  const translate = useTranslationFunction();

  const {
    itemList: unsortedItemList,
    itemCount,
    isLoading,
  } = useCatalogList({
    limit: 9999,
    filter: filters,
    query: searchQuery,
    orderBy: !isEmpty(sortMode) ? sortMode.sort : 'startDate',
    orderDir: !isEmpty(sortMode) ? sortMode.order : 'asc',
  });

  // special sorting logic is necessary, otherwise courses with startDate=0
  // would be displayed at the top when sorting by start date for the next course
  const itemList = useMemo(() => {
    if ((!isLoading && isEmpty(sortMode)) || (!isLoading && sortMode.id === 'date_asc')) {
      const sortedItemList = [
        ...unsortedItemList.filter((item) => item.startDate),
        ...unsortedItemList.filter((item) => !item.startDate),
      ];
      return sortedItemList;
    }
    return unsortedItemList;
  }, [unsortedItemList, sortMode, isLoading]);

  // target groups
  const { getClassification } = useClassifications();
  const targetGroups = getClassification(CAMPUS_CLASS_TARGETGROUPS);

  const filterConfig = useFilterCatalog(
    setFilters,
    () => {
      setSearchQuery('');
      setPage(1);
      window.location.hash = 'page=1';
    },
    filters,
  );

  const firstCourseRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const hashPage = window.location.hash.match(/page=(\d+)/);
    if (Array.isArray(hashPage)) {
      const pageNumber = Number(hashPage[1]);
      if (pageNumber !== page) {
        const checkedPage = pageNumber > 0 ? pageNumber : 1;
        setPage(checkedPage);
      }
    }
  }, [router.asPath]);

  const firstCourseScroll = () => {
    if (firstCourseRef.current !== null) {
      window.scrollTo(0, firstCourseRef.current.offsetTop - 80);
    }
  };
  useEffect(firstCourseScroll, [page]);

  const changePage = (newPage) => {
    window.location.hash = `page=${page + newPage}`;
    setPage(page + newPage);
  };

  const landingUrl = useSelector(campusReferenceUrlSelector);

  const handleSearchSubmit = (searchTerm) => {
    setSearchQuery(searchTerm);
    setPage(1);
    window.location.hash = 'page=1';
  };

  const handleSearchChange = (searchTerm) => {
    searchTerm.length <= 0 && setSearchQuery(searchTerm);
  };

  if (ciamLoading) {
    return (
      <main className={styles.campusMain}>
        <div className={`grid-container ${styles.loaderWrapper}`}>
          <Loader />
        </div>
      </main>
    );
  }

  if (!ciamToken) {
    router.replace(landingUrl);
    return null;
  }

  return (
    <CampusLayout metaData={metaData}>
      <PageHeadline
        Format={TitleFormats.h1}
        title={translate('campus_headline_catalog')}
        pageHeadline
      />
      <div className={`grid-container ${styles.introText}`}>
        <p>
          <Translation id="campus_text_catalog" />
        </p>
      </div>
      <div className={styles.campusSearch}>
        <Search
          name="searchcampus"
          placeholder={translate('campus_search')}
          onSubmit={(searchTerm) => handleSearchSubmit(searchTerm)}
          onChange={(searchTerm) => handleSearchChange(searchTerm)}
          val={searchQuery}
        />
      </div>
      <FilterWrapper filterConfig={filterConfig} />
      <div ref={firstCourseRef}></div>
      {itemList && itemList.length > 0 && (
        <div className="grid-container">
          <aside className={`${courseList.listHead} ${courseList.listHeadCatalog}`}>
            <div className={courseList.courseCount}>
              <Translation id="campus_result_indicator" placeholder={{ amount: itemCount }} />
            </div>
            <SortPage sortMode={sortMode} setSortMode={setSortMode} />
          </aside>
        </div>
      )}
      {itemList && itemList.length > 0 ? (
        <>
          {itemList.slice(page * 4 - 4, page * 4).map((course) => (
            <CatalogCourseTeaserLoader
              key={key(course)}
              courseId={course.componentId}
              catalogData={course}
              targetGroups={targetGroups}
            />
          ))}
          {Math.ceil(itemCount / 4) > 1 && (
            <div className={styles.pagination}>
              <PaginationArrows
                length={Math.ceil(itemCount / 4)}
                handleClickDots={(nextPage) => {
                  window.location.hash = `page=${nextPage + 1}`;
                  setPage(nextPage + 1);
                }}
                handleClickButtons={changePage}
                currentPageIndex={page - 1}
              />
            </div>
          )}
        </>
      ) : (
        <>
          {isLoading ? (
            <div className="grid-container">
              <Loader />
            </div>
          ) : (
            <NoResults
              textKey="campus_results_noresults"
              buttonKey="campus_resetfilter"
              filterReset={filterConfig.setFilterReset}
            />
          )}
        </>
      )}
    </CampusLayout>
  );
}

// components
import { Translation } from '../Translation/Translation';
import SocialShareView from './SocialShareView';

interface SocialShareProps {
  contentType: 'page' | 'video';
  metaData: MetaData;
  productPage?: boolean;
  hashtags?: string[];
  useInContentPage?: boolean;
}

export default function SocialShare({
  productPage = false,
  hashtags = [],
  metaData,
  contentType = 'page',
  useInContentPage,
}: Readonly<SocialShareProps>) {
  return (
    <SocialShareView
      useInContentPage={useInContentPage}
      hashtags={hashtags}
      metaData={metaData}
      contentType={contentType}
      headline={
        <Translation
          id={productPage ? 'web20_social_share_headline_product' : 'web20_social_share_headline'}
        />
      }
    />
  );
}

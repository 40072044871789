import { CourseMetaItem } from './types';

export function getMetatag(tagId: string, tags: CourseMetaItem[], raw = false): undefined | string {
  if (!tags || tags.length === 0) {
    return undefined;
  }

  const tagFound = tags.find((item) => item.id === tagId);
  if (!tagFound) {
    return undefined;
  }

  return tagFound[raw ? 'rawContent' : 'content'] || undefined;
}

export function getMetatags(tagIds: string[], tags: CourseMetaItem[]): CourseMetaItem[] {
  if (!tags || tags.length === 0) {
    return [];
  }

  return tags.filter((tag) => tagIds.includes(tag.id));
}

// components
import { Lightbox } from 'components/lightbox/lightbox';
import { LightboxHeader } from 'components/lightbox/lightbox-header';
import CartOrders from './dlc-cart-orders';
import { useTranslationByKey } from 'utils/hooks/use-translations';

interface DownloadcenterLightboxProps {
  downloadAsZip: () => void;
  type: 'download' | 'order';
  form: any;
  handleClose: () => void;
  orders: any[];
  downloads: any[];
  updateOrder: (order: any) => void;
  updateAmount: (order: any) => void;
  updateDownloads: (download: any) => void;
}

function DownloadcenterLightbox({
  downloadAsZip,
  type,
  form,
  handleClose,
  orders,
  downloads,
  updateOrder,
  updateAmount,
  updateDownloads,
}: Readonly<DownloadcenterLightboxProps>) {
  const titleTranslationKey =
    type === 'order'
      ? 'web20_downloadcenter_cart_headline_your_order'
      : 'web20_downloadcenter_cart_headline_your_downloads';
  const title = useTranslationByKey(titleTranslationKey);

  return (
    <Lightbox onCloseRequest={handleClose}>
      <div className="c-lightbox__container grid-container">
        <LightboxHeader handleClose={handleClose} title={title} />
        <CartOrders
          orders={type === 'order' ? orders : downloads}
          updateOrder={type === 'order' ? updateOrder : updateDownloads}
          updateAmount={updateAmount}
          form={form}
          type={type}
          getSessionStorage={() => {}}
          onFinished={() => {}}
          downloadAsZip={downloadAsZip}
        />
      </div>
    </Lightbox>
  );
}

export default DownloadcenterLightbox;

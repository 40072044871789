'use client';

import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';
import { Container, Row, Col, Typo, Button } from '@geberit/gdds';

// components
import Layout from './Layout';
import { Formats, Headline } from 'components/ContentElements/Headline';

// styles
import { Wrapper } from './ciam-deletion-page.styles';

// utils
import { getLanguage } from 'components/ContentElements/Ciam/use-script';
import { buildSize, gridSizes } from 'utils/gridSize';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { homepageSelector } from 'utils/selectors/homepageListSelectors';
import { useGigya } from 'utils/hooks/use-gigya';
import { emptyStringFallback } from 'utils/is-empty';
import { ciamSelector } from 'utils/selectors/globalsSelectors';
import { contentExists, useContent } from 'components/Page/page-hooks';

export function CiamDeletionPage(pageFromServer: Readonly<Content>) {
  const { metaData } = pageFromServer;
  const content = useContent();

  const lang = useCurrentLanguage();
  const ciam = useSelector(ciamSelector);
  const { gigya } = useGigya();
  const router = useRouter();
  const homepage = useSelector(homepageSelector);

  const openLoginScreen = useCallback(() => {
    const country = lang.slice(-2);
    const langParam = ciam?.ciamOverwriteLangCode
      ? getLanguage(ciam.ciamOverwriteLangCode)
      : getLanguage(lang);
    gigya.accounts.showScreenSet({
      screenSet: 'Geberit-RegistrationLogin',
      startScreen: 'geberit-login-nosc',
      regSource: `${country}_WebsitePlatform`,
      lang: String(langParam).toLowerCase(),
      context: {
        country: lang,
      },
    });
  }, [lang, gigya, ciam]);

  const openAccountDeletionScreen = useCallback(() => {
    setTimeout(() => {
      gigya.accounts.showScreenSet({
        screenSet: 'Geberit-ProfileUpdate',
        startScreen: 'geberit-deletion-request-screen',
      });
    }, 500);
  }, [gigya]);

  useEffect(() => {
    if (!gigya) return;

    const handler = {
      onLogin: openAccountDeletionScreen,
      onLogout: () => {
        gigya.accounts.hideScreenSet({
          screenSet: 'Geberit-ProfileUpdate',
          startScreen: 'geberit-deletion-request-screen',
        });
        router.push(homepage.url);
      },
    };

    window.gigya.accounts.addEventHandlers(handler);
  }, [gigya, openAccountDeletionScreen, router, homepage]);

  if (!contentExists(content)) return null;

  const {
    page: { headline, subHeadline },
  } = content;

  return (
    <Layout
      metaData={{
        ...metaData,
        title: emptyStringFallback(metaData.title, 'Geberit ID account deletion'),
        noTitleClaim: true,
      }}
    >
      <Container maxContentWidth={buildSize(gridSizes.full)}>
        <Row>
          <Col>
            <Wrapper>
              <Headline format={Formats.h1 as TitleFormats} title={headline}></Headline>
              <Typo as="p" content={subHeadline}>
                {subHeadline}
              </Typo>
              <Button disabled={!gigya} onClick={openLoginScreen} stylingType="primary">
                Log in
              </Button>
            </Wrapper>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// styles
import styles from './paymentError.module.scss';

// components
import { Translation } from 'components/Translation/Translation';
import LinkWrapper from '../../Components/LinkWrapper/LinkWrapper';
import ErrorMessageBox from '../../Components/ErrorMessageBox/ErrorMessageBox';
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';

// utils
import { resetCheckoutStateAction as resetCheckoutState } from '../../actions';
import { useTranslationFunction } from 'utils/hooks/use-translations';

const VALID_CODES = [700, 720, 730, 740, 750, 760, 770];

export default function PaymentError({ errorCode = 780 }: Readonly<{ errorCode?: number }>) {
  const translate = useTranslationFunction();
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(resetCheckoutState());
    },
    [dispatch],
  );

  return (
    <div>
      <Breadcrumb item={translate('web20_checkout_summary_breadcrumb')} />

      <h1 className={styles.h1}>
        <Translation id="web20_checkout_payment_error_heading" />
        {/* Sorry, something went wrong */}
      </h1>

      <ErrorMessageBox>
        {VALID_CODES.includes(errorCode) ? errorCode : 780} -{' '}
        <Translation id="web20_checkout_payment_error" />
        {/* Sorry, your order could not be processed. Please contact our */}{' '}
        <LinkWrapper translationLink={translate('web20_checkout_link_customer_service')} />
      </ErrorMessageBox>
      <p className={`${styles.bottomMargin} ${styles.explanationText}`}>
        <Translation id="web20_checkout_summary_questions_part1" />
        {/* Please check our FAQ */}{' '}
        <LinkWrapper
          translationLink={translate('web20_checkout_link_frequently_asked_questions')}
        />
        .
      </p>
    </div>
  );
}

import { trackTemplate } from 'utils/tracking';

/**
 * DLC Filter click
 * @param string text
 */
export function clickDlcFilter(url) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Filter',
      action: 'DownloadCenter',
      label: url,
      value: '0',
    },
  });
}

import qs from 'qs';
import { Icon } from '@geberit/gdds';

import type { Platform } from './constants';

// utils
import { useTracking } from 'utils/tracking/track';

// components
import { CmsLink } from 'components/Link/CmsLink';

interface SocialShareIconProps {
  baseUrl: string;
  url: string;
  symbol: Platform;
  color: string;
  image?: string | string[];
  title?: string;
  hashtags?: string[];
  trackingCategory?: string;
  contentType: 'page' | 'video';
}

export default function SocialShareIcon({
  baseUrl,
  url,
  hashtags = [],
  symbol,
  color,
  title = '',
  image,
  trackingCategory,
  contentType,
}: Readonly<SocialShareIconProps>) {
  let sharingBody;
  let trackingIcon = symbol;
  let iconSymbol = symbol;
  const { trackShareAction } = useTracking();

  switch (symbol) {
    case 'facebook':
      sharingBody = qs.stringify({
        u: url,
        hashtags: hashtags.join(','),
      });
      break;
    case 'x':
      sharingBody = qs.stringify({
        text: title,
        url,
        hashtags: hashtags.join(','),
      });
      trackingIcon = 'x';
      break;
    case 'pinterest':
      sharingBody = qs.stringify({
        url,
        media: image,
        description: title,
      });
      break;
    case 'linkedin':
      sharingBody = qs.stringify({
        mini: 'true',
        url,
        title,
        summary: `\n${title}\n${url}`,
        provider: url,
      });
      break;
    case 'mail':
      sharingBody = qs.stringify({
        body: `\n\n\n${title}\n${url}`,
      });
      trackingIcon = 'mail';
      iconSymbol = 'envelope';
      break;
    default:
      return null;
  }

  const link = {
    target: `${baseUrl}${sharingBody}`,
    window: symbol !== 'mail' ? '_blank' : '',
    rel: 'nofollow',
  };

  const capitalizeIcon = trackingIcon.charAt(0).toUpperCase() + trackingIcon.slice(1);
  return (
    <CmsLink
      link={link}
      socialTrackingName="Social share"
      socialTrackingIcon={capitalizeIcon}
      trackingCategory={trackingCategory}
      tracking={() => {
        trackShareAction({
          content_type: contentType,
          method: trackingIcon,
          shareUrl: link.target,
        });
      }}
    >
      <Icon width="1.5rem" symbol={iconSymbol} color={color} />
    </CmsLink>
  );
}

import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { Typo, Icon } from '@geberit/gdds';

// styles
import { StyledCategories } from './categories.styles';

// types
import type { IEntry } from '../../locator.types';

// utils
import { useLocatorConfiguration } from '../../utils/use-locator-configuration';
import { decodingContent } from 'utils/decodingContent';

export function Categories({ products }: Readonly<Pick<IEntry, 'products'>>) {
  const locatorConfig = useLocatorConfiguration();
  const router = useRouter();

  if (products.length === 0) {
    return null;
  }

  const scrollToMoreEquipment = (e) => {
    e.preventDefault();
    const url = `${location.origin}${location.pathname}${location.search}#${locatorConfig.dealerPage.detailPageHash}`;
    router.replace(url);
  };

  return (
    <StyledCategories>
      <Typo variant="p1" fontWeight={700}>
        {decodingContent(locatorConfig?.dealerPage?.categories)}
      </Typo>
      <Typo variant="p1">{products.flatMap((p) => p.category).join(', ')}</Typo>
      <Typo className="link" variant="p2">
        <Icon withHitSpace symbol="arrow_right" />
        <Link onClick={scrollToMoreEquipment} href={`#${locatorConfig.dealerPage.detailPageHash}`}>
          {decodingContent(locatorConfig?.dealerPage?.furtherEquipment)}
        </Link>
      </Typo>
    </StyledCategories>
  );
}

import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/navigation';
import { Dropdown } from '@geberit/gdds';

// types
import { Channel } from './types';

// utils
import { decodingContent } from 'utils/decodingContent';
import { useTranslationFunction } from 'utils/hooks/use-translations';

interface VideocenterDropdownProps {
  channels: Channel[];
  searchQueryChannelId: string;
}

export default function VideocenterDropdown({
  channels,
  searchQueryChannelId,
}: Readonly<VideocenterDropdownProps>) {
  const router = useRouter();
  const translate = useTranslationFunction();
  const pathname = router.asPath;

  const memoisedHandleOnChange = useCallback(
    (option) => {
      if (Array.isArray(option)) {
        router.push(option[0].value);
      } else {
        router.push(option.value);
      }
    },
    [pathname],
  );

  const dropdownItems = useMemo((): {
    sq: string;
    id: string;
    value: string;
    text: string;
  }[] => {
    if (typeof window === 'undefined') {
      return [];
    }

    return channels
      .map((channel) => {
        const url = new URL(channel.url.replace(/^\/$/, ''), window.location.origin);
        url.searchParams.append('channel', channel.id);

        return {
          sq: channel.id,
          id: `${channel.reactKey}-routerlink`,
          value: `${url.pathname}?${url.searchParams.toString()}`,
          text: decodingContent(channel.title),
        };
      })
      .filter((e) => !!e.sq);
  }, [channels, pathname]);

  const currentChannel = dropdownItems.find((item) => item.sq && item.sq === searchQueryChannelId);

  return (
    <Dropdown
      name={'videocenter_channel'}
      label={translate('web20_videocenter_channel_header')}
      placeholder={translate('web20_videocenter_channel_change')}
      defaultValue={currentChannel?.value}
      outlined
      forceCustom
      onChange={memoisedHandleOnChange}
      items={dropdownItems}
      resetDefaultValue
    ></Dropdown>
  );
}

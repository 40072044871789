'use client';

// components
import DownloadCenterContainer, {
  type DownloadcenterProps,
} from '../components/group/downloadcenter';
import Layout from 'scenes/Group/Layout';

export interface DownloadCenterGroupProps extends DownloadcenterProps {
  metaData: MetaData;
}

export default function DownloadCenterGroup({
  metaData,
  breadcrumbs,
  page,
  contentAreas,
}: Readonly<DownloadCenterGroupProps>) {
  return (
    <Layout metaData={metaData}>
      <DownloadCenterContainer
        page={page}
        contentAreas={contentAreas}
        breadcrumbs={breadcrumbs}
      />
    </Layout>
  );
}

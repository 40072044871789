import Head from 'next/head';

// components
import SummaryContainer from '../Containers/Summary/SummaryContainer';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';

export function Step2() {
  const translate = useTranslationFunction();

  return (
    <>
      {translate('web20_checkout_summary_breadcrumb') &&
        translate('web20_checkout_steps_summary') && (
          <Head>
            <title>{`${translate('web20_checkout_summary_breadcrumb')} - ${translate(
              'web20_checkout_steps_summary',
            )}`}</title>
          </Head>
        )}
      <SummaryContainer />
    </>
  );
}

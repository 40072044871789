import { useSelector } from 'react-redux';

// components
import SearchTab from './SearchTab';
import { Translation } from 'components/Translation/Translation';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { TAB_CONTENT, TAB_PRODUCT } from '../constants';
import {
  globalsSearchResultArticles,
  globalsSearchResultContent,
} from 'utils/selectors/globalsSelectors';

interface SearchTabsProps {
  handleClick: (tabId: number) => void;
  activeTab: number;
  softContentCount: number;
  productsCount: number;
}

export default function SearchTabs({
  handleClick,
  activeTab = 0,
  softContentCount = 0,
  productsCount = 0,
}: Readonly<SearchTabsProps>) {
  const articlesDisabled = useSelector(globalsSearchResultArticles);
  const contentDisabled = useSelector(globalsSearchResultContent);

  return (
    <div className="RRT__container">
      <div className="RRT__tabs" role="tablist">
        {!contentDisabled && (
          <SearchTab
            index={TAB_CONTENT}
            active={activeTab === TAB_CONTENT}
            handleClick={handleClick}
          >
            <Translation id="web20_content" /> (
            <InnerHtml as="div" className="inline" content={`${softContentCount}`} />)
          </SearchTab>
        )}
        {!articlesDisabled && (
          <SearchTab
            index={TAB_PRODUCT}
            active={activeTab === TAB_PRODUCT}
            handleClick={handleClick}
          >
            <Translation id="web20_products_articles" /> (
            <InnerHtml as="div" className="inline" content={`${productsCount}`} />)
          </SearchTab>
        )}
      </div>
    </div>
  );
}

import { isEmpty } from 'utils/is-empty';

interface BreadcrumbDataProps {
  items: Partial<Breadcrumb>[];
}

function BreadcrumbData({ items }: Readonly<BreadcrumbDataProps>) {
  if (isEmpty(items)) return null;

  const listItems = items.map((breadcrumb, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    item: {
      '@id': breadcrumb.url,
      name: breadcrumb.label,
    },
  }));

  const jsonTemplate = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: listItems,
  };

  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonTemplate) }}
    />
  );
}

export default BreadcrumbData;

// types
import type { ReadonlyHeaders } from 'next/dist/server/web/spec-extension/adapters/headers';

// utils
import getEndpoint from 'utils/endpoints';
import { getPreFilter } from './actions';
import { Log } from 'services/log';
import { fetchService } from 'services/fetch-service';

type FetcherProps = {
  page: number;
  segment: string | null;
  options: { prefilterTags: string[]; itemsPerPage?: number };
  ITEMS_PER_PAGE: number;
  filter: string;
  q: string;
  sort?: string;
  replaceSearchParams?: (query: Record<string, string>) => void;
  headersList?: ReadonlyHeaders;
};

export function fetcher({
  page,
  sort = 'date',
  segment,
  options,
  ITEMS_PER_PAGE,
  filter,
  q,
  replaceSearchParams,
  headersList,
}: Readonly<FetcherProps>) {
  return async ({ pageParam = 1 }) => {
    const includeHistory = pageParam !== page + 1;
    const params: Record<string, string> = {
      sort,
      segment: segment ?? '',
      prefilter: getPreFilter(options.prefilterTags),
      agg: 'filter1.filter2.filter3.filter4',
      agg_mode: 'all',
      num: String(includeHistory ? page * ITEMS_PER_PAGE : ITEMS_PER_PAGE),
      offset: String((pageParam - 1) * ITEMS_PER_PAGE),
    };
    if (filter) {
      params.filters = filter;
    }
    if (q) {
      params.q = q;
    }
    const endpoint = `${getEndpoint('search', {})}?${new URLSearchParams(params).toString()}`;
    if (!includeHistory && replaceSearchParams) {
      replaceSearchParams({ page: String(pageParam) });
    }
    if (headersList) {
      return fetchService('elasticsearchservice', headersList).json(
        `/search/?${new URLSearchParams(params).toString()}`,
      );
    }

    return fetch(endpoint).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        Log.error('Failure fetching downloads');
        throw new Error('Failure fetching downloads');
      }
    });
  };
}

// components
import CampusLayout from './CampusLayout';
import CampusCourseContent from './CampusCourseContent';
import { CampusCourseRegistration } from './CampusCourseRegistration';
import CampusParticipantLogin from './CampusParticipantLogin';
import CampusBookingConfirmation from './CampusBookingConfirmation';
import CampusCancelConfirmation from './CampusCancelConfirmation';
import { Cancellation } from 'components/Campus/Cancellation/Cancellation';

// types
import { Course } from 'components/Campus/types';

// utils
import { useEmployeeToken } from 'utils/hooks/useCampus';
import { useCampusToken } from 'utils/hooks/useCiam';
import { useHashState } from 'utils/hooks/use-hash-state';

interface CampusDetailWorkflowWrapperProps {
  courseId: number;
  data: Course;
  reload: () => void;
  subitems: any;
}

export default function CampusDetailWorkflowWrapper({
  courseId,
  data,
  reload,
  subitems,
  children,
}: Readonly<React.PropsWithChildren<CampusDetailWorkflowWrapperProps>>) {
  const [hash] = useHashState();
  const { campusToken: ciamToken } = useCampusToken();
  const { token: employeeToken } = useEmployeeToken(courseId);
  const register = hash?.match(/^register$/);
  const cancel = hash?.match(/^cancel$/);
  const bookingConfirm = hash?.match(/^booking-confirm$/);
  const cancelConfirm = hash?.match(/^cancel-confirm$/);
  const contentId = hash?.match(/^content-(\d+)$/);

  const content =
    contentId &&
    subitems?.filter((i) => parseInt(i?.media?.id, 10) === parseInt(contentId[1], 10)).shift();

  if (!employeeToken && !ciamToken) {
    return <CampusParticipantLogin courseId={courseId} />;
  }

  // if state is show course contents then return conent page
  if (content) {
    return <CampusCourseContent course={data} content={content.media} />;
  }

  if (register) {
    return <CampusCourseRegistration courseId={courseId} course={data} reloadDetails={reload} />;
  }

  if (cancel) {
    return (
      <CampusLayout>
        <Cancellation courseId={courseId} reloadDetails={reload} data={data} />
      </CampusLayout>
    );
  }

  if (bookingConfirm) {
    return (
      <CampusLayout>
        <CampusBookingConfirmation course={data} />
      </CampusLayout>
    );
  }

  if (cancelConfirm) {
    return (
      <CampusLayout>
        <CampusCancelConfirmation course={data} />
      </CampusLayout>
    );
  }

  return children;
}

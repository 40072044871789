import { MY_DATA } from '../constants';

export const createFormObject = (values) => {
  const mapEmpties = (value) => (value === '' ? null : value.trim());
  const {
    salutation,
    firstName,
    lastName,
    zip,
    city,
    email,
    phone,
    company,
    street,
    country,
    deliveryCheckbox,
    deliverySalutation,
    deliveryFirstName,
    deliveryLastName,
    deliveryCompany,
    deliveryStreetAndHouse,
    deliveryZipCode,
    deliveryCity,
    deliveryCountry,
  } = values;

  const invoiceAddressObject = {
    salutation: mapEmpties(salutation),
    firstName: firstName.trim(),
    lastName: lastName.trim(),
    email: email.trim(),
    company: mapEmpties(company),
    phone: mapEmpties(phone),
    street: mapEmpties(street),
    zip: zip.trim(),
    city: city.trim(),
    country,
  };

  if (deliveryCheckbox) {
    return {
      invoiceAddress: invoiceAddressObject,
      deliveryAddress: {
        salutation: mapEmpties(deliverySalutation),
        firstName: deliveryFirstName.trim(),
        lastName: deliveryLastName.trim(),
        email: email.trim(),
        company: mapEmpties(deliveryCompany),
        phone: mapEmpties(phone),
        street: mapEmpties(deliveryStreetAndHouse),
        zip: deliveryZipCode.trim(),
        city: deliveryCity.trim(),
        country: deliveryCountry,
      },
    };
  }

  return {
    invoiceAddress: invoiceAddressObject,
    deliveryAddress: invoiceAddressObject,
  };
};

export const transformPrice = (price) => Number(price).toFixed(2);

export const mapSapAndCiamCodesToSalutations = (code) => {
  switch (code) {
    case '0001':
      return 'web20_checkout_address_salutation_2';
    case '0002':
      return 'web20_checkout_address_salutation_1';
    case '9008':
      return 'web20_checkout_address_salutation_3';
    case 'Mr.':
      return 'web20_checkout_address_salutation_1';
    case 'Ms.':
      return 'web20_checkout_address_salutation_2';
    case 'Divers':
      return 'web20_checkout_address_salutation_3';
    default:
      return '';
  }
};

export const mapSapCiamSalutationsToSapCodes = (code) => {
  switch (code) {
    case 'Mr.':
      return '0002';
    case 'Ms.':
      return '0001';
    case 'Divers':
      return '9008';
    case '0001':
      return '0001';
    case '0002':
      return '0002';
    case '9008':
      return '9008';
    default:
      return '';
  }
};

export const hasSessionData = (userType) => {
  const getStorageData = window.sessionStorage.getItem(MY_DATA);

  if (getStorageData) {
    const parsedData = JSON.parse(getStorageData);
    return !!parsedData[userType];
  }

  return false;
};

export const userType = (check) => (check ? 'user' : 'guest');

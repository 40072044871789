import { ICON_COLORS } from '../Icon/Icon';

export type Platform = 'facebook' | 'x' | 'pinterest' | 'linkedin' | 'mail';

type PlatformShareData = {
  baseUrl: string;
  symbol: Platform;
  color: (typeof ICON_COLORS)[keyof typeof ICON_COLORS];
};

const SOCIAL_SHARE_DATA: PlatformShareData[] = [
  {
    baseUrl: 'https://www.facebook.com/sharer/sharer.php?',
    symbol: 'facebook',
    color: ICON_COLORS.PRIMARY,
  },
  {
    baseUrl: 'https://twitter.com/intent/tweet?',
    symbol: 'x',
    color: ICON_COLORS.PRIMARY,
  },
  {
    baseUrl: 'https://pinterest.com/pin/create/button/?',
    symbol: 'pinterest',
    color: ICON_COLORS.WHITE,
  },
  {
    baseUrl: 'https://www.linkedin.com/shareArticle?',
    symbol: 'linkedin',
    color: ICON_COLORS.WHITE,
  },
  {
    baseUrl: 'mailto:?',
    symbol: 'mail',
    color: ICON_COLORS.PRIMARY,
  },
];

export default SOCIAL_SHARE_DATA;

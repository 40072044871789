import * as React from 'react';

// components
import ListRow from './list-row';

interface InsightsTeaserListProps {
  rows: any[];
}

function InsightsTeaserList({ rows, ...props }: Readonly<InsightsTeaserListProps>) {
  if (rows.length < 1) {
    return null;
  }

  return (
    <>
      {rows.map(({ key, row }) => (
        <ListRow key={key} row={row} {...props} />
      ))}
    </>
  );
}

export default InsightsTeaserList;

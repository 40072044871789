import key from 'object-hash';

// types
import type { InsightsDetailPageProps } from './insights-teaser-list.types';

function splitItemsInRows(items: InsightsDetailPageProps[]): InsightsDetailPageProps[][] {
  let newRows: InsightsDetailPageProps[][] = [];
  let currentRow: InsightsDetailPageProps[] = [];
  items.forEach((item, idx) => {
    const tile = item.tile;
    if (tile.type === 'large') {
      if (!tile.side) {
        tile.side = 'left';
      }
      if (currentRow.length > 0) {
        newRows.push(currentRow);
        currentRow = [];
      }
      newRows.push([item]);
    } else {
      if (currentRow.length >= 2) {
        newRows.push(currentRow);
        currentRow = [];
      }
      currentRow.push(item);
    }
  });
  newRows.push(currentRow);
  return newRows.filter((row) => row.length > 0);
}

function checkTeaserLeftRight(row, lastRow) {
  if (
    row[0].tile.type === 'large' &&
    lastRow?.[0].tile.type === 'large' &&
    lastRow?.[0].tile.side === 'left'
  ) {
    row[0].tile.side = 'right';
  }
}

function shrinkSecondMediumTile(row) {
  if (row.length > 1 && row[0].tile.type === 'medium' && row[1].tile.type === 'medium') {
    row[1].tile.type = 'small';
  }
}

function smallMediumTakeTurns(row, lastRow) {
  if (lastRow && lastRow.length > 1 && row.length > 1) {
    const lastRowIsMediumAndSmall =
      lastRow[0].tile.type === 'medium' && lastRow[1].tile.type === 'small';
    const lastRowIsSmallAndMedium =
      lastRow[0].tile.type === 'small' && lastRow[1].tile.type === 'medium';
    const thisRowIsMediumAndSmall = row[0].tile.type === 'medium' && row[1].tile.type === 'small';
    const thisRowIsSmallAndMedium = row[0].tile.type === 'small' && row[1].tile.type === 'medium';

    if (lastRowIsMediumAndSmall && thisRowIsMediumAndSmall) {
      row[0].tile.type = 'small';
      row[1].tile.type = 'medium';
    }
    if (lastRowIsSmallAndMedium && thisRowIsSmallAndMedium) {
      row[0].tile.type = 'medium';
      row[1].tile.type = 'small';
    }
  }
}

export function transformRows(newItems) {
  // step 1: split items in rows
  const newRows = splitItemsInRows(newItems);

  // step 2: context parser
  let lastRow: InsightsDetailPageProps[] | null = null;
  newRows.forEach((row) => {
    // is the large teaser image on the left or the right?
    checkTeaserLeftRight(row, lastRow);

    // if there are two medium tiles in a row, shrink the second
    shrinkSecondMediumTile(row);

    // let medium and small size tiles take turns
    smallMediumTakeTurns(row, lastRow);

    lastRow = row;
  });
  return newRows.map((row) => ({ key: key(row), row }));
}

import React from 'react';

// styles
import styles from './intro-text.module.scss';

// components
import { InnerHtml } from '../../InnerHtml/inner-html';

// utils
import { isEmpty } from 'utils/is-empty';

/**
 * Introtext following the page title
 *
 * @param text Placeholder for contents of the introtext container
 * @param grid Defines whether the introtext should get grid classes or is used independently
 * @returns {*}
 * @constructor
 */

interface IntroTextProps {
  text: string;
  grid: boolean;
}

function IntroText({ text = '', grid = false }: Readonly<IntroTextProps>) {
  const wrapperClass = grid ? 'grid-container' : '';

  if (isEmpty(text)) {
    return null;
  }

  return (
    <div className={`${wrapperClass} ${styles.introText}`}>
      <InnerHtml as="div" content={text} />
    </div>
  );
}

export default IntroText;

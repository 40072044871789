import React from 'react';
import { Icon, Typo } from '@geberit/gdds';

// styles
import { NoteWrapper } from './note.styles';

// utils
import { useLocatorConfiguration } from '../../utils/use-locator-configuration';
import { decodingContent } from 'utils/decodingContent';

export function Note() {
  const locatorConfig = useLocatorConfiguration();

  return (
    <NoteWrapper>
      <div className="note">
        <Icon symbol="warning_info" />
        <Typo variant="p1" fontWeight={700}>
          {decodingContent(locatorConfig?.dealerPage?.note)}
        </Typo>
      </div>
      <Typo variant="p2">{decodingContent(locatorConfig?.dealerPage?.noteText)}</Typo>
    </NoteWrapper>
  );
}

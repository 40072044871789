import { useTranslationFunction } from 'utils/hooks/use-translations';

export function useToggleItems() {
  const translate = useTranslationFunction();

  return [
    { value: 'yes', text: translate('campus_form_yes') },
    { value: 'no', text: translate('campus_form_no') },
  ];
}

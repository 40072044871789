interface ProductDetailFiltersSelectViewProps {
  disabled?: boolean;
  currentValue: string;
  defaultValue?: string | null;
  options?: any[];
  checkOptionStatus?: (option: any) => boolean;
  onSelectChange: ({ currentTarget: { value } }: { currentTarget: { value: any } }) => void;
}

function ProductDetailFiltersSelectView({
  options,
  onSelectChange,
  currentValue = '',
  defaultValue = '',
  disabled = false,
  checkOptionStatus,
}: Readonly<ProductDetailFiltersSelectViewProps>) {
  return (
    <div className={`gb-select ${disabled ? ' gb-select--disabled' : ''}`}>
      <div className="gb-select__value">{currentValue || defaultValue}</div>
      <select
        onChange={onSelectChange}
        disabled={disabled}
        value={disabled ? defaultValue : currentValue}
      >
        <option value="">{defaultValue}</option>
        {!disabled &&
          options?.map((option) => (
            <option
              key={option}
              value={option}
              disabled={checkOptionStatus ? checkOptionStatus(option) : false}
            >
              {option}
            </option>
          ))}
      </select>
    </div>
  );
}

export default ProductDetailFiltersSelectView;

'use client';

import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePathname, useRouter } from 'next/navigation';

// types
import { TitleFormats } from 'components/ContentElements/Title/title.types';

// components
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { Loader } from 'components/Loader/Loader';
import { Title } from 'components/ContentElements/Title/Title';
import ProductListing from './ProductListing';
import ResultsPerPage from 'components/Pagination/ResultsPerPage';
import PaginationArrows from 'components/Pagination/PaginationArrows';
import LoadMoreButton from 'components/LoadMoreButton/LoadMoreButton';

// utils
import { fetchLimitedProductsByCategory } from '../actions';
import { useIsDesktop } from 'components/App/SizeProvider';
import { homepageSelector } from 'utils/selectors/homepageListSelectors';
import { navigationMainItemsSelector } from 'utils/selectors/navigationSelectors';
import { seoConformPLPUrl } from 'utils/seoConformProductUrl';
import {
  categoryProductsByProductKeySelector,
  totalCategoryProductsByProductKeySelector,
  currentCatalogSelector,
} from 'utils/selectors/productCatalogSelectors';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { isEmpty } from 'utils/is-empty';

const maxResultsPerPage = 12;

interface ProductListingContainerProps {
  categoryId: string;
  categoryName: string;
  sortHints: string;
}

export default function ProductListingContainer({
  categoryId,
  sortHints,
  categoryName,
}: Readonly<ProductListingContainerProps>) {
  const isDesktop = useIsDesktop();
  const router = useRouter();
  const pathname = usePathname();
  const dispatch: React.Dispatch<any> = useDispatch();

  const language = useCurrentLanguage();
  const catalog: string | null = useSelector(currentCatalogSelector);
  const homepage: any = useSelector(homepageSelector);
  const navigation: any[] | null = useSelector(navigationMainItemsSelector);
  const productsByCategory: any[] | null = useSelector((state: AppState) =>
    categoryProductsByProductKeySelector(state, categoryId),
  );
  const total = useSelector((state: AppState) =>
    totalCategoryProductsByProductKeySelector(state, categoryId),
  );

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(maxResultsPerPage);
  const [hasLoadMore, setHasLoadMore] = useState(false);
  const [currentProductsByCategory, setCurrentProductsByCategory] = useState(productsByCategory);
  const prevLocation = useRef(pathname);

  /**
   *   slice products array to show only products from current page
   */
  const sliceFromAllProducts = (products) => {
    const newResultsLength = currentPageIndex * resultsPerPage + resultsPerPage;
    const allProducts = products || currentProductsByCategory;
    const allProductsLength = allProducts?.length;

    // Slice allProducts if it has newPageLength or it has hit the totalResults
    if (allProductsLength === newResultsLength || (total && allProductsLength === total)) {
      const currentProducts = allProducts.slice(
        currentPageIndex * resultsPerPage,
        newResultsLength,
      );

      setCurrentProductsByCategory(currentProducts);
    }
  };

  /**
   * func to fetch new products regarding page index and page size
   * @returns {*} products which are displayed on result page
   */
  const getNewProducts = async () => {
    const newOffset = resultsPerPage * currentPageIndex;

    if (
      !isEmpty(currentProductsByCategory) &&
      productsByCategory &&
      productsByCategory.length > newOffset &&
      productsByCategory.length > resultsPerPage
    ) {
      return sliceFromAllProducts(productsByCategory);
    }

    await dispatch(
      fetchLimitedProductsByCategory(categoryId, catalog, resultsPerPage, newOffset, sortHints),
    );

    return sliceFromAllProducts();
  };

  /**
   * callback function when dropdown in ResultsPerPage-Component is triggered
   * @param newResultsPerPage = 12 || 24 || 48 on desktop
   */
  const setResultPerPageOnClick = (newResultsPerPage) => {
    setResultsPerPage(newResultsPerPage);
    setCurrentPageIndex(0);
  };

  /**
   * fetch products as soons as catalog is given
   */
  useEffect(() => {
    if (isEmpty(productsByCategory)) {
      getNewProducts();
    }
  }, [catalog]);

  /**
   * Handle Load more button visibility
   */
  useEffect(() => {
    if (!isDesktop) setHasLoadMore(total > maxResultsPerPage);
  }, [isDesktop]);

  /**
   *   update currentProducts after did mount
   */
  useEffect(() => {
    if (
      !currentProductsByCategory ||
      currentProductsByCategory.length !== productsByCategory.length
    ) {
      sliceFromAllProducts(productsByCategory);
    }
  }, [productsByCategory]);

  /**
   *   on pageIndex update fetch regarding products
   */
  useEffect(() => {
    if (productsByCategory) getNewProducts();
  }, [currentPageIndex, resultsPerPage]);

  /**
   * calculate amount of pagination points/indices to be displayed
   * @returns {number}
   */
  const getLength = () => (total > 0 ? Math.ceil(total / resultsPerPage) : 0);

  /**
   * callback func for LoadMoreButton: calculate amount of displayed product tiles
   */
  const handleLoadMore = () => {
    const amount = Math.min(maxResultsPerPage + resultsPerPage, total);

    if (amount >= total) {
      setHasLoadMore(false);
    }

    setResultPerPageOnClick(amount);
  };

  /**
   * callback func for Pagination: set current page index when clicked on pagination
   * @param index
   */
  const handleClickDots = (index) => {
    if (index < getLength() && index >= 0) {
      setCurrentPageIndex(index);
    }
  };

  /**
   * calculate current page index when clicked on arrow buttons of pagination
   * @param direction = 1 || -1
   */
  const handleClickButtons = (direction) => {
    if (currentPageIndex + direction >= 0 && currentPageIndex + direction < getLength()) {
      setCurrentPageIndex(currentPageIndex + direction);
    }
  };

  if (isEmpty(navigation)) {
    return null;
  }

  const productsObject = {
    categoryName,
    categoryId,
  };

  const productPath = seoConformPLPUrl(productsObject, navigation[0].url, language);

  if (productPath !== prevLocation.current && productPath !== pathname) {
    router.replace(productPath);
    return null;
  }

  const hasPagination = total > maxResultsPerPage;
  const paginationLength = getLength();

  return (
    <div className="grid-container product-listing-page">
      {!isEmpty(homepage) && !isEmpty(navigation) && (
        <Breadcrumb
          items={[
            { label: 'homepage', url: homepage.url },
            { label: navigation[0].label, url: navigation[0].url },
            { label: categoryName, url: '' },
          ]}
        />
      )}
      <Title title={categoryName} Format={TitleFormats.h1} pageHeadline />
      <div className="grid-container">
        {!isEmpty(currentProductsByCategory) || total ? (
          <div className="c-pagination">
            {isDesktop && setResultPerPageOnClick && hasPagination && (
              <ResultsPerPage
                total={total}
                resultsPerPage={resultsPerPage}
                setResultsPerPage={setResultPerPageOnClick}
                currentPageIndex={currentPageIndex}
              />
            )}
            <ProductListing
              isLoaded={!isEmpty(currentProductsByCategory)}
              products={currentProductsByCategory}
              linkType="product_detail_page"
            />
            {!isDesktop && hasLoadMore && <LoadMoreButton handleLoadMore={handleLoadMore} />}
            {isDesktop && hasPagination && paginationLength > 1 && (
              <div className="c-pagination__bar">
                <PaginationArrows
                  length={paginationLength}
                  handleClickDots={handleClickDots}
                  handleClickButtons={handleClickButtons}
                  currentPageIndex={currentPageIndex}
                />
              </div>
            )}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

import { SyntheticEvent } from 'react';
import key from 'weak-key';

// types
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';

// utils
import { popUpLink } from 'components/Link/trackingActions';
import { handlePopup } from './openInPopup';

function handlePopupLink(e: SyntheticEvent<HTMLButtonElement>, boxLink: Link, track: { trackEvent: (data: unknown) => void },) {
  e.preventDefault();
  track.trackEvent(popUpLink(boxLink?.target));
  const windowId = key(boxLink);
  handlePopup(boxLink, windowId);
}

export function onButtonClick(
  e: SyntheticEvent<HTMLButtonElement>,
  boxLink: Link,
  track: { trackEvent: (data: unknown) => void },
  router: AppRouterInstance,
  setIframeShown?: (target: string) => void,
) {
  if (boxLink?.type === 'internal_link') {
    if (boxLink?.window === '_blank') {
      window.open(boxLink?.target);
    } else if (boxLink.window === '_popup') {
      handlePopupLink(e, boxLink, track);
    } else {
      router.push(boxLink?.target);
    }
  } else if (boxLink?.type === 'mail_link') {
    window.open(boxLink?.target, '_self');
  } else if (boxLink.window === '_self') {
    window.location.href = boxLink?.target;
  } else if (boxLink.window === '_popup') {
    handlePopupLink(e, boxLink, track);
  } else if (boxLink.window === '_iframe') {
    e.preventDefault();
    setIframeShown?.(boxLink?.target);
  } else {
    window.open(boxLink?.target, '_blank');
  }
}

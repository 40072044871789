'use client';

import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';

// Styles
import styles from './shoppingbasket.module.scss';

// Components
import { Translation } from '../Translation/Translation';
import ArticleListContainer from '../ArticleList/ArticleListContainer';
import ShoppingBasketFooter from './ShoppingBasketFooter';
import { Loader } from '../Loader/Loader';

// Utils
import { loadArticleCounter, loadList } from './actions';
import { SessionContext } from '../Session/SessionContext';
import {
  shoppingBasketCountSelector,
  shoppingBasketItemsSelector,
  shoppingBasketLoadingSelector,
} from 'utils/selectors/shoppingBasketSelector';
import { currentCatalogSelector } from 'utils/selectors/productCatalogSelectors';
import { useCiamLogin } from 'utils/hooks/useCiam';
import { webshopEnabledSelector } from 'utils/selectors/globalsSelectors';
import {
  webshopMaintenanceEnabledSelector,
  webshopMaintenanceLoadingSelector,
} from 'utils/selectors/maintenanceSelectors';
import { homepageSelector } from 'utils/selectors/homepageListSelectors';

interface ShoppingBasketContainerProps {
  type: string;
}

export default function ShoppingBasketContainer({ type }: Readonly<ShoppingBasketContainerProps>) {
  const items = useSelector(shoppingBasketItemsSelector);
  const lang = useSelector(currentCatalogSelector);
  const count = useSelector(shoppingBasketCountSelector);
  const basketLoading = useSelector(shoppingBasketLoadingSelector);
  const webshopDowntime = useSelector(webshopMaintenanceEnabledSelector);
  const webshopEnabled = useSelector(webshopEnabledSelector);
  const webshopDowntimeLoading = useSelector(webshopMaintenanceLoadingSelector);
  const homepage = useSelector(homepageSelector);

  const router = useRouter();
  const dispatch = useDispatch();
  const gigyaWindow = typeof window !== 'undefined' ? window.gigya : null;

  useCiamLogin(gigyaWindow);

  const {
    state: { session, ciam, sessionIsLoading },
  } = useContext(SessionContext);

  useEffect(() => {
    if (session && lang && !sessionIsLoading && webshopEnabled && !webshopDowntime) {
      dispatch(loadList(session, lang, ciam));
      dispatch(loadArticleCounter(session, lang, ciam));
    }
  }, [session, ciam, sessionIsLoading, webshopDowntime]);

  useEffect(() => {
    if (!webshopDowntimeLoading && (!webshopEnabled || webshopDowntime)) {
      router.push(homepage?.url || '/');
    }
  }, [webshopEnabled, webshopDowntime, webshopDowntimeLoading, homepage]);

  return (
    <div className="grid-container">
      <div className={`title--block grid-container c-page-headline ${styles.headline}`}>
        <h1>
          <Translation
            id="web20_webshop_basket_subtitle"
            placeholder={{ amount: basketLoading ? '' : count.toString() }}
          />
        </h1>
      </div>
      <div className={styles.introText}>
        <Translation id="web20_webshop_basket_intro" />
      </div>
      <div className="my-list__wrapper">
        <div className="grid-x my-list__row my-list__row-head">
          <div className="my-list__image large-2">&nbsp;</div>
          <div className="my-list__article-number large-2">
            <Translation id="web20_webshop_basket_art_no" />
          </div>
          <div className="my-list__product large-3">
            <Translation id="web20_webshop_basket_product" />
          </div>
          <div className="my-list__action-amount large-2">
            <Translation id="web20_webshop_basket_price" />
          </div>
          <div className="my-list__action-amount large-2">
            <Translation id="web20_webshop_basket_amount" />
          </div>
          <div className="my-list__action-delete large-1">
            <Translation id="web20_webshop_basket_delete" />
          </div>
        </div>
        {!sessionIsLoading &&
          !basketLoading &&
          items?.map((article) => (
            <ArticleListContainer
              type={type}
              article={article}
              key={`${article.key}-${article.variantId}`}
              count={article.quantity}
              id={article.key || article.productId}
            />
          ))}
        {!sessionIsLoading && !basketLoading && items?.length === 0 && (
          <div className="my-list__product large-12">
            <Translation id="web20_webshop_basket_empty" />
          </div>
        )}
        {(sessionIsLoading || basketLoading) && (
          <div className={`my-list__product large-12 ${styles.loaderWrapper}`}>
            <Loader />
          </div>
        )}
        <ShoppingBasketFooter items={items} />
      </div>
    </div>
  );
}

// components
import ProductDetailInfoTools from '../DetailInfoTools/ProductDetailInfoTools';
import ProductDetailFilters from '../DetailFilters/ProductDetailFilters';
import { Translation } from 'components/Translation/Translation';
import {
  BUTTON_COLORS,
  BUTTON_BACKGROUND,
  BUTTON_ALIGNMENT,
  Button,
} from 'components/Button/Button';

// utils
import { scrollToSection } from 'utils/scrollTo';

interface ProductDetailFilterBoxProps {
  product: any;
  sparePartsGiven: boolean;
  documentsGiven: boolean;
  expandAccordionItem: (stateName: string, expanded: boolean) => void;
  language: string;
}

export default function ProductDetailFilterBox({
  product,
  product: { key, series },
  sparePartsGiven,
  documentsGiven,
  expandAccordionItem,
}: Readonly<ProductDetailFilterBoxProps>) {
  const scrollToDetails = (stateName, id) => {
    scrollToSection(id);
    expandAccordionItem(stateName, true);
  };

  return (
    <div className="c-product-detail__filter-box">
      {series && (
        <div className="desc">
          <span className="name">
            <Translation id="web20_product_articles_series" />
          </span>
          <span>{series}</span>
        </div>
      )}

      <ProductDetailFilters product={product} />

      <div className="desc">
        <Button
          symbol="arrow-link-bold-down"
          color={BUTTON_COLORS.WHITE}
          background={BUTTON_BACKGROUND.PRIMARY}
          align={BUTTON_ALIGNMENT.CENTER}
          onClick={() => scrollToDetails('detail', 'product-detail')}
        >
          <Translation id="web20_product_articles_scroll_to_details" />
        </Button>
      </div>

      <ProductDetailInfoTools
        productKey={key}
        documentsGiven={documentsGiven}
        sparePartsGiven={sparePartsGiven}
        scrollToDetails={scrollToDetails}
      />
    </div>
  );
}

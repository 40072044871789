import React from 'react';

interface ProductDetailListProps {
  content: string[];
}

function ProductDetailList({ content }: Readonly<ProductDetailListProps>) {
  return (
    <div className="grid-container">
      <div className="list-block-column">
        <ul>
          {content.map((item) => (
            <li key={item.trim()}>{item.trim()}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ProductDetailList;

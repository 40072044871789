import { useContext } from 'react';
import { Typo, Notification } from '@geberit/gdds';
import { useTheme } from 'styled-components';

// styles
import { ZipEntryBoxContainer, ZipEntryContainer } from './zip-entry.styles';

// components
import { SearchContainer, SearchContext } from '../search';

// utils
import { useLocatorConfiguration } from '../utils/use-locator-configuration';
import { decodingContent } from 'utils/decodingContent';
import { useNord } from 'utils/hooks/use-nord';

interface ZipEntryProps {
  headline: string;
  subHeadline?: string;
  isSection?: boolean;
}

export function ZipEntry({ headline, subHeadline, isSection }: Readonly<ZipEntryProps>) {
  const { error, resetError } = useContext(SearchContext);
  const locatorConsent = useLocatorConfiguration();
  const theme = useTheme();
  const isNord = useNord();

  const H3_SIZES = {
    xsmall: theme.fontTokens.font07,
    medium: theme.fontTokens.font06,
    large: theme.fontTokens.font05,
    xlarge: theme.fontTokens.font04,
  };
  const decodedHeadline = decodingContent(headline);
  const decodedSubHeadline = decodingContent(subHeadline);

  return (
    <ZipEntryContainer>
      <ZipEntryBoxContainer>
        {decodedHeadline && (
          <Typo
            size={H3_SIZES}
            variant={isSection ? 'h2' : 'h1'}
            fontWeight={isNord ? 400 : 700}
            isUppercase={isNord}
          >
            {decodedHeadline}
          </Typo>
        )}
        {decodedSubHeadline && <Typo variant="p1">{decodedSubHeadline}</Typo>}
        <SearchContainer />
      </ZipEntryBoxContainer>
      {error && (
        <Notification
          type="broadcast"
          text={locatorConsent?.consent?.geoLocationFailureNote ?? ''}
          buttonStyleType="flatInverted"
          showCloseButton
          positionTop={0}
          onClick={resetError}
          className="notification"
        />
      )}
    </ZipEntryContainer>
  );
}

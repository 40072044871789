import _isEmpty from 'lodash.isempty';
import key from 'weak-key';

// types
import type { Download } from '../../nordics/types';

// components
import { BUTTON_BACKGROUND, BUTTON_COLORS, BUTTON_TYPES, Button } from 'components/Button/Button';
import { Translation } from 'components/Translation/Translation';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { decodingContent } from 'utils/decodingContent';

type CartDownloadsProps = {
  downloads: Download[];
  updateDownload: (download: Download) => void;
  downloadZipHandler: () => void;
  downloadProgress: number;
};

function CartDownloads({
  downloads,
  updateDownload,
  downloadZipHandler,
  downloadProgress,
}: Readonly<CartDownloadsProps>) {
  const tableData = downloads.map((download) => {
    const {
      meta: { title, indexer_filetype: downloadDoctype },
    } = download;

    return (
      <tr key={key(download)}>
        <td>
          {!_isEmpty(title) && !_isEmpty(title[0]) && (
            <InnerHtml as="span" content={decodingContent(title[0])} />
          )}
        </td>
        <td>{String(decodingContent(downloadDoctype)).toUpperCase()}</td>
        <td>
          <Button
            type={BUTTON_TYPES.SEAMLESS}
            symbol="trashcan"
            color={BUTTON_COLORS.PRIMARY}
            onClick={() => updateDownload(download)}
          />
        </td>
      </tr>
    );
  });

  const downloadCompleted = downloadProgress === 100;
  const downloadIconAnimation =
    downloadProgress > 0 && !downloadCompleted ? 'download-icon-animation' : '';
  const checkIconClass = downloadCompleted ? 'download-icon-finished' : '';
  let buttonLabel = downloadCompleted
    ? 'web20_downloadcenter_cartdownload_as_zip_completed'
    : 'web20_downloadcenter_cartdownload_as_zip';

  if (downloadProgress === 0.1) {
    buttonLabel = 'web20_downloadcenter_cartdownload_as_zip_creating_zip';
  }

  return (
    <div className="c-cart-downloads">
      <table>
        <tbody>{tableData}</tbody>
        <thead>
          <tr>
            <th>
              <Translation id="web20_downloadcenter_cartmodal_cell_designation" />
            </th>
            <th>
              <Translation id="web20_downloadcenter_cartmodal_cell_format" />
            </th>
            <th />
          </tr>
        </thead>
      </table>
      <Button
        color={BUTTON_COLORS.WHITE}
        background={BUTTON_BACKGROUND.PRIMARY}
        onClick={downloadZipHandler}
        className={`${downloadIconAnimation} ${checkIconClass}`}
      >
        <div className="icon-download__wrapper">
          <span className="icon-download__icon" />
        </div>
        <span className="progress-bar" style={{ width: `${downloadProgress}%` }}></span>
        <span className="download-button--text">
          <Translation id={buttonLabel} />
        </span>
      </Button>
    </div>
  );
}

export default CartDownloads;

import { useCallback } from 'react';

// styles
import styles from './checkoutIntro.module.scss';

// components
import { BUTTON_BACKGROUND, BUTTON_COLORS, Button } from '../Button/Button';
import { Translation } from '../Translation/Translation';

// utils
import { useCiamLogin } from 'utils/hooks/useCiam';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';

interface CheckoutIntroViewProps {
  onGuestClick: () => void;
  hasGuestButton: boolean;
}

export function CheckoutIntroView({
  onGuestClick,
  hasGuestButton,
}: Readonly<CheckoutIntroViewProps>) {
  const gigyaWindow = typeof window !== 'undefined' ? window['gigya'] : null;
  const language = useCurrentLanguage();
  const { login } = useCiamLogin(gigyaWindow);

  const openLogin = useCallback(() => {
    const country = language.slice(language.length - 2);
    login(language, `${country}_Webshop`);
  }, [language, login]);

  return (
    <div className="grid-container">
      <div className={styles.checkoutIntro}>
        <div className="title--block c-page-headline">
          <h1>
            <b>
              <Translation id="web20_checkout_intro_title" />
            </b>
            <Translation id="web20_checkout_intro_subtitle" />
          </h1>
        </div>
        <div className="grid-x grid-margin-x">
          <div className={`cell small-12 ${styles.checkoutIntroText}`}>
            <Translation id="web20_checkout_intro_text" />
          </div>
          <div className={`cell small-12 ${styles.checkoutButtons}`}>
            <Button
              symbol="user"
              background={BUTTON_BACKGROUND.PRIMARY}
              color={BUTTON_COLORS.WHITE}
              onClick={openLogin}
            >
              <Translation id="web20_checkout_intro_button_login_register" />
            </Button>
            {hasGuestButton && (
              <Button
                symbol="arrow-link-light-right"
                onClick={onGuestClick}
                className={styles.editButton}
              >
                <Translation id="web20_checkout_intro_button_guest" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import key from 'weak-key';

// Components
import { SingleTile } from '../Tile/SingleTile';
import { Loader } from '../../Loader/Loader';

/**
 * Render Product List
 *
 * @param products - Products on one page
 * @param isLoaded
 * @param props
 * @returns {*}
 * @constructor
 */

interface ProductListingProps {
  products: any[] | null;
  isLoaded: boolean;
}

function ProductListing({ products = null, isLoaded, ...props }: Readonly<ProductListingProps>) {
  return (
    <div className="grid-x c-product-tiles">
      {isLoaded ? (
        products.map((product) => (
          <div
            className="cell small-12 medium-6 large-3 c-product-tiles__column"
            key={key(product)}
          >
            <SingleTile product={product} listingPage {...props} />
          </div>
        ))
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default ProductListing;

import { useEffect, useState } from 'react';
import { HeroImage } from '@geberit/gdds';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';

// styled
import { StyledHeroImageThemedWrapper } from './hero-image-themed.styles';

// types
import type { HeroImageThemedProps } from './hero-image-themed.types';

// components
import { HeroImageThemedDefault } from './hero-image-themed-default';

// utils
import { currentPageSelector } from 'utils/selectors/pageSelectors';
import { useThemeName } from 'utils/hooks/use-theme';
import { getIdsOfEmptyContentBoxes } from './hero-image-themed.utils';

const DELAY_DEFAULT = 6;
const DELAY_INFINITY = 99999;

function HeroImageThemed(props: HeroImageThemedProps) {
  const { contentBoxPosition, isSeoConformCarousel, items } = props;
  const { ref: refSlider, inView } = useInView({
    threshold: 0,
  });

  const themeName = useThemeName();

  const page = useSelector(currentPageSelector);

  const [navHeight, setNavHeight] = useState<number>(0);

  const [isForgroundVideoOpen, setIsForgroundVideoOpen] = useState<boolean>(false);

  const emptyContentBoxIds = getIdsOfEmptyContentBoxes({ items });

  useEffect(() => {
    const headerHeight = document.getElementById('page-header')?.offsetHeight;
    setNavHeight(headerHeight ?? 0);
  }, []);

  const isNordicsContentPage = page?.type === 'nordics_contentpage';

  useEffect(() => {
    if (isForgroundVideoOpen) return;

    window?.swiper?.autoplay?.run();
  }, [isForgroundVideoOpen]);

  if (themeName === 'DEFAULT') {
    return (
      <div ref={refSlider}>
        <HeroImageThemedDefault
          {...props}
          emptyContentBoxIds={emptyContentBoxIds}
          isNordicsContentPage={isNordicsContentPage}
          navHeight={navHeight}
          isForgroundVideoOpen={isForgroundVideoOpen}
          setIsForgroundVideoOpen={setIsForgroundVideoOpen}
          sliderDelay={isForgroundVideoOpen || !inView ? DELAY_INFINITY : DELAY_DEFAULT}
        />
      </div>
    );
  }

  return (
    <StyledHeroImageThemedWrapper
      className="hero-image-themed"
      emptyContentBoxIds={emptyContentBoxIds}
      isSlider={items.length > 1}
      ref={refSlider}
    >
      <HeroImage
        type={items.length > 1 ? 'b' : 'a'}
        items={items}
        previewId={isNordicsContentPage ? '#pt_heroTypeABItems' : undefined}
        previewIdExtractor={
          isNordicsContentPage
            ? (_, index) => {
                return `#${index}`;
              }
            : undefined
        }
        navigationHeight={navHeight}
        isSeoConformCarousel={isSeoConformCarousel}
        contentBoxPosition={contentBoxPosition}
      />
    </StyledHeroImageThemedWrapper>
  );
}

export { HeroImageThemed };

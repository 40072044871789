import { useState } from 'react';
import { HeroImage } from '@geberit/gdds';

// types
import type { HeroImageThemedDefaultProps } from './hero-image-themed-default.types';

// components
import { HeroImageHeadlineReplacer } from '../hero-image-headline-replacer';
import { ForegroundVideo } from '../foreground-video';

// utils
import { StyledHeroImageThemed } from './hero-image-themed.styles';
import { normalizeItems } from './hero-image-themed.utils';
import { useIsMobile } from 'components/App/SizeProvider';
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { classNameBuilder } from 'utils/classNameBuilder';

function HeroImageThemedDefault(props: HeroImageThemedDefaultProps) {
  const {
    contentBoxPosition,
    emptyContentBoxIds,
    isSeoConformCarousel,
    items,
    isForgroundVideoOpen,
    isNordicsContentPage,
    navHeight,
    setIsForgroundVideoOpen,
    sliderDelay,
    isSection,
  } = props;

  const [foregroundVideo, setForegroundVideo] = useState<VideoProp | undefined>(undefined);
  const videoPlayButtonLabel = useTranslationByKey('web20_video_play_button');

  const isMobile = useIsMobile();

  const handleForegroundVideoOpen = (video: VideoProp) => {
    setForegroundVideo(video);
    setIsForgroundVideoOpen(true);
  };

  const handleForegroundVideoClose = () => {
    setForegroundVideo(undefined);
    setIsForgroundVideoOpen(false);
  };

  const _items = normalizeItems({
    isMobile,
    items,
    onForegroundVideoClick: handleForegroundVideoOpen,
    videoPlayButtonLabel,
  });

  const _type = items.length === 1 ? 'a' : 'b';

  return (
    <StyledHeroImageThemed.Default
      emptyContentBoxIds={emptyContentBoxIds}
      isSlider={items.length > 1}
      className={classNameBuilder(
        'hero-image-themed hero-image-themed__default',
        isSection && 'is-section',
      )}
    >
      <HeroImageHeadlineReplacer heroImageType={_type} items={_items}>
        <HeroImage
          type={_type}
          items={_items}
          previewId={isNordicsContentPage ? '#pt_heroTypeABItems' : undefined}
          previewIdExtractor={
            isNordicsContentPage
              ? (_, index) => {
                  return `#${index}`;
                }
              : undefined
          }
          navigationHeight={navHeight}
          isSeoConformCarousel={isSeoConformCarousel}
          contentBoxPosition={contentBoxPosition}
          slidingInterval={sliderDelay}
        />
        {foregroundVideo && isForgroundVideoOpen && (
          <ForegroundVideo onClose={handleForegroundVideoClose} video={foregroundVideo} />
        )}
      </HeroImageHeadlineReplacer>
    </StyledHeroImageThemed.Default>
  );
}

export { HeroImageThemedDefault };

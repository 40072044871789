import { Col, Row, Tiles, ExpressiveTile, Typo } from '@geberit/gdds';
import { useRouter } from 'next/navigation';

// styles
import styles from './press-tiles.module.scss';

// types
import type { Material } from '../types/mynewsdesk.types';
import type { PressTilesProps } from '../types/press-portal.types';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { formatDate, getMaterialUniqueIdentifier } from '../utils';
import { useIsMobile, useIsTablet } from 'components/App/SizeProvider';
import { useLocale } from 'utils/use-locale';
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { classNameBuilder } from 'utils/classNameBuilder';

export default function PressTiles({
  language,
  materials,
  withHeroTile = true,
  fallbackImg,
  fallbackBackgroundColor,
}: Readonly<PressTilesProps>) {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const locale = useLocale(language);
  const router = useRouter();
  const learnmoreTranslation = useTranslationByKey('press_learn_more');

  const getImageUrl = (material: Material, originSize?: boolean) => {
    if (!material) {
      return undefined;
    }

    if (originSize) return material.image;
    if (isMobile) return material.image_small;
    if (isTablet) return material.image_medium;

    return material.image_medium;
  };

  return (
    <div className={styles.materialsWrapper}>
      <Row>
        <Col>
          <Tiles
            fallbackImg={fallbackImg}
            backgroundColor={fallbackBackgroundColor}
            type="expressive"
          >
            {materials.map((material, index) => {
              const pathname = `?page=detailpage&title=${getMaterialUniqueIdentifier(material)}`;
              const target = new URL(pathname, window.location.href).toString();
              const link =
                typeof window !== 'undefined'
                  ? {
                      text: learnmoreTranslation,
                      target,
                      hasArrow: false,
                      onClick: (e: Event) => {
                        e.preventDefault();
                        router.push(target, undefined, { shallow: true });
                      },
                    }
                  : undefined;
              const imageStyles = {
                size: 'cover' as const,
                repeat: false,
              };
              const datetimeText = formatDate(material, locale);

              const commonProps = { link, imageStyles };

              if (withHeroTile && index === 0)
                return (
                  <ExpressiveTile
                    key={material.id}
                    type={'tlea3'}
                    backgroundType="dark"
                    imageSize="m"
                    customParagraph={
                      <>
                        <Typo as="p" className={styles.publishedAt} variant="p2">
                          {datetimeText}
                        </Typo>
                        <InnerHtml
                          className={styles.copyText}
                          as="h3"
                          content={material?.header}
                          isGdds
                        />
                      </>
                    }
                    image={getImageUrl(material, true)}
                    {...commonProps}
                  />
                );

              return (
                <ExpressiveTile
                  key={material.id}
                  type={'tleb1'}
                  noBorder
                  renderHeadline={() => (
                    <Typo
                      as="p"
                      className={classNameBuilder(styles.publishedAt, styles.tleb1)}
                      variant="p2"
                    >
                      {datetimeText}
                    </Typo>
                  )}
                  customParagraph={
                    <InnerHtml
                      className={classNameBuilder(styles.copyText, styles.tleb1)}
                      as="h4"
                      content={material.header}
                      isGdds
                    />
                  }
                  image={getImageUrl(material)}
                  {...commonProps}
                />
              );
            })}
          </Tiles>
        </Col>
      </Row>
    </div>
  );
}

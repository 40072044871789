import { useRouter } from 'next/navigation';
import { Button, Container, Col, Row } from '@geberit/gdds';
import key from 'weak-key';

// styles
import styles from './hero-image.module.scss';

// components
import { Image } from 'components/image/image';
import { Headline, Formats, getFontWeight } from 'components/ContentElements/Headline';
import HeroImageCarousel from './hero-image-carousel';

// utils
import { classNameBuilder as buildClassName } from 'utils/classNameBuilder';
import { decodingContent } from 'utils/decodingContent';
import { useIsMobile } from 'components/App/SizeProvider';
import { useThemeName } from 'utils/hooks/use-theme';
import { isEmpty } from 'utils/is-empty';

function HeroImage({ items }: Readonly<HeroImageProps>) {
  const isMobile = useIsMobile();
  const router = useRouter();
  const themeName = useThemeName();
  if (isEmpty(items)) return null;

  const renderCarouselWrapper = (children) => {
    if (items?.length > 1) {
      return <HeroImageCarousel items={items}>{children}</HeroImageCarousel>;
    }

    return children;
  };

  return (
    <div className={styles.heroimageWrapper}>
      {renderCarouselWrapper(
        items.map((item) => {
          const {
            imageObjectMobile,
            imageObject,
            imageAlt,
            pictureAlt,
            headline,
            subline,
            emphasize,
            link,
            colorVariant,
          } = item;
          const imageObj = isMobile && imageObjectMobile.url ? imageObjectMobile : imageObject;

          return (
            <div className={styles.heroimage} key={key(item)}>
              <Image data={imageObj} alt={imageAlt || pictureAlt} title={imageAlt || pictureAlt} />
              <Container className={styles.styledContainer} maxContentWidth="78rem">
                <Row className={styles.styledRow}>
                  {items.length > 1 && <Col className={styles.desktopCol} size={[0, 1, 1]} />}
                  <Col className={styles.styledCol}>
                    <div className={buildClassName(styles.contentBoxWrapper, styles[colorVariant])}>
                      <Headline
                        title={decodingContent(headline?.slice(0, 50), true)}
                        subtitle={decodingContent(subline?.slice(0, 50), true)}
                        format={Formats.h1}
                        titleFontWeight={getFontWeight(
                          emphasize === 'title',
                          themeName,
                          Formats.h1,
                        )}
                        subtitleFontWeight={getFontWeight(
                          emphasize === 'subtitle',
                          themeName,
                          Formats.h1,
                        )}
                      />
                      {link && (
                        <Button
                          onClick={() => {
                            if (link?.window === '_blank') {
                              window.open(link?.target);
                            } else if (link?.type === 'external_link') {
                              window.location.href = link?.target;
                            } else router.push(link?.target);
                          }}
                          stylingType={colorVariant === 'light' ? 'secondary' : 'flatInverted'}
                        >
                          {link.text}
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          );
        }),
      )}
    </div>
  );
}

export default HeroImage;

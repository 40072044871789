import { useState, useEffect } from 'react';
import key from 'weak-key';
import { usePathname } from 'next/navigation';

// components
import VideocenterDropdown from './VideocenterDropdown';
import VideoItem from '../Video/VideoItem';
import VideoItemLarge from '../Video/VideoItemLarge';
import { Translation } from '../Translation/Translation';
import { BUTTON_ALIGNMENT, BUTTON_BACKGROUND, BUTTON_COLORS, Button } from '../Button/Button';
import { Title as PageHeadline } from '../ContentElements/Title/Title';
import { TitleFormats } from '../ContentElements/Title/title.types';

// utils
import { useIsMobile } from 'components/App/SizeProvider';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';

interface VideocenterChannelProps {
  searchQueryChannelId: string;
  channels: any[];
}

export default function VideocenterChannel({
  searchQueryChannelId,
  channels,
}: Readonly<VideocenterChannelProps>) {
  const translate = useTranslationFunction();
  const isMobile = useIsMobile();
  const pathname = usePathname();
  const hash = pathname.split('#')?.[1];
  const [currentLength, setCurrentLength] = useState(isMobile ? 6 : 12);
  const [sharedVideo, setSharedVideo] = useState<number>(-1);

  useEffect(() => {
    if (hash && !isEmpty(channels)) {
      const channel = channels.find((item) => item.id === searchQueryChannelId);
      const videoId = hash;
      const video = channel.videos
        .filter((_, index) => index >= 1) // exclude first video which is displayed in full width
        .find((videoItem) => videoItem.video.videoId === videoId);
      if (isEmpty(video)) return;
      setSharedVideo(video.index - 1);
      let videoLength = video.index + 1;
      // videoLength is out of range of the initially displayed number of videos: isMobile ? 6 : 12
      if (currentLength < videoLength) {
        // only display videos in steps of 3
        videoLength = videoLength % 3 === 0 ? videoLength : videoLength - (videoLength % 3) + 3;
        setCurrentLength(videoLength);
      }
    }
  }, [hash, channels, currentLength, searchQueryChannelId]);

  if (isEmpty(channels)) return null;

  const channel = channels.find((item) => item.id === searchQueryChannelId) ?? { videos: [] };

  channel.videos = channel.videos?.filter((item) => item.video.videoId);
  const firstVideo = channel?.videos[0];
  const videos = channel?.videos.slice(0, currentLength + 1);
  const channelUrl = channel && `${channel.url}?channel=${channel.id}`;

  function handleLoadMore() {
    if (currentLength < channel.videos.length - 3) {
      setCurrentLength(currentLength + 3);
    } else if (currentLength < channel.videos.length) {
      setCurrentLength(channel.videos.length);
    }
  }

  return (
    <>
      <PageHeadline
        Format={TitleFormats.h1}
        title={translate('web20_videocenter_channel')}
        subtitle={channel ? channel.title : ''}
        pageHeadline
      />
      <div className="grid-container c-video-center c-video-center--channel-page">
        <div className="c-video-center__channel">
          {firstVideo && <VideoItemLarge video={firstVideo} key={key(VideoItemLarge)} />}
        </div>
        <VideocenterDropdown searchQueryChannelId={searchQueryChannelId} channels={channels} />
        <div className="c-video-center__channel">
          {videos
            ?.filter((video, index) => index >= 1)
            .map((video, index) => {
              const videoCopy = {
                ...video,
                ...(isEmpty(video.channelUrl) ? { channelUrl } : {}),
              };
              return <VideoItem key={key(video)} video={videoCopy} open={sharedVideo === index} />;
            })}
          {channel && currentLength < channel.videos.length && (
            <div className="c-video-center__button" key="button-wrapper">
              <div className="c-video-center__item" key="button-item-wrapper">
                <Button
                  key="button"
                  color={BUTTON_COLORS.WHITE}
                  background={BUTTON_BACKGROUND.PRIMARY}
                  symbol="reload"
                  align={BUTTON_ALIGNMENT.CENTER}
                  onClick={handleLoadMore}
                >
                  <Translation id="web20_more_results" key="translation" />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import { Button, Icon } from '@geberit/gdds';

// styles
import styles from './layout.module.scss';

// types
import type { Course } from 'components/Campus/types';

// components
import CampusLayout from './CampusLayout';
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import { InternalLink } from 'components/Link/InternalLink';
import { Translation } from 'components/Translation/Translation';
import { CampusVideo } from 'components/Campus/CampusVideo/CampusVideo';
import { ScormPlayer } from 'components/Campus/ScormPlayer/ScormPlayer';

// utils
import { getMetatag } from 'components/Campus/metatags';
import { useIsMobile } from 'components/App/SizeProvider';
import { useCampusEnv } from 'utils/hooks/useCampus';

interface CampusCourseContentProps {
  course: Course;
  content: { contentType: string; id: string };
}

export default function CampusCourseContent({
  course,
  content,
}: Readonly<CampusCourseContentProps>) {
  const { CAMPUS_META } = useCampusEnv();
  const isMobile = useIsMobile();

  const closeContent = (e) => {
    e.preventDefault();
    window.location.hash = '';
  };

  if (!course) return null;

  const metaTags = course?.metaTags;

  return (
    <CampusLayout>
      {/* section: header */}
      <div className={`grid-container ${styles.courseDetailsHeader}`}>
        {/* link to overview */}
        {!isMobile && (
          <InternalLink
            content={{ target: window.location.pathname, showArrow: true }}
            className={`web20-icon-arrow-link-light-left ${styles.gobackLinkTop}`}
            onClick={closeContent}
          >
            <Translation id="campus_back_course" />
          </InternalLink>
        )}
        {/* headline */}
        <PageHeadline
          Format={TitleFormats.h1}
          title={getMetatag(CAMPUS_META.NAME, metaTags)}
          className={styles.courseHeadline}
          pageHeadline
        />
        {/* content */}
        {isMobile ? (
          <div className={styles.mobileNotAvailable}>
            <div className={styles.errorIcon}>
              <span>
                <Icon symbol="Close" width="1.15rem" color="#DA0012" />
              </span>
            </div>
            <b>Not available</b>
            Unfortunately the course is not available on the smartphone. Please use a computer or
            tablet to access the course.
            <Button stylingType="primary" onClick={closeContent}>
              <Translation id="campus_back_course" />
            </Button>
          </div>
        ) : (
          <div className={styles.courseContentFrame}>
            {content?.contentType === 'MEDIA_FILE' && (
              <CampusVideo courseId={course?.id} mediaId={content.id} content={content} />
            )}
            {content?.contentType === 'WBT_SCORM' && (
              <ScormPlayer courseId={course?.id} content={content} />
            )}
          </div>
        )}
      </div>
    </CampusLayout>
  );
}

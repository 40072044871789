import styled, { css } from 'styled-components';
import { IntroText } from 'components/ContentElementsGdds/IntroText/intro-text';

export const IntroTextStyles = styled(IntroText)(({ isGdds }: { isGdds: boolean }) => {
  return (
    !isGdds &&
    css`
      span > * {
        font-size: 15px !important;
        line-height: 1.5rem !important;
      }

      sub,
      sup {
        font-size: 0.75rem !important;
      }
    `
  );
});

import key from 'weak-key';

// components
import { CmsLink } from '../components/Link/CmsLink';

// utils
import { isEmpty } from './is-empty';
import { decodingContent } from './decodingContent';

export function renderLinks(itemOrItems) {
  const items = itemOrItems.length > 0 ? [...itemOrItems] : [{ ...itemOrItems }];
  return items
    .filter((link) => !isEmpty(link))
    .map((link) => {
      if (!link.text && !link.target) {
        return null;
      }

      link.text = decodingContent(link.text);

      return <CmsLink key={key(link)} link={link} />;
    });
}

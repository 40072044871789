import { ChangeEvent, useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

// components
import ShoppingBasketEntry from '../ShoppingBasket/ShoppingBasketEntry';
import type { BasketItem } from '../ShoppingBasket/reducer.types';
import { SessionContext, State as SessionContextState } from '../Session/SessionContext';

// utils
import { ARTICLE_LIST_ACTIONS } from './contants';

interface ArticleListContainerProps {
  count: number;
  id: string;
  type: string;
  article?: BasketItem;
}

type ActionDispatchers = {
  addProduct: (
    id: string,
    session: SessionContextState['session'],
    ciam: SessionContextState['ciam'],
  ) => void;
  decreaseProduct: (
    id: string,
    session: SessionContextState['session'],
    ciam: SessionContextState['ciam'],
  ) => void;
  setProduct: (
    id: string,
    count: number,
    session: SessionContextState['session'],
    ciam: SessionContextState['ciam'],
  ) => void;
  deleteProduct: (
    id: string,
    session: SessionContextState['session'],
    ciam: SessionContextState['ciam'],
  ) => void;
};

const ArticleListContainer = ({
  article,
  count,
  id,
  type,
}: Readonly<ArticleListContainerProps>) => {
  const [countInput, setCountInput] = useState<string>(String(count));
  const {
    state: { session, ciam },
  } = useContext(SessionContext);
  const dispatch = useDispatch();
  useEffect(() => {
    setCountInput(String(count));
  }, [count]);

  const actionDispatchers: ActionDispatchers = {
    addProduct: (id, session, ciam) =>
      dispatch(ARTICLE_LIST_ACTIONS[type].addProduct(id, session, ciam)),
    decreaseProduct: (id, session, ciam) =>
      dispatch(ARTICLE_LIST_ACTIONS[type].decreaseProduct(id, session, ciam)),
    setProduct: (id, count, session, ciam) =>
      dispatch(ARTICLE_LIST_ACTIONS[type].setProduct(id, count, session, ciam)),
    deleteProduct: (id, session, ciam) =>
      dispatch(ARTICLE_LIST_ACTIONS[type].deleteProduct(id, session, ciam)),
  };

  const setItem = (e: ChangeEvent<HTMLInputElement>) => {
    const inputNum = e.target.value;
    setCountInput(inputNum);
    let parsedNum = parseInt(inputNum, 10);

    if (parsedNum > 99) parsedNum = 99;
    else if (parsedNum < 1) parsedNum = 1;

    if (!isNaN(parsedNum)) {
      actionDispatchers.setProduct(id, parsedNum, session, ciam);
    }
  };

  const onInputBlur = () => {
    if (countInput !== String(count)) {
      setCountInput(String(count));
    }
  };

  const debouncedIncreaseProduct = useDebouncedCallback(
    () => actionDispatchers.addProduct(id, session, ciam),
    500,
  );
  const debouncedDecreaseProduct = useDebouncedCallback(
    () => actionDispatchers.decreaseProduct(id, session, ciam),
    500,
  );

  return (
    <ShoppingBasketEntry
      article={article}
      count={count}
      countInput={countInput}
      decreaseItem={debouncedDecreaseProduct}
      deleteItem={() => actionDispatchers.deleteProduct(id, session, ciam)}
      increaseItem={debouncedIncreaseProduct}
      onInputBlur={onInputBlur}
      setItem={setItem}
    />
  );
};

export default ArticleListContainer;

import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { Icon, Typo } from '@geberit/gdds';
import { useTheme } from 'styled-components';

// styles
import { StyledHeader } from './header.styles';

// types
import { IEntry } from '../../locator.types';
import { DealerLabel } from '../../map';

// utils
import { useLocatorConfiguration } from '../../utils/use-locator-configuration';
import { decodingContent } from 'utils/decodingContent';
import { useNord } from 'utils/hooks/use-nord';

export function Header(
  entry: {
    locatorPath: string | undefined;
  } & IEntry,
) {
  const { locatorPath, name } = entry;
  const router = useRouter();
  const searchParams = useSearchParams();
  const locatorConfig = useLocatorConfiguration();
  const theme = useTheme();
  const isNord = useNord();

  const searchTerm = searchParams.get('_search');

  const H3_SIZES = {
    xsmall: theme.fontTokens.font07,
    medium: theme.fontTokens.font06,
    large: theme.fontTokens.font05,
    xlarge: theme.fontTokens.font04,
  };

  return (
    <StyledHeader>
      <Link
        onClick={(e) => {
          e.preventDefault();
          if (searchTerm) {
            router.back();
          } else {
            router.push(locatorPath!);
          }
        }}
        className="overview-link"
        href={`${locatorPath!}${searchTerm ? decodeURIComponent(searchTerm) : ''}`}
      >
        <Icon symbol="arrow_left" />
        {decodingContent(locatorConfig?.dealerPage?.toOverviewPage)}
      </Link>
      <Typo size={H3_SIZES} variant="h1" fontWeight={isNord ? 400 : 700} isUppercase={isNord}>
        {name}
      </Typo>
      <DealerLabel entry={entry} />
    </StyledHeader>
  );
}

import { Col, Container, Row, AspectRatio, Typo, List, Button } from '@geberit/gdds';

// styles
import styles from './press-portal-asset-detail-page.module.scss';

// types
import type { Media } from './types/mynewsdesk.types';
import type { PressPortalAssetDetailsProps } from './types/press-portal.types';

// components
import { Headline, FontWeights, Formats } from 'components/ContentElements/Headline';
import RelatedMediaTiles from './media-tiles/related-media-tiles';
import RelatedPressTiles from './press-tiles/related-press-tiles';
import Tags from '../Tags/tags';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';

// utils & hooks
import { buildSize, gridSizes } from 'utils/gridSize';
import { useFetchMaterial as useViewMaterial } from './hooks/use-fetch-material';
import { downloadMedia, formatDate, useGetMaterialReqConfig } from './utils';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useMediaDetails } from './hooks/use-media-details';
import { useLocale } from 'utils/use-locale';
import { useKolo } from 'utils/hooks/use-kolo';
import { useIsDesktop } from 'components/App/SizeProvider';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useTranslationFunction } from 'utils/hooks/use-translations';

const mediaTypeTranslationKeyMap = {
  video: 'press_download_video',
  image: 'press_download_image',
  pdf: 'press_download_pdf',
  docx: 'press_download_file',
};

function PressPortalAssetDetailPage({
  breadcrumbs,
  isGdds,
}: Readonly<PressPortalAssetDetailsProps>) {
  const isGddsDesktop = useIsDesktop({ gdds: true });
  const language = useCurrentLanguage();
  const locale = useLocale(language);
  const materialReqConfig = useGetMaterialReqConfig(language);
  const { material } = useViewMaterial<Media>(materialReqConfig);
  const details: string[] = useMediaDetails(material, true);
  const isKolo = useKolo();
  const hasFileOrPdf =
    material &&
    material.type_of_media === 'document' &&
    (material.document_format === '.pdf' || material.document_format === '.docx');

  let downloadTranslationKey = material
    ? mediaTypeTranslationKeyMap[material.type_of_media]
    : undefined;

  if (hasFileOrPdf) {
    downloadTranslationKey = mediaTypeTranslationKeyMap[material.document_format.replace('.', '')];
  }
  const translate = useTranslationFunction();

  function getImageUrl(): {
    imageUrl?: string;
    aspectRatio: string;
    mode?: 'portrait' | 'landscape';
  } {
    if (!material) {
      return { imageUrl: undefined, aspectRatio: '16:9' };
    }

    if (material.type_of_media === 'image') {
      let mode: 'portrait' | 'landscape' = 'portrait';
      const [width, height] = material.image_dimensions?.split('x')?.map(Number) || [0, 0];

      if (width > height) {
        mode = 'landscape';
      }

      return {
        imageUrl: material.image,
        aspectRatio: mode === 'portrait' ? '3:4' : '16:9',
        mode,
      };
    }

    if (material.type_of_media === 'document') {
      return {
        imageUrl: material.document_thumbnail,
        aspectRatio: '3:4',
        mode: 'portrait',
      };
    }

    if (material.type_of_media === 'video') {
      return {
        imageUrl: material.thumbnail,
        aspectRatio: '16:9',
        mode: 'landscape',
      };
    }

    return { imageUrl: undefined, aspectRatio: '16:9', mode: 'landscape' };
  }

  if (!material) {
    return null;
  }

  const imageInfo = getImageUrl();

  return (
    <>
      <Breadcrumb
        items={breadcrumbs.concat({ breadcrumbDisabled: true, label: material?.header })}
      />
      <div className={styles.pressPortalAssetDetailPage}>
        <Container maxContentWidth={isGdds ? buildSize(gridSizes.gddsFullGrid) : '80rem'}>
          <Row className={styles.titleWrapper}>
            <Col size={[0, 0, 2]} />
            <Col size={[4, 8, 8]}>
              <Typo variant="p1">{formatDate(material, locale)}</Typo>
              {material?.header && (
                <Headline
                  title={material?.header}
                  format={Formats.h1}
                  titleFontWeight={isKolo || !isGdds ? FontWeights.bold : undefined}
                  subtitleFontWeight={isKolo ? FontWeights.bold : undefined}
                  {...(!isGdds && { isUppercase: true })}
                />
              )}
            </Col>
            <Col size={[0, 0, 2]} />
          </Row>

          <Row className={classNameBuilder(styles.document, !isGdds && styles.documentFontSize)}>
            {/* render Col on portrait and only on Desktop */}
            {imageInfo.mode === 'portrait' && isGddsDesktop && <Col size={[0, 0, 2]} />}

            <Col size={imageInfo.mode === 'portrait' ? [4, 4, 4] : [4, 8, 6]}>
              <div
                className={classNameBuilder(
                  styles.wrapper,
                  imageInfo.mode === 'landscape' && styles.landscape,
                  hasFileOrPdf && styles.documentBorder,
                )}
              >
                {material && imageInfo.imageUrl && (
                  <div className={styles.imageContainer}>
                    <AspectRatio ratio={imageInfo.aspectRatio}>
                      {<img className={styles.heroImageC} src={imageInfo.imageUrl} />}
                    </AspectRatio>
                  </div>
                )}
              </div>
            </Col>
            <Col size={[4, imageInfo.mode === 'portrait' ? 4 : 5, 4]}>
              <div className={styles.desc}>
                <Headline
                  format="h4"
                  titleFontWeight={FontWeights.bold}
                  subtitleFontWeight={isKolo ? FontWeights.bold : undefined}
                  className={styles.headline}
                >
                  {translate('press_media_details')}
                </Headline>
                <List level="1" listItemMarginLeft={16} listItemMarginTop={12}>
                  {details.map((detail) => (
                    <List.Item key={detail}>
                      <Typo variant="p1" className={styles.text}>
                        {detail}
                      </Typo>
                    </List.Item>
                  ))}
                </List>
                <Button
                  symbol="Download"
                  onClick={(e) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    downloadMedia(material);
                  }}
                >
                  {translate(downloadTranslationKey)}
                </Button>
                <Tags tags={material.tags} />
              </div>
            </Col>

            {/* render Col on portrait and only on Desktop */}
            {imageInfo.mode === 'portrait' && isGddsDesktop && <Col size={[0, 0, 2]} />}
          </Row>
          <RelatedPressTiles materials={material.related_news} language={language} />
          <RelatedMediaTiles mediaList={material.related_materials} language={language} />
        </Container>
      </div>
    </>
  );
}

export default PressPortalAssetDetailPage;

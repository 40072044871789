import React, { useState, useEffect } from 'react';
import { Typo } from '@geberit/gdds';

// Components
import DownloadcenterListItem from './dlc-list-item';

// Utils
import { categoryHeadingSize } from './utils/sizes';

// Styles
import styles from './downloadcenter.module.scss';

interface DownloadcenterCategoryProps {
  items: any[];
  updateDownloads: (item: any) => void;
  updateOrders: (item: any) => void;
  categoryName: string;
  downloads: any[];
  orders: any[];
}

function DownloadcenterCategory({
  items,
  updateDownloads,
  updateOrders,
  categoryName,
  downloads,
  orders,
}: Readonly<DownloadcenterCategoryProps>) {
  // Hooks
  const [uniqueItems, setUniqueItems] = useState([]);

  useEffect(() => {
    if (items) {
      const tempUniqueItems = [];

      items.forEach((item) => {
        const found = tempUniqueItems.find((element) => element.id === item.id);
        if (!found) {
          tempUniqueItems.push(item);
        }
      });

      setUniqueItems(tempUniqueItems);
    }
  }, [items]);

  return (
    <>
      {uniqueItems.length > 0 && (
        <div className={styles.categoryContainer}>
          <Typo
            tag="div"
            size={categoryHeadingSize}
            fontWeight="600"
            className={styles.categoryHeadline}
          >
            {categoryName}
          </Typo>
          {uniqueItems.length > 0 &&
            uniqueItems.map((item) => (
              <DownloadcenterListItem
                key={item.id}
                item={item}
                setDownloadCounter={() => updateDownloads(item)}
                setOrderCounter={() => updateOrders(item)}
                downloads={downloads}
                orders={orders}
              />
            ))}
        </div>
      )}
    </>
  );
}

export default DownloadcenterCategory;

import { useSelector } from 'react-redux';

// components
import { Translation } from '../../Translation/Translation';

// utils
import { TAB_PRODUCT, TAB_CONTENT } from '../constants';
import {
  selectSearchProducttResultsTotal,
  selectSearchContentResultsTotal,
  selectSearchQuery,
} from '../../../utils/selectors/searchSelectors';
import { translationByKeySelector } from '../../../utils/selectors/translationSelectors';

interface SearchInfoProps {
  tab: number;
}

function SearchInfo({ tab }: Readonly<SearchInfoProps>) {
  const query: string | undefined = useSelector(selectSearchQuery);
  const productResult: number = useSelector(selectSearchProducttResultsTotal);
  const contentResult = useSelector(selectSearchContentResultsTotal);
  const sparePartsLink = useSelector((state: AppState) =>
    translationByKeySelector(state, 'web20_search_find_spareparts_target'),
  );

  return (
    <div className="grid-x grid-margin-x">
      <p className="page-infotext cell small-12 medium-6 large-8">
        <Translation
          id="web20_search_stats"
          placeholder={{
            query: query ?? '',
            count: tab === TAB_CONTENT ? contentResult : productResult,
          }}
        />
      </p>
      {tab === TAB_PRODUCT && (
        <a
          className="cell small-0 medium-6 large-4"
          href={sparePartsLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Translation id="web20_search_find_spareparts" />
        </a>
      )}
    </div>
  );
}

export default SearchInfo;

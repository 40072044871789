// types
import { Point } from '../locator.types';

export const mapConfig = {
  mobile: {
    popupHeight: 344,
    transitionDuration: 3,
    mapHeight: 300,
  },
  tablet: {
    popupHeight: 284,
    transitionDuration: 3,
    mapHeight: 452,
  },
  desktop: {
    popupHeight: 116,
    popupWidth: 240,
    popupOffsetBottom: 4,
    searchbarSafeAreaHeight: 60,
    popupPadding: 5,
  },
  activePinHeight: 44,
  mapPaddingBottom: 15,
};

/** helper only for desktop **/
export const isPointLeftFromMap = (point: Point, popupWidth: number): boolean => {
  return point.x < popupWidth / 2;
};

export const isPointTopFromSearchbar = (point: Point, popupHeight: number): boolean => {
  return (
    point.y - popupHeight - mapConfig.activePinHeight < mapConfig.desktop.searchbarSafeAreaHeight
  );
};

export const isPointRightFromMap = (
  point: Point,
  mapWidth: number,
  popupWidth: number,
): boolean => {
  return point.x > mapWidth - popupWidth / 2 - mapConfig.desktop.popupPadding;
};

export const isPointBottomFromMap = (point: Point, mapHeight: number): boolean => {
  return point.y > mapHeight - mapConfig.mapPaddingBottom;
};

export const clampPointLeft = (point: Point, popupWidth: number): number => {
  return Math.max(0, popupWidth / 2 - point.x + mapConfig.desktop.popupPadding);
};

export const clampPointBottom = (point: Point, mapHeight: number): number => {
  return point.y - mapHeight + mapConfig.mapPaddingBottom;
};

export const clampPointTop = (point: Point, popupHeight: number): number => {
  return -Math.max(
    0,
    mapConfig.desktop.searchbarSafeAreaHeight +
      popupHeight +
      mapConfig.activePinHeight +
      mapConfig.desktop.popupPadding -
      point.y,
  );
};

export const clampPointRight = (point: Point, mapWidth: number, popupWidth: number): number => {
  return mapWidth - popupWidth / 2 - mapConfig.desktop.popupPadding - point.x;
};

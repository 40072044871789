import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Formik, FormikValues } from 'formik';

// styles
import styles from './registrationForm.module.scss';

// types
import type { Course, Participant } from '../types';

// components
import RegistrationFormView from './RegistrationFormView';
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import { TitleFormats } from 'components/ContentElements/Title/title.types';

// utils
import { campusShowFactoryTourSelector } from 'utils/selectors/globalsSelectors';
import { validate } from './validationFields';
import { useIsMandatory } from './hooks';
import { useBookCourse, useUserAttributes, useCourseParticipants } from 'utils/hooks/useCampus';
import { useCiamLogin } from 'utils/hooks/useCiam';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { Log } from 'services/log';
import { buildParticipant } from './utils';

interface RegistrationFormProps {
  termOfUse: Course['termOfUse'];
  courseId: string | number;
  courseType: string;
  isBooked: boolean;
  reloadDetails: () => void;
}

export function RegistrationForm({
  courseId,
  courseType,
  isBooked,
  reloadDetails,
  termOfUse,
}: Readonly<RegistrationFormProps>) {
  const showFactoryTour = useSelector(campusShowFactoryTourSelector);
  const bookCourse = useBookCourse();
  const [cateringItems] = useUserAttributes('EATING_HABITS');
  const [roleItems] = useUserAttributes('ROLEZWEI');
  const gigyaWindow = typeof window !== 'undefined' ? window.gigya : null;
  const { profile } = useCiamLogin(gigyaWindow);
  const { participants: bookedUsers, isLoading } = useCourseParticipants(courseId);
  const isMandatory = useIsMandatory();

  const translate = useTranslationFunction();
  const participantsTitle = translate('campus_participant_details');
  const mandatoryErrorMessage = translate('campus_form_required');
  const invalidMailErrorMessage = translate('campus_form_invalid');

  const removedCiamUser = useMemo(() => {
    if (profile) {
      if (!isLoading) {
        const hasCiamBooked = bookedUsers.some(
          (user) =>
            `${user?.firstname}-${user?.lastname}-${user?.email}`.toLowerCase() ===
            `${profile?.firstName}-${profile?.lastName}-${profile?.email}`.toLowerCase(),
        );
        return hasCiamBooked;
      }
    }

    return false;
  }, [profile, isLoading, bookedUsers]);

  const newParticipant: Participant = {
    salutation: '',
    firstname: '',
    lastname: '',
    email: '',
    role: '',
  };

  if (courseType === 'seminar' || courseType === 'event') {
    newParticipant.needhotel = '';
    newParticipant.catering = '';
    if (showFactoryTour) {
      newParticipant.factory = 'no';
    }
  }

  const toggleItems = [
    { value: 'yes', text: translate('campus_form_yes') },
    { value: 'no', text: translate('campus_form_no') },
  ];

  const termsAndConditions = [
    {
      id: 'termsAndConditions',
      value: 'termsAndConditions',
      text: termOfUse?.bundleNameValue,
    },
  ];

  const onSubmit = async (values: FormikValues) => {
    // remove numbers from key
    const newParticipants = (values.participants || []).map((participant: Participant) => {
      return Object.fromEntries(
        Object.entries(participant).map(([key, entry]) => {
          if (key !== 'key') key = key.replace(/\d/g, '');
          return [key, entry];
        }),
      );
    });

    let inquirerParticipant: Participant | undefined = buildParticipant({
      isBooked,
      values,
      courseType,
      showFactoryTour,
    });

    const data = {
      componentId: courseId,
      inquirer: inquirerParticipant,
      participants: newParticipants,
      comment: values.comment,
    };

    if (data.participants.length > 0 || data.inquirer) {
      try {
        bookCourse(data);
        reloadDetails();
        sessionStorage.setItem(
          'bookedParticipants',
          JSON.stringify(data.participants.concat(data.inquirer).filter(Boolean)),
        );
        sessionStorage.setItem('bookedComment', JSON.stringify(data.comment));
        window.scrollTo(0, 0);
        window.location.hash = 'booking-confirm';
      } catch (e) {
        Log.error('Campus booking error', e);
      }
    }
  };

  const initialValues = {
    participants: [],
    participate: '',
    termsAndConditions: false,
    factory: 'no',
    ...newParticipant,
  };
  if (courseType === 'seminar' || courseType === 'event') {
    initialValues.comment = '';
  }

  return (
    <div className="grid-container">
      <div className="grid-x align-center">
        <div className="small-12 large-8">
          <PageHeadline
            Format={TitleFormats.h2}
            title={participantsTitle}
            className={styles.gddsHeadlineH2}
          />
          <Formik
            onSubmit={onSubmit}
            validate={validate(removedCiamUser, isMandatory)}
            initialValues={initialValues}
          >
            {(formikProps) => (
              <RegistrationFormView
                toggleItems={toggleItems}
                termsAndConditions={termsAndConditions}
                courseType={courseType}
                newParticipant={newParticipant}
                mandatoryErrorMessage={mandatoryErrorMessage}
                invalidMailErrorMessage={invalidMailErrorMessage}
                isBooked={isBooked}
                cateringItems={cateringItems}
                roleItems={roleItems}
                profile={profile}
                removedCiamUser={removedCiamUser}
                isLoading={isLoading}
                {...formikProps}
              />
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

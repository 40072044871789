import { useRef, useEffect, useState, useCallback } from 'react';
import SlickSlider from 'react-slick';
import key from 'weak-key';
import _throttle from 'lodash.throttle';

// styles
import { SliderVideoPlayerContainer, SliderWrapper } from './';

// types
import type { SliderProps } from './';

// components
import SlideImage from './slide-image';
import SlideVideo from './slide-video';
import { VideoPlayer, VIDEO_PLAYER_TYPE } from 'components/ContentElementsGdds/VideoPlayer';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

// utils
import { useInView } from 'react-intersection-observer';
import { useUniqueId } from 'utils/hooks/use-unique-id';
import { useTracking } from 'utils/hooks/useTracking';
import { sliderClick } from './tracking-actions';
import { getBgVideoSlide } from './';
import { classNameBuilder } from 'utils/classNameBuilder';
import { isEmpty } from 'utils/is-empty';
import { useTracking as useTracking2 } from 'utils/tracking/track';

export function Slider(props: Readonly<SliderProps>) {
  const {
    items = [],
    slideDuration = 6000, // from videocenter overview

    // from parent components
    sliderContext = 'default',
    sliderType = 'small',
  } = props;

  const sliderWrapper = useRef(null);
  const id = useUniqueId();
  const isContextDefaultTypeSmall = sliderContext === 'default' && sliderType === 'small';

  const sliderRef = useRef<SlickSlider | null>(null);
  const sliderContainer = useRef<HTMLDivElement | null>(null);
  const [hasBottomPadding, setHasBottomPadding] = useState(false);
  const track = useTracking();
  const { trackClick } = useTracking2();
  const [activePlayer, setActivePlayer] = useState<Mi24Player | null>(null);
  const isHeroElement = sliderContext !== 'default';

  const { ref: refVideoPlayer, inView } = useInView({
    threshold: 0,
  });

  const handleResize = useCallback(
    _throttle(() => {
      const playerNode: HTMLElement | null = document.querySelector('.mi-content');

      if (playerNode) {
        const videoContentWidth = playerNode.offsetWidth;

        Array.from(document.querySelectorAll('.mi-controls')).forEach((element: HTMLElement) => {
          element.style.width = `${videoContentWidth}px`;
        });
      }
    }, 50),
    [],
  );

  useEffect(() => {
    setHasBottomPadding(isContextDefaultTypeSmall);

    if (sliderWrapper.current) {
      const el = sliderWrapper.current as HTMLDivElement;
      const nextSibling = (el.parentNode as HTMLDivElement).nextElementSibling;
      const sectionEl = nextSibling?.querySelector('section');

      if (sectionEl) {
        const homepageTiles = sectionEl.querySelector('.c-tiles-homepage');
        setHasBottomPadding(!homepageTiles);
      }
    }
  }, [sliderWrapper, isContextDefaultTypeSmall]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (sliderRef.current) {
      setSliderTextColor(items[0].textColor);
    }
  }, [items]);

  const setSliderTextColor = (textColor) => {
    if (textColor === 'white') {
      sliderContainer.current?.classList.add('slider-container--color-white');
    } else {
      sliderContainer.current?.classList.remove('slider-container--color-white');
    }
  };

  const handlePlay = (player: Mi24Player) => {
    setActivePlayer(player);
    handleResize();

    if (slideDuration > 0 && sliderRef.current) {
      sliderRef.current.slickPause();
    }
  };

  const handleStop = () => {
    if (slideDuration > 0 && sliderRef.current) {
      sliderRef.current.slickPlay();
    }
  };

  const handleCTAButtonClick = (text?: string) => {
    track.trackEvent(sliderClick(text ?? ''));
    trackClick({
      click_intent: 'cta',
      click_element: 'hero_slider_button',
      click_text: text ?? '',
    });
  };

  const renderSlides = () => {
    return items.map((slide, index) => {
      if (slide.video?.videoId && sliderType !== 'hero-tile') {
        return (
          <SlideVideo
            key={`${key(slide)}-video`}
            slide={slide}
            onPlay={handlePlay}
            onPause={handleStop}
            sliderOnClickCTA={handleCTAButtonClick}
          />
        );
      }

      return (
        <SlideImage
          key={`${key(slide)}-image`}
          slide={slide}
          index={index}
          type={sliderType}
          context={sliderContext}
          sliderOnClickCTA={handleCTAButtonClick}
        />
      );
    });
  };

  const renderSlider = () => (
    <InlineEdit previewId={sliderContext === 'default' ? '#st_heroSlider' : undefined}>
      <SlickSlider
        ref={sliderRef}
        lazyLoad="progressive"
        dots
        arrows={sliderType !== 'hero-tile'}
        beforeChange={(currentSlide, nextSlide) => {
          activePlayer?.pause();

          // should not be triggered on language change because nextSlide is not the right one
          if (sliderContainer.current) {
            const nextSlideItem = items[nextSlide];
            setSliderTextColor(nextSlideItem.textColor);
          }
        }}
        responsive={[
          {
            breakpoint: 992, // Breakpoint "large"
            settings: {
              arrows: false,
            },
          },
        ]}
        autoplay={slideDuration > 0 ? true : undefined}
        autoplaySpeed={slideDuration > 0 ? slideDuration : undefined}
      >
        {renderSlides()}
      </SlickSlider>
    </InlineEdit>
  );

  const renderSingleSlide = () => (
    <div className="slick-initialized slick-slider">
      <div className="slick-list">
        <div className="slick-track">
          <div className="slick-slide slick-active">
            <div data-preview-id={sliderContext === 'default' ? '#st_heroSlider' : undefined}>
              {renderSlides()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (isEmpty(items)) {
    return null;
  }

  const bgVideoSlide = getBgVideoSlide(items);

  return (
    <SliderWrapper hasBottomPadding={hasBottomPadding} ref={sliderWrapper}>
      {bgVideoSlide?.video ? (
        <SliderVideoPlayerContainer ref={refVideoPlayer} isHeroElement={isHeroElement}>
          <VideoPlayer
            isHeroElement={isHeroElement}
            playerType={VIDEO_PLAYER_TYPE.BACKGROUND}
            video={bgVideoSlide.video}
            inView={inView}
            loopType={bgVideoSlide.loopType}
            heading={bgVideoSlide.headline}
            subheading={bgVideoSlide.subheadline}
            button={bgVideoSlide.link}
            color={bgVideoSlide?.textColor ?? 'black'}
            fallbackImg={bgVideoSlide.image}
            title={bgVideoSlide.pictureAlt}
            cssID={'player-container' + '-' + id}
            hasPreviewId={false}
          />
        </SliderVideoPlayerContainer>
      ) : (
        <div
          className={classNameBuilder(
            `slider-container slider-container--${sliderType}`,
            items[0]?.textColor === 'white' && ' slider-container--color-white',
          )}
          ref={sliderContainer}
        >
          {items.length === 1 && renderSingleSlide()}
          {items.length > 1 && renderSlider()}
        </div>
      )}
    </SliderWrapper>
  );
}

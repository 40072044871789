// components
import { Image } from 'components/image/image';
import { Translation } from 'components/Translation/Translation';
import { CmsLink } from 'components/Link/CmsLink';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

// utils
import { getImageAltSuffix, imageAlt, imageAltSubcategory } from 'utils/imageAltTag';
import { isEmpty } from 'utils/is-empty';
import { useTracking as useTracking2 } from 'utils/tracking/track';

interface SingleTileViewProps {
  productLink?: string;
  categoryName?: string;
  isCategoryPage?: boolean;
  currentImage?: string;
  product: Record<string, any>;
  filterProductThumbnails?: (imageContent: string, description: string) => JSX.Element[];
  imageAltPrefix?: string;
  imageAltSuffix?: string;
  total?: number;
  listingPage?: boolean;
  tracking?: (name: string) => void;
  isWebshop?: boolean;
  okvType?: string;
  hideCount?: boolean;
  linkType?: string;
}

export function SingleTileView({
  isCategoryPage = false,
  currentImage,
  filterProductThumbnails,
  product,
  imageAltPrefix = 'Image of',
  imageAltSuffix = '',
  total,
  categoryName,
  listingPage = false,
  productLink,
  tracking,
  isWebshop = false,
  linkType,
}: Readonly<SingleTileViewProps>) {
  const {
    name,
    state,
    description,
    numberArticles,
    articles,
    pictureObject,
    title,
    subtitle,
    categories,
  } = product;

  const { trackClick } = useTracking2();

  const articleName = isCategoryPage ? categoryName : name;
  const imageCategory = isCategoryPage ? '' : imageAltSubcategory(categories);
  const imageAltCategory = !isEmpty(imageCategory) ? ` - ${imageCategory}` : '';
  const imagePrefix = imageAlt(imageAltPrefix);
  const imageSuffix = getImageAltSuffix(imageAltSuffix);

  const imageContent = `${imagePrefix}${articleName}${imageAltCategory}${imageSuffix}`;

  let articlesNumber;
  if ('articles' in product) articlesNumber = articles.articlesListSize;
  else if ('numberArticles' in product) articlesNumber = numberArticles;
  else articlesNumber = 0;

  const listingPageClass = listingPage ? ' c-product-tile--listing' : '';

  const link = {
    target: productLink,
    type: isWebshop ? 'internal_link' : 'external_link',
    window: !isWebshop && '_blank',
  };

  const TitleTag = linkType === 'product_detail_page' ? 'p' : 'h3';

  function linkClickHandler(href?: string) {
    tracking?.(name || title);

    if (href) {
      trackClick({
        click_intent: 'catalog',
        click_element: 'link',
        click_text: name || title,
      });
    }
  }

  return (
    <div className={`c-product-tile${listingPageClass}`}>
      <CmsLink link={link} onClick={() => linkClickHandler(link.target)}>
        <div className="c-product-tile__image">
          <Image
            format={pictureObject ? pictureObject.format : '1_1'}
            src={currentImage || pictureObject.url}
            alt={imageContent}
            title={imageContent}
          />
        </div>

        <TitleTag className="c-product-tile__title">{name || title}</TitleTag>
        {subtitle && (
          <h4 className="c-product-tile__subtitle">
            <InlineEdit previewId="#st_subtitle">{subtitle}</InlineEdit>
          </h4>
        )}
        {!isCategoryPage && state && state.indicator && (
          <div className="c-product-tile__label">
            <Translation id="web20_new_label" />
          </div>
        )}
        {!isCategoryPage && (
          <div className="c-product-tile__thumbnails">
            {filterProductThumbnails && filterProductThumbnails(imageContent, description)}
          </div>
        )}
        {isCategoryPage && (!isEmpty(total) || (total ?? 0) > 0) && (
          <div className="c-product-tile__articles">
            {total}
            &nbsp;
            <Translation id="web20_categories_products" />
          </div>
        )}
        {!isCategoryPage && (
          <div className="c-product-tile__articles">
            {articlesNumber}
            &nbsp;
            <Translation id="web20_products_articles" />
          </div>
        )}
      </CmsLink>
    </div>
  );
}

import styled from 'styled-components';
import { pxToRem } from '../../../utils/pxToRem';
import { styleConfig } from '../../../config';

export const SearchLoadMoreWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > button {
    min-width: 16rem;

    @media (max-width: ${pxToRem(styleConfig.tabletMin)}) {
      min-width: 100%;
    }
  }

  > span {
    margin-top: 1rem;
    color: ${({ theme }) => theme.colors.cl12};
  }
`;

interface SearchTabProps {
  active: boolean;
  handleClick: (tabId: number) => void;
  index: number | null;
  children: React.ReactNode;
}
function SearchTab({
  active = false,
  handleClick,
  index = null,
  children,
}: Readonly<SearchTabProps>) {
  return (
    <div
      role="tab"
      className={`RRT__tab tab-nav__tabs RRT__tab--first ${active && 'RRT__tab--selected'}`}
      id={`search-tab-${index}`}
      tabIndex={index}
      aria-selected={active}
      aria-expanded={active}
      aria-disabled="false"
      aria-controls={`search-panel-${index}`}
      onClick={() => handleClick(index)}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && handleClick) {
          handleClick(index);
        }
      }}
    >
      <span>{children}</span>
    </div>
  );
}

export default SearchTab;

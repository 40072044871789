import { Dropdown } from '@geberit/gdds';

// types
import type { FilterNordProps } from './filter.types';

// utils
import { useTranslationByKey } from 'utils/hooks/use-translations';

function FilterNord({
  activeItems,
  filterKey = 'invalid or empty string',
  options,
  handleFilterChange,
}: Readonly<FilterNordProps>) {
  const filterTranslation = useTranslationByKey(`web20_downloadcenter_${filterKey}`);

  return (
    <Dropdown
      name={filterKey}
      placeholder={filterTranslation}
      items={options.map((p) => ({
        ...p,
        id: p.value,
        text: p.value,
      }))}
      label={filterTranslation}
      onChange={(option) => handleFilterChange(filterKey, option)}
      outlined
      values={{ [filterKey]: activeItems }}
      multiple
      forceCustom
    />
  );
}

export default FilterNord;

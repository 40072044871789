import styled, { css } from 'styled-components';

// types
import type { TextColor } from 'components/ContentElementsGdds/hero-image/hero-image-gdds.types';

export const StyledHeroImageVideoPlayer = styled.div<{
  color: TextColor;
}>((props) => {
  const { color, theme } = props;

  const mobileNavHeightOffset = 60;
  const desktopNavHeightOffset = 98;

  return css`
    width: ${theme.sizes.full};
    height: calc(${theme.sizes.screenHeight} - ${mobileNavHeightOffset}px);
    overflow: hidden;

    background-color: ${() => (color === 'white' ? 'var(--white-80)' : 'var(--black-70)')};

    @media ${() => theme.device.medium} {
      width: ${theme.sizes.full};
      min-height: calc(${theme.sizes.screenHeight} - ${mobileNavHeightOffset}px);
    }

    @media ${() => theme.device.large} {
      width: ${theme.sizes.full};
      min-height: calc(${theme.sizes.screenHeight} - ${desktopNavHeightOffset}px);
    }
  `;
});

import { useEffect, useRef, useState } from 'react';

// types
import type { HeroImageHeadlineReplacerProps } from './hero-image-headline-replacer.types';

// utils
import { replaceHeadingsWithTemplateStrings } from './hero-image-headline-replacer.utils';

export function HeroImageHeadlineReplacer(props: Readonly<HeroImageHeadlineReplacerProps>) {
  const { children, heroImageType, items } = props;
  const ref = useRef<HTMLHeadingElement>(null);

  const [replacementDone, setReplacementDone] = useState<boolean>(false);

  useEffect(() => {
    const isReplacementDone = replaceHeadingsWithTemplateStrings({ heroImageType, ref, items });
    setReplacementDone(!!isReplacementDone);
  }, [heroImageType, items]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!replacementDone) {
      const isReplacementDone = replaceHeadingsWithTemplateStrings({
        heroImageType,
        ref,
        items,
      });
      setReplacementDone(!!isReplacementDone);
    }
  });

  return (
    <div className="hero-image-headline-replacer" ref={ref}>
      {children}
    </div>
  );
}

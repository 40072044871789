import { decode } from 'html-entities';
import sanitize from 'xss';
import { Typo } from '@geberit/gdds';

// styles
import styles from './softcontent-item.module.scss';

// utils
import { decodingContent } from '../../../utils/decodingContent';
import { truncateMiddle } from '../../../utils/truncate';
import { InnerHtml } from '../../InnerHtml/inner-html';

type Props = {
  url: string;
  title?: string;
  snippet?: string;
};

export default function SoftContentItem({ url, title = '', snippet = '' }: Readonly<Props>) {
  let snippetText = sanitize(snippet);
  if (!String(snippet).endsWith('.') && snippet.length > 0) {
    snippetText += '...';
  }

  return (
    <a href={url} className={styles.softContentItem}>
      <Typo as="h4" variant="h7">
        {decodingContent(decode(title))}
      </Typo>
      <Typo as="span" variant="p1">
        {truncateMiddle(sanitize(url), 58, '...')}
      </Typo>
      <Typo as="div" variant="p1">
        <InnerHtml content={snippetText} as="p" isGdds />
      </Typo>
    </a>
  );
}

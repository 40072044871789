import { useSelector } from 'react-redux';

// styles
import styles from './summaryContainer.module.scss';

// types
import { CheckoutState } from '../../reducer.types';

// components
import { Translation } from 'components/Translation/Translation';
import { BUTTON_BACKGROUND, BUTTON_COLORS, BUTTON_TYPES, Button } from 'components/Button/Button';
import AddressBox from '../../Components/AddressBox/AddressBox';
import OrderDetailsBox from '../../Components/OrderDetailsBox/OrderDetailsBox';
import CartTable from '../../Components/CartTable/CartTable';

// utils
import { transformPrice, mapSapAndCiamCodesToSalutations } from '../../util/helperFunctions';
import {
  webshopLandingpageUrlSelector,
  webshopShippingInfoSelector,
} from 'utils/selectors/globalsSelectors';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';

interface SummaryDataProps {
  orderId: string;
  orderData: CheckoutState['orderData'] | null;
}

export function SummaryData({ orderId, orderData }: Readonly<SummaryDataProps>) {
  const translate = useTranslationFunction();
  const webshopHomeUrl = useSelector(webshopLandingpageUrlSelector);
  const showShippingInfo = useSelector(webshopShippingInfoSelector);

  const salutationTranslationKeyInvoiceAddress =
    orderData && !isEmpty(orderData.invoiceAddress) && orderData.invoiceAddress.salutation
      ? mapSapAndCiamCodesToSalutations(orderData.invoiceAddress.salutation)
      : '';
  const salutationTranslationKeyDeliveryAddress =
    orderData && !isEmpty(orderData.deliveryAddress) && orderData.deliveryAddress.salutation
      ? mapSapAndCiamCodesToSalutations(orderData.deliveryAddress.salutation)
      : '';

  if (isEmpty(orderData)) {
    return null;
  }

  return (
    <>
      <div className="grid-x">
        <div className="cell small-12 medium-4 large-4">
          <OrderDetailsBox
            orderData={orderData.orderDetails}
            orderId={orderId}
            title={translate('web20_checkout_summary_order_heading')}
          />
        </div>
        <div className="cell small-12 medium-4 large-4">
          <AddressBox
            country={translate(`countries_${orderData.invoiceAddress.country.toLowerCase()}`)}
            salutation={translate(salutationTranslationKeyInvoiceAddress)}
            address={{
              ...orderData.invoiceAddress,
              sapCustomerNumber: orderData.sapCustomerNumber,
            }}
            title={translate('web20_checkout_address_invoice_form_heading')}
          />
        </div>
        <div className="cell small-12 medium-4 large-4">
          <AddressBox
            country={translate(`countries_${orderData.deliveryAddress.country.toLowerCase()}`)}
            salutation={translate(salutationTranslationKeyDeliveryAddress)}
            address={orderData.deliveryAddress}
            title={translate('web20_checkout_address_delivery_form_heading')}
            isDelivery
          />
        </div>
      </div>
      <h4 className="h4">
        <Translation id="web20_checkout_summary_basket_table_heading" />
        {/* Order Contents */}
      </h4>
      <div className={`grid-x grid-margin-x ${styles.bottomMargin}`}>
        <div className="cell large-8 medium-12 small-12">
          <CartTable data={orderData?.lineItemsList ?? []} />
        </div>
        <div className={`cell large-4 medium-12 small-12 grid-y ${styles.cartFooter}`}>
          <div className={`cell grid-x ${styles.cartFooterText}`}>
            <div className={`cell auto ${styles.textAlignRight}`}>
              <Translation
                id="web20_checkout_overview_basket_footer_total_price"
                placeholder={{
                  number: orderData?.lineItemsList ? orderData.quantityAllItems : 'all',
                }}
              />
              {/* Total price of ${cart.lineItemList ? cart.lineItemList.length : 'all'} articles exl.
              VAT: */}
            </div>
            <div className={`cell large-4 medium-3 small-4 ${styles.textAlignRight}`}>
              {transformPrice(orderData.subtotalPriceVatExcluding)}{' '}
              <Translation id="web20_checkout_country_default_currency" />
              {/* 68.42 € */}
            </div>
          </div>
          <div className={`cell grid-x ${styles.cartFooterText}`}>
            <div className={`cell auto ${styles.textAlignRight}`}>
              <div>
                <Translation id="web20_checkout_overview_basket_footer_postage" />
              </div>
              {/* Postage and packaging incl. VAT: */}
              {showShippingInfo && (
                <div>
                  <Translation id="web20_webshop_shipping_info" />
                </div>
              )}
              {/* Shipping information - Free shipping for orders exceeding 100 CHF */}
            </div>
            <div className={`cell large-4 medium-3 small-4 ${styles.textAlignRight}`}>
              {transformPrice(orderData.postalCostVatIncluding)}{' '}
              <Translation id="web20_checkout_country_default_currency" />
              {/* 7.00 € */}
            </div>
          </div>
          <div className={`cell grid-x ${styles.cartFooterText}`}>
            <div className={`cell auto ${styles.textAlignRight}`}>
              <Translation
                id="web20_checkout_overview_basket_footer_vat"
                placeholder={{
                  percents: translate('web20_checkout_country_based_vat_percentige_amount'),
                }}
              />
              {/* 19% VAT: */}
            </div>
            <div className={`cell large-4 medium-3 small-4 ${styles.textAlignRight}`}>
              {transformPrice(orderData.vatAmount)}{' '}
              <Translation id="web20_checkout_country_default_currency" />
              {/* 14.13 € */}
            </div>
          </div>
          {transformPrice(orderData.roundingDifference) !== '0.00' && (
            <div className={`cell grid-x ${styles.cartFooterText}`}>
              <div className={`cell auto ${styles.textAlignRight}`}>
                <Translation id="web20_checkout_overview_basket_footer_rounding_discount" />:
                {/* Rounding discount: */}
              </div>
              <div className={`cell large-4 medium-3 small-4 ${styles.textAlignRight}`}>
                {transformPrice(orderData.roundingDifference)}{' '}
                <Translation id="web20_checkout_country_default_currency" />
              </div>
            </div>
          )}
          <div className={`cell grid-x ${styles.cartFooterText}`}>
            <div className="cell">
              <hr className={styles.cartOverviewDevider} />
            </div>
          </div>
          <div className="cell grid-x">
            <div className={`cell auto ${styles.textAlignRight} bold`}>
              <Translation id="web20_checkout_address_basket_table_headings_price" />:
              {/* Price (incl. VAT): */}
            </div>
            <div className={`cell large-4 medium-3 small-4 ${styles.textAlignRight} bold`}>
              {transformPrice(orderData.totalPriceVatIncluding)}{' '}
              <Translation id="web20_checkout_country_default_currency" />
              {/* 81.50 € */}
            </div>
          </div>
        </div>
      </div>
      <div className="grid-x grid-margin-x">
        <div className="cell auto"></div>
        <div className={`cell shrink ${styles.bottomMargin}`}>
          <Button
            type={BUTTON_TYPES.DEFAULT}
            symbol="arrow-link-light-right"
            color={BUTTON_COLORS.WHITE}
            background={BUTTON_BACKGROUND.PRIMARY}
            onClick={() => {
              window.location.href = webshopHomeUrl ?? '';
            }}
          >
            {translate('web20_checkout_summary_button_next')}
          </Button>
        </div>
      </div>
    </>
  );
}

import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container } from '@geberit/gdds';

// styles
import styles from './press-portal.module.scss';

// types
import type { MediaType, Pressroom, Media, Material } from './types/mynewsdesk.types';
import { Tabs } from './types/press-portal.types';

// components
import PressTiles from './press-tiles/press-tiles';
import { FilterSearchBar } from './filtersearch-bar/filter-search-bar';
import { NavigationTabs } from './navigation-tabs/navigation-tabs';
import { GDDSLoadmoreButton } from './loadmore-button/gdds-loadmore-button';
import { NoResults } from './no-result/no-result';
import MediaTiles from './media-tiles/media-tiles';
import ContactTiles from './contact-tiles/contact-tiles';

// utils
import { buildSize, gridSizes } from 'utils/gridSize';
import { useFetchMaterials } from './hooks/use-fetch-materials';
import { useFetchPressroomInfo } from './hooks/usefetchPressroom';
import { useIsoLang } from 'scenes/MetaData';
import { MATERIAL_TYPES } from './constants';
import { monochromeLogoSelector } from 'utils/selectors/globalsSelectors';

const skipFirst = (_: { id: string; value: string; text: string }, i: number): boolean => i > 0;

function PressPortal({ isGdds }: Readonly<{ isGdds: boolean }>): JSX.Element | null {
  const [types, setTypes] = useState<{ mediaType?: MediaType; mediaTypes?: MediaType[] }>();
  const [tags, setTags] = useState<string[] | undefined>([]);
  const [selectedTab, setSelectedTab] = useState<Tabs | undefined>();
  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState<string | undefined>();
  const language = useIsoLang();
  const { pressroom } = useFetchPressroomInfo<Pressroom>({
    locale: language,
  });
  const monochromeLogo = useSelector(monochromeLogoSelector);

  useEffect(() => {
    setTypes({
      mediaType: undefined,
      mediaTypes:
        selectedTab === undefined
          ? undefined
          : MATERIAL_TYPES[selectedTab].filter(skipFirst).map((m) => m.value as MediaType),
    });
  }, [selectedTab]);

  const { materials, loadMore, loadable, loading } = useFetchMaterials({
    mediaType: types?.mediaType,
    mediaTypes: types?.mediaTypes,
    tags,
    search: query,
    limit: (iteration) => (iteration === 0 && selectedTab === Tabs.News ? 13 : 12),
    offset: offset,
    locale: language.toLowerCase(),
  });

  const pressroomTags = useMemo(
    () =>
      pressroom?.tag_cloud.map((tag) => ({
        id: tag.name,
        value: tag.name,
        text: tag.name,
      })),
    [pressroom],
  );

  function getMediaTypeChangehandler() {
    return (option) => {
      if (!Array.isArray(option)) {
        setTypes((types) => ({
          ...types,
          mediaType: option.value === 'all' ? undefined : option.value,
        }));
      }
    };
  }

  function getTagsChangehandler() {
    return (options) => {
      if (Array.isArray(options)) {
        if (options.length === pressroomTags?.length) {
          setTags([]);
        } else if (options.length === 0) {
          setTags(undefined);
        } else {
          setTags(options);
        }
      }
    };
  }

  function getSearchChangehandler() {
    return (term?: string) => {
      setOffset(0);
      setQuery(term);
    };
  }

  function renderMaterials() {
    if (!materials || materials.length === 0) {
      return null;
    }

    if (!query && (!pressroomTags || pressroomTags.length === 0)) {
      return null;
    }

    if (selectedTab === Tabs.News) {
      return (
        <PressTiles
          language={language}
          materials={materials as Material[]}
          fallbackImg={monochromeLogo?.logoRef}
          fallbackBackgroundColor={isGdds ? '#566777' : '#646468'}
        />
      );
    }

    if (selectedTab === Tabs.MediaLibrary) {
      return (
        <MediaTiles
          language={language}
          mediaList={materials as Media[]}
          fallbackImg={monochromeLogo?.logoRef}
          fallbackBackgroundColor={isGdds ? '#566777' : '#646468'}
        />
      );
    }

    return null;
  }

  return (
    <div className={styles.pressPortal}>
      <div className={styles.feedSection}>
        <NavigationTabs onChange={setSelectedTab} />
        <Container maxContentWidth={isGdds ? buildSize(gridSizes.gddsFullGrid) : '80rem'}>
          <FilterSearchBar
            materialType={types?.mediaType}
            query={query}
            getSearchChangehandler={getSearchChangehandler()}
            getTagsChangehandler={getTagsChangehandler()}
            materialTypes={MATERIAL_TYPES[selectedTab || Tabs.News]}
            pressroomTags={pressroomTags}
            getMediaTypeChangehandler={getMediaTypeChangehandler()}
          />
          {renderMaterials() || <NoResults loading={loading} />}
          {loadable && materials?.length && (
            <GDDSLoadmoreButton
              onClick={() => {
                loadMore();
                setOffset((prev) => prev + 1);
              }}
              disabled={loading}
            />
          )}
        </Container>
      </div>
      <Container {...(!isGdds && { maxContentWidth: '80rem' })}>
        {pressroom?.presscontacts && (
          <ContactTiles
            withDivider={false}
            language={language}
            contacts={pressroom?.presscontacts}
          />
        )}
      </Container>
    </div>
  );
}

export default PressPortal;

import React, { useState } from 'react';

function getMovingPosition(e) {
  // If not a touch, determine point from mouse coordinates
  return 'changedTouches' in e
    ? { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY }
    : { x: e.clientX, y: e.clientY };
}

function getPosition(e) {
  // If not a touch, determine point from mouse coordinates
  return 'touches' in e
    ? { x: e.touches[0].clientX, y: e.touches[0].clientY }
    : { x: e.clientX, y: e.clientY };
}

function rotateByAngle(pos, angle) {
  if (angle === 0) {
    return pos;
  }

  const { x, y } = pos;

  const angleInRadians = (Math.PI / 180) * angle;
  const rotatedX = x * Math.cos(angleInRadians) + y * Math.sin(angleInRadians);
  const rotatedY = y * Math.cos(angleInRadians) - x * Math.sin(angleInRadians);
  return { x: rotatedX, y: rotatedY };
}

function calculatePos(e, state) {
  const { x, y } = rotateByAngle(getMovingPosition(e), state.rotationAngle);

  const deltaX = state.x - x;
  const deltaY = state.y - y;

  const absX = Math.abs(deltaX);
  const absY = Math.abs(deltaY);

  const time = Date.now() - state.start;
  const velocity = Math.sqrt(absX * (absX + absY) * absY) / time;

  return {
    deltaX,
    deltaY,
    absX,
    absY,
    velocity,
  };
}

interface SwipeableProps {
  onSwipedLeft: (e: any) => void;
  onSwipedRight: (e: any) => void;
  onSwiping: (
    e: any,
    deltaX: number,
    deltaY: number,
    absX: number,
    absY: number,
    velocity: number,
  ) => void;
  onSwipingLeft: (e: any, absX: number) => void;
  onSwipingRight: (e: any, absX: number) => void;
  preventDefaultTouchmoveEvent: boolean;
  rotationAngle: number;
  nodeName: string;
  disabled: boolean;
}

function Swipeable({
  onSwipingLeft,
  onSwipingRight,
  nodeName = 'div',
  rotationAngle = 0,
  disabled = false,
  preventDefaultTouchmoveEvent = false,
  onSwipedLeft = null,
  onSwipedRight = null,
  onSwiping = null,
  ...newProps
}) {
  const [x, setX] = useState(null);
  const [y, setY] = useState(null);
  const [swiping, setSwiping] = useState(false);
  const [start, setStart] = useState(0);

  const eventStart = (e) => {
    // if more than a single touch don't track, for now...
    if (e.touches && e.touches.length > 1) return;

    const { x: xNew, y: yNew } = rotateByAngle(getPosition(e), rotationAngle);

    setStart(Date.now());
    setX(xNew);
    setY(yNew);
    setSwiping(false);
  };

  const eventMove = (e) => {
    if (!x || !y || (e.touches && e.touches.length > 1)) {
      return;
    }

    const pos = calculatePos(e, { x, y, swiping, start, rotationAngle });

    if (onSwiping) {
      onSwiping(e, pos.deltaX, pos.deltaY, pos.absX, pos.absY, pos.velocity);
    }

    if (pos.absX > pos.absY) {
      if (pos.deltaX > 0) {
        if (onSwipingLeft || onSwipedLeft) {
          if (onSwipingLeft) onSwipingLeft(e, pos.absX);
        }
      } else if (onSwipingRight || onSwipedRight) {
        if (onSwipingRight) onSwipingRight(e, pos.absX);
      }
    }

    setSwiping(true);
  };

  if (!disabled) {
    newProps.onTouchStart = eventStart;

    if (!preventDefaultTouchmoveEvent) {
      newProps.onTouchMove = eventMove;
    }
  }

  return React.createElement(nodeName, newProps);
}

export default Swipeable;

import axios from 'axios';
import { Dispatch } from 'redux';

// Utils
import { DownloadCenterResponse } from './action.types';
import { currentLanguageSelector } from 'utils/selectors/languageSelectors';
import { getEndpoint } from 'utils/endpoints';
import { isEmpty } from 'utils/is-empty';

export function getPreFilter(prefilterTags: string[]): string {
  const mappedPrefilters: { key: string; value: string }[] = [];
  if (!isEmpty(prefilterTags)) {
    prefilterTags.forEach((filterTag) => {
      mappedPrefilters.push({
        key: 'indexer_source',
        value: `downloads.prefiltertags:${filterTag}`,
      });
    });
  } else {
    mappedPrefilters.push({ key: 'indexer_source', value: 'downloads' });
  }

  return mappedPrefilters.map((prefilter) => `${prefilter.key}:${prefilter.value}`).join('|');
}

function downloadCenterDataRequest(language: string) {
  return {
    type: 'downloadCenter/DOWNLOAD_CENTER_DATA_REQUEST_PENDING' as const,
    language,
  };
}

function downloadCenterDataRequestFulfilled(
  language: string,
  response: { data: DownloadCenterResponse },
) {
  return {
    type: 'downloadCenter/DOWNLOAD_CENTER_DATA_REQUEST_FULFILLED' as const,
    language,
    data: response.data,
  };
}

function downloadCenterDataRequestRejected(language: string, error: RequestError) {
  return {
    type: 'downloadCenter/DOWNLOAD_CENTER_DATA_REQUEST_REJECTED' as const,
    language,
    error,
  };
}

function downloadEditingRequestPending() {
  return {
    type: 'downloadCenter/DOWNLOAD_EDITING_REQUEST_PENDING' as const,
  };
}
function downloadEditingRequestFulfilled(
  downloads: {
    previewId: string;
  }[],
) {
  return {
    type: 'downloadCenter/DOWNLOAD_EDITING_REQUEST_FULFILLED' as const,
    downloads,
  };
}

export function removeEditableDownload(previewId: string) {
  return {
    type: 'downloadCenter/DOWNLOAD_EDITING_DELETE' as const,
    previewId,
  };
}

export function fetchEditableDownloads() {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(downloadEditingRequestPending());

    const state = getState();
    const language = currentLanguageSelector(state);
    const endpoint = `${getEndpoint('downloads', state)}?language=${language}&onlyWithfiles=true`;

    axios.get(endpoint).then((response) => {
      dispatch(downloadEditingRequestFulfilled(response.data));
    });
  };
}
export function fetchDownloadZip(downloads, setDownloadProgress, brandName) {
  return async (_: Dispatch, getState: () => AppState) => {
    const state = getState();
    const endpoint = `${getEndpoint('downloads', state)}/zip/`;
    const config = {
      responseType: 'blob' as const,
      onDownloadProgress: ({ loaded, currentTarget }) => {
        if (!navigator.userAgent.search('Firefox')) {
          const percentage = Math.round((loaded / currentTarget.response.size) * 100);
          if (percentage !== 0) {
            setDownloadProgress(percentage);
          }
        }
      },
    };

    // animation should start immadiately
    setDownloadProgress(0.1);

    const response = await axios.post(endpoint, { files: downloads.map((v) => v.url) }, config);
    if (navigator.userAgent.search('Firefox')) {
      setDownloadProgress(100);
    }

    if (typeof window !== 'undefined') {
      const blob = new Blob([response.data], { type: response.data.type });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      const fileName = `${brandName?.toLowerCase()}-downloads.zip`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };
}

export type DownloadCenterAction =
  | ReturnType<typeof downloadCenterDataRequest>
  | ReturnType<typeof downloadCenterDataRequestFulfilled>
  | ReturnType<typeof downloadCenterDataRequestRejected>
  | ReturnType<typeof downloadEditingRequestPending>
  | ReturnType<typeof downloadEditingRequestFulfilled>
  | ReturnType<typeof removeEditableDownload>;

import key from 'weak-key';

// components
import { BUTTON_TYPES, Button } from '../Button/Button';

// utils
import partialBind from '../../utils/partialBind';

interface PaginationButtonsProps {
  index: number;
  length: number;
  handleClickIndex: React.MouseEventHandler<HTMLButtonElement>;
  withPagination?: boolean;
  isComparison?: boolean;
}

export default function PaginationButtons({
  index,
  length,
  isComparison = false,
  withPagination = false,
  handleClickIndex,
  ...props
}: Readonly<PaginationButtonsProps>) {
  const dots: number[] = [];
  const type = withPagination ? 'number' : 'dot';

  for (let i = 0; i < length; i += 1) {
    // only render a button if its the first / last / current or one above/ under the current
    if (
      isComparison ||
      i === 0 ||
      i === length - 1 ||
      i === index ||
      i === index + 1 ||
      i === index - 1
    ) {
      dots.push(i);
    } else if (i > 0 && dots[dots.length - 1] !== -1) {
      // set a -1 for span placeholder
      dots.push(-1);
    }
  }

  return (
    <div className="c-progress-bar">
      {dots.map((number) => {
        if (number < 0) {
          return (
            <span className="c-progress-bar__placeholder" key={key({ number })}>
              ...
            </span>
          );
        }
        return (
          <Button
            onClick={number !== index ? partialBind(handleClickIndex, this, number) : null}
            key={number === index ? `${number}-active` : `${number}-inactive`}
            className={`c-progress-bar__${type} ${number === index ? 'active' : ''}`}
            type={BUTTON_TYPES.SEAMLESS}
            {...props}
          >
            {type === 'number' ? number + 1 : null}
          </Button>
        );
      })}
    </div>
  );
}

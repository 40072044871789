import React from 'react';
import key from 'weak-key';
import _isEmpty from 'lodash.isempty';
import _sortBy from 'lodash.sortby';

// Components
import Filter from './Filter';
import { Translation } from '../Translation/Translation';

interface FilterAreaProps {
  data: {
    aggregations: any;
  };
  handleFilterChange: any;
  activeFilters:
    | {
        filter1: string[];
        filter2: string[];
        filter3: string[];
        filter4: string[];
      }
    | {};
}

function FilterArea({
  data: { aggregations },
  handleFilterChange,
  activeFilters = {},
}: Readonly<FilterAreaProps>) {
  return (
    <section className="c-filter-area grid-container">
      <form onSubmit={handleFilterChange} id="form1" key={key(activeFilters)}>
        <div className="c-filter-area__filter grid-x grid-margin-x">
          <div className="cell medium-12">
            <h3 className="h4">
              <Translation id="web20_downloadcenter_filter_documents" />
            </h3>
          </div>
          {aggregations &&
            Object.keys(aggregations).map((filterKey) => (
              <div className="cell small-12 medium-6 large-3" key={key(aggregations[filterKey])}>
                <Filter
                  isCheckbox
                  filterKey={filterKey}
                  options={_sortBy(aggregations[filterKey], [(o) => o.value.toLowerCase()])}
                  handleFilterChange={handleFilterChange}
                  activeItems={
                    !_isEmpty(activeFilters[filterKey]) ? activeFilters[filterKey] : null
                  }
                />
              </div>
            ))}
        </div>
      </form>
    </section>
  );
}

export default FilterArea;

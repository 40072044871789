'use client';

// styles
import styles from './nordics.module.scss';

// types
import type { DownloadCenterContainerProps } from '../components/nordics/types';
import type { Data } from 'components/Filter/SumAndSortArea';

// components
import DownloadCenterContainer from '../components/nordics/downloadcenter-container';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Layout from 'scenes/Nordics/layout';

export interface DownloadCenterNordicsProps extends DownloadCenterContainerProps {
  metaData: MetaData;
  breadcrumbs: Content['breadcrumbs'];
  initialData: { pages: Data[] };
}

export default function DownloadCenterNordics({
  breadcrumbs,
  page,
  contentAreas,
  initialData,
}: Readonly<DownloadCenterNordicsProps>) {
  return (
    <Layout>
      <Breadcrumb items={breadcrumbs} className={styles.breadcrumbs} />
      <DownloadCenterContainer page={page} contentAreas={contentAreas} initialData={initialData} />
    </Layout>
  );
}

import type { HeroTileItemType, SliderItemType } from './home.types';

// utils
import { exhaustiveGuard } from 'utils/exhaustiveGuard';

export function getHeroImageItems({
  items,
}: {
  items: SliderItemType[] | HeroTileItemType[];
}): HeroImageItems[] {
  if (!items || items.length === 0) return [];

  const itemType = items[0].type;

  switch (itemType) {
    case 'slider_item':
      return items.map((item) => ({
        ...item,
        colorVariant: item.textColor,
        emphasize: undefined,
        // heroType vase fwvideo not defined
        isInSubscription: null,
        textbox: {
          color: item.textColor,
          headline: item.headline,
          link: item.link,
          position: item.position,
          subline: item.subheadline || '',
        },
        type: 'hero_a_b_item',
        imageAlt: item.pictureAlt,
      }));
    case 'hero_tile_item':
      return items.map((item) => ({
        ...item,
        emphasize: undefined,
        heroType: 'picture',
        isInSubscription: null,
        textbox: {
          color: item.textColor,
          headline: item.headline,
          link: item.link,
          position: item.position,
          subline: item.subheadline || '',
        },
        colorVariant: item.textColor,
        type: 'hero_a_b_item',
        imageAlt: item.pictureAlt,
      }));
    default:
      return exhaustiveGuard(itemType);
  }
}

import { useState, useCallback } from 'react';
import { Link, Icon } from '@geberit/gdds';

// styles
import styles from '../dlc-nordics.module.scss';

// types
import type { CartNordicsProps } from './cart.types';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { Lightbox } from './lightbox-nordics';

// utils
import { clickZipCart } from 'features/download-center/tracking';
import { useTracking } from 'utils/hooks/useTracking';
import { isEmpty } from 'utils/is-empty';
import { useTracking as useTracking2 } from 'utils/tracking/track';

export function CartNordics(props: Readonly<CartNordicsProps>) {
  const {
    updateDownload,
    updateOrder,
    updateAmount,
    downloadZip,
    translations = {},
    getSessionStorage,
    setDownloadProgress,
    downloads = [],
    downloadForm,
    orders = [],
  } = props;

  const track = useTracking();
  const { trackClick } = useTracking2();
  const [openDownloads, setOpenDownloads] = useState(false);
  const [openOrders, setOpenOrders] = useState(false);
  const [finished, setFinished] = useState(false);
  const totalOrder = orders.length;
  const totalDownload = downloads.length;
  const [form] = downloadForm ?? [];

  const handleDownloadChange = useCallback(
    (product) => {
      updateDownload(product);

      // close on last download removed
      if (downloads.find((download) => download.id === product.id) && downloads.length === 1) {
        setOpenDownloads(false);
      }
    },
    [downloads],
  );

  const handleOpenDownloads = useCallback(() => {
    if (downloads.length > 0) {
      setOpenDownloads(true);
    }
  }, [downloads]);

  const handleOpenOrders = useCallback(() => {
    trackClick({ click_intent: 'download-center_order', click_element: 'download_center' });
    if (orders.length > 0) {
      setOpenOrders(true);
      setFinished(false);
    }
  }, [orders]);

  const handleClose = useCallback(() => {
    setOpenDownloads(false);
    setOpenOrders(false);
    setDownloadProgress(0);
  }, []);

  const handleDownloadZip = useCallback(() => {
    const downloadTitles = downloads.map((download) => download.title).join(', ');
    // needs to be disabled because linter thinks useTracking is a real hook.
    track.trackEvent(clickZipCart(downloadTitles));
    trackClick({
      click_intent: 'download-center_download_basket',
      click_element: 'download_center',
    });
    downloadZip(downloads);
  }, [downloads]);

  const handleFinished = useCallback(() => {
    setFinished(true);
  }, []);

  return (
    <>
      <div className={styles.cart} suppressHydrationWarning>
        <InnerHtml
          as="span"
          content={translations.web20_downloadcenter_cart}
          className={styles.shoppingBasket}
        />
        <Link onClick={handleOpenDownloads} stylingType="inverted">
          <>
            <Icon symbol="download" />
            <span suppressHydrationWarning>
              {translations.web20_downloadcenter_cartdownload_as_zip} ({totalDownload})
            </span>
          </>
        </Link>
        {!isEmpty(downloadForm) && (
          <Link onClick={handleOpenOrders} stylingType="inverted">
            <>
              <Icon symbol="onlineshop" />
              <span suppressHydrationWarning>
                {translations.web20_downloadcenter_order} ({totalOrder})
              </span>
            </>
          </Link>
        )}
      </div>
      {(openDownloads || openOrders) && (
        <Lightbox
          isFinished={finished}
          openDownloads={openDownloads}
          openOrders={openOrders}
          downloads={downloads}
          handleClose={handleClose}
          handleDownloadChange={handleDownloadChange}
          handleDownloadZip={handleDownloadZip}
          orders={orders}
          updateOrder={updateOrder}
          updateAmount={updateAmount}
          form={form}
          getSessionStorage={getSessionStorage}
          handleFinished={handleFinished}
        ></Lightbox>
      )}
    </>
  );
}

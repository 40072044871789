import isEmpty from 'lodash.isempty';
import { validate as validateEmail } from 'email-validator';

export const VALIDATION_FIELD_RULES = {
  mandatory: [
    'participate',
    'lastname',
    'firstname',
    'email',
    'salutation',
    'role',
    'catering',
    'needhotel',
  ],
};

export function fieldHasError(name, index, errors, touched) {
  if (!touched) return undefined;

  if (
    index &&
    errors.participants &&
    (isEmpty(touched.participants) || !Array.isArray(touched.participants)) &&
    Array.isArray(errors.participants)
  ) {
    return (
      touched[name] &&
      errors.participants[index - 1] &&
      errors.participants[index - 1][name] &&
      errors.participants[index - 1]
    );
  }

  if (
    !isEmpty(touched.participants) &&
    Array.isArray(touched.participants) &&
    index &&
    errors.participants &&
    Array.isArray(errors.participants)
  ) {
    return (
      touched.participants[index - 1] &&
      touched.participants[index - 1][name] &&
      errors.participants[index - 1][name] &&
      errors.participants[index - 1]
    );
  }

  return touched[name] && errors[name] && errors;
}

const checkMandatoryFields = (errors, ciamUser, isMandatory) => {
  Object.keys(ciamUser)
    .filter((k) => !['key'].includes(k) && isMandatory(k))
    .forEach((key) => {
      if (isEmpty(ciamUser[key])) {
        errors[key] = { [key]: 'isRequired' };
      }
    });

  if (ciamUser.role && ciamUser.role === '0') {
    errors.role = { role: 'isRequired' };
  }

  if (!validateEmail(ciamUser.email)) {
    errors.email = { email: 'invalid' };
  }
};

function checkParticipantError(isMandatory, acc, cur, i) {
  const currentParticipantErrors = Object.keys(cur).reduce((accPart, curPart) => {
    const fieldName = curPart.substring(0, curPart.length - 1);
    if (isMandatory(fieldName) && isEmpty(cur[curPart])) {
      accPart[curPart] = { [curPart]: 'isRequired' };
    }
    if (fieldName === 'role' && cur[curPart] === '0') {
      accPart[curPart] = { [curPart]: 'isRequired' };
    }
    if (fieldName === 'email' && !validateEmail(cur[curPart])) {
      accPart[curPart] = { [curPart]: 'invalid' };
    }
    return accPart;
  }, {});

  if (!isEmpty(currentParticipantErrors)) {
    if (!acc.participants) acc.participants = [];
    acc.participants[i] = { ...currentParticipantErrors };
  }
  return acc;
}

export const validate =
  (removedCiamUser, isMandatory: (key: string) => boolean) =>
  ({ participants, ...ciamUser }) => {
    const errors: Record<string, Record<string, string>> = {};

    if (ciamUser.participate === '' && !removedCiamUser) {
      errors.participate = { participate: 'isRequired' };
    }

    if (ciamUser.termsAndConditions === false) {
      errors.termsAndConditions = { termsAndConditions: 'isRequired' };
    }

    if (ciamUser.participate === 'yes') {
      checkMandatoryFields(errors, ciamUser, isMandatory);
    }

    const participantsErrors = participants.reduce(
      (acc, cur, i) => checkParticipantError(isMandatory, acc, cur, i),
      {},
    );
    return { ...errors, ...participantsErrors };
  };

import { format as dateFormat } from 'date-fns';

export const formatCampusDate = (date: string, localeDateFormat: string) => {
  if (!date) return null;
  const timestamp = Date.parse(date);

  return isNaN(timestamp)
    ? null
    : {
        day: dateFormat(timestamp, localeDateFormat),
        time: dateFormat(timestamp, 'HH:mm'),
      };
};

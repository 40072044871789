// components
import { Translation } from 'components/Translation/Translation';
import { Image } from 'components/image/image';
import SelectPrimaryImage from '../DetailGallery/SelectPrimaryImage';

type ImageThumbnailProps = {
  article: {
    gallery: {
      list: any[];
    };
    state: {
      indicator: boolean;
    };
  };
  imgAlt: string;
};

const ImageThumbnail = ({ article, imgAlt, ...props }: Readonly<ImageThumbnailProps>) => (
  <div className="c-product-thumbnail">
    <SelectPrimaryImage images={article.gallery.list}>
      {(image) => (
        <>
          {image && <Image {...props} src={image.thumbnail} alt={imgAlt} staticImage external />}
          {article.state.indicator && (
            <span>
              <Translation id="web20_new_label" />
            </span>
          )}
        </>
      )}
    </SelectPrimaryImage>
  </div>
);

export default ImageThumbnail;
